import React from 'react'

import { toPx } from '@guiker/components-core'
import { GuikerLogo } from '@guiker/icons'

import { Flex, Link, useLayoutContext } from '../../../'
import { makeStyles } from '../../../styles'
import { Typography } from '../../Data Display/Typography'

export type GuikerLogoWithTextProps = {
  darkMode?: boolean
  subTitle?: string
  subTitleUrl?: string
  isLogoOnly?: boolean
}

const useStyles = makeStyles(
  (theme) => ({
    root: {
      color: theme.palette.text.primary.main,
    },
    logoText: {
      fontFamily: theme.typography.fontFamilies.header.bold,
      fontSize: 27,
      fontWeight: 500,
      marginTop: toPx(6),
      color: theme.palette.text.primary.main,
    },
    subTitle: {
      marginLeft: theme.spacing(1),
      marginTop: toPx(6),
      lineHeight: toPx(21),
      fontSize: 21,
      fontWeight: 200,
      color: theme.palette.text.primary.main,
    },
  }),
  { name: 'GuikerLogoWithText' },
)

const GuikerLogoWithText: React.FC<GuikerLogoWithTextProps> = ({ isLogoOnly = false, subTitle, subTitleUrl }) => {
  const classes = useStyles()
  const { isMobile } = useLayoutContext()

  let subtitleComponent = subTitle && (
    <Typography component='span' className={classes.subTitle}>
      {subTitle}
    </Typography>
  )

  subtitleComponent = subTitleUrl ? (
    <Link to={subTitleUrl} underline={false} hoverUnderline={false}>
      {subtitleComponent}
    </Link>
  ) : (
    subtitleComponent
  )

  return (
    <Flex alignItems='center' flexWrap='nowrap' className={classes.root}>
      <GuikerLogo />
      {!isLogoOnly && !isMobile && (
        <Flex ml={1} alignItems='flex-end'>
          <Typography component='div' className={classes.logoText}>
            Guiker
          </Typography>
          {subtitleComponent}
        </Flex>
      )}
    </Flex>
  )
}

export { GuikerLogoWithText }
