import React from 'react'

import { SvgIcon, SvgIconProps } from '../../../SvgIcon'

const PlusIcon: React.FC<SvgIconProps> = ({ height = 14, width = 14, ...props }) => {
  return (
    <SvgIcon height={height} width={width} viewBox='0 0 14 14' {...props}>
      <path d='M6 2H8V12H6V2Z' fill='currentColor' />
      <path d='M12 6V8L2 8L2 6L12 6Z' fill='currentColor' />
    </SvgIcon>
  )
}

export { PlusIcon }
