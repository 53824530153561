import React from 'react'

import { AnyColor, theme } from '@guiker/components-core'

export interface HeartIconProps {
  color?: AnyColor
}

const HeartIcon: React.FC<HeartIconProps> = ({ color: colorKey }) => {
  const color = theme.palette.getColor(colorKey) || 'currentColor'

  return (
    <svg width='24' height='22' viewBox='0 0 24 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12.0009 20.8441L2.41287 10.8441C1.57021 10.0021 1.01404 8.91615 0.823235 7.74029C0.632434 6.56443 0.816691 5.35834 1.34987 4.29309V4.29309C1.75193 3.48921 2.33924 2.79237 3.06342 2.25998C3.7876 1.72759 4.62791 1.37489 5.51513 1.23094C6.40234 1.08699 7.31107 1.15592 8.16642 1.43204C9.02178 1.70815 9.79928 2.18356 10.4349 2.81909L12.0009 4.38409L13.5669 2.81909C14.2025 2.18356 14.98 1.70815 15.8353 1.43204C16.6907 1.15592 17.5994 1.08699 18.4866 1.23094C19.3738 1.37489 20.2141 1.72759 20.9383 2.25998C21.6625 2.79237 22.2498 3.48921 22.6519 4.29309C23.1843 5.35793 23.3683 6.5633 23.1777 7.73849C22.9871 8.91368 22.4316 9.99912 21.5899 10.8411L12.0009 20.8441Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export { HeartIcon }
