import React from 'react'

import { Dropdown, DropdownProps as CLDropdownProps } from '@guiker/components-library'
import { useTranslation } from '@guiker/i18n'
import { Controller, FieldPath, PathValue, useFormInput } from '@guiker/react-hook-form'

export type DropdownProps<T extends object, P extends FieldPath<T>> = Omit<CLDropdownProps, 'name' | 'defaultValue'> & {
  name: P
  defaultValue?: PathValue<T, P>
}

const RHFDropdown = <T extends object, P extends FieldPath<T>>({
  defaultValue,
  name,
  onChange,
  ref,
  ...props
}: DropdownProps<T, P>) => {
  const { t } = useTranslation()
  const { control, errorMessage } = useFormInput<T, P>({ name, t })

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || null}
      render={({ field: { ref, onChange: onChangeRenderProps, ...renderProps }, fieldState }) => (
        <Dropdown
          name={name}
          onChange={(event) => {
            onChange && onChange(event)
            onChangeRenderProps(event.target.value)
          }}
          error={!!fieldState.error}
          errorMessage={fieldState.error && errorMessage}
          {...renderProps}
          {...props}
        />
      )}
    />
  )
}

export { RHFDropdown }
