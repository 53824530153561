import React from 'react'

import { useT } from '@guiker/i18n'

import { ButtonWithLoader, SecondaryButton } from '../../Inputs'
import { Flex } from '../../Layout'
import { ModalStepProps } from '../StepperModal/StepperModal'

export const StepperModalButtonContainer: React.FC<ModalStepProps> = ({
  backLabel,
  onClickBack,
  onClickNext,
  nextLabel,
  isFirstStep,
  isLoading,
}) => {
  const { tBase } = useT()
  return (
    <Flex gap={2}>
      {!isFirstStep && <SecondaryButton onClick={onClickBack}>{backLabel ?? tBase('actions.back')}</SecondaryButton>}
      <ButtonWithLoader isLoading={isLoading} onClick={() => onClickNext()}>
        {nextLabel ?? tBase('actions.next')}
      </ButtonWithLoader>{' '}
    </Flex>
  )
}
