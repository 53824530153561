import React from 'react'

import { Flex } from '../../Layout'
import * as Typograph from '../Typography'
import { TypographyProps } from '../Typography'

type KeyValuePairProps = {
  KeyComponent?: React.FC<TypographyProps>
  ValueComponent?: React.FC<TypographyProps>
  keyName: string | React.ReactNode
  value: string | React.ReactNode
}

export const KeyValuePair: React.FC<KeyValuePairProps> = ({
  KeyComponent = Typograph.PBold,
  ValueComponent = Typograph.P,
  keyName,
  value,
}) => {
  return (
    <Flex gap={2} justifyContent='space-between'>
      <KeyComponent textAlign='left' mb={0}>
        {keyName}
      </KeyComponent>
      <ValueComponent textAlign='right' mb={0}>
        {value}
      </ValueComponent>
    </Flex>
  )
}
