import React from 'react'

// import '@blocknote/core/style.css'
import { BlockNoteView, useBlockNote } from '@blocknote/react'

export type BlockEditorProps = {
  defaultValue?: string
  onChange?: (content: string) => void
}

export const BlockEditor: React.FC<BlockEditorProps> = ({ defaultValue, onChange }) => {
  const editor = useBlockNote({
    defaultStyles: false,
    onEditorContentChange: (editor) => {
      const _onChange = async () => {
        const markdown = await editor.blocksToHTML(editor.topLevelBlocks)
        onChange?.(markdown)
      }
      _onChange()
    },
    onEditorReady: (editor) => {
      if (!defaultValue) return
      const getBlocks = async () => {
        const blocks = await editor.HTMLToBlocks(defaultValue)
        try {
          editor.replaceBlocks(editor.topLevelBlocks, blocks)
        } catch (e) {}
      }
      getBlocks()
    },
  })

  return <BlockNoteView editor={editor} />
}
