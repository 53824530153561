import * as React from 'react'

import { clsx } from '@guiker/clsx'
import { makeStyles } from '@guiker/components-core'

import { Badge, CircularProgress } from '../../'

export type FloatingBadgeProps = React.PropsWithChildren & {
  badgeNumber?: string | number
  className?: string
  overlap?: boolean
  isLoading?: boolean
}

const useStyles = makeStyles(
  () => ({
    root: {
      display: 'flex',
      position: 'relative',
    },
    badge: ({ overlap }: { overlap: boolean }) => ({
      position: 'absolute',
      right: overlap ? -10 : -16,
      top: overlap ? -10 : -12,
    }),
    hidden: {
      visibility: 'hidden',
    },
  }),
  { name: 'FloatingBadge' },
)

export const FloatingBadge: React.FC<FloatingBadgeProps> = ({
  badgeNumber,
  children,
  className,
  isLoading,
  overlap = true,
  ...props
}) => {
  const classes = useStyles({ overlap })

  return (
    <div className={classes.root} {...props}>
      <Badge className={clsx(classes.badge, { [classes.hidden]: badgeNumber == null })}>
        {isLoading ? <CircularProgress size={12} color='inherit' variant='indeterminate' /> : badgeNumber}
      </Badge>
      {children}
    </div>
  )
}
