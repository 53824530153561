import React from 'react'

import { SvgIconProps, SvgIconSmallViewBox } from '../../../SvgIcon'

export type MinusSmallIconProps = SvgIconProps

const MinusSmallIcon: React.FC<MinusSmallIconProps> = (props) => {
  return (
    <SvgIconSmallViewBox {...props}>
      <path
        d='M9.42857 6.85718H14.8571C15.1602 6.85718 15.4509 6.97759 15.6653 7.19191C15.8796 7.40624 16 7.69693 16 8.00003C16 8.30314 15.8796 8.59383 15.6653 8.80816C15.4509 9.02248 15.1602 9.14289 14.8571 9.14289H9.42857H6.57143H1.14286C0.839753 9.14289 0.549062 9.02248 0.334735 8.80816C0.120408 8.59383 0 8.30314 0 8.00003C0 7.69693 0.120408 7.40624 0.334735 7.19191C0.549062 6.97759 0.839753 6.85718 1.14286 6.85718H6.57143H9.42857Z'
        fill='currentColor'
      />
    </SvgIconSmallViewBox>
  )
}

export { MinusSmallIcon }
