import React from 'react'

import { AnyColor, theme } from '@guiker/components-core'

const ParkBenchIcon: React.FC<{ color?: AnyColor }> = ({ color: colorKey }) => {
  const color = theme.palette.getColor(colorKey)
  return (
    <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8 18.2866H1.25C1.10796 18.2866 0.978114 18.2063 0.914592 18.0793C0.85107 17.9523 0.864778 17.8002 0.950002 17.6866L4.55 12.8866C4.63523 12.773 4.64893 12.6209 4.58541 12.4939C4.52189 12.3668 4.39204 12.2866 4.25 12.2866H2.75C2.60796 12.2866 2.47811 12.2063 2.41459 12.0793C2.35107 11.9522 2.36478 11.8002 2.45 11.6866L6.05 6.88659C6.13523 6.77296 6.14893 6.62093 6.08541 6.49388C6.02189 6.36684 5.89204 6.28659 5.75 6.28659H4.979C4.83912 6.2854 4.71159 6.20626 4.64844 6.08143C4.58529 5.95661 4.59708 5.80698 4.679 5.69359L7.7 1.46359C7.77041 1.36516 7.88399 1.30676 8.005 1.30676C8.12602 1.30676 8.2396 1.36516 8.31 1.46359L11.331 5.69359C11.4129 5.80698 11.4247 5.95661 11.3616 6.08143C11.2984 6.20626 11.1709 6.2854 11.031 6.28659H10.25C10.108 6.28659 9.97811 6.36684 9.91459 6.49388C9.85107 6.62093 9.86478 6.77296 9.95 6.88659L12.857 10.7616'
        stroke={color || 'currentColor'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8 18.2866V22.7866'
        stroke={color || 'currentColor'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.5 19.7866V15.2866C12.5 14.4582 13.1716 13.7866 14 13.7866H20C20.8284 13.7866 21.5 14.4582 21.5 15.2866V19.7866'
        stroke={color || 'currentColor'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11 19.7866H23'
        stroke={color || 'currentColor'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14 19.7866V22.7866'
        stroke={color || 'currentColor'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20 19.7866V22.7866'
        stroke={color || 'currentColor'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export { ParkBenchIcon }
