import React from 'react'

import { Label } from '../../Data Display'
import { Input } from '../Input'
import { extractInputProps } from '../Input/extract-input-props'
import { BaseCheckbox, BaseCheckboxProps } from './BaseCheckbox'

type AdornmentPosition = 'start' | 'end'
type AlignItems = 'flex-start' | 'flex-end' | 'center'

type CheckboxProps = Omit<BaseCheckboxProps, 'label' | 'color' | 'defaultValue'> & {
  label?: string | React.ReactNode
  adornment?: React.ReactNode
  description?: string
  adornmentPosition?: AdornmentPosition
  adornmentClassName?: string
  align?: AlignItems
}

const Checkbox: React.FC<CheckboxProps> = (props) => {
  const {
    adornment,
    condensed,
    description,
    disabled,
    label,
    checked,
    defaultChecked,
    adornmentPosition,
    align,
    adornmentClassName,
    value,
    ...other
  } = props
  const { inputProps, otherProps } = extractInputProps(other)
  const isControlled = defaultChecked === undefined

  return (
    <Input {...inputProps}>
      <BaseCheckbox
        disabled={disabled}
        condensed={condensed}
        checked={isControlled && checked != null ? checked : undefined}
        defaultChecked={defaultChecked}
        value={value}
        label={
          <Label
            text={label}
            condensed={condensed}
            adornment={{ node: adornment, position: adornmentPosition, className: adornmentClassName, align }}
            description={description}
            disabled={disabled}
            readOnly={inputProps.readOnly}
          />
        }
        {...otherProps}
      />
    </Input>
  )
}

export { Checkbox, CheckboxProps }
