import React, { createContext, useEffect, useLayoutEffect, useState } from 'react'

import { isEqual } from '@guiker/lodash'
import { generateUseContext } from '@guiker/react-context'
import { usePersistancyContext } from '@guiker/react-persistance'

import { theme, useMediaQuery } from '..'

export type LayoutContextProps = React.PropsWithChildren & {
  appBarIsDisplayed?: boolean
  footerIsDisplayed?: boolean
}

export type Context = {
  appBarIsDisplayed: boolean
  footerIsDisplayed: boolean
  isTakeover: boolean
  hasDrawer: boolean
  isDrawerOpen: boolean
  isRightDrawerOpen: boolean
  isModalOpen: boolean
  isMobile: boolean
  isTablette: boolean
  isAtMostTablette: boolean
  isAtMostSmallSizeDesktop: boolean
  isAtLeastTablette: boolean
  isMidSizeDesktop: boolean
  isAtMostMidSizeDesktop: boolean
  isAtLeastMidSizeDesktop: boolean
  isLargeScreenDesktop: boolean
  setAppBarIsDisplayed: (value: boolean) => void
  setFooterIsDisplayed: (value: boolean) => void
  setIsModalOpen: (isOpen: boolean) => void
  setIsDrawerOpen: (isOpen: boolean) => void
  setIsRightDrawerOpen: (isOpen: boolean) => void
  setIsTakeover: (value: boolean) => void
  heightDownPx: (value: number) => boolean
  heightUpPx: (value: number) => boolean
}

export const LayoutContext = createContext<Context>(null)

type PersistedData = {
  layout: {
    drawer: {
      isDrawerOpen: boolean
    }
  }
}

export const LayoutContextProvider: React.FC<LayoutContextProps> = ({ children, ...props }) => {
  const [appBarIsDisplayed, setAppBarIsDisplayed] = useState<boolean>(props.appBarIsDisplayed || true)
  const [footerIsDisplayed, setFooterIsDisplayed] = useState<boolean>(props.footerIsDisplayed || true)
  const { data, setData } = usePersistancyContext<PersistedData>()
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean | undefined>(undefined)
  const [isRightDrawerOpen, setIsRightDrawerOpen] = useState<boolean | undefined>(undefined)
  const [isModalOpen, setIsModalOpen] = useState<boolean | undefined>(undefined)
  const [isTakeover, setIsTakeover] = useState<boolean>(false)

  useLayoutEffect(() => {
    setIsDrawerOpen(data?.layout?.drawer?.isDrawerOpen)
  }, [])

  useEffect(() => {
    const newState = {
      drawer: {
        isDrawerOpen,
      },
    }

    if (isEqual(data?.layout, newState)) return

    setData({ layout: newState })
  }, [isDrawerOpen])

  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const isTablette = useMediaQuery(theme.breakpoints.between('xs', 'sm'))
  const isAtMostTablette = useMediaQuery(theme.breakpoints.down('sm'))
  const isAtLeastTablette = useMediaQuery(theme.breakpoints.up('sm'))
  const isAtMostSmallSizeDesktop = useMediaQuery(theme.breakpoints.down('md'))
  const isMidSizeDesktop = useMediaQuery(theme.breakpoints.between('md', 'lg'))
  const isAtMostMidSizeDesktop = useMediaQuery(theme.breakpoints.down('lg'))
  const isAtLeastMidSizeDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const isLargeScreenDesktop = useMediaQuery(theme.breakpoints.up('lg'))

  const value = {
    isMobile,
    isTablette,
    isAtMostTablette,
    isAtMostSmallSizeDesktop,
    isAtLeastTablette,
    isMidSizeDesktop,
    isAtMostMidSizeDesktop,
    isAtLeastMidSizeDesktop,
    isLargeScreenDesktop,
    isRightDrawerOpen,
    isTakeover,
    appBarIsDisplayed,
    setAppBarIsDisplayed,
    footerIsDisplayed,
    setFooterIsDisplayed,
    isModalOpen,
    isDrawerOpen,
    hasDrawer: isDrawerOpen !== undefined,
    setIsModalOpen,
    setIsDrawerOpen,
    setIsRightDrawerOpen,
    setIsTakeover,
    heightDownPx: (dimension: number) => useMediaQuery(theme.breakpoints.heightDownPx(dimension)),
    heightUpPx: (dimension: number) => useMediaQuery(theme.breakpoints.heightUpPx(dimension)),
  }

  return <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
}

export const useLayoutContext = generateUseContext(LayoutContext)
