import React from 'react'

const BmoIcon: React.FC = () => {
  return (
    <svg width='37' height='25' viewBox='0 0 37 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.333313 2.16638C0.333313 1.33795 1.00489 0.666382 1.83331 0.666382H34.8333C35.6617 0.666382 36.3333 1.33795 36.3333 2.16638V23.1664C36.3333 23.9948 35.6617 24.6664 34.8333 24.6664H1.83331C1.00489 24.6664 0.333313 23.9948 0.333313 23.1664V2.16638Z'
        fill='#0079C1'
      />
      <path
        d='M18.3333 4.38538C16.6956 4.38538 15.0944 4.87105 13.7327 5.78098C12.371 6.6909 11.3095 7.98424 10.6827 9.49737C10.056 11.0105 9.89197 12.6756 10.2115 14.2819C10.531 15.8883 11.3197 17.3638 12.478 18.5219C13.636 19.6801 15.1113 20.4687 16.7178 20.7883C18.3243 21.1078 19.9892 20.9438 21.5023 20.317C23.0154 19.6903 24.3089 18.6289 25.2188 17.267C26.1287 15.9052 26.6143 14.3042 26.6143 12.6664C26.6143 10.4701 25.7418 8.3638 24.189 6.81083C22.6358 5.25784 20.5297 4.38538 18.3333 4.38538Z'
        fill='#ED1C24'
      />
      <path
        d='M18.0802 10.1361L16.6426 8.26139C16.6128 8.22201 16.5741 8.19008 16.53 8.16812C16.4856 8.14615 16.4369 8.13479 16.3873 8.13486C16.3392 8.13459 16.2919 8.14546 16.2488 8.16662C16.2057 8.18779 16.168 8.21863 16.1388 8.25679L14.4276 10.4121V13.7936L16.1342 11.6106C16.1653 11.5712 16.2047 11.5394 16.2498 11.5175C16.2948 11.4956 16.3442 11.4842 16.3942 11.4841C16.4449 11.4839 16.4949 11.4954 16.5403 11.5178C16.586 11.5401 16.6258 11.5727 16.6566 11.6129L18.1011 13.5014C18.1283 13.5369 18.163 13.5659 18.2028 13.5866C18.2425 13.6072 18.2863 13.619 18.331 13.621C18.376 13.6195 18.4197 13.6079 18.4595 13.5872C18.4992 13.5665 18.534 13.5372 18.5612 13.5014L20.0057 11.6129C20.0369 11.5731 20.0766 11.5408 20.122 11.5185C20.1674 11.4962 20.2174 11.4844 20.2681 11.4841C20.3181 11.4836 20.3678 11.4948 20.4128 11.5168C20.4579 11.5387 20.4973 11.5708 20.5278 11.6106L22.23 13.7912V10.4098L20.5185 8.2545C20.489 8.21631 20.4509 8.18544 20.4075 8.16431C20.3641 8.14314 20.3161 8.13231 20.2681 8.13257C20.2187 8.133 20.17 8.1446 20.1256 8.16653C20.0816 8.18842 20.0428 8.22009 20.0127 8.25907L18.5864 10.1361C18.5569 10.175 18.5185 10.2065 18.4744 10.2281C18.4307 10.2497 18.3823 10.2607 18.3333 10.2603C18.2846 10.2602 18.2362 10.2489 18.1925 10.2274C18.1488 10.2059 18.1104 10.1746 18.0802 10.1361ZM22.2416 16.2709H14.4207V13.7912H22.2416V16.2709Z'
        fill='white'
      />
    </svg>
  )
}

export { BmoIcon }
