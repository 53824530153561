import React, { useLayoutEffect, useRef, useState } from 'react'

import { clsx } from '@guiker/clsx'
import { makeStyles, theme, toPx } from '@guiker/components-core'
import { useTimeout } from '@guiker/react-utils'
import { default as MuiSwipableDrawer } from '@material-ui/core/SwipeableDrawer'

import { ButtonProps, ButtonWithLoader, Flex, useLayoutContext, useResizeObserver } from '../../..'

const zIndex = 1200

export type InquiryCardProps = {
  title?: string | React.ReactElement
  open: boolean
  onOpen: () => void
  onClose: () => void
  isLoading?: boolean
  drawerBleeding?: number
  buttonLabel?: string
  SubmitButton: React.FC<ButtonProps & React.PropsWithChildren>
  isSwipable?: boolean
} & React.PropsWithChildren

const pullerHeightFactor = 4
const buttonHeightFactor = 5

const useStyle = makeStyles(
  {
    fixedButton: {
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: zIndex + 2,
      padding: theme.spacing(2),
      position: 'fixed',
      borderColor: 'white',
      borderStyle: 'solid',
      backgroundColor: theme.palette.common.white,
    },
    swipableDrawer: ({ cardHeight, drawerBleeding }: { cardHeight: number; drawerBleeding: number }) => ({
      '& .MuiPaper-root': {
        height: `calc(${toPx(cardHeight)} - ${toPx(drawerBleeding - theme.spacing(buttonHeightFactor))})`,
        overflow: 'visible',
        borderTopLeftRadius: theme.spacing(2),
        borderTopRightRadius: theme.spacing(2),
      },
    }),
    container: ({
      drawerBleeding,
      isSwipable,
      isRendered,
    }: {
      isRendered: boolean
      isReady: boolean
      drawerBleeding: number
      isSwipable: boolean
    }) => ({
      position: 'absolute',
      boxShadow: theme.shadows.heavy,
      top: -drawerBleeding,
      borderTopLeftRadius: theme.spacing(2),
      borderTopRightRadius: theme.spacing(2),
      pointerEvents: 'all !important' as any,
      zIndex: zIndex + 1,
      visibility: isRendered ? 'visible' : ('hidden !important' as any),
      right: 0,
      left: 0,
      backgroundColor: theme.palette.common.white,
      padding: theme.spacing(2),
      paddingTop: isSwipable ? theme.spacing(pullerHeightFactor + 1) : theme.spacing(3),
      overflowY: 'auto',
    }),
    pullerContainer: {
      position: 'absolute',
      top: 0,
      left: 0,
      height: theme.spacing(pullerHeightFactor),
      width: '100%',
    },
    puller: {
      width: theme.spacing(7),
      height: 6,
      backgroundColor: theme.palette.grey[30],
      position: 'relative',
      borderRadius: 3,
      top: theme.spacing(pullerHeightFactor / 2),
      left: `calc(50% - ${theme.spacing(7) / 2}px)`,
    },
  },
  {
    name: 'InquiryCard',
  },
)

export const MobileInquiryCard: React.FC<InquiryCardProps> = ({ children, isSwipable = true, ...props }) => {
  const [cardHeight, setCardHeight] = useState<number>(0)
  const [isCardMounted, setIsCardMounted] = useState(false)
  const [isRendered, setIsRendered] = useState(false)
  const [open, setOpen] = useState(true)
  const { setIsModalOpen } = useLayoutContext()
  const cardRef = useRef(null)

  const { title, onOpen, onClose, isLoading, drawerBleeding, buttonLabel, SubmitButton } = props
  const buttonProps = { children: buttonLabel, fullWidth: true, isLoading }

  useLayoutEffect(() => {
    setCardHeight(cardRef.current?.clientHeight)
    !isCardMounted && setIsCardMounted(true)
  }, [cardRef])

  useLayoutEffect(() => {
    isCardMounted && setOpen(props.open)
  }, [isCardMounted, props.open])

  useLayoutEffect(() => {
    isCardMounted && setIsModalOpen(open)
    return () => setIsModalOpen(false)
  }, [open])

  useTimeout(() => setIsRendered(true), 300, [!!cardHeight])

  useResizeObserver(cardRef, () => {
    setCardHeight(cardRef.current?.clientHeight)
  })

  const classes = useStyle({
    isSwipable,
    cardHeight,
    isRendered,
    drawerBleeding,
  })

  const handleClick = (e: any) => {
    if (!open) {
      e?.preventDefault && e.preventDefault()
      e?.stopPropagation && e.stopPropagation()
      onOpen()
    }
  }

  const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent)

  return (
    <>
      <MuiSwipableDrawer
        className={clsx(classes.swipableDrawer)}
        anchor='bottom'
        open={open}
        onClose={isSwipable && onClose}
        onOpen={isSwipable && onOpen}
        disableDiscovery={true}
        disableBackdropTransition={!iOS}
        disableSwipeToOpen={false}
        swipeAreaWidth={drawerBleeding}
        ModalProps={{
          disablePortal: true,
          keepMounted: true,
          style: { zIndex },
        }}
      >
        <div ref={cardRef} className={classes.container}>
          {isSwipable && (
            <div className={classes.pullerContainer} onClick={open ? onClose : onOpen}>
              <div className={classes.puller} />
            </div>
          )}
          {title}
          {children}
        </div>
      </MuiSwipableDrawer>

      {buttonProps.children && (
        <Flex className={classes.fixedButton}>
          {!isSwipable || open ? (
            <SubmitButton {...buttonProps} />
          ) : (
            <ButtonWithLoader type='button' onClick={(e) => handleClick(e)} {...buttonProps} />
          )}
        </Flex>
      )}
    </>
  )
}
