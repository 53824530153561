import React, { useState } from 'react'

import { clsx } from '@guiker/clsx'
import { makeStyles, theme } from '@guiker/components-core'
import { default as Chip } from '@material-ui/core/Chip'
import { default as TextField } from '@material-ui/core/TextField'
import { AutocompleteGetTagProps, default as MuiAutocomplete } from '@material-ui/lab/Autocomplete'

import { useMediaQuery } from '../../../hooks'
import { useUtilityStyle } from '../../../styles'
import { CircularProgress, Flex, Label, P } from '../../'
import { FormControl } from '../FormControl'
import { FormHelperText } from '../FormHelperText/FormHelperText'

export type BaseAutocompleteProps = {
  className?: string
  clearOnBlur?: boolean
  defaultValue?: unknown[] | unknown
  value?: unknown[] | unknown
  disabled?: boolean
  error?: boolean
  errorMessage?: string
  forcePopupIcon?: 'auto' | boolean
  getOptionLabel: (option: unknown) => string
  getOptionSelected?: (option: unknown, value: unknown) => boolean
  helperText?: string
  isLoading?: boolean
  label?: string
  limitTags?: number
  mb?: number
  maxWidth?: number | string
  multiple?: boolean
  name: string
  onInputChange?: (event: React.ChangeEvent, newValue: string, reason?: 'input' | 'reset' | 'clear') => void
  onChange?: (event: React.ChangeEvent, newValue?: any, reason?: string) => void
  options: unknown[]
  startAdornment?: React.ReactNode
  type: 'tag' | 'text'
  readOnly?: boolean
  required?: boolean
}

const useStyles = makeStyles((theme) => ({
  root: {
    '&.MuiAutocomplete-root': {
      border: `1px solid transparent`,
      '&.Mui-focused': {
        border: `1px solid ${theme.palette.primary.main}`,
      },
    },
    '& .MuiInputBase-fullWidth': {
      '&:hover': {
        backgroundColor: theme.palette.grey[10],
      },
      color: theme.palette.grey[60],
      backgroundColor: theme.palette.grey[5],
      padding: theme.spacing(0.5),
      minHeight: 52,
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      borderRadius: 0,
      ...theme.typography.variants.body,
    },
    '& .MuiFilledInput-underline:after': {
      borderBottom: 'none !important',
    },
    '& .MuiFilledInput-underline:before': {
      borderBottom: 'none !important',
    },
  },
  container: {
    marginBottom: ({ mb }: { mb: number }) => theme.spacing(mb),
  },
  chip: {
    backgroundColor: `${theme.palette.common.white} !important`,
    border: 'none',
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.12)',
    ...theme.typography.variants.bodySmall,
  },
  readOnly: {
    '&:hover': {
      backgroundColor: theme.palette.common.white,
    },
    backgroundColor: 'transparent',
  },

  listbox: {
    '& > .MuiAutocomplete-option': {
      height: 60,
      display: 'flex',
      alignItems: 'center',
    },
  },
  tag: {
    margin: 4,
  },
  progress: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}))

const Autocomplete: React.FC<BaseAutocompleteProps> = (props) => {
  const {
    className,
    disabled,
    error,
    errorMessage,
    getOptionLabel,
    helperText,
    label,
    mb = 2,
    maxWidth,
    readOnly,
    type,
    startAdornment,
    defaultValue,
    value = type === 'tag' ? defaultValue ?? [] : undefined,
    onInputChange,
    onChange,
    options,
    required,
    isLoading,
    ...autocompleteProps
  } = props
  const classes = useStyles({ disabled, mb })
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const utilityClasses = useUtilityStyle({ maxWidth: isMobile ? null : maxWidth })
  const helperTextId = helperText ? `${props.name}-helper-text` : undefined
  const errorHelperTextId = errorMessage ? `${props.name}-error-text` : undefined
  const formattedLabel = label ? (required ? `${label} *` : label) : undefined

  const [inputValue, setInputValue] = useState('')

  const renderTags = (tags: string[], getTagProps: AutocompleteGetTagProps) =>
    tags.map((option, index: number) => {
      const label = getOptionLabel(option)
      return (
        <Chip
          key={`label-${index}`}
          className={classes.chip}
          classes={{ root: classes.chip }}
          variant='outlined'
          label={label}
          {...getTagProps({ index })}
        />
      )
    })

  const handleInputChange = (event: React.ChangeEvent, newValue: string, reason: 'input' | 'reset' | 'clear') => {
    if (reason === 'clear') {
      setInputValue('')
    } else {
      setInputValue(newValue)
    }
    onInputChange(event, newValue, reason)
  }

  const typeSpecificProps =
    type === 'tag'
      ? {
          multiple: true,
          renderTags,
        }
      : {
          forcePopupIcon: false,
          clearOnBlur: false,
          clearOnEscape: false,
          freeSolo: true,
          onInputChange: handleInputChange,
          inputValue,
        }

  const InputElement = (
    <MuiAutocomplete
      className={clsx(className, utilityClasses.maxWidth)}
      classes={{
        root: classes.root,
        tag: classes.tag,
        listbox: classes.listbox,
      }}
      autoHighlight
      {...typeSpecificProps}
      {...autocompleteProps}
      disabled={disabled || readOnly}
      value={value}
      defaultValue={defaultValue}
      options={options}
      onChange={onChange}
      getOptionLabel={getOptionLabel}
      renderOption={(option) => <P mb={0}>{getOptionLabel(option)}</P>}
      renderInput={(params) => {
        const adornment = isLoading ? (
          <CircularProgress size={16} color='inherit' variant='indeterminate' className={classes.progress} />
        ) : (
          startAdornment
        )
        const startAdornmentProps = startAdornment ? { startAdornment: adornment } : {}
        const InputProps = Object.assign({ ...params.InputProps }, startAdornmentProps)
        return (
          <TextField
            variant='filled'
            {...params}
            InputProps={InputProps}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && options.length < 1) {
                e.preventDefault()
                e.stopPropagation()
              }
            }}
          />
        )
      }}
    />
  )

  return (
    <FormControl
      className={clsx(classes.root, className, classes.container, utilityClasses.maxWidth)}
      disabled={disabled || readOnly}
      error={error || !!errorMessage}
    >
      <Flex flexDirection='column' gap={1}>
        {label && <Label disabled={disabled} readOnly={readOnly} text={formattedLabel} />}
        {InputElement}
        {errorMessage && (
          <FormHelperText color='alert' role='alert' aria-label={errorHelperTextId} id={errorHelperTextId}>
            {errorMessage}
          </FormHelperText>
        )}
        {helperText && (
          <FormHelperText aria-label='helper-message' id={helperTextId}>
            {helperText}
          </FormHelperText>
        )}
      </Flex>
    </FormControl>
  )
}

export { Autocomplete }
