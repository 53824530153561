import React from 'react'

import { SvgIconMediumViewBox, SvgIconProps } from '../../../SvgIcon'

export const InvestIcon: React.FC<SvgIconProps> = ({ strokeWidth = 1.5, ...props }) => {
  return (
    <SvgIconMediumViewBox {...props}>
      <path
        d='M7.5 23.25L13.72 17.03C13.8605 16.8893 14.0512 16.8102 14.25 16.8102C14.4488 16.8102 14.6395 16.8893 14.78 17.03L16.72 18.97C16.8605 19.1107 17.0512 19.1898 17.25 19.1898C17.4488 19.1898 17.6395 19.1107 17.78 18.97L23.25 13.5'
        stroke='currentColor'
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M19.5 13.5H23.25V17.25'
        stroke='currentColor'
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.75 13.5001V1.50007C15.7492 1.25457 15.6283 1.02499 15.4264 0.885429C15.2244 0.745864 14.9669 0.713982 14.737 0.800072L6.487 5.07007C6.19491 5.17944 6.00099 5.45817 6 5.77007V9.75007'
        stroke='currentColor'
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.5 5.25H15.75'
        stroke='currentColor'
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12 8.25H15.75'
        stroke='currentColor'
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M0.75 12.75H3'
        stroke='currentColor'
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M0.75 15.75H4.5'
        stroke='currentColor'
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.25 4.15598V0.750977'
        stroke='currentColor'
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.25 18V11.25C8.25 10.4216 7.57843 9.75 6.75 9.75H2.25C1.42157 9.75 0.75 10.4216 0.75 11.25V23.25H3.75'
        stroke='currentColor'
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIconMediumViewBox>
  )
}
