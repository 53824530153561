import React from 'react'

import { SvgIconMediumViewBox, SvgIconProps } from '../../../SvgIcon'

const AlarmIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIconMediumViewBox {...props}>
    <path
      d='M10 21.75C10.2609 22.6387 11.0763 23.2492 12.0025 23.2492C12.9287 23.2492 13.7441 22.6387 14.005 21.75'
      stroke='#4A90E2'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M12 3V0.75' stroke='#4A90E2' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12 3C16.1421 3 19.5 6.35786 19.5 10.5C19.5 17.546 21 18.75 21 18.75H3C3 18.75 4.5 16.834 4.5 10.5C4.5 6.35786 7.85786 3 12 3Z'
      stroke='#4A90E2'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </SvgIconMediumViewBox>
)

export { AlarmIcon }
