import React, { useEffect, useState } from 'react'

import { safelyGetWindow } from '@guiker/react-utils'

export const useEventListener = (
  eventType: string,
  handler: (event: React.MouseEvent) => void,
  dependencies: unknown[] = [],
) => {
  return useEffect(() => {
    safelyGetWindow()?.addEventListener(eventType, handler as unknown as (event: Event) => void)

    return () => {
      safelyGetWindow()?.removeEventListener(eventType, handler as unknown as (event: Event) => void)
    }
  }, dependencies)
}

export const useOnWheel = (handler: (event: React.MouseEvent) => void, dependencies: unknown[] = []) => {
  return useEventListener('wheel', handler, dependencies)
}

export const useOnScroll = (handler: (event: React.MouseEvent) => void, dependencies: unknown[] = []) => {
  return useEventListener('scroll', handler, dependencies)
}

export const useScrollDirection = (threshold = 0, onlyMouseScrolling = true) => {
  const [scrollDir, setScrollDir] = useState<'down' | 'up'>()
  const [isMouseScrolling, setIsMouseScrolling] = useState(false)
  const [lastScrollY, setLastScrollY] = useState(0)
  const [isTicking, setIsTicking] = useState(false)

  useOnWheel(() => {
    setIsMouseScrolling(true)
  }, [])

  useOnScroll(() => {
    const scrollY = safelyGetWindow()?.pageYOffset

    const updateScrollDir = () => {
      if (Math.abs(scrollY - lastScrollY) < threshold) {
        setIsTicking(false)
        return
      }

      setScrollDir(scrollY > lastScrollY ? 'down' : 'up')
      setLastScrollY(scrollY > 0 ? scrollY : 0)
      setIsTicking(false)
    }

    if (!isTicking && (!onlyMouseScrolling || isMouseScrolling)) {
      safelyGetWindow()?.requestAnimationFrame(updateScrollDir)
      setIsTicking(true)
    }

    setIsMouseScrolling(false)
  }, [scrollDir, isTicking, isMouseScrolling])

  return scrollDir
}

export const useOnScrollDirection = (direction: 'down' | 'up', scrollThreshold: number) => {
  const scrollDirection = useScrollDirection(scrollThreshold)

  return !scrollDirection || scrollDirection === direction
}
