import React from 'react'

import { RadioGroup as CLRadioGroup } from '@guiker/components-library'
import { FieldPath } from '@guiker/react-hook-form'

import { useApiFormContext } from '../Form'
import { RadioGroupProps as RHFRadioGroupProps, RHFRadioGroup } from './RHFRadioGroup'

export type RadioGroupProps<T extends object = any, P extends FieldPath<T> = any> = RHFRadioGroupProps<T, P>

export const RadioGroup = <T extends object = any, P extends FieldPath<T> = any>(props: RadioGroupProps<T, P>) => {
  const { readOnly: contextReadOnly } = useApiFormContext({ shouldThrowIfUndefined: false })
  if (props.readOnly) {
    return <CLRadioGroup {...props} />
  } else {
    return <RHFRadioGroup readOnly={contextReadOnly} {...props} />
  }
}
