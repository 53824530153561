import React, { useEffect } from 'react'

import { isMobile, isTablette, makeStyles, theme } from '@guiker/components-core'

import { useLayoutContext, useMediaQuery } from '../../..'
import { BoxProps } from '../Box'

const useStyles = makeStyles(
  {
    root: {
      backgroundColor: theme.palette.common.white,
      display: 'flex',
      minHeight: `calc(100vh - ${theme.dimensions.appBar.height.desktop}px)`,
      height: '100%',
      justifyContent: 'center',
      [isMobile]: {
        minHeight: `calc(100vh - ${theme.dimensions.appBar.height.mobile}px)`,
      },
    },
    screenContainer: {
      backgroundColor: theme.palette.common.white,
      display: 'flex',
      flexDirection: ({ imageAlign }: { imageAlign: string }) => (imageAlign === 'left' ? 'row' : 'row-reverse'),
      maxWidth: 1440,
      width: '100%',
      flexShrink: 2,
      flexGrow: 1,
      [isTablette]: {
        justifyContent: 'center',
      },
    },
    formContainer: {
      minHeight: '100%',
      display: 'flex',
      maxWidth: ({ maxWidth }: { maxWidth: number }) => maxWidth ?? 740,
      justifyContent: 'center',
      flexShrink: 0,
      width: '100%',
    },
    formContent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      width: '100%',
      padding: ` 0 ${theme.spacing(8)}px`,
      [isMobile]: {
        padding: `0 ${theme.spacing(2)}px`,
      },
    },
    imgContainer: {
      display: 'flex',
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      overflow: 'hidden',
      objectFit: 'cover',
    },
  },
  { name: 'SplitView' },
)

export type SplitViewProps = BoxProps &
  React.PropsWithChildren & {
    imageAlign?: 'right' | 'left'
    maxWidth?: number
    image: React.ReactNode
  }

export const SplitView: React.FC<SplitViewProps> = ({ children, imageAlign = 'left', image, maxWidth }) => {
  const classes = useStyles({ imageAlign, maxWidth })
  const { setFooterIsDisplayed } = useLayoutContext()
  const isTablet = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {
    setFooterIsDisplayed(false)
  }, [])

  return (
    <div className={classes.root}>
      <div className={classes.screenContainer}>
        {!isTablet && <div className={classes.imgContainer}>{image}</div>}

        <div className={classes.formContainer}>
          <div className={classes.formContent}>{children}</div>
        </div>
      </div>
    </div>
  )
}
