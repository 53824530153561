import React from 'react'

import { AnyColor, theme } from '@guiker/components-core'

type ViewMiniIconProps = {
  color?: AnyColor
}

const ViewMiniIcon: React.FC<ViewMiniIconProps> = ({ color }) => {
  const colorKey = theme.palette.getColor(color)
  const fill = colorKey || 'currentColor'

  return (
    <svg width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M5.71484 5.99983C5.71484 6.60603 5.95566 7.18741 6.38431 7.61607C6.81297 8.04472 7.39435 8.28554 8.00056 8.28554C8.60677 8.28554 9.18815 8.04472 9.6168 7.61607C10.0455 7.18741 10.2863 6.60603 10.2863 5.99983C10.2863 5.39362 10.0455 4.81224 9.6168 4.38358C9.18815 3.95493 8.60677 3.71411 8.00056 3.71411C7.39435 3.71411 6.81297 3.95493 6.38431 4.38358C5.95566 4.81224 5.71484 5.39362 5.71484 5.99983Z'
        fill={fill}
      />
      <path
        d='M15.392 4.46279L15.2308 4.28564C13.9428 2.88907 11.5428 0.285645 7.99995 0.285645C4.45709 0.285645 2.05709 2.88907 0.769093 4.28564L0.60795 4.4605C0.216193 4.87667 -0.00195312 5.42667 -0.00195312 5.99822C-0.00195312 6.56977 0.216193 7.11976 0.60795 7.53593L0.76795 7.70965C2.05709 9.11079 4.45709 11.7142 7.99995 11.7142C11.5428 11.7142 13.9428 9.11079 15.2308 7.71422L15.3908 7.5405C15.7834 7.12436 16.0021 6.57396 16.0024 6.00187C16.0026 5.42978 15.7842 4.87922 15.392 4.46279ZM13.5211 6.19422C12.0354 7.79422 10.3268 9.4285 7.99995 9.4285C5.67309 9.4285 3.96452 7.79422 2.47881 6.19422C2.42981 6.14138 2.40258 6.07199 2.40258 5.99993C2.40258 5.92787 2.42981 5.85848 2.47881 5.80564C3.98966 4.17822 5.67881 2.57136 7.99995 2.57136C10.3211 2.57136 12.0102 4.17822 13.5211 5.80564C13.5701 5.85848 13.5973 5.92787 13.5973 5.99993C13.5973 6.07199 13.5701 6.14138 13.5211 6.19422Z'
        fill={fill}
      />
    </svg>
  )
}

export { ViewMiniIcon }
