import React from 'react'

const CibcIcon: React.FC = () => {
  return (
    <svg width='37' height='25' viewBox='0 0 37 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M2.37331 0.313232H35.3733C36.0637 0.313232 36.6233 0.872877 36.6233 1.56323V22.5632C36.6233 23.2536 36.0637 23.8132 35.3733 23.8132H2.37331C1.68295 23.8132 1.12331 23.2536 1.12331 22.5632V1.56323C1.12331 0.872877 1.68295 0.313232 2.37331 0.313232Z'
        fill='white'
        stroke='#D7D7D7'
        strokeWidth='0.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <g clip-path='url(#clip0_15_500)'>
        <path
          d='M7.66715 13.9553C7.34533 14.1997 6.94596 14.326 6.5386 14.3124C5.37692 14.3124 4.60558 13.4435 4.60558 12.0628C4.60558 10.6598 5.36968 9.80206 6.5386 9.80206C6.91986 9.79047 7.29471 9.89977 7.6071 10.1136L8.47474 8.96352C7.93531 8.61151 7.40521 8.39404 6.37916 8.39404C4.14691 8.39404 2.87445 9.86578 2.87445 12.0628C2.87445 14.3154 4.20593 15.7204 6.37916 15.7204C7.76655 15.7204 8.30494 15.2561 8.55342 15.0912L7.66715 13.9553Z'
          fill='#C41F3E'
        />
        <path
          d='M23.4077 13.9441C23.0815 14.1939 22.6769 14.3254 22.2626 14.3164C21.0999 14.3164 20.3233 13.4475 20.3233 12.0627C20.3233 10.6598 21.0936 9.80201 22.2564 9.80201C22.6385 9.79182 23.0138 9.9029 23.3259 10.1186L24.1946 8.96853C23.6551 8.61652 23.125 8.39905 22.099 8.39905C19.8709 8.39905 18.5932 9.87079 18.5932 12.0678C18.5932 14.3204 19.9247 15.7254 22.099 15.7254C23.4864 15.7254 24.0248 15.2611 24.2733 15.0962L23.4077 13.9441Z'
          fill='#C41F3E'
        />
        <path
          d='M15.1268 8.52246C16.7265 8.52246 17.5713 9.14858 17.5713 10.3341C17.5713 11.1908 16.9998 11.6318 16.45 11.8513V11.8665C17.094 12.0455 17.8498 12.4532 17.8498 13.5759C17.8498 14.8788 16.8631 15.599 15.074 15.599H12.525V8.52246H15.1268ZM14.9104 12.524H14.2085V14.3548H14.9332C15.658 14.3548 16.1477 14.0756 16.1477 13.4606C16.1446 12.8102 15.6642 12.525 14.9104 12.525V12.524ZM14.9104 9.69884H14.2085V11.3405H14.868C15.5047 11.3405 15.9644 11.0927 15.9644 10.5313C15.9624 10.15 15.7263 9.69986 14.9104 9.69986V9.69884Z'
          fill='#C41F3E'
        />
        <path d='M9.29671 15.596H10.973V8.52454H9.29671V15.596Z' fill='#C41F3E' />
        <path
          d='M32.0882 8.36973C32.0138 8.27095 31.9157 8.19159 31.8024 8.13866C31.6892 8.08574 31.5644 8.06088 31.439 8.06628H28.7398C28.8673 8.06122 28.9941 8.08745 29.1086 8.14258C29.2231 8.1977 29.3216 8.27997 29.3952 8.38187C29.6675 8.73589 32.1762 12.0658 32.1762 12.0658C32.1762 12.0658 29.6675 15.3957 29.3952 15.7507C29.3214 15.8522 29.2228 15.9341 29.1083 15.9888C28.9938 16.0436 28.8672 16.0695 28.7398 16.0643H31.438C31.5634 16.0697 31.6882 16.0448 31.8014 15.9919C31.9146 15.9389 32.0128 15.8596 32.0871 15.7608L34.8743 12.0627L32.0882 8.36973Z'
          fill='#C41F3E'
        />
        <path
          d='M30.0899 9.29324C29.7472 8.84211 29.478 8.48403 29.3962 8.38288C29.3197 8.28488 29.2214 8.20524 29.1086 8.15004C28.9958 8.09484 28.8716 8.06554 28.7454 8.06437C28.6193 8.0632 28.4945 8.09019 28.3807 8.14329C28.2669 8.19638 28.1669 8.27418 28.0886 8.37075L25.3034 12.0627L28.0886 15.7558C28.1667 15.8521 28.2664 15.9298 28.3799 15.9829C28.4934 16.0361 28.6178 16.0632 28.7436 16.0623C28.8695 16.0615 28.9935 16.0326 29.1062 15.9779C29.2189 15.9232 29.3174 15.8441 29.3942 15.7466L30.0879 14.8312L28.0006 12.0627L30.0899 9.29324Z'
          fill='#8B1D41'
        />
      </g>
    </svg>
  )
}

export { CibcIcon }
