import React from 'react'

import { SvgIconProps, SvgIconSmallViewBox } from '../../../SvgIcon'

export const CircularAddIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIconSmallViewBox {...props}>
      <path
        d='M13.6569 2.34315C12.538 1.22433 11.1126 0.462403 9.56072 0.153721C8.00887 -0.15496 6.40034 0.00346637 4.93853 0.608967C3.47672 1.21447 2.22729 2.23985 1.34824 3.55544C0.469192 4.87103 8.42937e-08 6.41775 0 8C-1.68587e-07 9.58225 0.469192 11.129 1.34824 12.4446C2.22729 13.7602 3.47672 14.7855 4.93853 15.391C6.40034 15.9965 8.00887 16.155 9.56072 15.8463C11.1126 15.5376 12.538 14.7757 13.6569 13.6569C15.1548 12.1553 15.996 10.121 15.996 8C15.996 5.87905 15.1548 3.84469 13.6569 2.34315ZM9.19925 11.9598C9.20494 12.1209 9.17813 12.2814 9.12042 12.4319C9.06271 12.5823 8.97529 12.7196 8.86336 12.8356C8.75144 12.9515 8.61731 13.0437 8.46898 13.1067C8.32065 13.1697 8.16115 13.2021 8 13.2021C7.83885 13.2021 7.67935 13.1697 7.53102 13.1067C7.38269 13.0437 7.24856 12.9515 7.13664 12.8356C7.02471 12.7196 6.93729 12.5823 6.87958 12.4319C6.82187 12.2814 6.79506 12.1209 6.80075 11.9598L6.79962 9.20039L4.0402 9.19926C3.72946 9.18829 3.43512 9.05714 3.21917 8.83343C3.00322 8.60972 2.88253 8.31093 2.88253 8C2.88253 7.68907 3.00322 7.39028 3.21917 7.16658C3.43512 6.94287 3.72947 6.81172 4.0402 6.80075L6.79962 6.79962L6.80075 4.04021C6.81172 3.72947 6.94287 3.43512 7.16657 3.21917C7.39028 3.00322 7.68907 2.88254 8 2.88254C8.31093 2.88254 8.60972 3.00322 8.83343 3.21917C9.05713 3.43512 9.18828 3.72947 9.19925 4.04021L9.20038 6.79962L11.9598 6.80075C12.2705 6.81172 12.5649 6.94287 12.7808 7.16658C12.9968 7.39028 13.1175 7.68907 13.1175 8C13.1175 8.31093 12.9968 8.60972 12.7808 8.83343C12.5649 9.05714 12.2705 9.18829 11.9598 9.19926L9.20038 9.20039L9.19925 11.9598Z'
        fill='currentColor'
      />
    </SvgIconSmallViewBox>
  )
}
