import React from 'react'

import { SvgIconProps, SvgIconSmallViewBox } from '../../../SvgIcon'

const SearchIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIconSmallViewBox {...props}>
      <path
        d='M15.6652 15.6651C15.8795 15.4508 15.9998 15.1602 15.9998 14.8571C15.9998 14.5541 15.8795 14.2635 15.6652 14.0491L12.6126 10.9966C12.5654 10.9505 12.5355 10.8895 12.528 10.8239C12.5205 10.7583 12.5359 10.6921 12.5715 10.6366C13.509 9.21767 13.8862 7.50125 13.6301 5.82003C13.3741 4.13881 12.5029 2.61254 11.1855 1.5371C9.8681 0.461663 8.19834 -0.0863487 6.49989 -0.000698844C4.80143 0.084951 3.19527 0.798161 1.99276 2.00068C0.790242 3.20319 0.0770317 4.80935 -0.00861815 6.50781C-0.094268 8.20626 0.453743 9.87602 1.52918 11.1934C2.60463 12.5108 4.13089 13.382 5.81211 13.6381C7.49333 13.8941 9.20975 13.5169 10.6286 12.5794C10.6837 12.5432 10.7496 12.5271 10.8151 12.5338C10.8807 12.5404 10.942 12.5695 10.9886 12.616L14.0412 15.6686C14.1477 15.7756 14.2744 15.8605 14.4139 15.9184C14.5534 15.9762 14.7029 16.0058 14.8539 16.0055C15.0049 16.0052 15.1544 15.9749 15.2936 15.9165C15.4329 15.8581 15.5592 15.7727 15.6652 15.6651ZM2.28579 6.85714C2.28579 5.953 2.5539 5.06916 3.05622 4.31739C3.55853 3.56562 4.27249 2.97969 5.10781 2.63369C5.94313 2.28769 6.86229 2.19716 7.74906 2.37355C8.63583 2.54994 9.45038 2.98533 10.0897 3.62465C10.729 4.26398 11.1644 5.07853 11.3408 5.9653C11.5172 6.85207 11.4267 7.77123 11.0807 8.60655C10.7347 9.44187 10.1487 10.1558 9.39697 10.6581C8.6452 11.1605 7.76136 11.4286 6.85722 11.4286C5.6448 11.4286 4.48204 10.9469 3.62473 10.0896C2.76742 9.23232 2.28579 8.06956 2.28579 6.85714Z'
        fill='currentColor'
      />
    </SvgIconSmallViewBox>
  )
}

export { SearchIcon }
