import { useState } from 'react'

export const useModal = (open = false) => {
  const [isOpen, setIsOpen] = useState(open)
  return {
    isOpen,
    openModal: () => setIsOpen(true),
    closeModal: () => setIsOpen(false),
  }
}
