import React from 'react'

import { ColumnGridLayout, ColumnGridLayoutProps } from '../ColumnGridLayout'

type TwoColumnsGridLayoutProps = Omit<ColumnGridLayoutProps, 'gridTemplateColumns' | 'gridColumn'>

const TwoColumnsGridLayout: React.FC<TwoColumnsGridLayoutProps> = ({ children, gap, xs, ...props }) => {
  return (
    <ColumnGridLayout
      gap={gap}
      gridColumn={{ start: 'auto', span: 1 }}
      gridTemplateColumns={2}
      xs={{ gridTemplateColumns: 1, gridColumn: { span: 1 }, ...xs }}
      {...props}
    >
      {children}
    </ColumnGridLayout>
  )
}

export { TwoColumnsGridLayout }
