import React from 'react'

import { SvgIconMediumViewBox, SvgIconProps } from '../../../SvgIcon'

type FacebookIconProps = SvgIconProps

const FacebookIcon: React.FC<FacebookIconProps> = (props) => {
  return (
    <SvgIconMediumViewBox {...props}>
      <path
        d='M17.9989 0.00499355L14.8867 0C11.3902 0 9.13059 2.31826 9.13059 5.90637V8.6296H6.00139C5.73099 8.6296 5.51202 8.84882 5.51202 9.11922V13.0649C5.51202 13.3353 5.73124 13.5542 6.00139 13.5542H9.13059V23.5104C9.13059 23.7808 9.34956 23.9998 9.61996 23.9998H13.7027C13.9731 23.9998 14.1921 23.7805 14.1921 23.5104V13.5542H17.8508C18.1212 13.5542 18.3402 13.3353 18.3402 13.0649L18.3417 9.11922C18.3417 8.98939 18.29 8.86505 18.1984 8.77317C18.1067 8.68129 17.9819 8.6296 17.8521 8.6296H14.1921V6.32108C14.1921 5.21152 14.4565 4.64825 15.9018 4.64825L17.9984 4.6475C18.2685 4.6475 18.4875 4.42828 18.4875 4.15813V0.494361C18.4875 0.22446 18.2688 0.0054929 17.9989 0.00499355Z'
        fill='currentColor'
      />
    </SvgIconMediumViewBox>
  )
}

export { FacebookIcon }
