import React, { useEffect, useState } from 'react'

import { default as BaseCarousel, Settings } from 'react-slick'

import { clsx } from '@guiker/clsx'
import { Box, Button, makeStyles, theme, useMediaQuery } from '@guiker/components-library'
import { ChevronLeftIcon, ChevronRightIcon } from '@guiker/icons'

import { slickSliderBaseStyles } from './styles'

type Props = {
  items: React.ReactNode[]
  isMobile?: boolean
  onChange?: (currentIndex: number) => void
  withNavigation?: boolean
}

const useStyles = makeStyles(
  {
    root: {
      ...slickSliderBaseStyles,
    },
    slider: {
      '& .slick-slide img': {
        objectFit: 'contain',
        height: '100%',
        borderRadius: 8,
      },
    },
    sliderWrapper: {
      paddingLeft: 0,
      paddingRight: 0,
      margin: 'auto',
    },
    mainSlider: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3),
      zIndex: 0,
      '& .slick-slide img': {
        height: ({ isMobile }: { isMobile: boolean }) => (isMobile ? '60vh' : '72vh'),
        width: '100%',
        maxWidth: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
        borderRadius: ({ isMobile }: { isMobile: boolean }) => (isMobile ? 0 : 16),
      },
    },
    mainSliderWrapper: {
      marginLeft: ({ isMobile }: { isMobile: boolean }) => (isMobile ? theme.spacing(-3) : 'auto'),
      marginRight: ({ isMobile }: { isMobile: boolean }) => (isMobile ? theme.spacing(-3) : 'auto'),
    },
    navSlider: {
      '& .slick-slide img': {
        border: `1px solid ${theme.palette.common.white}`,
        borderRadius: 4,
        objectFit: 'cover',
        height: '100%',
      },
      '& .slick-slide .lazyload-wrapper': {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        height: 60,
      },
      '& .slick-current .lazyload-wrapper img': {
        border: `1px solid ${theme.palette.secondary.main}`,
      },
    },
    navSliderWrapper: {
      maxWidth: 1200,
    },
    item: {
      outline: 'none',
      '& img': {
        margin: 0,
        width: '100%',
        maxWidth: 'initial',
        maxHeight: 'initial',
      },
    },
  },
  { name: 'Carousel' },
)

type NavArrowProps = {
  isNextButton: boolean
  onClick: () => void
  Icon: typeof ChevronLeftIcon | typeof ChevronRightIcon
}

const BaseCarouselComponent = BaseCarousel as unknown as React.FC<React.PropsWithChildren & Settings & { ref: any }>

const NavArrow: React.FC<NavArrowProps> = ({ isNextButton, onClick, Icon }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const positionValue = isMobile ? 8 : 0

  return (
    <Button
      size='small'
      startIcon={<Icon color='secondary' />}
      color='textPrimary'
      onClick={onClick}
      style={{
        zIndex: 1,
        position: 'absolute',
        top: '45%',
        ...(isNextButton ? { right: positionValue } : { left: positionValue }),
      }}
    />
  )
}

const PrevArrow: React.FC<any> = (props) => <NavArrow Icon={ChevronLeftIcon} isNextButton={false} {...props} />
const NextArrow: React.FC<any> = (props) => <NavArrow Icon={ChevronRightIcon} isNextButton={true} {...props} />

const Carousel: React.FC<Props> = ({ items, onChange, withNavigation = true }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const classes = useStyles({ isMobile })
  const [currentIndex, setCurrentIndex] = useState(0)
  const [mainSlider, setMainSlider] = useState<BaseCarousel>()
  const [navSlider, setNavSlider] = useState<BaseCarousel>()

  const handleArrowKeydown = (event: KeyboardEvent, navSlider: BaseCarousel) => {
    if (event.key === 'ArrowLeft') {
      const prevIndex = currentIndex <= 0 ? items.length - 1 : currentIndex - 1
      navSlider.slickGoTo(prevIndex)
      setCurrentIndex(prevIndex)
    }
    if (event.key === 'ArrowRight') {
      const nextIndex = (currentIndex + 1) % items.length
      navSlider.slickGoTo(nextIndex)
      setCurrentIndex(nextIndex)
    }
  }

  useEffect(() => {
    const handler = (e: KeyboardEvent) => handleArrowKeydown(e, navSlider)

    if (navSlider) {
      document.addEventListener('keydown', handler, false)
    }

    return () => document.removeEventListener('keydown', handler, false)
  }, [currentIndex, navSlider])

  const mainSettings: Settings = {
    accessibility: false,
    arrows: true,
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    focusOnSelect: false,
    swipeToSlide: false,
    fade: true,
    asNavFor: navSlider,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  }

  const navSettings: Settings = {
    accessibility: true,
    arrows: false,
    dots: false,
    infinite: true,
    slidesToScroll: 1,
    slidesToShow: Math.min(items.length, 7),
    swipeToSlide: isMobile,
    centerMode: true,
    focusOnSelect: true,
    asNavFor: mainSlider,
    afterChange: (index) => onChange && onChange(index),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: Math.min(items.length, 5),
        },
      },
      {
        breakpoint: 840,
        settings: {
          slidesToShow: Math.min(items.length, 3),
        },
      },
    ],
  }

  const renderItems = (items: React.ReactNode[]): React.ReactNode =>
    items.map((item, index) => (
      <Box className={classes.item} key={`item-${index}`}>
        {item}
      </Box>
    ))

  return (
    <Box className={classes.root} width='100%'>
      <Box className={clsx([classes.sliderWrapper, classes.mainSliderWrapper])}>
        <BaseCarouselComponent
          ref={setMainSlider}
          className={clsx([classes.slider, classes.mainSlider])}
          {...mainSettings}
        >
          {renderItems(items)}
        </BaseCarouselComponent>
      </Box>
      {withNavigation && (
        <Box className={clsx([classes.sliderWrapper, classes.navSliderWrapper])}>
          <Box display={items.length > 1 ? 'block' : 'none'}>
            <BaseCarouselComponent
              ref={setNavSlider}
              className={clsx([classes.slider, classes.navSlider])}
              {...navSettings}
            >
              {renderItems(items)}
            </BaseCarouselComponent>
          </Box>
        </Box>
      )}
    </Box>
  )
}

export { Carousel }
