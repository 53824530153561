import React from 'react'

import { AnyColor, theme } from '@guiker/components-core'

type BackgroundCheckCreditReportProps = {
  color?: AnyColor
}

const BackgroundCheckCreditReport: React.FC<BackgroundCheckCreditReportProps> = ({ color: colorKey }) => {
  const color = theme.palette.getColor(colorKey)

  return (
    <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0)'>
        <path
          d='M20.25 8.36328V2.36328C20.25 1.96546 20.092 1.58393 19.8107 1.30262C19.5294 1.02132 19.1478 0.863281 18.75 0.863281H2.25C1.85218 0.863281 1.47064 1.02132 1.18934 1.30262C0.908035 1.58393 0.75 1.96546 0.75 2.36328V14.3633C0.75 14.7611 0.908035 15.1426 1.18934 15.4239C1.47064 15.7052 1.85218 15.8633 2.25 15.8633H11.25'
          stroke={color}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path d='M0.75 5.36328H20.25' stroke={color} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M3.75 9.11328H9.75' stroke={color} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M3.75 12.1133H6.75' stroke={color} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path
          d='M14.25 13.4822V23.3632'
          stroke={color}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M14.25 18.9823L15.54 18.5523C16.0725 18.3747 16.6385 18.3211 17.1948 18.3957C17.7511 18.4702 18.2831 18.6708 18.75 18.9823C19.2169 19.2938 19.7489 19.4945 20.3052 19.569C20.8615 19.6435 21.4275 19.5899 21.96 19.4123L23.25 18.9823V11.9823L21.96 12.4123C21.4275 12.5899 20.8615 12.6435 20.3052 12.569C19.7489 12.4945 19.2169 12.2938 18.75 11.9823C18.2831 11.6708 17.7511 11.4702 17.1948 11.3957C16.6385 11.3211 16.0725 11.3747 15.54 11.5523L14.25 11.9823V18.9823Z'
          stroke={color}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0'>
          <rect width='24' height='24' fill='white' transform='translate(0 0.113281)' />
        </clipPath>
      </defs>
    </svg>
  )
}

export { BackgroundCheckCreditReport }
