import * as React from 'react'

import { clsx } from '@guiker/clsx'
import { AnyColor, darken, fade, lighten, makeStyles, toPx } from '@guiker/components-core'
import { Link as BaseLink, LinkProps as BaseLinkProps } from '@guiker/router'

import { P, TypographyProps } from '../../Data Display'

export type LinkProps = React.PropsWithChildren &
  Omit<BaseLinkProps, 'color'> & {
    adornment?: React.ReactNode
    endAdornment?: React.ReactNode
    className?: string
    classes?: { text: string }
    color?: AnyColor
    underline?: boolean
    hoverUnderline?: boolean
    TextComponent?: React.FC<TypographyProps>
  }

const useStyles = makeStyles(
  (theme) => ({
    root: {
      color: ({ disabled, color }: LinkProps) => (disabled ? theme.palette.grey[30] : theme.palette.getColor(color)),
      cursor: ({ disabled }: { disabled: boolean }) => (disabled ? 'not-allowed' : 'pointer'),
      display: 'inline-flex',
      alignItems: 'center',
      boxSizing: 'border-box',
      width: 'fit-content',
      textDecoration: ({ underline }: { underline: boolean }) => (underline ? 'underline !important' : 'none'),
      mixBlendMode: 'normal',
      '&:hover': {
        textDecoration: ({ hoverUnderline }: { hoverUnderline: boolean }) => (hoverUnderline ? 'underline' : 'none'),
        color: ({ disabled, color }: LinkProps) => {
          if (disabled) return theme.palette.grey[30]
          if (color === 'white') return fade(theme.palette.getColor(color), 0.1)
          return lighten(theme.palette.getColor(color), 0.15)
        },
      },
      '&:active': {
        color: ({ disabled, color }: LinkProps) => {
          if (disabled) return theme.palette.grey[30]
          if (color === 'white') return fade(theme.palette.getColor(color), 0.2)
          return darken(theme.palette.getColor(color), 0.15)
        },
      },
    },
    text: {
      color: ({ disabled, color }: LinkProps) => (disabled ? theme.palette.grey[30] : theme.palette.getColor(color)),
    },
    startAdornment: {
      lineHeight: toPx(16),
      marginRight: theme.spacing(1),
    },
    endAdornment: {
      lineHeight: toPx(16),
      marginLeft: theme.spacing(1),
    },
  }),
  { name: 'Link' },
)

const Link: React.FC<LinkProps> = ({
  adornment,
  children,
  className,
  classes: classesProps,
  color = 'textPrimary',
  disabled,
  endAdornment,
  hoverUnderline = true,
  underline: underlineProp,
  onClick,
  TextComponent = P,
  ...props
}) => {
  const isStringLink = typeof children === 'string'
  const underline = underlineProp ?? (isStringLink ? true : false)
  const classes = useStyles({ disabled, color, underline, hoverUnderline })

  const handleClick = (e: any) => {
    e?.stopPropagation && e.stopPropagation()
    onClick && onClick(e)
  }

  return (
    <BaseLink
      className={clsx(className, classes.root)}
      disabled={disabled}
      onClick={!disabled ? handleClick : null}
      {...props}
    >
      {adornment && <div className={clsx(classes.startAdornment)}>{adornment}</div>}
      {isStringLink ? (
        <TextComponent component='span' mb={0} className={clsx(classesProps?.text, classes.text)}>
          {children}
        </TextComponent>
      ) : (
        children
      )}
      {endAdornment && <div className={clsx(classes.endAdornment)}>{endAdornment}</div>}
    </BaseLink>
  )
}

export { Link }
