import React from 'react'

import { SvgIconProps, SvgIconSmallViewBox } from '../../../SvgIcon'

const InfoMiniIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIconSmallViewBox size='smaller' {...props}>
      <circle cx='8' cy='8' r='8' fill='black' />
      <path
        d='M6.8848 3.92C6.8848 3.608 7.0018 3.348 7.2358 3.14C7.4698 2.92333 7.76447 2.815 8.1198 2.815C8.47514 2.815 8.7698 2.92333 9.0038 3.14C9.2378 3.348 9.3548 3.608 9.3548 3.92C9.3548 4.22333 9.2378 4.48333 9.0038 4.7C8.7698 4.91667 8.47514 5.025 8.1198 5.025C7.76447 5.025 7.4698 4.91667 7.2358 4.7C7.0018 4.48333 6.8848 4.22333 6.8848 3.92ZM7.0148 12.5V5.74H9.2248V12.5H7.0148Z'
        fill='white'
      />
    </SvgIconSmallViewBox>
  )
}

export { InfoMiniIcon }
