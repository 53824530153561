import React from 'react'

const BankOfAmericaIcon: React.FC = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='409.29998779296875 240.10000610351562 405.3999938964844 219.5'
      fill='none'
      height='26'
      width='38'
    >
      <path
        fill='#E31837'
        d='M611.4,459.6c64-54.8,154-108.2,203.3-127.3c-7.7-4.9-19.6-11.9-32.9-19.5c-63.6,21-138,67.8-204.5,120.6&#10;&#9;&#9;&#9;&#9;C588.6,441.5,600.3,450.6,611.4,459.6z'
      />
      <path
        fill='#012169'
        d='M582.1,310.6c-9.5-4.4-19.7-8.8-27.9-12.5c-24.1,11.9-55.4,30-95.1,58.2c8.7,4.6,17.8,10,27.6,15.5&#10;&#9;&#9;&#9;&#9;C517.2,349.3,548,327.8,582.1,310.6z'
      />
      <path
        fill='#E31837'
        d='M639.1,284.2c-11.5-6.6-51.9-21.2-79.7-27.6c-8.2,3.1-19.8,7.6-27.7,10.9c9.9,2.8,47.5,12.7,79.5,28.9&#10;&#9;&#9;&#9;&#9;C619.7,292.6,631,287.6,639.1,284.2z'
      />
      <path
        fill='#012169'
        d='M502.8,280.2c-35.8,16.5-73.1,39.1-93.5,52c7.2,3.3,14.6,6.1,24.7,11.1c45-30.5,80.4-49,94.5-55.2&#10;&#9;&#9;&#9;&#9;C518.5,284.8,508.8,282.1,502.8,280.2z'
      />
      <path
        fill='#E31837'
        d='M665.8,275c8.1-2.6,17.8-5.3,26-7.6c-23.9-10.1-53.8-20.6-80.4-27.3c-4.2,1.1-16.9,4.7-26,7.4&#10;&#9;&#9;&#9;&#9;C594.6,249.8,624.9,256.8,665.8,275z'
      />
      <path
        fill='#E31837'
        d='M515.5,389.4c9.7,5.9,20.2,13.8,30.2,20.5c66.7-51.7,132.6-91.9,205-114.5c-10.1-5.1-18.9-9.6-30.1-15.1&#10;&#9;&#9;&#9;&#9;C677.2,291.4,603.3,321,515.5,389.4z'
      />
    </svg>
  )
}

export { BankOfAmericaIcon }
