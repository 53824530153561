import React from 'react'

const TdBankIcon: React.FC = () => {
  return (
    <svg width='37' height='25' viewBox='0 0 37 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.606003 1.73267C0.606003 0.904239 1.27758 0.232666 2.106 0.232666H35.106C35.9344 0.232666 36.606 0.904239 36.606 1.73267V22.7327C36.606 23.5611 35.9344 24.2327 35.106 24.2327H2.106C1.27758 24.2327 0.606003 23.5611 0.606003 22.7327V1.73267Z'
        fill='#54B948'
      />
      <path
        d='M22.2846 18.2495H16.9058V8.55985H19.6074V16.5331H22.2058C23.9995 16.5331 24.7529 15.2905 24.7529 12.0906C24.7529 8.87154 23.897 7.93608 22.0761 7.93608H16.1501V18.2495H13.4749V7.93608H9.5491V6.21583H22.9095C26.132 6.21583 27.6629 7.8833 27.6629 12.065C27.6629 17.5741 25.377 18.2495 22.2846 18.2495Z'
        fill='white'
      />
    </svg>
  )
}

export { TdBankIcon }
