import React from 'react'

import { clsx } from '@guiker/clsx'
import { makeStyles } from '@guiker/components-core'
import { CheckmarkSmallIcon } from '@guiker/icons'
import { CheckboxProps, default as MuiCheckbox } from '@material-ui/core/Checkbox'

export type CheckboxAdornmentProps = CheckboxProps & {
  condensed?: boolean
  checked?: boolean
  className?: string
  defaultChecked?: boolean
  disabled?: boolean
  readOnly?: boolean
}

const useStyles = makeStyles(
  (theme) => ({
    root: {
      alignItems: 'center',
      backgroundColor: theme.palette.common.white,
      borderColor: theme.palette.grey[60],
      borderRadius: 0,
      borderStyle: 'solid',
      borderWidth: 2,
      boxSizing: 'border-box',
      color: theme.palette.common.white,
      display: 'flex',
      height: 20,
      justifyContent: 'center',
      padding: 0,
      width: 20,
      pointerEvents: ({ readOnly, disabled }: { readOnly: boolean; disabled: boolean }) =>
        readOnly || disabled ? 'none' : 'auto',
    },
    condensed: {
      borderWidth: 1.5,
      height: 12,
      width: 12,
    },
    colorPrimary: {
      '&.Mui-checked': {
        color: theme.palette.common.white,
        borderColor: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.main,
      },
      '&.Mui-checked:hover': {
        backgroundColor: theme.palette.primary.main,
      },
      '&:hover': {
        backgroundColor: theme.palette.grey[10],
      },
      '&.Mui-disabled': {
        borderColor: theme.palette.grey[30],
      },
      '&.Mui-disabled.Mui-checked': {
        borderColor: theme.palette.grey[30],
        backgroundColor: theme.palette.grey[30],
      },
    },
    readOnly: {
      cursor: 'default',
      borderColor: `${theme.palette.grey[60]} !important`,
      '&.MuiCheckbox-colorPrimary.Mui-checked': {
        backgroundColor: `${theme.palette.grey[60]} !important`,
      },
    },
  }),
  { name: 'CheckboxAdornment' },
)

export const CheckboxAdornment: React.FC<CheckboxAdornmentProps> = ({
  condensed = false,
  disabled,
  className,
  defaultChecked,
  readOnly,
  checked,
  ...others
}) => {
  const classes = useStyles()

  return (
    <MuiCheckbox
      className={className}
      classes={{
        colorPrimary: classes.colorPrimary,
        root: clsx(classes.root, {
          [classes.readOnly]: readOnly,
          [classes.condensed]: condensed,
        }),
      }}
      disabled={disabled}
      icon={<span />}
      checkedIcon={<CheckmarkSmallIcon size={condensed ? 'smallest' : 'small'} />}
      checked={defaultChecked || checked || false}
      color='primary'
      disableRipple
      {...others}
    />
  )
}
