import React from 'react'

import { clsx } from '@guiker/clsx'
import { makeStyles } from '@guiker/components-core'
import { isArray } from '@guiker/lodash'

import { useScreenSize } from '../../../hooks/use-screen-size'
import { theme } from '../../../styles'
import { Box } from '../..'

export type VerticalGridProps = React.PropsWithChildren & {
  className?: string
  display: 'grid' | 'inline-grid'
  columnsPerBreakpoints?: { [key in 'lg' | 'md' | 'sm']: number }
  gridColumnGap?: number
  gridRowGap?: number
}

const useStyles = makeStyles(
  {
    root: ({
      columns,
      display,
      rows,
      gridRowGap,
      gridColumnGap,
    }: {
      columns: number
      display: 'grid' | 'inline-grid'
      rows: number
      gridColumnGap: number
      gridRowGap: number
    }) => ({
      display,
      gridAutoFlow: 'column',
      gridTemplateColumns: `repeat(${columns}, 1fr)`,
      gridTemplateRows: `repeat(${rows}, 1fr)`,
      gridRowGap: theme.spacing(gridRowGap),
      gridColumnGap: theme.spacing(gridColumnGap),
    }),
  },
  { name: 'VerticalGrid' },
)

const VerticalGrid: React.FC<VerticalGridProps> = ({
  children,
  className,
  display = 'grid',
  columnsPerBreakpoints = { lg: 3, md: 2, sm: 1 },
  gridColumnGap = 0,
  gridRowGap = 0,
}) => {
  const { isMd, isLg } = useScreenSize()

  const columns = isLg ? columnsPerBreakpoints['lg'] : isMd ? columnsPerBreakpoints['md'] : columnsPerBreakpoints['sm']
  const rows = isArray(children) ? Math.ceil(children.length / columns) : 1
  const classes = useStyles({ columns, display, rows, gridColumnGap, gridRowGap })

  return <Box className={clsx(className, classes.root)}>{children}</Box>
}

export { VerticalGrid }
