import React from 'react'

import { AnyColor, theme } from '@guiker/components-core'

const ProtectionIcon: React.FC<{ color?: AnyColor }> = ({ color: colorKey }) => {
  const color = theme.palette.getColor(colorKey)
  return (
    <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.25 11.4726C16.6854 11.3991 17.131 11.521 17.4682 11.806C17.8055 12.091 18 12.5101 18 12.9516V13.9596C18.0002 14.5279 17.6792 15.0475 17.171 15.3016L15.829 15.9716C15.3208 16.2258 14.9998 16.7454 15 17.3136V22.3856C15.0002 22.6784 15.1708 22.9443 15.4368 23.0666C15.7028 23.1889 16.0157 23.1451 16.238 22.9546L20.476 19.9646C20.8086 19.6797 21 19.2636 21 18.8256V10.1636C21.0739 5.20655 17.2266 1.07255 12.277 0.790646C9.84312 0.715701 7.48274 1.63017 5.73464 3.32532C3.98654 5.02047 2.99993 7.35161 3 9.78665V18.8256C3.00001 19.2636 3.19143 19.6797 3.524 19.9646L7.762 22.9546C7.98433 23.1451 8.29718 23.1889 8.56321 23.0666C8.82924 22.9443 8.9998 22.6784 9 22.3856V17.5576C8.99992 16.8695 8.53159 16.2696 7.864 16.1026L7.136 15.9206C6.46841 15.7537 6.00008 15.1538 6 14.4656V12.9516C6.00001 12.5101 6.19454 12.091 6.53176 11.806C6.86899 11.521 7.31465 11.3991 7.75 11.4726C9.15368 11.7214 10.5747 11.8599 12 11.8866C13.4253 11.8599 14.8463 11.7214 16.25 11.4726Z'
        stroke={color || 'currentColor'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12 11.8866V17.2866'
        stroke={color || 'currentColor'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export { ProtectionIcon }
