import React from 'react'

import { FormHelperText, FormHelperTextProps } from '../..'

export type ErrorMessageProps = FormHelperTextProps & {
  errorMessage?: string
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ errorMessage, id, ...props }) => {
  const errorHelperTextId = errorMessage ? `${id}-error-text` : undefined

  return errorMessage ? (
    <FormHelperText color='alert' role='alert' arial-label={errorHelperTextId} id={errorHelperTextId} {...props}>
      {errorMessage}
    </FormHelperText>
  ) : null
}

export { ErrorMessage }
