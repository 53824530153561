import React from 'react'

import { AnyColor, theme } from '@guiker/components-core'

const ParkingIcon: React.FC<{ color?: AnyColor }> = ({ color: colorKey }) => {
  const color = theme.palette.getColor(colorKey)
  return (
    <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0)'>
        <path
          d='M9.75 17.2866V6.78662'
          stroke={color || 'currentColor'}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M9.75 6.78662H11.55C12.3854 6.78662 13.1866 7.1185 13.7774 7.70923C14.3681 8.29997 14.7 9.10119 14.7 9.93662C14.7 10.7721 14.3681 11.5733 13.7774 12.164C13.1866 12.7547 12.3854 13.0866 11.55 13.0866H9.75'
          stroke={color || 'currentColor'}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M0.75 12.0366C0.75 15.0203 1.93526 17.8818 4.04505 19.9916C6.15483 22.1014 9.01631 23.2866 12 23.2866C14.9837 23.2866 17.8452 22.1014 19.955 19.9916C22.0647 17.8818 23.25 15.0203 23.25 12.0366C23.25 9.05294 22.0647 6.19145 19.955 4.08167C17.8452 1.97189 14.9837 0.786621 12 0.786621C9.01631 0.786621 6.15483 1.97189 4.04505 4.08167C1.93526 6.19145 0.75 9.05294 0.75 12.0366V12.0366Z'
          stroke={color || 'currentColor'}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0'>
          <rect width='24' height='24' fill='white' transform='translate(0 0.0366211)' />
        </clipPath>
      </defs>
    </svg>
  )
}

export { ParkingIcon }
