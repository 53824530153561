import React from 'react'

import { SvgIconMediumViewBox, SvgIconProps } from '../../../SvgIcon'

const PaperclipIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIconMediumViewBox {...props}>
      <path
        d='M18.75 15.749V7.5C18.75 5.70979 18.0388 3.9929 16.773 2.72703C15.5071 1.46116 13.7902 0.75 12 0.75C10.2098 0.75 8.4929 1.46116 7.22703 2.72703C5.96116 3.9929 5.25 5.70979 5.25 7.5V18.75C5.25 19.9435 5.72411 21.0881 6.56802 21.932C7.41193 22.7759 8.55653 23.25 9.75 23.25C10.9435 23.25 12.0881 22.7759 12.932 21.932C13.7759 21.0881 14.25 19.9435 14.25 18.75V7.5C14.25 6.90326 14.0129 6.33097 13.591 5.90901C13.169 5.48705 12.5967 5.25 12 5.25C11.4033 5.25 10.831 5.48705 10.409 5.90901C9.98705 6.33097 9.75 6.90326 9.75 7.5V17.25'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIconMediumViewBox>
  )
}

export { PaperclipIcon }
