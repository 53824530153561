import React from 'react'

import { SvgIconProps, SvgIconSmallViewBox } from '../../../SvgIcon'

const AlertIcon: React.FC<Omit<SvgIconProps, 'small'>> = (props) => {
  return (
    <SvgIconSmallViewBox {...props}>
      <path
        d='M15.5971 11.2305L9.99707 1.18094C9.80005 0.824086 9.51097 0.526578 9.15992 0.319395C8.80887 0.112211 8.4087 0.00292969 8.00107 0.00292969C7.59344 0.00292969 7.19327 0.112211 6.84222 0.319395C6.49117 0.526578 6.20209 0.824086 6.00507 1.18094L0.40187 11.2385C0.132719 11.7277 -0.00431143 12.2786 0.00428739 12.8368C0.0128862 13.3951 0.166817 13.9415 0.450907 14.4221C0.734996 14.9027 1.13944 15.3011 1.62436 15.5778C2.10928 15.8545 2.65795 16.0001 3.21627 16.0001H12.7811C13.3403 16.0002 13.8899 15.8544 14.3756 15.5771C14.8613 15.2999 15.2663 14.9008 15.5506 14.4192C15.8349 13.9376 15.9887 13.3902 15.9969 12.831C16.005 12.2718 15.8672 11.7202 15.5971 11.2305ZM7.20027 4.80014C7.20027 4.58797 7.28456 4.38448 7.43459 4.23445C7.58461 4.08442 7.7881 4.00014 8.00027 4.00014C8.21244 4.00014 8.41593 4.08442 8.56596 4.23445C8.71598 4.38448 8.80027 4.58797 8.80027 4.80014V8.00014C8.80027 8.21231 8.71598 8.4158 8.56596 8.56582C8.41593 8.71585 8.21244 8.80014 8.00027 8.80014C7.7881 8.80014 7.58461 8.71585 7.43459 8.56582C7.28456 8.4158 7.20027 8.21231 7.20027 8.00014V4.80014ZM8.00027 13.6001C7.68382 13.6001 7.37448 13.5063 7.11136 13.3305C6.84824 13.1547 6.64316 12.9048 6.52206 12.6124C6.40096 12.3201 6.36928 11.9984 6.43101 11.688C6.49275 11.3776 6.64514 11.0925 6.8689 10.8688C7.09266 10.645 7.37776 10.4926 7.68813 10.4309C7.9985 10.3691 8.3202 10.4008 8.61256 10.5219C8.90493 10.643 9.15481 10.8481 9.33062 11.1112C9.50643 11.3743 9.60027 11.6837 9.60027 12.0001C9.60027 12.4245 9.4317 12.8315 9.13164 13.1315C8.83158 13.4316 8.42462 13.6001 8.00027 13.6001Z'
        fill='currentColor'
      />
    </SvgIconSmallViewBox>
  )
}

export { AlertIcon }
