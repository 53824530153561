import React from 'react'

import { isString } from '@guiker/lodash'
import { useStateWithDependencies } from '@guiker/react-utils'
import { useLocation, useNavigate } from '@guiker/router'

import { Flex } from '../../Layout'
import { Tab, TabProps, Tabs, TabsProps } from '../Tab'

type Tab = {
  content: React.ReactNode
  icon?: TabProps['icon']
  path?:
    | string
    | {
        /** @description to replace the current pathname; default to false */
        replace?: boolean
        to: string
      }
  label: string
  Wrapper?: TabProps['Wrapper']
  rightComponent?: React.ReactNode
}

type TabLayoutProps = {
  classes?: {
    tab?: string
  }
  defaultIndex?: number
  gap?: 2 | 4 | 6 | 8
  tabs: Tab[]
  variant?: TabsProps['variant']
  noPadding?: boolean
  onChange?: (index: number) => unknown
}

const TabLayout: React.FC<TabLayoutProps> = ({
  tabs,
  variant = 'standard',
  noPadding = false,
  defaultIndex = 0,
  classes,
  gap = 2,
  onChange,
}) => {
  const currentLocation = useLocation()
  const navigate = useNavigate()

  const indexFromLocation = tabs.findIndex((tab) => {
    const path = tab.path && (isString(tab.path) ? tab.path : tab.path.to)
    return path && currentLocation.pathname.split('/').pop() === path?.replace('/', '')
  })

  const [currentIndex, setCurrentIndex] = useStateWithDependencies<number>(
    indexFromLocation !== -1 ? indexFromLocation : defaultIndex,
  )

  const handleChange = (_event: React.ChangeEvent<{}>, index: any) => {
    setCurrentIndex(index)
    onChange?.(index)
    const path = tabs[index].path
    if (!!path) {
      const relativePath = indexFromLocation >= 0 || (!isString(path) && path.replace) ? '../' : './'
      navigate(`${relativePath}${(isString(path) ? path : path.to).replace('/', '')}`)
    }
  }

  return (
    <Flex flexDirection='column' gap={gap} flexGrow={1}>
      {tabs.length > 1 && (
        <Flex justifyContent='space-between' alignItems='center'>
          <Tabs value={currentIndex} onChange={handleChange as any} variant={variant} noPadding={noPadding}>
            {tabs.map(({ content, ...tabProps }, index) => {
              return <Tab key={index} className={classes?.tab} {...tabProps} />
            })}
          </Tabs>
          {tabs[currentIndex]?.rightComponent}
        </Flex>
      )}
      {tabs[currentIndex]?.content}
    </Flex>
  )
}

export { TabLayout }
