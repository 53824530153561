import React from 'react'

import { SvgIconMediumViewBox, SvgIconProps } from '../../SvgIcon'

export interface PublishIconProps extends SvgIconProps {}

export const PublishIcon: React.FC<PublishIconProps> = (props) => {
  return (
    <SvgIconMediumViewBox {...props}>
      <path
        d='M22.5005 1.56689L20.3425 1.80689C18.6499 1.99502 17.0717 2.75362 15.8675 3.95789L6.06055 13.7649L10.3005 18.0069L20.1095 8.19989C21.3142 6.99599 22.0729 5.41765 22.2605 3.72489L22.5005 1.56689Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.06071 13.7649L2.43871 12.5569C2.31742 12.5167 2.20845 12.446 2.12217 12.3517C2.0359 12.2574 1.97519 12.1426 1.94582 12.0182C1.91645 11.8938 1.91941 11.764 1.95439 11.6411C1.98938 11.5181 2.05524 11.4062 2.14571 11.3159L2.37771 11.0839C3.16556 10.2959 4.15728 9.7429 5.24175 9.48679C6.32621 9.23069 7.46057 9.28164 8.51771 9.63394L9.77271 10.0519L6.06071 13.7649Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.06071 13.7649L2.43871 12.5569C2.31742 12.5167 2.20845 12.446 2.12217 12.3517C2.0359 12.2574 1.97519 12.1426 1.94582 12.0182C1.91645 11.8938 1.91941 11.764 1.95439 11.6411C1.98938 11.5181 2.05524 11.4062 2.14571 11.3159L2.37771 11.0839C3.16556 10.2959 4.15728 9.7429 5.24175 9.48679C6.32621 9.23069 7.46057 9.28164 8.51771 9.63394L9.77271 10.0519L6.06071 13.7649Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.3008 18.0068L11.5088 21.6288C11.5491 21.7501 11.6197 21.8591 11.714 21.9453C11.8083 22.0316 11.9231 22.0923 12.0475 22.1217C12.1719 22.1511 12.3017 22.1481 12.4246 22.1131C12.5476 22.0781 12.6595 22.0123 12.7498 21.9218L12.9828 21.6898C13.7706 20.9018 14.3235 19.9101 14.5794 18.8256C14.8353 17.7412 14.7842 16.6069 14.4318 15.5498L14.0158 14.2998L10.3008 18.0068Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.3008 18.0068L11.5088 21.6288C11.5491 21.7501 11.6197 21.8591 11.714 21.9453C11.8083 22.0316 11.9231 22.0923 12.0475 22.1217C12.1719 22.1511 12.3017 22.1481 12.4246 22.1131C12.5476 22.0781 12.6595 22.0123 12.7498 21.9218L12.9828 21.6898C13.7706 20.9018 14.3235 19.9101 14.5794 18.8256C14.8353 17.7412 14.7842 16.6069 14.4318 15.5498L14.0158 14.2998L10.3008 18.0068Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.77931 18.5379C7.7797 18.8335 7.72168 19.1261 7.60858 19.3992C7.49549 19.6722 7.32955 19.9202 7.12031 20.1289C6.24231 21.0069 1.82031 22.2499 1.82031 22.2499C1.82031 22.2499 3.06331 17.8249 3.94131 16.9499C4.32701 16.5633 4.84035 16.3304 5.38531 16.2949'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIconMediumViewBox>
  )
}
