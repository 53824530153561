import React, { useState } from 'react'

import { isAtMostTablette, makeStyles, theme } from '@guiker/components-core'
import { Sticky } from '@guiker/react-sticky'

import { Box, Card, Flex } from '../../..'
import { InquiryCardProps } from './InquiryCard'

type DesktopInquiryCardProps = InquiryCardProps

const useStyle = makeStyles(
  {
    inquiryFormContainer: {
      position: 'relative',
      minWidth: 380,
      marginLeft: theme.spacing(4),
    },
    cardContent: {
      width: '100%',
      maxWidth: 380,
      padding: theme.spacing(4),
      height: '100%',
      overflow: 'auto',
      [isAtMostTablette]: {
        padding: theme.spacing(2),
        paddingTop: 0,
        maxWidth: '100% !important',
        marginTop: theme.spacing(5),
      },
    },
  },
  {
    name: 'DesktopInquiryCard',
  },
)

export const DesktopInquiryCard: React.FC<DesktopInquiryCardProps> = ({ children, ...props }) => {
  const { title, isLoading, buttonLabel, SubmitButton } = props
  const classes = useStyle()
  const [isSticky, setIsSticky] = useState(false)
  const buttonProps = { children: buttonLabel, fullWidth: true, isLoading }
  const boundaryElementId = 'inquiry-card'

  return (
    <div id={boundaryElementId} className={classes.inquiryFormContainer}>
      <Sticky
        topOffset={-(theme.dimensions.appBar.height.desktop + theme.spacing(3))}
        boundaryElement={`#${boundaryElementId}`}
        onFixedToggle={setIsSticky}
      >
        <Box mt={isSticky ? 10 : 0} mb={isSticky ? 3 : 0}>
          <Card className={classes.cardContent} shadow='medium'>
            {title && <Box mb={2}>{title}</Box>}
            {children}
            {buttonProps.children && (
              <Flex flexDirection='column' alignItems='center'>
                <SubmitButton {...buttonProps} />
              </Flex>
            )}
          </Card>
        </Box>
      </Sticky>
    </div>
  )
}
