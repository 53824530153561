import React, { useEffect, useState } from 'react'

import { DataAndMeta, DataMetadataWithCountByStatus, Paginate as Pagination } from '@guiker/api-definition'
import {
  Button,
  FilterGroup,
  FiltersProps,
  Flex,
  GridLayout,
  Tab,
  Table,
  TableProps,
  Tabs,
  TextField,
  WithTooltip,
} from '@guiker/components-library'
import { useT } from '@guiker/i18n'
import { useQuery } from '@guiker/react-query'
import { useLocationQuery, useSearchParams } from '@guiker/router'

import { ExportCSV } from './ExportCSV'
import { StatusFilterCard } from './StatusFilterCard'
import { useSort } from './use-sort'
export { Pagination }

export type FetcherArg<
  Status extends string = never,
  TabValue extends object = never,
  Search extends boolean = never,
  FiltersData extends Record<string, any> = never,
> = Pagination &
  ([Status] extends [never] ? {} : { statuses: Status[] }) &
  ([TabValue] extends [never] ? {} : TabValue) &
  ([Search] extends [never] ? {} : Search extends true ? { searchString: string } : {}) &
  ([FiltersData] extends [never] ? {} : { filters: FiltersData })

export type StatusGroup<StatusGroupName extends string> = {
  name: StatusGroupName
  label: string
  tooltip?: React.ReactNode
}

export type TabItem<TabValue extends object = {}> = {
  name: string
  label: string
  value: TabValue
  selected?: boolean
}

type StatusGroupMapper<StatusGroupName extends string, Status extends string> = {
  [key in StatusGroupName]: Status[]
}

const getStatusGroupNameByStatuses = <StatusGroupName extends string, Status extends string>(
  mapper: StatusGroupMapper<StatusGroupName, Status>,
  statuses: Status[],
) => {
  return Object.keys(mapper).find((key) => {
    const values = mapper[key as StatusGroupName]
    return statuses.every((status) => values.includes(status))
  })
}

const groupCountByStatus = <G extends string, S extends string>(
  countByStatus: { status: S; count: number }[],
  mapper: StatusGroupMapper<Exclude<G, 'all'>, S>,
) => {
  return countByStatus?.reduce((acc, { status, count }) => {
    const newAcc = { ...acc }
    newAcc.all = (acc.all || 0) + count

    const groupName = Object.keys(mapper).find((key) =>
      mapper[key as Exclude<G, 'all'>].find((value) => value === status),
    ) as G
    newAcc[groupName as G] = (newAcc[groupName] || 0) + count

    return newAcc
  }, {} as { [key in G | 'all']: number })
}

type Fetcher<
  T,
  Status extends string = never,
  TabValue extends object = never,
  Search extends boolean = never,
  FiltersData extends Record<string, any> = never,
> = [Status] extends [never]
  ? (pagination: FetcherArg<Status, TabValue, Search, FiltersData>) => Promise<DataAndMeta<T[]>>
  : (pagination: FetcherArg<Status, TabValue, Search, FiltersData>) => Promise<DataMetadataWithCountByStatus<T, Status>>

export type ExportFetcher<T extends object, Status extends string> = (statuses: Status[]) => Promise<T[]>

export type PaginatedTableProps<
  T,
  StatusGroupName extends string = never,
  Status extends string = never,
  TabValue extends object = never,
  Search extends boolean = never,
  FiltersData extends Record<string, any> = never,
  CSVBody extends object = object,
> = Pick<
  TableProps<T>,
  | 'emptyState'
  | 'columns'
  | 'condensed'
  | 'onRowClick'
  | 'isRowCollapsible'
  | 'pagination'
  | 'collapsibleOptions'
  | 'minWidth'
> & {
  title?: string
  filters?: FiltersProps<FiltersData>
  queryKey: string
  showPagination?: boolean
  fetcher: Fetcher<T, Status, TabValue, Search, FiltersData>
  refetchTrigger?: string
  search?:
    | {
        label?: string
      }
    | boolean
  hideIfEmpty?: boolean
  statusGroups?: {
    selectedStatusGroupName?: StatusGroupName
    onSelect?: (status: Status[]) => void
    mapper: StatusGroupMapper<Exclude<StatusGroupName, 'all'>, Status>
    groups?: StatusGroup<StatusGroupName>[]
    statuses?: { type: Status; label: string }[]
  }
  tabs?: TabItem<TabValue>[]
  onFetchSuccess?: (data: DataAndMeta<T[]>) => unknown
  exportAsCSV?: {
    queryKey: string
    fetcher: ExportFetcher<CSVBody, Status>
  }
}

const findDefaultTab = (tabs: TabItem[]) => {
  const id = tabs.findIndex((tab) => tab.selected)
  return id === -1 ? 0 : id
}

const PaginatedTable = <
  T extends object,
  StatusGroupName extends string = never,
  Status extends string = never,
  CSVBody extends object = never,
  TabValue extends object = never,
  Search extends boolean = never,
  FiltersData extends Record<string, any> = never,
>({
  columns,
  condensed,
  fetcher,
  filters,
  isRowCollapsible = false,
  collapsibleOptions,
  onRowClick,
  onFetchSuccess,
  pagination: { sort: sortProps = 'createdAt', sortOrder: sortOrderProps = -1, perPage: perPageProps = 20 } = {},
  showPagination = true,
  queryKey,
  refetchTrigger = undefined,
  hideIfEmpty = false,
  search,
  statusGroups,
  tabs,
  minWidth,
  exportAsCSV,
}: PaginatedTableProps<T, StatusGroupName, Status, TabValue, Search, FiltersData, CSVBody>) => {
  const { tBase, t } = useT({})
  const { status: paramsStatusGroupName } = useLocationQuery(['searchString', 'status'])
  const { getSearchParam, getSearchArrayParams, addSearchParam, removeSearchParam } = useSearchParams()
  const paramsSearchString = getSearchParam('searchString')
  const paramStatuses = getSearchArrayParams('statuses') ?? []
  const [selectedGroupName, setSelectedGroupName] = useState<StatusGroupName>()
  const [filtersData, setFiltersData] = useState<FiltersData>({} as FiltersData)

  const addStatusParams = (statuses: Status[]) => {
    paramsStatusGroupName && removeSearchParam('status')
    if (!statuses?.length) {
      removeSearchParam('statuses')
    } else {
      addSearchParam('statuses', statuses)
    }
  }

  useEffect(() => {
    if (!!paramsStatusGroupName) {
      addStatusParams(statusGroups.mapper[paramsStatusGroupName as Exclude<StatusGroupName, 'all'>])
    }
  }, [paramsStatusGroupName])

  const { sort, sortOrder, setSortBy } = useSort({ sort: sortProps, sortOrder: sortOrderProps })
  const [page, setPage] = useState<number>(1)
  const [perPage] = useState<number>(perPageProps)
  const [searchInput, setSearchInput] = useState(paramsSearchString)
  const [searchString, setSearchString] = useState(searchInput)
  const completeFilters = statusGroups?.statuses
    ? ({
        ...(filters ?? {}),
        statuses: {
          type: 'FilterMultiSelect',
          name: 'statuses',
          label: tBase('filters.status'),
          inputLabel: tBase('filters.byStatus'),
          options: statusGroups.statuses?.map((status) => ({
            value: status.type,
            label: status.label,
          })),
        },
      } as FiltersProps<FiltersData>)
    : (filters as FiltersProps<FiltersData>)

  const [statusGroupCount, setStatusGroupCount] = useState<{ [key in StatusGroupName]: number }>(
    statusGroups
      ? Object.keys(statusGroups.mapper).reduce(
          (acc, key) => ({ ...acc, [key]: 0 }),
          {} as { [key in StatusGroupName]: number },
        )
      : undefined,
  )

  const [currentTabIndex, setCurrentTabIndex] = useState<number>(tabs ? findDefaultTab(tabs) : undefined)

  const onTabClicked = (_event: React.ChangeEvent<{}>, index: any) => {
    setCurrentTabIndex(index)
  }

  const onSearchTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(event.target.value)
  }

  const {
    isLoading,
    data: pagedData,
    isFetching,
    refetch,
  } = useQuery(
    [
      queryKey,
      {
        page,
        perPage,
        sort,
        sortOrder,
        searchString,
        statuses: !!paramStatuses?.length ? paramStatuses : null,
        ...(filters ? filtersData : {}),
        ...(tabs ? tabs[currentTabIndex]?.value : {}),
      },
    ],
    () =>
      fetcher({
        page,
        perPage,
        sort,
        sortOrder,
        searchString,
        statuses: !!paramStatuses?.length ? paramStatuses : null,
        ...(filters ? filtersData : {}),
        ...(tabs ? tabs[currentTabIndex]?.value : {}),
      } as any),
    {
      onSuccess: (res) => {
        statusGroups &&
          setStatusGroupCount(
            groupCountByStatus(
              (res?.meta as unknown as { countByStatus: { status: Status; count: number }[] })?.countByStatus,
              statusGroups?.mapper,
            ),
          )
        onFetchSuccess?.(res)
      },
      keepPreviousData: true,
    },
  )

  useEffect(() => {
    refetchTrigger && refetch()
  }, [refetchTrigger])

  const submitSearch = (event: React.FormEvent) => {
    event.preventDefault()
    setSearchString(searchInput)
  }

  useEffect(() => {
    if (!!statusGroups) {
      if (!paramStatuses?.length) {
        setSelectedGroupName(statusGroups.groups[0].name)
      } else {
        setSelectedGroupName(
          getStatusGroupNameByStatuses<Exclude<StatusGroupName, 'all'>, Status>(
            statusGroups.mapper,
            paramStatuses as Status[],
          ) as StatusGroupName,
        )
      }
    }
    if (!!completeFilters?.statuses) {
      if (
        (!filtersData?.statuses?.length && !!paramStatuses?.length) ||
        (!!filtersData?.statuses?.length &&
          !filtersData?.statuses?.every((s: string) => (paramStatuses ?? []).includes(s)))
      ) {
        setFiltersData({ ...(filtersData ?? {}), statuses: paramStatuses } as unknown as FiltersData)
      }
    }
  }, [paramStatuses])

  if (hideIfEmpty && !pagedData?.data?.length) {
    return <></>
  }

  return (
    <Flex flexDirection='column' gap={3}>
      {(tabs || search) && (
        <Flex gap={3} justifyContent='flex-end'>
          {tabs && (
            <Flex flexDirection='column' flexGrow={1} justifyContent='flex-end'>
              <Tabs value={currentTabIndex} onChange={onTabClicked as any} withBorder size='small'>
                {tabs.map((tab) => (
                  <Tab label={tab.label} />
                ))}
              </Tabs>
            </Flex>
          )}
          {search && (
            <form onSubmit={submitSearch}>
              <Flex alignItems='flex-end' gap={2}>
                <TextField
                  condensed
                  width={300}
                  name='searchInput'
                  label={(search as { label: string })?.label || t('common:actions.search')}
                  defaultValue={searchInput}
                  onChange={onSearchTextFieldChange}
                />
                <Button size='medium' type='submit'>
                  {t('common:actions.search')}
                </Button>
              </Flex>
            </form>
          )}
        </Flex>
      )}
      {statusGroups?.groups && (
        <GridLayout gap={2} columnMinWidth={180}>
          {statusGroups?.groups.map((group) => {
            return (
              <WithTooltip title={group.tooltip}>
                <StatusFilterCard
                  selected={selectedGroupName === group.name}
                  label={group.label}
                  value={statusGroupCount?.[group.name] || 0}
                  onClick={() => {
                    addStatusParams(statusGroups.mapper[group.name as Exclude<StatusGroupName, 'all'>])
                    setSelectedGroupName(group.name)
                    statusGroups.onSelect?.(
                      statusGroups.mapper[group.name as Exclude<StatusGroupName, 'all'>] as Status[],
                    )
                    setPage(1)
                  }}
                />
              </WithTooltip>
            )
          })}
        </GridLayout>
      )}
      {completeFilters && filtersData && (
        <FilterGroup
          filtersData={filtersData}
          onChange={(values) => {
            setFiltersData(values)
            if (!!values?.statuses) {
              addStatusParams(values.statuses)
            } else {
              removeSearchParam('statuses')
            }
          }}
          filters={completeFilters as FiltersProps<FiltersData>}
        />
      )}
      {exportAsCSV && (
        <ExportCSV
          queryKey={exportAsCSV.queryKey}
          fetcher={exportAsCSV.fetcher}
          statuses={!!paramStatuses?.length ? paramStatuses : null}
        />
      )}
      <Table
        columns={columns}
        condensed={condensed}
        sort={{
          by: sort,
          order: sortOrder,
          setSortBy,
        }}
        data={pagedData?.data}
        minWidth={minWidth}
        pagination={
          showPagination
            ? {
                page,
                perPage,
                total: pagedData?.meta?.total || pagedData?.data?.length,
                totalPages: pagedData?.meta?.totalPages || 1,
                setPage,
              }
            : undefined
        }
        isLoading={isLoading || isFetching}
        isRowCollapsible={isRowCollapsible}
        onRowClick={onRowClick}
        collapsibleOptions={collapsibleOptions}
      />
    </Flex>
  )
}

export { PaginatedTable }
