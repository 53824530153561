import React from 'react'

import { clsx } from '@guiker/clsx'
import { makeStyles } from '@guiker/components-core'
import { useRouter } from '@guiker/router'

import { Label, Link, ListItem, ListItemIcon } from '../../../'
import { theme } from '../../../styles'
import { useInPageNavigationContext } from './InPageNavigation'
import { InPageNavigationItem } from './InPageNavigationItem'

export type BaseSideMenuItemProps = InPageNavigationItem & {
  disabled?: boolean
  endAdornment?: React.ReactNode
  hasActiveNestedItem?: boolean
  isActive?: boolean
  isNestedItem?: boolean
  onClick?: (e?: React.SyntheticEvent) => void
  startAdornment?: React.ReactNode
}

const useStyle = makeStyles(
  {
    root: {
      padding: theme.spacing(1.5),
      height: '100%',
      '&.Mui-selected': {
        backgroundColor: theme.palette.grey[5],
      },
    },
    rootColumn: {
      borderLeft: '4px solid transparent',
    },
    rootRow: {
      borderBottom: `1px solid ${theme.palette.grey[10]}`,
    },
    container: {
      width: '100% !important',
    },
    rootItemActiveColumn: {
      borderLeft: `4px solid ${theme.palette.primary.main}`,
    },
    rootItemActiveRow: {
      borderBottom: `4px solid ${theme.palette.primary.main}`,
    },
    text: {
      ...theme.typography.variants.body,
      flex: 1,
      paddingLeft: ({ isNestedItem }: { isNestedItem: boolean }) =>
        isNestedItem ? theme.spacing(1) : theme.spacing(0),
    },
    icon: {
      justifyContent: 'flex-end',
    },
    rowMenuItemText: {
      whiteSpace: 'nowrap',
      textAlign: 'center',
    },
  },
  {
    name: 'BaseSideMenuItem',
  },
)

export const BaseSideMenuItem: React.FC<BaseSideMenuItemProps> = ({
  disabled,
  endAdornment,
  href,
  prefixWithLocale,
  hasActiveNestedItem = false,
  isActive,
  isNestedItem = false,
  startAdornment,
  text,
  ...props
}) => {
  const classes = useStyle({ isNestedItem })
  const { direction } = useInPageNavigationContext()
  const { prefixWithLocale: routerPrefixWithLocale } = useRouter() || {}

  return (
    <Link
      className={classes.container}
      underline={false}
      hoverUnderline={false}
      disabled={disabled}
      to={href}
      prefixWithLocale={prefixWithLocale ?? routerPrefixWithLocale}
      {...props}
    >
      <ListItem
        className={clsx(classes.root, {
          [classes.rootColumn]: direction === 'column',
          [classes.rootRow]: direction === 'row',
          [classes.rootItemActiveColumn]: direction === 'column' && isActive,
          [classes.rootItemActiveRow]: direction === 'row' && isActive,
        })}
        selected={isActive || hasActiveNestedItem}
        disabled={disabled}
      >
        {startAdornment && <ListItemIcon className={classes.icon}>{startAdornment}</ListItemIcon>}
        <Label className={clsx(classes.text, direction === 'row' && classes.rowMenuItemText)} text={text}></Label>
        {endAdornment && <ListItemIcon className={classes.icon}>{endAdornment}</ListItemIcon>}
      </ListItem>
    </Link>
  )
}
