import React from 'react'

import { SvgIconMediumViewBox, SvgIconProps } from '../../SvgIcon'

type LegalCertificateIconProps = SvgIconProps

const LegalCertificateIcon: React.FC<LegalCertificateIconProps> = (props) => {
  return (
    <SvgIconMediumViewBox {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.25 6.75C21.9069 6.75 23.25 5.40685 23.25 3.75C23.25 2.09315 21.9069 0.75 20.25 0.75C18.5931 0.75 17.25 2.09315 17.25 3.75C17.25 5.40685 18.5931 6.75 20.25 6.75Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.81361 1.01758C4.89698 0.85342 5.06549 0.75 5.24961 0.75C5.43373 0.75 5.60224 0.85342 5.68561 1.01758L6.69961 3.05958C6.7708 3.20179 6.90719 3.30006 7.06461 3.32258L9.33761 3.65058C9.51856 3.67658 9.66942 3.80229 9.72761 3.97558C9.7844 4.1483 9.73739 4.33828 9.60661 4.46458L7.95661 6.06458C7.84413 6.17365 7.79252 6.33109 7.81861 6.48558L8.20761 8.74058C8.23848 8.92093 8.16302 9.10297 8.01361 9.20858C7.86323 9.31511 7.66579 9.32825 7.50261 9.24258L5.47661 8.18458C5.33458 8.10956 5.16465 8.10956 5.02261 8.18458L2.99961 9.24258C2.83644 9.32825 2.639 9.31511 2.48861 9.20858C2.33921 9.10297 2.26375 8.92093 2.29461 8.74058L2.68361 6.48558C2.7097 6.33109 2.6581 6.17365 2.54561 6.06458L0.899611 4.46458C0.768833 4.33828 0.721824 4.1483 0.778611 3.97558C0.836606 3.80216 0.98757 3.67635 1.16861 3.65058L3.44061 3.32258C3.59581 3.29823 3.7296 3.20022 3.79961 3.05958L4.81361 1.01758Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M20.25 0.75H9.75' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M17.25 3.75V18.75C17.25 21.2353 15.2353 23.25 12.75 23.25H0.75C3.23528 23.25 5.25 21.2353 5.25 18.75V12.75'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.25 17.25H14.25'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.25 14.25H14.25'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.25 11.25H14.25'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.25 8.25H14.25'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIconMediumViewBox>
  )
}

export { LegalCertificateIcon }
