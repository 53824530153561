import React from 'react'

const CommercialIcon: React.FC = () => {
  return (
    <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M21.75 12.75V21.75C21.75 22.5784 21.0784 23.25 20.25 23.25H3.75C2.92157 23.25 2.25 22.5784 2.25 21.75V12.75'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M21.148 0.75H2.852C2.50038 0.749941 2.19579 0.993863 2.119 1.337L0.75 7.5C0.75 8.74264 1.75736 9.75 3 9.75C4.24264 9.75 5.25 8.74264 5.25 7.5C5.25 8.74264 6.25736 9.75 7.5 9.75C8.74264 9.75 9.75 8.74264 9.75 7.5C9.75 8.74264 10.7574 9.75 12 9.75C13.2426 9.75 14.25 8.74264 14.25 7.5C14.25 8.74264 15.2574 9.75 16.5 9.75C17.7426 9.75 18.75 8.74264 18.75 7.5C18.75 8.74264 19.7574 9.75 21 9.75C22.2426 9.75 23.25 8.74264 23.25 7.5L21.88 1.337C21.804 0.993807 21.4995 0.749658 21.148 0.75Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.75 16.5C18.75 14.8431 17.4069 13.5 15.75 13.5C14.0931 13.5 12.75 14.8431 12.75 16.5V23.25H18.75V16.5Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.25 16.5C5.25 16.0858 5.58579 15.75 6 15.75H9C9.41421 15.75 9.75 16.0858 9.75 16.5V19.5C9.75 19.9142 9.41421 20.25 9 20.25H6C5.58579 20.25 5.25 19.9142 5.25 19.5V16.5Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.125 18C16.3321 18 16.5 18.1679 16.5 18.375C16.5 18.5821 16.3321 18.75 16.125 18.75C15.9179 18.75 15.75 18.5821 15.75 18.375C15.75 18.1679 15.9179 18 16.125 18'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export { CommercialIcon }
