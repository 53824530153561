import React from 'react'

import { SvgIconProps, SvgIconSmallViewBox } from '../../../SvgIcon'

export const PaymentSmallIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIconSmallViewBox {...props}>
      <path
        d='M3 7.25C3.13807 7.25 3.25 7.36193 3.25 7.5C3.25 7.63807 3.13807 7.75 3 7.75C2.86193 7.75 2.75 7.63807 2.75 7.5C2.75 7.36193 2.86193 7.25 3 7.25'
        stroke='currentColor'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M13 12.25C13.1381 12.25 13.25 12.3619 13.25 12.5C13.25 12.6381 13.1381 12.75 13 12.75C12.8619 12.75 12.75 12.6381 12.75 12.5C12.75 12.3619 12.8619 12.25 13 12.25'
        stroke='currentColor'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M0.5 6.5C0.5 5.67157 1.17157 5 2 5H14C14.8284 5 15.5 5.67157 15.5 6.5V13.5C15.5 14.3284 14.8284 15 14 15H2C1.17157 15 0.5 14.3284 0.5 13.5V6.5Z'
        stroke='currentColor'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M8 12C9.10457 12 10 11.1046 10 10C10 8.89543 9.10457 8 8 8C6.89543 8 6 8.89543 6 10C6 11.1046 6.89543 12 8 12Z'
        stroke='currentColor'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path d='M1.5 3H14.5' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' />
      <path d='M3.5 1H12.5' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' />
    </SvgIconSmallViewBox>
  )
}
