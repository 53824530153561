import React from 'react'

import { SvgIconMediumViewBox, SvgIconProps } from '../../../SvgIcon'

type ToiletIconProp = SvgIconProps

const ToiletIcon: React.FC<ToiletIconProp> = (props) => {
  return (
    <SvgIconMediumViewBox {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.75 13.2029H20.25C21.0784 13.2029 21.75 12.5582 21.75 11.7629V3.12286C21.75 2.32757 21.0784 1.68286 20.25 1.68286H17.25C16.4216 1.68286 15.75 2.32757 15.75 3.12286V13.2029Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.75 10.3229C2.92157 10.3229 2.25 10.9676 2.25 11.7629C2.25 12.5582 2.92157 13.2029 3.75 13.2029H15.75V10.3229H3.75Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.2 15.936C19.423 15.5426 20.2472 14.4429 20.247 13.2048H3.75C3.74942 16.1818 5.48785 18.9076 8.25 20.2608L7.5 23.2848H17.25V17.3136C17.2305 16.7075 17.6112 16.1554 18.2 15.936Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIconMediumViewBox>
  )
}

export { ToiletIcon }
