import React from 'react'

import { SvgIconProps, SvgIconSmallViewBox } from '../../../SvgIcon'

export type WalkIconProps = SvgIconProps

const WalkIcon: React.FC<WalkIconProps> = (props) => {
  return (
    <SvgIconSmallViewBox {...props}>
      <path
        d='M6.27042 10.5461L7.44942 11.7251L6.43053 14.4762C6.31408 14.7818 6.02297 14.971 5.71731 14.971C5.62997 14.971 5.54264 14.9565 5.4553 14.9274C5.0623 14.7818 4.85852 14.3452 5.00408 13.9522L6.27042 10.5461Z'
        fill='currentColor'
      />
      <path
        d='M4.0714 7.53301L4.77007 6.19389C4.95929 5.84456 5.23585 5.55344 5.57062 5.34967C7.1863 4.389 7.39008 4.40355 7.59385 4.41811L8.68552 4.49089C9.0203 4.50544 9.25319 4.59277 10.374 6.23756C10.4176 6.29578 10.4759 6.33945 10.5632 6.354L11.9023 6.55778C12.2662 6.616 12.5136 6.95078 12.4554 7.31467C12.3972 7.67856 12.0624 7.92601 11.6985 7.86779L10.3594 7.66401C9.92275 7.59123 9.52975 7.35834 9.28231 6.99445C9.22408 6.90712 9.18042 6.83434 9.12219 6.76156L8.26341 9.48346L9.45697 10.677C9.63164 10.8517 9.7772 11.0846 9.86453 11.3175L10.7815 13.9957C10.9271 14.3887 10.7088 14.8254 10.3158 14.9564C10.2284 14.9855 10.1556 15 10.0683 15C9.74808 15 9.45697 14.7963 9.34053 14.4906L8.42352 11.8124C8.40897 11.7832 8.39441 11.7687 8.37986 11.7396L6.25474 9.5999C5.94907 9.29424 5.83263 8.87212 5.91996 8.46457L6.38574 6.39767C6.34207 6.42678 6.2984 6.45589 6.24018 6.485C6.10918 6.55778 6.00729 6.67423 5.93451 6.80523L5.23585 8.14434C5.1194 8.36268 4.88651 8.49368 4.65362 8.49368C4.55173 8.49368 4.44984 8.46457 4.34795 8.4209C4.02773 8.24623 3.91129 7.85323 4.0714 7.53301Z'
        fill='currentColor'
      />
      <path
        d='M9.82609 2.77954C9.91394 1.97241 9.33085 1.24688 8.52372 1.15903C7.71658 1.07118 6.99106 1.65428 6.90321 2.46141C6.81536 3.26854 7.39845 3.99407 8.20558 4.08192C9.01272 4.16977 9.73824 3.58667 9.82609 2.77954Z'
        fill='currentColor'
      />
    </SvgIconSmallViewBox>
  )
}

export { WalkIcon }
