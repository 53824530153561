import React from 'react'

import { AnyColor, theme } from '@guiker/components-core'

type ArrowRightIconProps = {
  color?: AnyColor
}

const ArrowRightIcon: React.FC<ArrowRightIconProps> = ({ color: colorKey }) => {
  const color = theme.palette.getColor(colorKey)

  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M0.75 12H23.25' stroke={color} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M12.75 1.5L23.25 12L12.75 22.5'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export { ArrowRightIcon }
