import React from 'react'

import { SvgIconMediumViewBox, SvgIconProps } from '../../../SvgIcon'

type ZoomInIconProps = SvgIconProps

export const ZoomInIcon: React.FC<ZoomInIconProps> = (props) => {
  return (
    <SvgIconMediumViewBox {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.875 9.81102C19.8754 13.4768 17.6674 16.7819 14.2807 18.185C10.894 19.588 6.99562 18.8127 4.40349 16.2205C1.81136 13.6284 1.03603 9.73 2.43907 6.3433C3.84211 2.9566 7.14718 0.748619 10.813 0.749023C15.8176 0.749576 19.8744 4.80645 19.875 9.81102Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.25 9.74902H15.25'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.75 14.249V5.24902'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.221 16.219L24.25 23.249'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIconMediumViewBox>
  )
}
