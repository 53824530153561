import React from 'react'

import { clsx } from '@guiker/clsx'
import { makeStyles, theme } from '@guiker/components-core'
import { default as MuiSwitch, SwitchProps } from '@material-ui/core/Switch'

export type BaseToggleProps = Omit<SwitchProps, 'color' | 'defaultValue'> & {
  maxWidth?: string | number
  label?: string
  adornment?: React.ReactNode
  description?: string
  readOnly?: boolean
}

const useStyles = makeStyles({
  root: {
    width: 40,
    height: 24,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: theme.palette.primary.main,
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 22,
    height: 22,
    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24)',
    backgroundColor: theme.palette.common.white,
  },
  track: {
    borderRadius: 24 / 2,
    background: theme.palette.grey[5],
    opacity: 1,
    transition: theme.transitions.create(['background-color']),
  },
  checked: {},
  focusVisible: {},
})

const BaseToggle: React.FC<BaseToggleProps> = ({ className, disabled, ...other }) => {
  const classes = useStyles({ disabled })

  return (
    <MuiSwitch
      className={clsx(classes.root, className)}
      focusVisibleClassName={classes.focusVisible}
      disabled={disabled}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...other}
    />
  )
}

export { BaseToggle }
