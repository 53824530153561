import React from 'react'

import { Dropdown as CLDropdown } from '@guiker/components-library'
import { FieldPath } from '@guiker/react-hook-form'

import { useApiFormContext } from '../Form'
import { DropdownProps as RHFDropdownProps, RHFDropdown } from './RHFDropdown'

export type DropdownProps<T extends object, P extends FieldPath<T>> = RHFDropdownProps<T, P>

const Dropdown = <T extends object = any, P extends FieldPath<T> = any>({
  defaultValue,
  ...props
}: DropdownProps<T, P>) => {
  const { readOnly: contextReadOnly } = useApiFormContext({ shouldThrowIfUndefined: false })
  if (props.readOnly) {
    return <CLDropdown value={defaultValue} {...props} />
  } else {
    return <RHFDropdown defaultValue={defaultValue} readOnly={contextReadOnly} {...props} />
  }
}

export { Dropdown }
