import React from 'react'

import { makeStyles, theme, toPx } from '@guiker/components-core'

const useStyle = makeStyles({
  root: {
    scrollMarginTop: toPx(theme.dimensions.appBar.height.desktop + theme.spacing(3)),
    [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
      scrollMarginTop: toPx(theme.dimensions.appBar.height.mobile + theme.spacing(2)),
    },
  },
})

export const SideMenuScrollableItem: React.FC<React.PropsWithChildren & { id: string }> = ({ children, id }) => {
  const classes = useStyle()

  return (
    <div id={id} className={classes.root}>
      {children}
    </div>
  )
}
