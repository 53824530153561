export enum LocalStorageKey {
  OFFICE_NAVIGATION = 'office-webapp.nagivation',
  SERVICES_PACKAGE_TYPE = 'servicesPackageType',
  WEBAPP_SESSION = 'webapp.session',
  OFFICE_WEBAPP_SESSION = 'office-webapp.session',
  WILLOW_WEBAPP_SESSION = 'willow-webapp.session',
  SESSION = 'session',
  I18N = 'i18nextLng',
  DIRECTED_SOURCE = 'directedSource',
}
