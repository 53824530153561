import i18n, { Resource } from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import { PRIMARY_LANGUAGE, supportedLanguages } from '@guiker/base-entity'
import * as defaultResources from '@guiker/i18n-resources'
import { merge } from '@guiker/lodash'

interface Options {
  resources?: Resource
  defaultNamespaces?: string
  namespaces?: string | [string]
  referenceLng?: string
}

const defaultOptions: Partial<Options> = {
  resources: {},
  defaultNamespaces: 'common',
  namespaces: '',
  referenceLng: PRIMARY_LANGUAGE,
}

export function initialize(options?: Options): typeof i18n {
  const defaultedOptions = { ...defaultOptions, ...options }

  const i18nInstance = i18n.createInstance()
  const resources = merge(defaultResources, defaultedOptions.resources)

  i18nInstance
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
      supportedLngs: supportedLanguages,
      fallbackLng: defaultedOptions.referenceLng,
      saveMissing: true,
      ns: defaultedOptions.namespaces,
      defaultNS: defaultedOptions.defaultNamespaces,
      interpolation: {
        escapeValue: false,
      },
      resources,
      react: {
        bindI18n: 'languageChanged',
        useSuspense: false,
      },
    })

  return i18nInstance
}
