import React from 'react'

import { AnyColor, theme } from '@guiker/components-core'

type DownloadMiniIconProps = {
  color?: AnyColor
}

const DownloadMiniIcon: React.FC<DownloadMiniIconProps> = ({ color }) => {
  const colorKey = theme.palette.getColor(color)
  const fill = colorKey || 'currentColor'

  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M15.1107 11.292C14.8964 11.292 14.6909 11.3771 14.5394 11.5287C14.3879 11.6802 14.3027 11.8857 14.3027 12.1V13.3367C14.3024 13.6143 14.1919 13.8804 13.9956 14.0766C13.7992 14.2728 13.533 14.3832 13.2554 14.3833H2.74341C2.46581 14.3832 2.19962 14.2728 2.00327 14.0766C1.80691 13.8804 1.69643 13.6143 1.69608 13.3367V12.1C1.69608 11.8857 1.61095 11.6802 1.45942 11.5287C1.30789 11.3771 1.10237 11.292 0.888078 11.292C0.673783 11.292 0.468265 11.3771 0.316736 11.5287C0.165206 11.6802 0.0800781 11.8857 0.0800781 12.1V13.3367C0.0800776 14.0433 0.360571 14.7211 0.859948 15.2211C1.35932 15.7211 2.03675 16.0024 2.74341 16.0033H13.2554C13.9627 16.0033 14.6409 15.7224 15.141 15.2223C15.6411 14.7222 15.9221 14.0439 15.9221 13.3367V12.1C15.9221 11.9936 15.9011 11.8883 15.8603 11.79C15.8194 11.6918 15.7596 11.6025 15.6842 11.5275C15.6089 11.4524 15.5194 11.393 15.421 11.3525C15.3226 11.3121 15.2171 11.2916 15.1107 11.292Z'
        fill={fill}
      />
      <path
        d='M7.9995 0C7.65666 0 7.32787 0.136191 7.08544 0.378613C6.84302 0.621035 6.70683 0.94983 6.70683 1.29267V7.33667C6.70683 7.38087 6.68927 7.42326 6.65801 7.45452C6.62676 7.48577 6.58437 7.50333 6.54016 7.50333H4.7675C4.64349 7.50338 4.52212 7.53909 4.41783 7.60619C4.31355 7.67329 4.23076 7.76896 4.17933 7.8818C4.1279 7.99464 4.11 8.11988 4.12776 8.24261C4.14552 8.36534 4.19819 8.48037 4.2795 8.574L7.5115 12.2913C7.57214 12.3612 7.64709 12.4173 7.73129 12.4557C7.81548 12.4942 7.90695 12.514 7.9995 12.514C8.09204 12.514 8.18351 12.4942 8.26771 12.4557C8.3519 12.4173 8.42686 12.3612 8.4875 12.2913L11.7195 8.574C11.8008 8.48037 11.8535 8.36534 11.8712 8.24261C11.889 8.11988 11.8711 7.99464 11.8197 7.8818C11.7682 7.76896 11.6854 7.67329 11.5812 7.60619C11.4769 7.53909 11.3555 7.50338 11.2315 7.50333H9.45883C9.41463 7.50333 9.37223 7.48577 9.34098 7.45452C9.30972 7.42326 9.29216 7.38087 9.29216 7.33667V1.29267C9.29216 0.94983 9.15597 0.621035 8.91355 0.378613C8.67113 0.136191 8.34233 0 7.9995 0Z'
        fill={fill}
      />
    </svg>
  )
}

export { DownloadMiniIcon }
