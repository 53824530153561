import * as React from 'react'

import { default as MuiList, ListProps as MuiListProps } from '@material-ui/core/List'

export type ListProps = MuiListProps

const List: React.FC<ListProps> = (props) => {
  return <MuiList {...(props as any)} />
}

export { List }
