import React from 'react'

import { SvgIconLargerViewBox, SvgIconProps } from '../../../SvgIcon'

const FlexHoursIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIconLargerViewBox {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M48 18C52.4183 18 56 14.4183 56 10C56 5.58172 52.4183 2 48 2C43.5817 2 40 5.58172 40 10C40 14.4183 43.5817 18 48 18Z'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M34 38V42H40L42 62H54L56 42H62V36C61.9971 31.2005 59.5373 26.7366 55.4814 24.1706C51.4255 21.6046 46.3382 21.2936 42 23.3466'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M48 22V34' stroke='currentColor' strokeWidth='3' strokeLinecap='round' strokeLinejoin='round' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18 34C26.8366 34 34 26.8366 34 18C34 9.16344 26.8366 2 18 2C9.16344 2 2 9.16344 2 18C2 26.8366 9.16344 34 18 34Z'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M24.856 18H18V11.1413'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIconLargerViewBox>
  )
}

export { FlexHoursIcon }
