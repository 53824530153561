import React from 'react'

import { NumericInput as CLNumericInput } from '@guiker/components-library'
import { FieldPath } from '@guiker/react-hook-form'

import { useApiFormContext } from '../Form'
import { RHFNumericInput, RHFNumericInputProps } from './RHFNumericInput'

export type NumericInputProps<T extends object, P extends FieldPath<T>> = RHFNumericInputProps<T, P>

const NumericInput = <T extends object = any, P extends FieldPath<T> = any>({
  defaultValue = 0 as any,
  ...props
}: NumericInputProps<T, P>) => {
  const { readOnly: contextReadOnly } = useApiFormContext({ shouldThrowIfUndefined: false })
  if (props.readOnly) {
    return <CLNumericInput defaultValue={defaultValue} {...props} />
  } else {
    return <RHFNumericInput defaultValue={defaultValue} readOnly={contextReadOnly} {...props} />
  }
}

export { NumericInput }
