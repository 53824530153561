import React from 'react'

const NationalBankIcon: React.FC = () => {
  return (
    <svg width='37' height='25' viewBox='0 0 37 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.156921 2.43091C0.156921 1.60248 0.828494 0.930908 1.65692 0.930908H34.6569C35.4853 0.930908 36.1569 1.60248 36.1569 2.43091V23.4309C36.1569 24.2593 35.4853 24.9309 34.6569 24.9309H1.65692C0.828495 24.9309 0.156921 24.2593 0.156921 23.4309V2.43091Z'
        fill='#00324D'
      />
      <path
        d='M20.0011 9.23216C19.7423 9.29687 19.5643 9.33731 19.3783 9.25643C19.2408 9.20789 19.1195 9.03804 19.0224 8.90053L17.2915 6.53059C17.1863 6.39309 17.0731 6.22323 16.9356 6.1747C16.7576 6.1019 16.5635 6.15043 16.3128 6.19896L10.7074 7.69534V18.1215L16.3208 16.6251C16.5797 16.5765 16.7657 16.5199 16.9437 16.6008C17.0812 16.6655 17.2025 16.8192 17.2996 16.9567L19.0224 19.3347C19.1276 19.4722 19.2408 19.6259 19.3783 19.6906C19.5563 19.7553 19.7504 19.7149 20.0011 19.6664L25.6065 18.17V7.73578L20.0011 9.23216Z'
        fill='#E41C23'
      />
    </svg>
  )
}

export { NationalBankIcon }
