import React from 'react'

import { isAtMostTablette, makeStyles, theme } from '@guiker/components-core'
import { isString } from '@guiker/shared-framework'

import { useLayoutContext } from '../../../hooks'
import { Display3, DisplayBodyBig } from '../../Data Display'
import { ColumnGridLayout } from '../ColumnGridLayout'
import { Flex } from '../Flex'

const useStyle = makeStyles({
  contentContainer: {
    minHeight: 500,
    paddingTop: theme.spacing(10),
    [isAtMostTablette]: {
      paddingTop: 0,
      minHeight: 'auto',
    },
  },
  imageContainer: {
    padding: theme.spacing(2),
    alignItems: 'center',
    justifyContent: 'center',
    '& > img': {
      maxWidth: 500,
      maxHeight: 500,
      [isAtMostTablette]: {
        objectFit: 'contain',
        width: '100%',
      },
    },
    '& > object': {
      maxWidth: 500,
      maxHeight: 600,
      [isAtMostTablette]: {
        objectFit: 'contain',
        width: '100%',
      },
    },
    [isAtMostTablette]: {
      minHeight: 'initial',
    },
  },
})

type StaticComponentProps = {
  minHeight?: number
  maxHeight?: number
}

type StaticPageContentProps = React.PropsWithChildren & {
  direction?: 'left' | 'right'
  asset?: string | React.ReactNode
  assetProps?: StaticComponentProps
  blockProps?: StaticComponentProps
}

export const StaticPageContent: React.FC<StaticPageContentProps> = ({ direction = 'left', asset, children }) => {
  const classes = useStyle({ direction })
  const { isAtMostTablette } = useLayoutContext()

  const textContent = (
    <Flex flexDirection='column' className={classes.contentContainer}>
      {children}
    </Flex>
  )

  return (
    <ColumnGridLayout
      gap={12}
      gridColumn={{ start: 'auto', span: 1 }}
      gridTemplateColumns={2}
      sm={{ gap: 6, gridTemplateColumns: 1, gridColumn: { span: 1 } }}
    >
      {isAtMostTablette || direction === 'left' ? textContent : <></>}
      {asset && (
        <Flex className={classes.imageContainer}>
          {isString(asset) ? <img src={asset} alt='sectionAsset' /> : asset}
        </Flex>
      )}
      {!isAtMostTablette && direction === 'right' ? textContent : <></>}
    </ColumnGridLayout>
  )
}

type StaticPageContentItem = {
  adornment?: React.ReactNode
  title: string
  asset: React.ReactNode | string
  content: React.ReactNode
  cta?: React.ReactNode
}

type StaticPageContentsProps = {
  items: StaticPageContentItem[]
}

export const StaticPageContents: React.FC<StaticPageContentsProps> = ({ items }) => {
  const { isAtMostTablette } = useLayoutContext()

  return (
    <Flex gap={15} flexDirection='column'>
      {items.map((item, index) => (
        <StaticPageContent asset={item.asset} direction={index % 2 === 0 ? 'left' : 'right'}>
          <Flex flexDirection='column' gap={isAtMostTablette ? 5 : 8}>
            <Flex flexDirection='column' gap={isAtMostTablette ? 4 : 5}>
              <Flex gap={2} alignItems='baseline'>
                {item.adornment}
                <Display3 mb={0}>{item.title}</Display3>
              </Flex>
              <DisplayBodyBig breakline whiteSpace='pre-line'>
                {item.content}
              </DisplayBodyBig>
            </Flex>
            <Flex justifyContent='center'>{item.cta}</Flex>
          </Flex>
        </StaticPageContent>
      ))}
    </Flex>
  )
}
