import React from 'react'

import { SvgIconMediumViewBox, SvgIconProps } from '../../../SvgIcon'

const RentalIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIconMediumViewBox {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.5 9.75C21.1569 9.75 22.5 8.40685 22.5 6.75C22.5 5.09315 21.1569 3.75 19.5 3.75C17.8431 3.75 16.5 5.09315 16.5 6.75C16.5 8.40685 17.8431 9.75 19.5 9.75Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M23.25 17.25V15C23.25 12.9289 21.5711 11.25 19.5 11.25C17.4289 11.25 15.75 12.9289 15.75 15V17.25H17.25L18 23.25H21L21.75 17.25H23.25Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9 13.5H2.25V5.75C2.25 5.47386 2.47386 5.25 2.75 5.25H8.5C8.77614 5.25 9 5.47386 9 5.75V13.5Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9 13.5H14.25V1.5C14.25 1.08579 13.9142 0.75 13.5 0.75H6.75C6.33579 0.75 6 1.08579 6 1.5V5.25'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M2.25 13.5H0.75' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M6 8.25H9' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M12 5.25H14.25' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M12 8.25H14.25' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    </SvgIconMediumViewBox>
  )
}

export { RentalIcon }
