import React from 'react'

import { Button, ButtonProps } from '../..'
import { Modal, ModalProps } from '..'

export type SuccessDialogProps = ModalProps & {
  text: string | React.ReactNode
  description?: string | React.ReactNode
  illustration?: React.ReactNode
  buttonProps?: ButtonProps
  maxWidth?: number | string
}

const SuccessDialog: React.FC<SuccessDialogProps> = ({
  text,
  buttonProps,
  maxWidth = 380,
  description,
  onClose,
  ...props
}) => {
  return (
    <Modal
      maxWidth={maxWidth}
      onClose={onClose}
      actions={buttonProps && <Button {...buttonProps} />}
      title={text}
      subtitle={description}
      {...props}
    ></Modal>
  )
}

export { SuccessDialog }
