import React, { Children, cloneElement } from 'react'

import { isAtMostTablette, makeStyles } from '@guiker/components-core'
import { default as MuiTabs, TabsProps as MuiTabsProps } from '@material-ui/core/Tabs'

const useStyles = makeStyles(
  (theme) => ({
    indicator: {
      backgroundColor: theme.palette.primary.main,
      height: ({ size }: TabsProps) => (size === 'small' ? 3 : 4),
    },
    root: ({ withBorder }: TabsProps) =>
      withBorder
        ? {
            overflow: 'hidden',
            boxShadow: `inset 0px -1px 0px 0px ${theme.palette.grey[10]}`,
          }
        : {},
    flexContainer: ({ size, variant, noPadding }: TabsProps) => {
      const desktopXPadding = size === 'large' ? 7 : size === 'medium' ? 4 : 0
      const desktopGap = size === 'large' ? 7 : size === 'medium' ? 4 : 2
      const mobileSpacing = variant === 'fullWidth' ? 0 : size === 'large' ? 3 : 2

      return {
        justifyContent: variant === 'fullWidth' ? 'space-between' : 'flex-start',
        paddingLeft: theme.spacing(noPadding ? 0 : desktopXPadding),
        paddingRight: theme.spacing(noPadding ? 0 : desktopXPadding),
        gap: theme.spacing(desktopGap),
        [isAtMostTablette]: {
          gap: theme.spacing(mobileSpacing),
          paddingLeft: theme.spacing(mobileSpacing),
          paddingRight: theme.spacing(mobileSpacing),
        },
      }
    },
  }),
  {
    name: 'Tabs',
  },
)

export type TabsProps = MuiTabsProps & {
  withBorder?: boolean
  size?: 'medium' | 'large' | 'small'
  noPadding?: boolean
}

const Tabs: React.FC<TabsProps> = ({ children, size, variant, noPadding = false, withBorder = false, ...props }) => {
  const classes = useStyles({ withBorder, size, variant, noPadding })

  return (
    <MuiTabs
      variant={variant}
      classes={{
        root: classes.root,
        flexContainer: classes.flexContainer,
        indicator: classes.indicator,
      }}
      {...props}
    >
      {Children.toArray(children)
        .filter((child) => !!child)
        .reduce((acc: any[], node: React.ReactElement) => {
          return [
            ...acc,
            cloneElement(node, {
              size,
            }),
          ]
        }, [])}
    </MuiTabs>
  )
}

export { Tabs }
