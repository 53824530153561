import React from 'react'

import { ErrorToaster, FullScreenSpinner } from '..'

type Props = {
  toasterMessage?: string | React.ReactNode
  toasterType?: 'error' | 'info' | 'success' | 'warning'
  toasterDuration?: number | null // pass null to disable auto hide
  loading?: boolean
  onToasterClose?: () => void
  showClose?: boolean
}

const FeedbackComponent: React.FC<Props> = ({
  toasterMessage,
  toasterType = 'error',
  toasterDuration = 3000,
  loading = false,
  onToasterClose = null,
  showClose = false,
}) => {
  return (
    <>
      {!!toasterMessage && (
        <ErrorToaster
          autoHideDuration={toasterDuration}
          type={toasterType}
          message={toasterMessage}
          onClose={onToasterClose}
          showClose={showClose}
        />
      )}
      {loading && <FullScreenSpinner />}
    </>
  )
}

export { FeedbackComponent, Props as FeedbackComponentProps }
