import React from 'react'

import { AnyColor, theme } from '@guiker/components-core'

const SwimmingIcon: React.FC<{ color?: AnyColor }> = ({ color: colorKey }) => {
  const color = theme.palette.getColor(colorKey)
  return (
    <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0)'>
        <path
          d='M23.25 22.4817C20.799 22.9917 19.278 20.5457 19.278 20.5457C18.8993 21.1471 18.3778 21.6454 17.7597 21.9963C17.1417 22.3472 16.4465 22.5397 15.736 22.5567C15.0649 22.5432 14.4097 22.3494 13.8393 21.9956C13.2689 21.6418 12.8042 21.1409 12.494 20.5457V20.5457C12.0372 21.1395 11.4567 21.627 10.7928 21.9742C10.1289 22.3215 9.39736 22.5202 8.649 22.5567C7.94305 22.5223 7.25508 22.3223 6.64066 21.973C6.02624 21.6237 5.50259 21.1347 5.112 20.5457V20.5457C5.112 20.5457 3.148 23.0367 0.75 22.5217'
          stroke={color || 'currentColor'}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M23.25 17.9817C20.799 18.4917 19.278 16.0457 19.278 16.0457C18.8993 16.6471 18.3778 17.1454 17.7597 17.4963C17.1417 17.8472 16.4465 18.0397 15.736 18.0567C15.0649 18.0432 14.4097 17.8494 13.8393 17.4956C13.2689 17.1418 12.8042 16.6409 12.494 16.0457C12.0372 16.6395 11.4567 17.127 10.7928 17.4742C10.1289 17.8215 9.39736 18.0202 8.649 18.0567C7.94305 18.0223 7.25508 17.8223 6.64066 17.473C6.02624 17.1237 5.50259 16.6347 5.112 16.0457V16.0457C5.112 16.0457 3.148 18.5367 0.75 18.0217'
          stroke={color || 'currentColor'}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M16.875 8.60669C16.875 9.30288 17.1516 9.97056 17.6438 10.4628C18.1361 10.9551 18.8038 11.2317 19.5 11.2317C20.1962 11.2317 20.8639 10.9551 21.3562 10.4628C21.8484 9.97056 22.125 9.30288 22.125 8.60669C22.125 7.9105 21.8484 7.24282 21.3562 6.75053C20.8639 6.25825 20.1962 5.98169 19.5 5.98169C18.8038 5.98169 18.1361 6.25825 17.6438 6.75053C17.1516 7.24282 16.875 7.9105 16.875 8.60669Z'
          stroke={color || 'currentColor'}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M7.37891 14.0366L11.2099 11.6366L8.39991 8.43063C8.09795 8.08559 7.8791 7.67592 7.76016 7.2331C7.64123 6.79029 7.62538 6.3261 7.71384 5.8762C7.80229 5.4263 7.99269 5.00266 8.27042 4.63783C8.54814 4.273 8.9058 3.97668 9.31591 3.77163L13.5789 1.63663C13.7553 1.54669 13.9477 1.49256 14.1451 1.47734C14.3424 1.46212 14.5409 1.48613 14.7289 1.54797C14.917 1.60981 15.091 1.70827 15.2408 1.83766C15.3906 1.96705 15.5133 2.12483 15.6019 2.30188C15.6905 2.47893 15.7431 2.67177 15.7568 2.86926C15.7705 3.06675 15.7449 3.265 15.6816 3.45257C15.6183 3.64015 15.5185 3.81333 15.3879 3.96214C15.2574 4.11096 15.0986 4.23245 14.9209 4.31963L11.5209 6.01963C11.4189 6.07175 11.3302 6.1465 11.2616 6.23817C11.1929 6.32983 11.1461 6.43598 11.1248 6.5485C11.1035 6.66102 11.1082 6.77693 11.1385 6.88736C11.1689 6.99779 11.2241 7.09981 11.2999 7.18563L16.6769 13.3306'
          stroke={color || 'currentColor'}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0'>
          <rect width='24' height='24' fill='white' transform='translate(0 0.0366211)' />
        </clipPath>
      </defs>
    </svg>
  )
}

export { SwimmingIcon }
