import * as React from 'react'

import { clsx } from '@guiker/clsx'
import { AnyColor, makeStyles } from '@guiker/components-core'

import { Flex } from '../../Layout'

export type IconButtonProps = React.PropsWithChildren & {
  color?: AnyColor
  onClick?: (args?: unknown) => unknown
  className?: string
}

const useStyles = makeStyles(
  (theme) => ({
    root: ({ color }: { color?: AnyColor }) => ({
      color: theme.palette.getColor(color),
      '&:hover': {
        color: theme.palette.getColor(color, 'hover'),
      },
      cursor: 'pointer',
    }),
  }),
  { name: 'IconButton' },
)

const IconButton: React.FC<IconButtonProps> = ({ className, onClick, children, color = 'primary' }) => {
  const classes = useStyles({ color })

  return (
    <Flex onClick={onClick} className={clsx(classes.root, className)}>
      {children}
    </Flex>
  )
}

export { IconButton }
