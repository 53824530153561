import React from 'react'

const HsbcIcon: React.FC = () => {
  return (
    <svg width='37' height='25' viewBox='0 0 37 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M2.27835 0.311157H35.2784C35.9687 0.311157 36.5284 0.870802 36.5284 1.56116V22.5612C36.5284 23.2515 35.9687 23.8112 35.2784 23.8112H2.27835C1.588 23.8112 1.02835 23.2515 1.02835 22.5612V1.56116C1.02835 0.870801 1.58799 0.311157 2.27835 0.311157Z'
        fill='white'
        stroke='#D7D7D7'
        strokeWidth='0.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M31.2978 12.0685L25.0418 5.79773V18.3246L31.2978 12.0685Z' fill='#DB0011' />
      <path d='M18.7857 12.0685L25.0418 5.79773H12.5296L18.7857 12.0685Z' fill='#DB0011' />
      <path d='M6.25885 12.0685L12.5296 18.3246V5.79773L6.25885 12.0685Z' fill='#DB0011' />
      <path d='M18.7857 12.0685L12.5296 18.3246H25.0418L18.7857 12.0685Z' fill='#DB0011' />
    </svg>
  )
}

export { HsbcIcon }
