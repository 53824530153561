import React, { useLayoutEffect } from 'react'

import { makeStyles, useLayoutContext } from '../../../hooks'
import { theme } from '../../../styles'
import { Box } from '../../Layout'
import { TakeOverHeader, TakeOverHeaderProps } from '../TakeOverHeader'

const useStyles = makeStyles(
  {
    root: {
      position: 'relative',
      top: 0,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100%',
      zIndex: theme.zIndexs.takeOver,
    },
  },
  { name: 'TakeOver' },
)

export type TakeOverProps = React.PropsWithChildren & TakeOverHeaderProps

export const TakeOver: React.FC<TakeOverProps> = (props) => {
  const {
    children,
    onBack,
    onClose,
    title,
    subtitleProps,
    subtitle,
    titleProps,
    hasBorder,
    hasProgressBar,
    progressBarProps,
    ...otherProps
  } = props

  const classes = useStyles()
  const { setIsTakeover } = useLayoutContext()

  useLayoutEffect(() => {
    setIsTakeover(true)
    return () => setIsTakeover(false)
  }, [])

  return (
    <Box className={classes.root} {...otherProps}>
      <TakeOverHeader
        onBack={onBack}
        onClose={onClose}
        title={title}
        titleProps={titleProps}
        subtitle={subtitle}
        subtitleProps={subtitleProps}
        hasBorder={hasBorder}
        hasProgressBar={hasProgressBar}
        progressBarProps={progressBarProps}
      />
      {children}
    </Box>
  )
}
