import React from 'react'

import { AnyColor, theme } from '@guiker/components-core'

export interface NotesTasksIconProps {
  color?: AnyColor
}

const NotesTasksIcon: React.FC<NotesTasksIconProps> = ({ color: colorKey = 'primary' }) => {
  const color = theme.palette.getColor(colorKey)

  return (
    <svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M15.875 4.5H19.625C20.0228 4.5 20.4044 4.65804 20.6857 4.93934C20.967 5.22064 21.125 5.60218 21.125 6V21.75C21.125 22.1478 20.967 22.5294 20.6857 22.8107C20.4044 23.092 20.0228 23.25 19.625 23.25H4.625C4.22718 23.25 3.84564 23.092 3.56434 22.8107C3.28304 22.5294 3.125 22.1478 3.125 21.75V6C3.125 5.60218 3.28304 5.22064 3.56434 4.93934C3.84564 4.65804 4.22718 4.5 4.625 4.5H8.375C8.375 3.50544 8.77009 2.55161 9.47335 1.84835C10.1766 1.14509 11.1304 0.75 12.125 0.75C13.1196 0.75 14.0734 1.14509 14.7767 1.84835C15.4799 2.55161 15.875 3.50544 15.875 4.5V4.5Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.125 3.75C12.1992 3.75 12.2717 3.77199 12.3333 3.8132C12.395 3.8544 12.4431 3.91297 12.4715 3.98149C12.4998 4.05002 12.5073 4.12542 12.4928 4.19816C12.4783 4.2709 12.4426 4.33772 12.3902 4.39016C12.3377 4.44261 12.2709 4.47833 12.1982 4.49279C12.1254 4.50726 12.05 4.49984 11.9815 4.47145C11.913 4.44307 11.8544 4.39501 11.8132 4.33334C11.772 4.27167 11.75 4.19917 11.75 4.125C11.75 4.02554 11.7895 3.93016 11.8598 3.85983C11.9302 3.78951 12.0255 3.75 12.125 3.75'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M6.875 10.5H12.125' stroke={color} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M12.125 10.5H17.375' stroke={color} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M17.375 14.25H8.125' stroke={color} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M9.125 14.25H6.875' stroke={color} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M6.875 18H12.125' stroke={color} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M11.125 18H17.375' stroke={color} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}

export { NotesTasksIcon }
