import React from 'react'

import { Flex, H5, P } from '../..'

type EmptyStateMessageProps = {
  label: String
  description?: String
}

const EmptyStateMessage: React.FC<EmptyStateMessageProps> = ({ label, description = null }) => {
  return (
    <Flex flexDirection='column' alignItems='center' py={3}>
      <Flex flexDirection='column' maxWidth={500} alignItems='center'>
        <H5 textAlign='center' whiteSpace='pre-line'>
          {label}
        </H5>
        {!!description && (
          <P textAlign='center' whiteSpace='pre-line' mb={0}>
            {description}
          </P>
        )}
      </Flex>
    </Flex>
  )
}

export { EmptyStateMessage }
