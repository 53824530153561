import React from 'react'

import { SvgIconMediumViewBox, SvgIconProps } from '../../../SvgIcon'

type TVIconProps = SvgIconProps

const TVIcon: React.FC<TVIconProps> = (props) => {
  return (
    <SvgIconMediumViewBox {...props}>
      <path
        d='M2.25 15C2.05109 15 1.86032 15.079 1.71967 15.2197C1.57902 15.3603 1.5 15.5511 1.5 15.75V21.75C1.5 21.9489 1.57902 22.1397 1.71967 22.2803C1.86032 22.421 2.05109 22.5 2.25 22.5H5.25C5.44891 22.5 5.63968 22.421 5.78033 22.2803C5.92098 22.1397 6 21.9489 6 21.75V20.25C6 20.0511 6.07902 19.8603 6.21967 19.7197C6.36032 19.579 6.55109 19.5 6.75 19.5H17.25C17.4489 19.5 17.6397 19.579 17.7803 19.7197C17.921 19.8603 18 20.0511 18 20.25V21.75C18 21.9489 18.079 22.1397 18.2197 22.2803C18.3603 22.421 18.5511 22.5 18.75 22.5H21.75C21.9489 22.5 22.1397 22.421 22.2803 22.2803C22.421 22.1397 22.5 21.9489 22.5 21.75V15.75C22.5 15.5511 22.421 15.3603 22.2803 15.2197C22.1397 15.079 21.9489 15 21.75 15H2.25Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.5 1.5H19.5V12H4.5V1.5Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M10.5 12L9.75 15' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M13.5 12L14.25 15'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIconMediumViewBox>
  )
}

export { TVIcon }
