import React from 'react'

import { SvgIconMediumViewBox, SvgIconProps } from '../../../SvgIcon'

type OvenIconProps = SvgIconProps

const OvenIcon: React.FC<OvenIconProps> = (props) => {
  return (
    <SvgIconMediumViewBox {...props}>
      <path
        d='M2.25 1.5H21.75V22.5H2.25V1.5Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M0.75 1.5H23.25' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M0.75 22.5H23.25' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M5.25 10.5H18.75V19.5H5.25V10.5Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M9 13.5H15' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M5.25 4.125C5.32417 4.125 5.39667 4.14699 5.45834 4.1882C5.52001 4.2294 5.56807 4.28797 5.59645 4.35649C5.62484 4.42502 5.63226 4.50042 5.61779 4.57316C5.60333 4.6459 5.56761 4.71272 5.51516 4.76516C5.46272 4.81761 5.3959 4.85333 5.32316 4.86779C5.25042 4.88226 5.17502 4.87484 5.10649 4.84645C5.03797 4.81807 4.9794 4.77001 4.9382 4.70834C4.89699 4.64667 4.875 4.57417 4.875 4.5C4.875 4.40054 4.91451 4.30516 4.98484 4.23484C5.05516 4.16451 5.15054 4.125 5.25 4.125Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.25 4.125C8.32417 4.125 8.39667 4.14699 8.45834 4.1882C8.52001 4.2294 8.56807 4.28797 8.59645 4.35649C8.62484 4.42502 8.63226 4.50042 8.61779 4.57316C8.60332 4.6459 8.56761 4.71272 8.51517 4.76516C8.46272 4.81761 8.3959 4.85333 8.32316 4.86779C8.25042 4.88226 8.17502 4.87484 8.10649 4.84645C8.03797 4.81807 7.9794 4.77001 7.9382 4.70834C7.89699 4.64667 7.875 4.57417 7.875 4.5C7.875 4.40054 7.91451 4.30516 7.98484 4.23484C8.05516 4.16451 8.15054 4.125 8.25 4.125'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M2.25 7.5H21.75' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M13.5 4.5H18.75' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    </SvgIconMediumViewBox>
  )
}

export { OvenIcon }
