import React from 'react'

import { clsx } from '@guiker/clsx'
import { isAtMostTablette, makeStyles, padding } from '@guiker/components-core'
import { default as MuiTab, TabProps as MuiTabProps } from '@material-ui/core/Tab'

const useStyles = makeStyles(
  (theme) => ({
    root: ({ size }: TabProps) => ({
      ...(size === 'small' ? theme.typography.variants.bodySmall : theme.typography.variants.body),
      padding: size === 'large' ? padding(3, 2) : size === 'medium' ? padding(2, 2) : padding(2, 1),
      [isAtMostTablette]: {
        padding: size === 'large' ? padding(2.5, 2) : padding(2, 1),
      },
      color: theme.palette.text.primary.main,
      textTransform: 'none',
      minWidth: 80,
      opacity: 1,
      '&.Mui-selected': {
        color: theme.palette.text.primary.main,
      },
    }),
    wrapper: {
      flexDirection: 'row',
      gap: theme.spacing(1.5),
      '&> *:first-child': {
        marginBottom: `0px !important`,
      },
    },
    labelIcon: {
      minHeight: 'initial',
    },
  }),
  {
    name: 'Tab',
  },
)

export type TabProps = MuiTabProps & {
  size?: 'medium' | 'large' | 'small'
  Wrapper?: React.FC<React.PropsWithChildren>
}

const Tab: React.FC<TabProps> = ({ className, Wrapper, ...props }) => {
  const classes = useStyles(props)

  return (
    <MuiTab
      {...props}
      disableRipple={true}
      component={Wrapper}
      className={clsx(classes.root, className)}
      classes={{ wrapper: classes.wrapper, labelIcon: classes.labelIcon }}
    />
  )
}

export { Tab }
