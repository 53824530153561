import React from 'react'

import { AnyColor, theme } from '@guiker/components-core'

export interface ChevronIconProps {
  color?: AnyColor
}

const ChevronIcon: React.FC<ChevronIconProps> = ({ color: colorKey }) => {
  const color = theme.palette.getColor(colorKey)

  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M20 8L12.3769 15.8393C12.3274 15.8902 12.2687 15.9307 12.204 15.9582C12.1393 15.9858 12.07 16 12 16C11.93 16 11.8607 15.9858 11.796 15.9582C11.7313 15.9307 11.6726 15.8902 11.6231 15.8393L4 8'
        stroke={color || 'currentColor'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export { ChevronIcon }
