import React from 'react'

import { SvgIconMediumViewBox, SvgIconProps } from '../../../SvgIcon'

type ZoomOutIconProps = SvgIconProps

export const ZoomOutIcon: React.FC<ZoomOutIconProps> = (props) => {
  return (
    <SvgIconMediumViewBox {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.875 9.81102C19.8754 13.4768 17.6675 16.7818 14.281 18.1849C10.8944 19.588 6.99605 18.8128 4.40384 16.2209C1.81164 13.629 1.03606 9.73073 2.43878 6.34399C3.84151 2.95726 7.14627 0.749023 10.812 0.749023C15.817 0.749023 19.8744 4.80606 19.875 9.81102Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.25 9.74902H15.25'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.221 16.219L24.25 23.249'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIconMediumViewBox>
  )
}
