import React from 'react'

import { ProgressTrackerItem, ProgressTrackerItemProps } from './ProgressTrackerItem'

export type ProgressTrackerProps = {
  className?: string
  items: ProgressTrackerItemProps[]
}

export const ProgressTracker: React.FC<ProgressTrackerProps> = ({ className, items }) => {
  return (
    <div className={className}>
      {items.map((item, index) => (
        <ProgressTrackerItem key={`progress-tracker-item-${index}`} {...item} />
      ))}
    </div>
  )
}
