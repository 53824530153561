import { useState } from 'react'

type EncodeType = 'base64'
type OutputType = 'application/pdf'

export const useBlob = () => {
  const [blobLink, setBlobLink] = useState<string>()

  const generateBlobLink = (args: {
    encoded: { content: string; type?: EncodeType }
    output: { type: OutputType }
  }) => {
    const { encoded, output } = args
    if (!encoded.content) return

    const { content, type: encodeType = 'base64' } = encoded
    const { type: outputType = 'application/pdf' } = output

    const buffer = Buffer.from(content, encodeType)
    const uint8array = new Uint8Array(buffer)
    const tempblob = new Blob([uint8array], { type: outputType })
    setBlobLink(URL.createObjectURL(tempblob))
  }

  return { blobLink, generateBlobLink }
}
