import React from 'react'

import { SvgIconMediumViewBox, SvgIconProps } from '../../../SvgIcon'

type PoolIconProps = SvgIconProps

const PoolIcon: React.FC<PoolIconProps> = (props) => {
  return (
    <SvgIconMediumViewBox {...props}>
      <path
        d='M0.75 22.8141C1.81668 23.2442 2.98439 23.3591 4.11444 23.1451C5.24449 22.9311 6.28938 22.3973 7.125 21.6071C8.23933 22.662 9.7155 23.2499 11.25 23.2499C12.7845 23.2499 14.2607 22.662 15.375 21.6071C16.4271 22.6029 17.804 23.1845 19.2515 23.2445C20.6989 23.3044 22.1192 22.8386 23.25 21.9331'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.25 18.75V3.75C17.25 2.95435 17.5661 2.19129 18.1287 1.62868C18.6913 1.06607 19.4544 0.75 20.25 0.75C21.0456 0.75 21.8087 1.06607 22.3713 1.62868C22.9339 2.19129 23.25 2.95435 23.25 3.75'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.25 18.75V3.75C8.25 2.95435 8.56607 2.19129 9.12868 1.62868C9.69129 1.06607 10.4544 0.75 11.25 0.75C12.0456 0.75 12.8087 1.06607 13.3713 1.62868C13.9339 2.19129 14.25 2.95435 14.25 3.75'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.25 11.25H17.25'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.25 15.75H17.25'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M8.25 6.75H17.25' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    </SvgIconMediumViewBox>
  )
}

export { PoolIcon }
