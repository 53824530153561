import { theme } from '..'
import { useMediaQuery } from '.'

export const useDeviceType = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))

  return {
    isMobile,
  }
}
