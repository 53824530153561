import React from 'react'

import { SvgIconProps, SvgIconSmallViewBox } from '../../../SvgIcon'

type CloseIconProps = SvgIconProps

const CloseIcon: React.FC<CloseIconProps> = (props) => {
  return (
    <SvgIconSmallViewBox {...props}>
      <path
        d='M15.1998 0.800049L0.799805 15.2'
        stroke='currentColor'
        strokeWidth='1.6'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M0.799805 0.800049L15.1998 15.2'
        stroke='currentColor'
        strokeWidth='1.6'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIconSmallViewBox>
  )
}

export { CloseIcon }
