import React from 'react'

import { makeStyles, padding } from '@guiker/components-core'

import { Typography } from '../../Data Display'
import { Flex } from '../../Layout'

const useStyles = makeStyles(
  (theme) => ({
    item: {
      cursor: 'pointer',
      width: '100%',
      flexGrow: 1,
      padding: padding(1.5, 2),
      '&:hover': {
        backgroundColor: theme.palette.secondary.hover,
      },
    },
    iconContainer: {
      width: 24,
      height: 24,
      minWidth: 24,
      minHeight: 24,
    },
    text: {
      whiteSpace: 'nowrap',
    },
  }),
  { name: 'BaseDrawerItem' },
)

type DrawerItemProps = React.PropsWithChildren & {
  item: {
    text?: string
    icon?: React.ReactNode
    onClick?: () => void
  }
}

export const DrawerItem: React.FC<DrawerItemProps> = ({ item, children }) => {
  const classes = useStyles()

  return (
    <Flex gap={2} flexWrap='nowrap' className={classes.item} onClick={item.onClick}>
      <Flex className={classes.iconContainer} alignItems='center' justifyContent='center'>
        {item.icon}
      </Flex>
      <Flex flexGrow={1}>
        <Typography mb={0} className={classes.text}>
          {item.text}
        </Typography>
      </Flex>
      {children}
    </Flex>
  )
}
