import React from 'react'

import { SvgIconProps, SvgIconSmallViewBox } from '../../../SvgIcon'

export const CheckCircle: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIconSmallViewBox {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM12.669 6.7433C13.0795 6.37384 13.1128 5.74155 12.7433 5.33104C12.3738 4.92053 11.7415 4.88726 11.331 5.25671L7.03624 9.12203L5.20711 7.2929C4.81658 6.90238 4.18342 6.90238 3.79289 7.2929C3.40237 7.68343 3.40237 8.31659 3.79289 8.70712L6.96376 11.878L12.669 6.7433Z'
        fill='currentColor'
      />
    </SvgIconSmallViewBox>
  )
}
