import React from 'react'

import { SvgIconLargerViewBox, SvgIconProps } from '../../SvgIcon'

type BusinessTeamIconProps = SvgIconProps

const BusinessTeamIcon: React.FC<BusinessTeamIconProps> = (props) => {
  return (
    <SvgIconLargerViewBox {...props}>
      <path
        d='M55.0695 62C52.5489 54.8229 45.7776 50.0145 38.1708 50H38.1548C30.5487 50.0148 23.7783 54.8233 21.2588 62'
        stroke={'currentColor'}
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M28.6777 29.416C33.9605 34.8896 42.0803 36.5208 49.0671 33.512'
        stroke={'currentColor'}
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M38.1543 46C44.2294 46 49.1543 41.0751 49.1543 35C49.1543 28.9249 44.2294 24 38.1543 24C32.0792 24 27.1543 28.9249 27.1543 35C27.1543 41.0751 32.0792 46 38.1543 46Z'
        stroke={'currentColor'}
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.2666 50C17.6849 50 21.2666 46.4183 21.2666 42C21.2666 37.5817 17.6849 34 13.2666 34C8.84832 34 5.2666 37.5817 5.2666 42C5.2666 46.4183 8.84832 50 13.2666 50Z'
        stroke={'currentColor'}
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.6373 54.2346C9.79084 53.0583 3.97184 56.3717 2 62'
        stroke={'currentColor'}
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M38.1631 50V62' stroke={'currentColor'} strokeWidth='3' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M62 62V2H28L36 10L28 18H62'
        stroke={'currentColor'}
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIconLargerViewBox>
  )
}

export { BusinessTeamIcon }
