import React from 'react'

import { SvgIconProps, SvgIconSmallViewBox } from '../../../SvgIcon'

const PaperclipSmallIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIconSmallViewBox {...props}>
      <path
        d='M12.5 10.4993V5C12.5 2.51472 10.4853 0.5 8 0.5C5.51472 0.5 3.5 2.51472 3.5 5V12.5C3.5 14.1569 4.84315 15.5 6.5 15.5C8.15685 15.5 9.5 14.1569 9.5 12.5V5C9.5 4.17157 8.82843 3.5 8 3.5C7.17157 3.5 6.5 4.17157 6.5 5V11.5'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIconSmallViewBox>
  )
}

export { PaperclipSmallIcon }
