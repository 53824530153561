import React from 'react'

import { TextField as CLTextField } from '@guiker/components-library'
import { FieldPath } from '@guiker/react-hook-form'

import { useApiFormContext } from '../Form'
import { RHFTextField, TextFieldProps as RHFTextFieldProps } from './RHFTextField'

export type TextFieldProps<T extends object = any, P extends FieldPath<T> = any> = RHFTextFieldProps<T, P>

const TextField = <T extends object = any, P extends FieldPath<T> = any>({
  defaultValue,
  ...props
}: TextFieldProps<T, P>) => {
  const { readOnly: contextReadOnly } = useApiFormContext({ shouldThrowIfUndefined: false })
  if (props.readOnly) {
    return <CLTextField value={defaultValue || '-'} {...props} />
  } else {
    return <RHFTextField defaultValue={defaultValue} readOnly={contextReadOnly} {...props} />
  }
}

export { TextField }
