import React from 'react'

import { SvgIconProps, SvgIconSmallViewBox } from '../../../SvgIcon'

type CheckmarkSmallIconProps = SvgIconProps

const CheckmarkSmallIcon: React.FC<CheckmarkSmallIconProps> = (props) => {
  return (
    <SvgIconSmallViewBox {...props}>
      <path d='M6.59998 13.35L1 7.75L3.09998 5.64999L6.5 9.05L12.9 2.65L15 4.75L6.59998 13.35Z' fill='currentColor' />
    </SvgIconSmallViewBox>
  )
}

export { CheckmarkSmallIcon }
