import React from 'react'

import { SvgIconMediumViewBox, SvgIconProps } from '../../../SvgIcon'

type ElevatorIconProps = SvgIconProps

const ElevatorIcon: React.FC<ElevatorIconProps> = (props) => {
  return (
    <SvgIconMediumViewBox {...props}>
      <path d='M6.75 0.75V6.75' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M9.75 3.75L6.75 0.75L3.75 3.75'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M17.25 6.75V0.75' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M20.25 3.75L17.25 6.75L14.25 3.75'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M0.75 9.75H23.25V23.25H0.75V9.75Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.25 15C5.25 15.5967 5.48705 16.169 5.90901 16.591C6.33097 17.0129 6.90326 17.25 7.5 17.25C8.09674 17.25 8.66903 17.0129 9.09099 16.591C9.51295 16.169 9.75 15.5967 9.75 15C9.75 14.4033 9.51295 13.831 9.09099 13.409C8.66903 12.9871 8.09674 12.75 7.5 12.75C6.90326 12.75 6.33097 12.9871 5.90901 13.409C5.48705 13.831 5.25 14.4033 5.25 15V15Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.25 22.5C11.25 21.5054 10.8549 20.5516 10.1517 19.8483C9.44839 19.1451 8.49456 18.75 7.5 18.75C6.50544 18.75 5.55161 19.1451 4.84835 19.8483C4.14509 20.5516 3.75 21.5054 3.75 22.5V23.25H11.25V22.5Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.25 15C14.25 15.2955 14.3082 15.5881 14.4213 15.861C14.5343 16.134 14.7001 16.3821 14.909 16.591C15.1179 16.7999 15.366 16.9657 15.639 17.0787C15.9119 17.1918 16.2045 17.25 16.5 17.25C16.7955 17.25 17.0881 17.1918 17.361 17.0787C17.634 16.9657 17.8821 16.7999 18.091 16.591C18.2999 16.3821 18.4657 16.134 18.5787 15.861C18.6918 15.5881 18.75 15.2955 18.75 15C18.75 14.7045 18.6918 14.4119 18.5787 14.139C18.4657 13.866 18.2999 13.6179 18.091 13.409C17.8821 13.2001 17.634 13.0343 17.361 12.9213C17.0881 12.8082 16.7955 12.75 16.5 12.75C16.2045 12.75 15.9119 12.8082 15.639 12.9213C15.366 13.0343 15.1179 13.2001 14.909 13.409C14.7001 13.6179 14.5343 13.866 14.4213 14.139C14.3082 14.4119 14.25 14.7045 14.25 15V15Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20.25 22.5C20.25 21.5054 19.8549 20.5516 19.1517 19.8483C18.4484 19.1451 17.4946 18.75 16.5 18.75C15.5054 18.75 14.5516 19.1451 13.8483 19.8483C13.1451 20.5516 12.75 21.5054 12.75 22.5V23.25H20.25V22.5Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIconMediumViewBox>
  )
}

export { ElevatorIcon }
