import React from 'react'

import { clsx } from '@guiker/clsx'
import { makeStyles, theme } from '@guiker/components-core'
import { default as FormControlLabel } from '@material-ui/core/FormControlLabel'
import { SwitchProps as MuiSwitchProps } from '@material-ui/core/Switch'

import { useUtilityStyle } from '../../../styles'
import { Label } from '../../Data Display'
import { BaseToggle } from './BaseToggle'

export type ToggleProps = Omit<MuiSwitchProps, 'color' | 'defaultValue'> & {
  maxWidth?: string | number
  label?: string
  adornment?: React.ReactNode
  description?: string
  readOnly?: boolean
}

const useStyles = makeStyles(
  {
    root: {},
    toggle: {
      display: 'flex',
      boxSizing: 'border-box',
      padding: theme.spacing(2),
      marginLeft: 0,
      justifyContent: 'space-between',
      minHeight: 50,
    },
    switchBase: {
      padding: 2,
      '&$checked': {
        transform: 'translateX(16px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: theme.palette.primary.main,
          opacity: 1,
          border: 'none',
          borderRadius: 2,
        },
      },
    },
    thumb: {
      width: 20,
      height: 20,
      borderRadius: 1,
      backgroundColor: theme.palette.common.white,
      boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24)',
    },
    track: {
      borderRadius: 2,
      background: theme.palette.grey[5],
      opacity: 1,
      transition: theme.transitions.create(['background-color']),
    },
    checked: {},
  },
  {
    name: 'toggle',
  },
)

const Toggle: React.FC<ToggleProps> = (props) => {
  const {
    className,
    label,
    adornment,
    description,
    value,
    disabled,
    onChange,
    checked,
    maxWidth = 360,
    readOnly,
    ...other
  } = props
  const classes = useStyles()
  const utilityClasses = useUtilityStyle({ maxWidth })

  return (
    <FormControlLabel
      className={clsx(className, classes.toggle, utilityClasses.maxWidth)}
      label={<Label text={label} adornment={{ node: adornment }} description={description} disabled={disabled} />}
      value={value}
      control={
        <BaseToggle
          disabled={disabled}
          onChange={onChange}
          checked={checked}
          readOnly={readOnly}
          classes={{
            root: classes.root,
            switchBase: classes.switchBase,
            thumb: classes.thumb,
            track: classes.track,
            checked: classes.checked,
          }}
          value={value}
          {...other}
        />
      }
    />
  )
}

export { Toggle }
