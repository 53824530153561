import React from 'react'

import { SvgIconMediumViewBox, SvgIconProps } from '../../SvgIcon'

export const AnnouncementIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIconMediumViewBox {...props}>
      <path
        d='M3.132 12.8L3.58 11.625L2.534 8.88098C2.37928 8.47322 2.52903 8.01273 2.894 7.77398L5.352 6.16898L6.119 3.33498C6.23317 2.91389 6.62525 2.62919 7.061 2.65098L9.993 2.79998L12.279 0.954979C12.619 0.68086 13.104 0.68086 13.444 0.954979L15.73 2.79998L18.662 2.65398C19.0977 2.63219 19.4898 2.91689 19.604 3.33798L20.371 6.17198L22.829 7.77698C23.1943 8.01549 23.3441 8.47623 23.189 8.88398L22.144 11.628L23.189 14.371C23.3443 14.779 23.1944 15.2401 22.829 15.479L20.371 17.079L19.6 19.914C19.4865 20.3357 19.0942 20.621 18.658 20.599L15.726 20.453L13.44 22.294'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M0.75 19.769L2.406 22.378'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.908 8.14697L16.202 18.063'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.871 17.541L2.075 21.857L1.081 20.291L10.239 8.66901L15.871 17.541V17.541Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.056 20.924L5.932 22.27C6.22474 22.7499 6.70248 23.0878 7.25251 23.204C7.80253 23.3203 8.37614 23.2045 8.838 22.884C9.80787 22.186 10.0653 20.8529 9.425 19.844L9.262 19.608'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIconMediumViewBox>
  )
}
