import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import { RecaptchaAction } from '@guiker/recaptcha-action'

const useRecaptcha = () => {
  const { executeRecaptcha } = useGoogleReCaptcha()

  return {
    executeRecaptcha: (action: RecaptchaAction) => {
      return executeRecaptcha && executeRecaptcha(action)
    },
  }
}

export { useRecaptcha }
