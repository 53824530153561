import React from 'react'

import { SvgIconLargerViewBox, SvgIconProps } from '../../SvgIcon'

type TargetCenterIconProps = SvgIconProps

const TargetCenterIcon: React.FC<TargetCenterIconProps> = (props) => {
  return (
    <SvgIconLargerViewBox {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M23 50C28.5228 50 33 45.5228 33 40C33 34.4772 28.5228 30 23 30C17.4772 30 13 34.4772 13 40C13 45.5228 17.4772 50 23 50Z'
        stroke={'currentColor'}
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M23 60C34.0457 60 43 51.0457 43 40C43 28.9543 34.0457 20 23 20C11.9543 20 3 28.9543 3 40C3 51.0457 11.9543 60 23 60Z'
        stroke={'currentColor'}
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M23 40L47 16' stroke={'currentColor'} strokeWidth='3' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M3 62L9.89333 55.1066'
        stroke={'currentColor'}
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M42.9998 62L36.1064 55.1066'
        stroke={'currentColor'}
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M52.515 21.8C51.625 22.6912 50.3672 23.1111 49.1204 22.9333L41.2004 21.8027L40.0697 13.8827C39.8913 12.6362 40.3102 11.3785 41.2004 10.488L49.6857 2L52.1017 10.8987L61.0004 13.3147L52.515 21.8Z'
        stroke={'currentColor'}
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIconLargerViewBox>
  )
}

export { TargetCenterIcon }
