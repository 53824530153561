import * as React from 'react'

import { clsx } from '@guiker/clsx'
import { AnyColor, darken, lighten, makeStyles, PaletteKey, StatusKey } from '@guiker/components-core'

export type ChipProps = React.PropsWithChildren & {
  borderRadiusStyle?: 'round' | 'semi-round'
  className?: string
  color?: AnyColor | string
  onClick?: () => unknown
  size?: 'small' | 'medium' | 'smaller'
  startAdornment?: React.ReactElement
  variant?: 'filled' | 'outlined' | 'light'
  alt?: string
}

const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: 'fit-content',
      height: 'fit-content',
      display: 'flex',
      textTransform: 'uppercase',
      gap: theme.spacing(1),
      alignItems: 'center',
    },
    sizeMedium: {
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      letterSpacing: 1,
    },
    sizeSmaller: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
      letterSpacing: 0.8,
    },
    sizeSmall: {
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
      letterSpacing: 0.8,
    },
    variantFilled: {
      color: theme.palette.common.white,
      backgroundColor: ({ color }: { color: PaletteKey | StatusKey }) =>
        theme.palette.getColor(color, 'main') || (color && lighten(color, 0.7)) || theme.palette.grey[10],
    },
    variantLight: {
      color: ({ color }: { color: PaletteKey | StatusKey }) => {
        const foundColor = theme.palette.getColor(color, 'main')

        return (foundColor && darken(foundColor, 0.2)) || theme.palette.grey[10]
      },
      backgroundColor: ({ color }: { color: PaletteKey | StatusKey }) => {
        const foundColor = theme.palette.getColor(color, 'background')
        const foundMainColor = theme.palette.getColor(color, 'main')

        return (
          (foundColor && lighten(foundColor, 0.5)) ||
          (foundMainColor && lighten(foundMainColor, 0.7)) ||
          theme.palette.grey[10]
        )
      },
    },
    variantOutlined: {
      borderWidth: 1,
      borderStyle: 'solid',
      backgroundColor: theme.palette.background.primary,
      borderColor: ({ color }: { color: PaletteKey | StatusKey }) =>
        theme.palette.getColor(color, 'main') || (color && lighten(color, 0.7)) || theme.palette.grey[10],
      color: ({ color }: { color: PaletteKey | StatusKey }) =>
        theme.palette.getColor(color, 'main') || (color && lighten(color, 0.7)) || theme.palette.grey[10],
    },
    radiusStyleRound: {
      borderRadius: theme.spacing(1),
    },
    radiusStyleSemi: {
      borderRadius: theme.spacing(1),
    },
    textSmall: {
      fontSize: theme.typography.variants.bodySmaller.fontSize,
      lineHeight: theme.typography.variants.bodySmaller.lineHeight,
    },
    textSmaller: {
      fontSize: theme.typography.variants.bodySmallest.fontSize,
      lineHeight: theme.typography.variants.bodySmallest.lineHeight,
    },
    textMedium: {
      fontSize: theme.typography.variants.bodySmall.fontSize,
      lineHeight: theme.typography.variants.bodySmall.lineHeight,
    },
    clickable: {
      cursor: 'pointer',
    },
  }),
  { name: 'Chip' },
)

const Chip: React.FC<ChipProps> = ({
  borderRadiusStyle = 'round',
  children,
  className,
  color,
  onClick,
  size = 'medium',
  startAdornment,
  variant = 'filled',
  alt,
}) => {
  const classes = useStyles({ color })

  return (
    <span
      title={alt}
      onClick={onClick}
      className={clsx(className, classes.root, {
        [classes.radiusStyleRound]: borderRadiusStyle === 'round',
        [classes.radiusStyleSemi]: borderRadiusStyle === 'semi-round',
        [classes.variantFilled]: variant === 'filled',
        [classes.variantLight]: variant === 'light',
        [classes.variantOutlined]: variant === 'outlined',
        [classes.sizeSmaller]: size === 'smaller',
        [classes.sizeSmall]: size === 'small',
        [classes.sizeMedium]: size === 'medium',
        [classes.textSmaller]: size === 'smaller',
        [classes.textSmall]: size === 'small',
        [classes.textMedium]: size === 'medium',
        [classes.clickable]: !!onClick,
      })}
    >
      {startAdornment}
      {children}
    </span>
  )
}

export { Chip }
