import React from 'react'

import { SvgIconMediumViewBox, SvgIconProps } from '../../../SvgIcon'

type HotWaterIconProps = SvgIconProps

const HotWaterIcon: React.FC<HotWaterIconProps> = (props) => {
  return (
    <SvgIconMediumViewBox {...props}>
      <path
        d='M21.978 14.934C22.6095 15.5957 22.9736 16.4677 23 17.382C23 20.623 17.963 23.252 11.75 23.252C5.537 23.252 0.5 20.623 0.5 17.382C0.526598 16.4686 0.890287 15.5973 1.521 14.936'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.95005 2.21902C6.0157 3.02052 5.425 4.15036 5.30005 5.37502C5.30005 7.34702 6.95005 9.31902 6.95005 11.292C6.82485 12.5163 6.23417 13.6458 5.30005 14.447'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.2001 2.21902C17.2657 3.02052 16.675 4.15036 16.55 5.37502C16.55 7.34702 18.2001 9.32002 18.2001 11.292C18.0751 12.5167 17.4844 13.6465 16.55 14.448'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.7731 0.752014C11.6143 1.74513 10.8818 3.14577 10.7271 4.66401C10.7271 7.11101 12.7731 9.55601 12.7731 12.001C12.6184 13.5199 11.8859 14.9212 10.7271 15.915'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIconMediumViewBox>
  )
}

export { HotWaterIcon }
