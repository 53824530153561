import React from 'react'

import { SvgIconMediumViewBox, SvgIconProps } from '../../../SvgIcon'

type MicrowaveIconProps = SvgIconProps

const MicrowaveIcon: React.FC<MicrowaveIconProps> = (props) => {
  return (
    <SvgIconMediumViewBox {...props}>
      <path
        d='M0.75 2.25H23.25V18.75H0.75V2.25Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.75 18.75V21.75'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20.25 18.75V21.75'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.75 5.25H15.75V15.75H3.75V5.25Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.75 8.25V12.75'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M19.125 14.25C19.125 14.1758 19.147 14.1033 19.1882 14.0417C19.2294 13.98 19.288 13.9319 19.3565 13.9035C19.425 13.8752 19.5004 13.8677 19.5732 13.8822C19.6459 13.8967 19.7127 13.9324 19.7652 13.9848C19.8176 14.0373 19.8533 14.1041 19.8678 14.1768C19.8823 14.2496 19.8748 14.325 19.8465 14.3935C19.8181 14.462 19.77 14.5206 19.7083 14.5618C19.6467 14.603 19.5742 14.625 19.5 14.625C19.4005 14.625 19.3052 14.5855 19.2348 14.5152C19.1645 14.4448 19.125 14.3495 19.125 14.25Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M19.125 9.75C19.125 9.67583 19.147 9.60333 19.1882 9.54166C19.2294 9.47999 19.288 9.43193 19.3565 9.40355C19.425 9.37516 19.5004 9.36774 19.5732 9.38221C19.6459 9.39668 19.7127 9.43239 19.7652 9.48483C19.8176 9.53728 19.8533 9.6041 19.8678 9.67684C19.8823 9.74958 19.8748 9.82498 19.8465 9.89351C19.8181 9.96203 19.77 10.0206 19.7083 10.0618C19.6467 10.103 19.5742 10.125 19.5 10.125C19.4005 10.125 19.3052 10.0855 19.2348 10.0152C19.1645 9.94484 19.125 9.84946 19.125 9.75Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIconMediumViewBox>
  )
}

export { MicrowaveIcon }
