import { useContext } from 'react'

import { PageLayoutContext } from '../components/Layout/PageLayout'

export const usePageLayoutContext = () => {
  const context = useContext(PageLayoutContext)

  if (context === undefined) {
    throw new Error('usePageLayoutContext can only be used inside PageLayoutContext')
  }

  return context
}
