import React from 'react'

import { default as MuiDialog, DialogProps as MuiDialogProps } from '@material-ui/core/Dialog'

import { makeStyles, theme } from '../../../styles'

export type DialogProps = Omit<MuiDialogProps, 'maxWidth'> & {
  valign?: 'top' | 'center' | 'bottom'
  maxWidth?: string | number
}

const useStyles = makeStyles({
  paper: {
    borderRadius: ({ fullScreen }: { fullScreen: boolean }) => (fullScreen ? 0 : 4),
    margin: ({ fullScreen }: { fullScreen: boolean }) => (fullScreen ? 0 : theme.spacing(2)),
    maxWidth: ({ maxWidth }: { maxWidth: string | number }) => (maxWidth ? maxWidth : 'auto'),
    width: ({ maxWidth, fullScreen }: { maxWidth: string | number; fullScreen: boolean }) => {
      return maxWidth || fullScreen ? '100%' : `calc(100% - ${theme.spacing(4)}px)`
    },
    alignSelf: ({ valign }: { valign: 'top' | 'center' | 'bottom' }) => {
      return valign === 'top' ? 'flex-start' : valign === 'center' ? 'center' : 'flex-end'
    },
  },
})

const Dialog: React.FC<DialogProps> = ({ className, fullScreen = false, valign = 'center', maxWidth, ...props }) => {
  const classes = useStyles({ valign, maxWidth, fullScreen })
  return (
    <MuiDialog className={className} PaperProps={{ className: classes.paper }} fullScreen={fullScreen} {...props} />
  )
}

export { Dialog }
