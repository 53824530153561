import React, { PropsWithChildren } from 'react'

import { isAtMostTablette, makeStyles, padding } from '@guiker/components-core'

import { Display2 } from '../../Data Display/Typography'
import { Flex } from '../Flex'

const useStyle = makeStyles(
  (theme) => ({
    root: {
      maxWidth: ({ maxWidth }: { maxWidth: number }) => maxWidth,
      whiteSpace: 'pre-line',
      textAlign: 'center',
    },
    container: {
      width: '100vw',
      backgroundColor: theme.palette.background.primary,
      padding: padding(15, 10),
      [isAtMostTablette]: {
        padding: padding(5),
      },
    },
  }),
  {
    name: 'Headline',
  },
)

type HeadlineProps = PropsWithChildren & {
  maxWidth?: number
}

export const Headline: React.FC<HeadlineProps> = ({ children, maxWidth }) => {
  const classes = useStyle({ maxWidth })

  return (
    <Flex className={classes.container}>
      <Display2 className={classes.root}>{children}</Display2>
    </Flex>
  )
}
