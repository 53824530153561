import React from 'react'

import { AnyColor, theme } from '@guiker/components-core'

const GardenIcon: React.FC<{ color?: AnyColor }> = ({ color: colorKey }) => {
  const color = theme.palette.getColor(colorKey)
  return (
    <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0)'>
        <path
          d='M16.6001 18.0366H22.5001C22.6394 18.0366 22.7759 17.9979 22.8944 17.9246C23.0128 17.8514 23.1086 17.7466 23.1709 17.6221C23.2332 17.4975 23.2595 17.358 23.247 17.2193C23.2345 17.0806 23.1836 16.9481 23.1001 16.8366L19.5001 12.0366H21.0001C21.1394 12.0366 21.2759 11.9979 21.3944 11.9246C21.5128 11.8514 21.6086 11.7466 21.6709 11.6221C21.7332 11.4975 21.7595 11.358 21.747 11.2193C21.7345 11.0806 21.6836 10.9481 21.6001 10.8366L18.0001 6.03665H18.7931C18.9307 6.03625 19.0656 5.99803 19.183 5.92616C19.3004 5.8543 19.3958 5.75155 19.4587 5.62915C19.5217 5.50674 19.5498 5.36939 19.54 5.2321C19.5302 5.09481 19.4828 4.96285 19.4031 4.85065L17.1101 1.63665C17.0407 1.53962 16.9491 1.46055 16.843 1.406C16.7369 1.35145 16.6194 1.323 16.5001 1.323C16.3808 1.323 16.2632 1.35145 16.1571 1.406C16.051 1.46055 15.9595 1.53962 15.8901 1.63665L13.6001 4.85065C13.5205 4.96264 13.4731 5.09432 13.4632 5.23136C13.4533 5.36839 13.4811 5.50552 13.5437 5.62782C13.6063 5.75013 13.7013 5.85292 13.8182 5.925C13.9352 5.99708 14.0697 6.0357 14.2071 6.03665H15.0001L11.6431 10.5126'
          stroke={color || 'currentColor'}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M16.5 18.0366V22.5366'
          stroke={color || 'currentColor'}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M3 12.7866V22.5366'
          stroke={color || 'currentColor'}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M7.5 12.7866V22.5366'
          stroke={color || 'currentColor'}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M12 12.7866V22.5366'
          stroke={color || 'currentColor'}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M0.75 15.0366H14.25'
          stroke={color || 'currentColor'}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M0.75 20.2866H14.25'
          stroke={color || 'currentColor'}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0'>
          <rect width='24' height='24' fill='white' transform='translate(0 0.0366211)' />
        </clipPath>
      </defs>
    </svg>
  )
}

export { GardenIcon }
