import React from 'react'

import { SvgIconMediumViewBox, SvgIconProps } from '../../../SvgIcon'

type HeatingIconProps = SvgIconProps

const HeatingIcon: React.FC<HeatingIconProps> = (props) => {
  return (
    <SvgIconMediumViewBox {...props}>
      <path
        d='M19.1775 0.751953C18.6863 1.36053 18.3987 2.10807 18.3555 2.88895C18.3555 4.22495 19.1775 5.56095 19.1775 6.89695C19.1342 7.67783 18.8467 8.42538 18.3555 9.03395'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.3533 0.751953C14.862 1.36053 14.5745 2.10807 14.5312 2.88895C14.5312 4.22495 15.3533 5.56095 15.3533 6.89695C15.31 7.67783 15.0225 8.42538 14.5312 9.03395'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M23.0007 0.751953C22.5092 1.36044 22.2213 2.10797 22.1777 2.88895C22.1777 4.22495 23.0007 5.56095 23.0007 6.89695C22.9576 7.67803 22.6697 8.42571 22.1777 9.03395'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.5 16.5V23.25H9.5V18.75C9.5 18.3522 9.65804 17.9706 9.93934 17.6893C10.2206 17.408 10.6022 17.25 11 17.25H12.5C12.8978 17.25 13.2794 17.408 13.5607 17.6893C13.842 17.9706 14 18.3522 14 18.75V23.25H20V16.5'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20.0059 12.0439L23.0009 15.0019'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M0.5 15L10.689 4.94095C10.9702 4.65993 11.3515 4.50204 11.749 4.50195'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIconMediumViewBox>
  )
}

export { HeatingIcon }
