import React, { createContext, useContext, useMemo, useState } from 'react'

import { StepConfig } from '@guiker/components-library'
import { get, yup } from '@guiker/shared-framework'

export type StepWithSchema<T> = StepConfig & {
  schema?: yup.Schema<T>
  schemaPath?: string
}

type ApiFormStepContext<T> = {
  data: T
  steps: StepWithSchema<T>[]
  setData: React.Dispatch<React.SetStateAction<T>>
  formName?: string
  stepState: {
    isSubmitting: boolean
    setIsSubmitting: (value: boolean) => void
  }
}

type ApiFormStepContextProps<T> = React.PropsWithChildren & {
  defaultValue: T
  steps: StepWithSchema<T>[]
  formName?: string
}

const ApiFormStepContext = createContext<ApiFormStepContext<any>>(null)

export const getStepWithDisabledStatus = <T,>(steps: StepWithSchema<T>[], data: T) => {
  let previousIsDisabled = false

  const isStepValid = (index: number) => {
    const { schema, schemaPath = '' } = { ...steps[index] }
    const schemedData = get(data, schemaPath, data)
    return schema ? !!schema.isValidSync(schemedData, { abortEarly: false }) : true
  }

  return steps.map((step, index) => {
    const currentStepIsDisabled = previousIsDisabled || !isStepValid(index - 1)

    previousIsDisabled = currentStepIsDisabled

    return {
      ...step,
      disabled: currentStepIsDisabled,
    }
  })
}

export const ApiFormStepContextProvider = <T,>({
  defaultValue,
  children,
  formName,
  steps: stepProps,
}: ApiFormStepContextProps<T>) => {
  const [data, setData] = useState<T>(defaultValue)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const steps = useMemo(() => getStepWithDisabledStatus(stepProps, data), [data, stepProps])
  const stepState = { isSubmitting, setIsSubmitting }

  const value = { data, steps, setData, formName, stepState }

  return <ApiFormStepContext.Provider value={value}>{children}</ApiFormStepContext.Provider>
}

export const useApiFormStepContext = <T,>() => {
  const context = useContext<ApiFormStepContext<T>>(ApiFormStepContext)

  if (context === undefined) {
    throw new Error('useApiFormStepContext can only be used inside ApiFormStepContextProvider')
  }

  return context
}
