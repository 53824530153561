import React, { PropsWithChildren } from 'react'

import { makeStyles, MinWidth } from '@guiker/components-core'

type GridLayoutProps = PropsWithChildren & {
  gap: number
  columnMinWidth: MinWidth
  columnFit?: 'auto-fit' | 'auto-fill'
}

const useStyles = makeStyles(
  (theme) => ({
    root: ({ columnMinWidth, gap, columnFit = 'auto-fit' }: GridLayoutProps) => ({
      gap: theme.spacing(gap),
      display: 'grid',
      gridTemplateColumns: `repeat(${columnFit}, minmax(${columnMinWidth}px, 1fr))`,
    }),
  }),
  { name: 'GridLayout' },
)

export const GridLayout: React.FC<GridLayoutProps> = ({ children, ...props }) => {
  const classes = useStyles(props)

  return <div className={classes.root}>{children}</div>
}
