import React from 'react'

import { AnyColor, theme } from '@guiker/components-core'

type DrawerChestIconProps = {
  color?: AnyColor
}

const DrawerChestIcon: React.FC<DrawerChestIconProps> = ({ color }) => {
  const colorKey = theme.palette.getColor(color)

  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M3 0.75H21V20.75C21 21.0152 20.8946 21.2696 20.7071 21.4571C20.5196 21.6446 20.2652 21.75 20 21.75H4C3.73478 21.75 3.48043 21.6446 3.29289 21.4571C3.10536 21.2696 3 21.0152 3 20.75V0.75Z'
        stroke={colorKey || 'currentColor'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.5 21.75V23.25'
        stroke={colorKey || 'currentColor'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M19.5 21.75V23.25'
        stroke={colorKey || 'currentColor'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1.5 0.75H22.5'
        stroke={colorKey || 'currentColor'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3 6.75H21'
        stroke={colorKey || 'currentColor'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3 12.75H21'
        stroke={colorKey || 'currentColor'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.5 9.75H13.5'
        stroke={colorKey || 'currentColor'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.5 3.75H13.5'
        stroke={colorKey || 'currentColor'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12 12.75V21.75'
        stroke={colorKey || 'currentColor'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.75 15.75H8.25'
        stroke={colorKey || 'currentColor'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.75 15.75H17.25'
        stroke={colorKey || 'currentColor'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export { DrawerChestIcon }
