import React, { useEffect } from 'react'

import { compact, uniq } from '@guiker/lodash'
import { PageMetaTags } from '@guiker/react-helmet'
import { useDeviceContext } from '@guiker/react-use-device'

import { useNavigate } from './use-navigate'

type RedirectProps = {
  path: string
  /**
   * @description Normally a call to navigate will push a new entry into the history stack
   * so the user can click the back button to get back to the page.
   * If you pass replace: true to navigate then the current entry in the history stack will be replaced with the new one.
   * */
  replace?: boolean
  /**
   * @description Normally a call to navigate will redirect using react-router in SPA
   * if reload: true is passed, it will force browser to call server again
   * */
  reload?: boolean
  /**
   * @description Normally redirect will not retain query params, pass true to retain it.
   * it ONLY applies to location.search. so queryparams passed as part of path will be retained
   * */
  retainQueryParams?: boolean | ((querystring: string) => string)
  state?: object
}

const buildQueryParams = (retain: RedirectProps['retainQueryParams']) => {
  if (typeof retain === 'boolean') {
    return location.search.substring(1)
  } else {
    return retain(location.search.substring(1))
  }
}

export const Redirect: React.FC<RedirectProps> = ({
  path,
  replace = true,
  reload = false,
  retainQueryParams = false,
  state,
}) => {
  const navigate = useNavigate()
  const { isBrowser } = useDeviceContext() || {}
  const partialPaths = path.split('?')
  const queryParams = uniq(
    compact([
      partialPaths.length > 1 ? partialPaths[1] : undefined,
      isBrowser && retainQueryParams ? buildQueryParams(retainQueryParams) : undefined,
    ]),
  ).join('&')

  useEffect(() => {
    const destination = [partialPaths[0], queryParams].filter((p) => !!p).join('?')

    navigate(destination, { replace, state, isExternalLink: reload })
  }, [reload])

  return null
}

export const RedirectNoIndex: React.FC<RedirectProps> = (props) => (
  <>
    <PageMetaTags noIndex />
    <Redirect {...props} />
  </>
)
