import React from 'react'

import { SvgIcon, SvgIconProps } from '../../../SvgIcon'

type GuikerMarkerIconProps = SvgIconProps

const GuikerMarkerIcon: React.FC<GuikerMarkerIconProps> = ({ ...props }) => {
  return (
    <SvgIcon width={32} height={32} {...props}>
      <path
        d='M16.0007 0C12.6424 0.00388172 9.42287 1.33965 7.04825 3.71427C4.67363 6.08889 3.33787 9.30845 3.33398 12.6667C3.33398 21.396 12.0513 29.3173 14.722 31.5373C15.0811 31.8356 15.5332 31.9989 16 31.9989C16.4668 31.9989 16.9189 31.8356 17.278 31.5373C19.9447 29.3173 28.6673 21.3933 28.6673 12.6653C28.6631 9.30735 27.3272 6.08813 24.9526 3.7138C22.578 1.33946 19.3586 0.00388103 16.0007 0Z'
        fill='currentColor'
      />
      <path d='M16 5.33325L23.6085 10.8611L20.7023 19.8054H11.2977L8.39155 10.8611L16 5.33325Z' fill='white' />
    </SvgIcon>
  )
}

export { GuikerMarkerIcon }
