import React from 'react'

import { SvgIconMediumViewBox, SvgIconProps } from '../../../SvgIcon'

export type MinusSmallIconProps = SvgIconProps

export const NextIcon: React.FC<MinusSmallIconProps> = (props) => {
  return (
    <SvgIconMediumViewBox {...props}>
      <path
        d='M23.251 9.74707H12C9.92893 9.74707 8.25 11.426 8.25 13.4971'
        stroke='currentColor'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M20.251 6.74707L23.251 9.74707L20.251 12.7471'
        stroke='currentColor'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M17.251 5.24707C17.251 4.41864 16.5794 3.74707 15.751 3.74707H2.251C1.42257 3.74707 0.750999 4.41864 0.750999 5.24707V18.7471C0.750999 19.5755 1.42257 20.2471 2.251 20.2471H15.751C16.5794 20.2471 17.251 19.5755 17.251 18.7471V14.2471'
        stroke='currentColor'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </SvgIconMediumViewBox>
  )
}
