import React from 'react'

import { SvgIconMediumViewBox, SvgIconProps } from '../../../SvgIcon'

type CheckmarkIconProps = SvgIconProps

const CheckmarkIcon: React.FC<CheckmarkIconProps> = (props) => {
  return (
    <SvgIconMediumViewBox {...props}>
      <path
        d='M1 15.0003L5.26555 20.1604C5.48984 20.425 5.77103 20.6374 6.08893 20.7826C6.40682 20.9278 6.75351 21.0019 7.10409 21C7.45467 20.9979 7.80042 20.9196 8.11652 20.7706C8.43262 20.6218 8.71119 20.4059 8.93222 20.1388L23 3'
        stroke='currentColor'
        strokeWidth='1.6'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIconMediumViewBox>
  )
}

export { CheckmarkIcon }
