import React from 'react'

const TangerineIcon: React.FC = () => {
  return (
    <svg width='37' height='25' viewBox='0 0 37 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.156982 2.11658C0.156982 1.28815 0.828555 0.616577 1.65698 0.616577H34.657C35.4854 0.616577 36.157 1.28815 36.157 2.11658V23.1166C36.157 23.945 35.4854 24.6166 34.657 24.6166H1.65698C0.828556 24.6166 0.156982 23.945 0.156982 23.1166V2.11658Z'
        fill='#F58426'
      />
      <path
        d='M24.8611 8.25136C25.021 8.51734 25.0104 8.86531 24.8179 9.13715L18.3173 18.3447C18.1506 18.5806 17.8224 18.6755 17.5366 18.5705C17.2506 18.4643 17.0738 18.1862 17.1485 17.9139C17.5204 16.5614 17.9397 14.231 16.7614 12.2749C15.5869 10.3165 13.2032 9.37806 11.751 8.93223C11.4588 8.84277 11.2961 8.55693 11.3527 8.28096C11.4099 8.00538 11.6746 7.80553 11.9836 7.80566L24.0178 7.80593C24.3731 7.80553 24.7008 7.98538 24.8611 8.25136Z'
        fill='white'
      />
    </svg>
  )
}

export { TangerineIcon }
