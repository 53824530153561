import React, { Children, cloneElement, PropsWithChildren, ReactElement } from 'react'

import { TooltipProps as MuiToolTipProps } from '@material-ui/core/Tooltip'

import { clsx } from '../../../styles'
import { BaseTooltip } from './BaseTooltip'

export type WithTooltipProps = PropsWithChildren &
  Omit<MuiToolTipProps, 'children' | 'arrow'> & {
    backgroundColor?: string
    color?: string
  }

const WithTooltip: React.FC<WithTooltipProps> = ({ className, backgroundColor, title, color, children, ...props }) => {
  const [open, setOpen] = React.useState(false)
  const handleTooltipClose = () => setOpen(false)
  const handleTooltipOpen = () => setOpen(true)
  const handleTooltipClick = () => setOpen((curr) => !curr)

  if (!title) {
    return (
      <>
        {Children.toArray(children).map((child: ReactElement) =>
          cloneElement(child, { className: clsx(child.props.className) }),
        )}
      </>
    )
  }

  return (
    <BaseTooltip
      title={title}
      arrow={true}
      backgroundColor={backgroundColor}
      color={color}
      className={className}
      onClose={handleTooltipClose}
      onOpen={handleTooltipOpen}
      open={open}
      {...props}
    >
      <span onClick={handleTooltipClick}>
        {Children.toArray(children).map((child: ReactElement) =>
          cloneElement(child, { className: clsx(child.props.className) }),
        )}
      </span>
    </BaseTooltip>
  )
}

export { WithTooltip }
