import React from 'react'

import { Helmet, HelmetProps } from '..'
import { keywords as baseKeywords } from './keywords'

type PageMetaTagsProps = React.PropsWithChildren & {
  canonicalUrl?: string
  description?: string
  imageUrl?: string
  keywords?: string[]
  localizedUrls?: { hrefLang: string; href: string }[]
  noIndex?: boolean
  subtitle?: string
}

const HelmetComponent = Helmet as unknown as React.FC<HelmetProps & React.PropsWithChildren>

const PageMetaTags: React.FC<PageMetaTagsProps> = ({
  canonicalUrl,
  description,
  imageUrl,
  keywords = [],
  localizedUrls,
  noIndex,
  subtitle,
}) => {
  const children = []

  if (subtitle) {
    const title = `Guiker | ${subtitle}`
    children.push(<title key='title'>{title}</title>)
    children.push(<meta key='og:title' property='og:title' content={title}></meta>)
  }

  if (description) {
    children.push(<meta key='description' name='description' content={description}></meta>)
    children.push(<meta key='og:description' property='og:description' content={description}></meta>)
  }

  if (imageUrl) {
    children.push(<meta key='og:image' property='og:image' content={imageUrl}></meta>)
  }

  if (canonicalUrl) {
    children.push(<link key='canonicalUrl' rel='canonical' href={canonicalUrl} />)
  }

  if (noIndex) {
    children.push(<meta key='noindex' name='robots' content='noindex' />)
  }

  if (localizedUrls) {
    localizedUrls.forEach(({ hrefLang, href }) => {
      children.push(<link key={hrefLang} rel='alternate' hrefLang={hrefLang} href={href} />)
    })
  }

  return (
    <HelmetComponent>
      <meta name='keywords' content={[...baseKeywords, ...keywords].join(', ')} />
      {children}
    </HelmetComponent>
  )
}

export { PageMetaTags }
