import React from 'react'

import { SvgIconMediumViewBox, SvgIconProps } from '../../../SvgIcon'

type BalconyIconProps = SvgIconProps

const BalconyIcon: React.FC<BalconyIconProps> = (props) => {
  return (
    <SvgIconMediumViewBox {...props}>
      <path
        d='M1.5 4.875L3 13.125'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1.5 19.125L3 13.125H6C6.39782 13.125 6.77936 13.283 7.06066 13.5643C7.34196 13.8456 7.5 14.2272 7.5 14.625V19.125'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M22.5 4.875L21 13.125'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M22.5 19.125L21 13.125H18C17.6022 13.125 17.2206 13.283 16.9393 13.5643C16.658 13.8456 16.5 14.2272 16.5 14.625V19.125'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M12 8.625V19.125' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M6.75 8.625H17.25'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIconMediumViewBox>
  )
}

export { BalconyIcon }
