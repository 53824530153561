import React from 'react'

import { clsx } from '@guiker/clsx'
import { makeStyles, theme } from '@guiker/components-core'

import { Flex, useLayoutContext } from '../../../'
import { BaseSideMenuItem } from './BaseSideMenuItem'
import { useInPageNavigationContext } from './InPageNavigation'
import { InPageNavigationItem } from './InPageNavigationItem'

type NestedMenuProps = {
  disabled?: boolean
  items: InPageNavigationItem[]
  isRootItemActive: boolean
  rootHref: string
}

const useStyles = makeStyles(
  {
    root: {
      position: 'absolute',
      top: 52,
      overflowX: 'scroll',
      width: '100%',
      backgroundColor: theme.palette.background.primary,
      left: 0,
    },
  },
  { name: 'NestedMenuItems' },
)

export const NestedMenuItems: React.FC<NestedMenuProps> = ({ disabled, rootHref, isRootItemActive, items }) => {
  const { hasDrawer } = useLayoutContext()
  const classes = useStyles({ hasDrawer })
  const { direction, isActiveItem } = useInPageNavigationContext()

  return (
    <Flex flexDirection={direction} className={clsx({ [classes.root]: direction === 'row' })}>
      {items.map((itemProps) => {
        return (
          <BaseSideMenuItem
            key={itemProps.href}
            disabled={disabled}
            href={isRootItemActive ? itemProps.href : `${rootHref}${itemProps.href}`}
            isActive={isActiveItem(itemProps)}
            isNestedItem
            {...itemProps}
          />
        )
      })}
    </Flex>
  )
}
