import React from 'react'

import { AnyColor, theme } from '@guiker/components-core'

export interface ErrorIconProps {
  color?: AnyColor
}

const ErrorIcon: React.FC<ErrorIconProps> = ({ color: colorKey = 'alert' }) => {
  const color = theme.palette.getColor(colorKey)

  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12 0C9.62663 0 7.30655 0.703788 5.33316 2.02236C3.35977 3.34094 1.8217 5.21509 0.913451 7.4078C0.0051995 9.60051 -0.232441 12.0133 0.230582 14.3411C0.693605 16.6689 1.83649 18.8071 3.51472 20.4853C5.19295 22.1635 7.33115 23.3064 9.65892 23.7694C11.9867 24.2324 14.3995 23.9948 16.5922 23.0866C18.7849 22.1783 20.6591 20.6402 21.9776 18.6668C23.2962 16.6935 24 14.3734 24 12C23.9964 8.81852 22.7309 5.76838 20.4813 3.51873C18.2316 1.26908 15.1815 0.00362976 12 0V0ZM16.5446 14.7274C16.7789 14.9698 16.9086 15.2945 16.9059 15.6316C16.9031 15.9687 16.768 16.2913 16.5298 16.5298C16.2915 16.7683 15.9691 16.9036 15.632 16.9067C15.2949 16.9098 14.97 16.7804 14.7274 16.5463L12 13.8189L9.27258 16.5463C9.15392 16.669 9.012 16.7669 8.8551 16.8342C8.69821 16.9015 8.52948 16.9369 8.35877 16.9383C8.18805 16.9397 8.01876 16.9071 7.86079 16.8424C7.70281 16.7777 7.5593 16.6821 7.43864 16.5614C7.31798 16.4406 7.22258 16.297 7.158 16.1389C7.09343 15.9809 7.06098 15.8116 7.06254 15.6409C7.06411 15.4702 7.09966 15.3015 7.16711 15.1446C7.23457 14.9878 7.33258 14.846 7.45543 14.7274L10.1829 12L7.45543 9.27257C7.33258 9.15403 7.23457 9.0122 7.16711 8.85537C7.09966 8.69854 7.06411 8.52985 7.06254 8.35913C7.06098 8.18842 7.09343 8.0191 7.158 7.86106C7.22258 7.70302 7.31798 7.55942 7.43864 7.43865C7.5593 7.31787 7.70281 7.22234 7.86079 7.15762C8.01876 7.09289 8.18805 7.06028 8.35877 7.06169C8.52948 7.06309 8.69821 7.09848 8.8551 7.16578C9.012 7.23309 9.15392 7.33097 9.27258 7.45371L12 10.1811L14.7274 7.45371C14.97 7.21962 15.2949 7.09018 15.632 7.09327C15.9691 7.09636 16.2915 7.23172 16.5298 7.47022C16.768 7.70872 16.9031 8.03125 16.9059 8.36837C16.9086 8.70548 16.7789 9.03019 16.5446 9.27257L13.8171 12L16.5446 14.7274Z'
        fill={color || 'currentColor'}
      />
    </svg>
  )
}

export { ErrorIcon }
