import React, { PropsWithChildren, useEffect, useRef } from 'react'

import { clsx, ColumnGridLayout, FullScreenSpinner, makeStyles } from '@guiker/components-library'
import { InfiniteData, QueryKey, useInfiniteQuery } from '@guiker/react-query'
import { useIsElementVisible } from '@guiker/react-utils'

type ResponseType<T> = { data: T[]; meta: { total: number; totalPages: number; page: number } }

export type InfiniteListFetcher<T> = (args: { page: number; perPage: number }) => Promise<ResponseType<T>>

export type InfiniteListProps<T extends { id: string }> = {
  CellComponent: React.FC<{ data: T }>
  Container?: React.FC<PropsWithChildren>
  className?: string
  columnCount?: number
  gap?: number
  emptyState?: React.ReactNode
  enabled?: boolean
  fetcher?: InfiniteListFetcher<T>
  LoadingComponent?: React.ReactNode
  onFetch?: (data: T[]) => void
  queryKey: QueryKey
  perPage?: number
  maxWidth?: number
}

const flattenPages = <T,>(response: InfiniteData<ResponseType<T>>) =>
  response?.pages?.reduce((acc, curr) => [...acc, ...curr.data], [])

const useStyles = makeStyles(
  () => ({
    root: {
      width: '100%',
      maxWidth: ({ maxWidth }: { maxWidth: number }) => maxWidth || '100%',
    },
    observer: {
      backgroundColor: 'transparent',
      height: 32,
    },
  }),
  { name: 'InfiniteList' },
)

export const InfiniteList = <T extends { id: string }>({
  queryKey,
  CellComponent,
  className,
  onFetch,
  columnCount = 1,
  gap = 4,
  LoadingComponent = <FullScreenSpinner />,
  emptyState,
  perPage = 6,
  enabled = true,
  fetcher,
  maxWidth,
}: InfiniteListProps<T>) => {
  const classes = useStyles({ maxWidth })
  const observerTarget = useRef(null)
  const elementIsVisible = useIsElementVisible(observerTarget)

  const {
    data: response,
    isFetching,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(queryKey, ({ pageParam = 1 }: { pageParam?: number }) => fetcher({ page: pageParam, perPage }), {
    enabled,
    onSuccess: (data) => {
      onFetch?.(flattenPages(data))
    },
    getNextPageParam: (lastPage) => {
      return lastPage.meta.totalPages > lastPage.meta.page ? lastPage.meta.page + 1 : undefined
    },
  })

  useEffect(() => {
    if (!isFetching && elementIsVisible && hasNextPage) {
      fetchNextPage()
    }
  }, [elementIsVisible, isFetching, hasNextPage])

  if (emptyState && !response?.pages.length) {
    return <>{emptyState}</>
  }

  return (
    <div className={clsx(classes.root, className)}>
      <ColumnGridLayout
        key={JSON.stringify(queryKey)}
        gap={gap}
        gridColumn={{ start: 'auto', span: 1 }}
        gridTemplateColumns={columnCount}
        sm={{ gridTemplateColumns: 1, gridColumn: { span: 1 } }}
      >
        {flattenPages(response)?.map((data) => (
          <CellComponent key={data.id} data={data} />
        ))}
      </ColumnGridLayout>
      {isFetching && !response?.pages.length && LoadingComponent}
      <div ref={observerTarget} className={classes.observer}></div>
    </div>
  )
}
