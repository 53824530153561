import { math } from '@guiker/lodash'
import { Currency, money } from '@guiker/money'

export type SliderFormatter = {
  toDisplay: (stateValue: any) => string | number
  toActual: (displayValue: any) => number
}

export const sliderNumberFormatter = {
  units: (): SliderFormatter => ({
    toDisplay: (value: string) => `${value} units`,
    toActual: (value: string) => Number(value ?? undefined),
  }),
  year: (): SliderFormatter => ({
    toDisplay: (value: string) => `${value} years`,
    toActual: (value: string) => Number(value ?? undefined),
  }),
  currency: (currency: Currency): SliderFormatter => ({
    toDisplay: (value: string) => money.fromAmount(Number(value ?? undefined), currency).toString('onlySymbol', true),
    toActual: (value: string) => money.toAmount(Number(value ?? undefined), currency).amount,
  }),
  percentage: (decimalPrecision: number): SliderFormatter => ({
    toDisplay: (value: string) => `${math.decimal.round(Number(value ?? undefined) * 100, decimalPrecision)}%`,
    toActual: (value: string) => math.decimal.round(Number(value ?? undefined) / 100, decimalPrecision),
  }),
}
