import React, { useMemo } from 'react'

import { clsx } from '@guiker/clsx'
import { isMobile, makeStyles, theme } from '@guiker/components-core'
import { safelyGetWindow } from '@guiker/react-utils'

import { SideMenu, SideMenuProps, useLayoutContext, useOnScrollDirection } from '../../../'

const ALWAYS_DISPLAY_HEIGHT = 40

type HorizontalNavigationTabsProps = Omit<SideMenuProps, 'direction'>

const useStyles = makeStyles(
  (theme) => ({
    horizontalSidemenuContainer: {
      backgroundColor: theme.palette.background.primary,
      overflowX: 'scroll',
    },
    fixedContainer: ({ hasDrawer }: { hasDrawer: boolean }) => ({
      position: 'fixed',
      left: hasDrawer ? theme.dimensions.drawerNavigation.width.minimized : 0,
      maxWidth: !hasDrawer ? '100%' : `calc(100% - ${theme.dimensions.drawerNavigation.width.minimized}px)`,
      width: '100%',
      zIndex: theme.zIndexs.appBar - 1,
      transition: theme.transitions.create(['top'], {
        duration: theme.transitions.duration.short,
      }),
    }),
    show: ({ isTakeover }: { isTakeover: boolean }) => ({
      top: theme.dimensions.appBar.height.desktop + (isTakeover ? theme.dimensions.progressBar.height : 0),
      [isMobile]: {
        top: theme.dimensions.appBar.height.mobile + (isTakeover ? theme.dimensions.progressBar.height : 0),
      },
    }),
    hide: {
      top: -theme.dimensions.topTabNavigation.height.desktop,
    },
    spacer: {
      height: ({ hasNestedMenuItems }: { hasNestedMenuItems: boolean }) => theme.spacing(hasNestedMenuItems ? 10 : 5),
    },
  }),
  { name: 'HorizontalNavigationTabs' },
)

export const HorizontalNavigationTabs: React.FC<HorizontalNavigationTabsProps> = ({ className, ...props }) => {
  const { hasDrawer, isMobile, isTakeover } = useLayoutContext()
  const classes = useStyles({
    isTakeover,
    hasDrawer,
    hasNestedMenuItems: props.items.some((i) => i.nestedItems?.length > 0),
  })

  const isScrollingUp = useOnScrollDirection(
    'up',
    isMobile ? theme.dimensions.appBar.height.mobile : theme.dimensions.appBar.height.desktop,
  )

  const shouldDisplayAsFixed = useMemo(() => {
    const pageYOffset = safelyGetWindow()?.pageYOffset
    const isInAlwaysDisplayZone = pageYOffset < ALWAYS_DISPLAY_HEIGHT

    return isInAlwaysDisplayZone || isScrollingUp
  }, [isScrollingUp])

  return (
    <>
      <div className={classes.spacer} />
      <div
        className={clsx(classes.fixedContainer, {
          [classes.show]: shouldDisplayAsFixed,
          [classes.hide]: !shouldDisplayAsFixed,
        })}
      >
        <SideMenu className={clsx(className, classes.horizontalSidemenuContainer)} direction='row' {...props} />
      </div>
    </>
  )
}
