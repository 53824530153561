import * as React from 'react'

import { default as MuiMenu, MenuProps as MuiMenuProps } from '@material-ui/core/Menu'

import { MaxWidth, useUtilityStyle } from '../../../styles'

export type MenuProps = React.PropsWithChildren &
  MuiMenuProps & {
    maxWidth?: MaxWidth
  }

const Menu: React.FC<MenuProps> = ({ children, maxWidth, ...props }) => {
  const utilityClasses = useUtilityStyle({ maxWidth })
  return (
    <MuiMenu classes={{ paper: utilityClasses.maxWidth }} {...props}>
      {children}
    </MuiMenu>
  )
}

export { Menu }
