import React from 'react'

import { AnyColor, theme } from '@guiker/components-core'

const BusIcon: React.FC<{ color?: AnyColor }> = ({ color: colorKey }) => {
  const color = theme.palette.getColor(colorKey)
  return (
    <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8.75 33.0182V36.1849C8.75 37.5656 9.86929 38.6849 11.25 38.6849C12.6307 38.6849 13.75 37.5656 13.75 36.1849V33.6849'
        stroke={color || 'currentColor'}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.25 8.68823H3.75C2.36929 8.68823 1.25 9.80752 1.25 11.1882V16.1882'
        stroke={color || 'currentColor'}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M33.75 8.68823H36.25C37.6307 8.68823 38.75 9.80752 38.75 11.1882V16.1882'
        stroke={color || 'currentColor'}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.75 33.6882H11.25C8.48858 33.6882 6.25 31.4497 6.25 28.6882V6.18823C6.25 3.42681 8.48858 1.18823 11.25 1.18823H28.75C31.5114 1.18823 33.75 3.42681 33.75 6.18823V18.6882'
        stroke={color || 'currentColor'}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.75 23.6882H6.25'
        stroke={color || 'currentColor'}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.25 28.6882H13.75'
        stroke={color || 'currentColor'}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.25 6.18823H23.75'
        stroke={color || 'currentColor'}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <rect
        x='18.75'
        y='23.6882'
        width='20'
        height='15'
        rx='1.5'
        stroke={color || 'currentColor'}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.75 28.6882H38.75'
        stroke={color || 'currentColor'}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M23.75 33.6882H27.5'
        stroke={color || 'currentColor'}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export { BusIcon }
