import React from 'react'

import { SvgIconMediumViewBox, SvgIconProps } from '../../../SvgIcon'

type ChatIconProps = SvgIconProps

const ChatIcon: React.FC<ChatIconProps> = (props) => {
  return (
    <SvgIconMediumViewBox {...props}>
      <path
        d='M22.8012 15.6007C22.8012 16.2372 22.5483 16.8477 22.0982 17.2977C21.6481 17.7478 21.0377 18.0007 20.4012 18.0007H12.0012L6.00117 22.8007V18.0007H3.60117C2.96465 18.0007 2.3542 17.7478 1.90412 17.2977C1.45403 16.8477 1.20117 16.2372 1.20117 15.6007V3.60068C1.20117 2.96416 1.45403 2.35371 1.90412 1.90363C2.3542 1.45354 2.96465 1.20068 3.60117 1.20068H20.4012C21.0377 1.20068 21.6481 1.45354 22.0982 1.90363C22.5483 2.35371 22.8012 2.96416 22.8012 3.60068V15.6007Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIconMediumViewBox>
  )
}

export { ChatIcon }
