import React from 'react'

import { Box } from '../Box'

type Props = React.PropsWithChildren & {
  header: React.ReactElement
  fullScreenHeader?: boolean
  px?: number
  pt?: number
  pb?: number
}

export const SectionWithHeader: React.FC<Props> = ({
  header,
  fullScreenHeader = true,
  px = 2,
  pt = 3,
  pb = 5,
  children,
}) => {
  return (
    <Box>
      {fullScreenHeader ? header : <Box px={px}>{header}</Box>}
      <Box pt={pt} pb={pb}>
        {children}
      </Box>
    </Box>
  )
}
