import React from 'react'

import { SvgIconProps, SvgIconSmallViewBox } from '../../../SvgIcon'

type CheckmarkMiniIconProps = SvgIconProps

const CheckmarkMiniIcon: React.FC<CheckmarkMiniIconProps> = (props) => {
  return (
    <SvgIconSmallViewBox {...props}>
      <path
        d='M8 0C6.41775 0 4.87104 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346627 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C15.9975 5.87905 15.1538 3.84569 13.6541 2.34595C12.1543 0.846211 10.121 0.00254057 8 0V0ZM11.9072 6.7072L7.5072 11.1072C7.41434 11.2001 7.30409 11.2738 7.18275 11.324C7.06141 11.3743 6.93135 11.4002 6.8 11.4002C6.66866 11.4002 6.5386 11.3743 6.41726 11.324C6.29592 11.2738 6.18567 11.2001 6.0928 11.1072L4.0928 9.1072C3.90524 8.91964 3.79987 8.66525 3.79987 8.4C3.79987 8.13475 3.90524 7.88036 4.0928 7.6928C4.28036 7.50524 4.53475 7.39987 4.8 7.39987C5.06526 7.39987 5.31964 7.50524 5.5072 7.6928L6.8 8.9856L10.4928 5.2928C10.5857 5.19993 10.6959 5.12626 10.8173 5.076C10.9386 5.02574 11.0687 4.99987 11.2 4.99987C11.3313 4.99987 11.4614 5.02574 11.5827 5.076C11.7041 5.12626 11.8143 5.19993 11.9072 5.2928C12.0001 5.38567 12.0737 5.49592 12.124 5.61727C12.1743 5.73861 12.2001 5.86866 12.2001 6C12.2001 6.13134 12.1743 6.26139 12.124 6.38273C12.0737 6.50408 12.0001 6.61433 11.9072 6.7072Z'
        fill='currentColor'
      />
    </SvgIconSmallViewBox>
  )
}

export { CheckmarkMiniIcon }
