import * as React from 'react'

import { clsx } from '@guiker/clsx'
import { darken, makeStyles, palette, theme } from '@guiker/components-core'

import { Button, ButtonProps } from '../Button'

export type TertiaryButtonProps = ButtonProps

const useStyles = makeStyles({
  root: {
    background: palette.getColor('secondary'),
    color: palette.getColor('primary'),
    borderStyle: 'none',
    boxShadow: 'none',
    '&:hover': {
      background: darken(theme.palette.getColor('secondary'), 0.05),
    },
    '&:active': {
      background: darken(theme.palette.getColor('secondary'), 0.1),
    },
    '&:disabled': {
      backgroundColor: theme.palette.getColor('secondary'),
      borderColor: theme.palette.getColor('primary'),
      color: theme.palette.getColor('primary'),
      opacity: 0.5,
    },
  },
})

const TertiaryButton: React.FC<TertiaryButtonProps> = ({ className, ...otherProps }) => {
  const classes = useStyles()

  return <Button className={clsx(classes.root, className)} {...otherProps} />
}

export { TertiaryButton }
