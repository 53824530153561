import React, { useState } from 'react'

import { clsx } from '@guiker/clsx'
import { makeStyles, theme } from '@guiker/components-core'
import { Sticky } from '@guiker/react-sticky'

import { Flex, SideMenu, SideMenuProps, useLayoutContext } from '../../../'

type StickySideMenuProps = Omit<SideMenuProps, 'direction'> & {
  id?: string
}

const useStyles = makeStyles(
  () => ({
    verticalSidemenuContainer: {
      position: 'relative',
      width: '100%',
      maxWidth: ({ width }: { width: string | number }) => width,
      minWidth: ({ width }: { width: string | number }) => width,
    },
  }),
  { name: 'StickySideMenu' },
)

export const StickySideMenu: React.FC<StickySideMenuProps> = ({
  width = 200,
  className,
  id = 'sidemenu-boundary',
  ...props
}) => {
  const classes = useStyles({ width })
  const [isSticky, setIsSticky] = useState(false)
  const { isMobile } = useLayoutContext()

  return (
    <div id={id} className={clsx(className, classes.verticalSidemenuContainer)}>
      <Sticky
        topOffset={isMobile ? -theme.dimensions.appBar.height.mobile : -theme.dimensions.appBar.height.desktop}
        boundaryElement={`#${id}`}
        onFixedToggle={setIsSticky}
      >
        <Flex mt={isSticky ? 10 : 0}>
          <SideMenu direction='column' width={width} {...props} />
        </Flex>
      </Sticky>
    </div>
  )
}
