import { useContext } from 'react'

import { RouterContext } from './RouterContext'

export const useRouter = () => {
  const context = useContext(RouterContext)

  if (context === undefined) {
    throw new Error('useRouter can only be used inside RouterContextProvider')
  }

  return context
}
