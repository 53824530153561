import React from 'react'

import { AnyColor, theme } from '@guiker/components-core'

const JanitorIcon: React.FC<{ color?: AnyColor }> = ({ color: colorKey }) => {
  const color = theme.palette.getColor(colorKey)
  return (
    <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0)'>
        <path
          d='M4.875 3.59961C4.875 3.94433 4.9429 4.28567 5.07482 4.60415C5.20673 4.92263 5.40009 5.21201 5.64384 5.45576C5.8876 5.69952 6.17698 5.89288 6.49546 6.02479C6.81394 6.15671 7.15528 6.22461 7.5 6.22461C7.84472 6.22461 8.18606 6.15671 8.50454 6.02479C8.82302 5.89288 9.1124 5.69952 9.35616 5.45576C9.59991 5.21201 9.79327 4.92263 9.92518 4.60415C10.0571 4.28567 10.125 3.94433 10.125 3.59961C10.125 3.25489 10.0571 2.91355 9.92518 2.59507C9.79327 2.27659 9.59991 1.98721 9.35616 1.74345C9.1124 1.4997 8.82302 1.30634 8.50454 1.17443C8.18606 1.04251 7.84472 0.974609 7.5 0.974609C7.15528 0.974609 6.81394 1.04251 6.49546 1.17443C6.17698 1.30634 5.8876 1.4997 5.64384 1.74345C5.40009 1.98721 5.20673 2.27659 5.07482 2.59507C4.9429 2.91355 4.875 3.25489 4.875 3.59961V3.59961Z'
          stroke={color || 'currentColor'}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M5.25 20.8496C5.25 21.4463 5.48705 22.0186 5.90901 22.4406C6.33097 22.8626 6.90326 23.0996 7.5 23.0996C8.09674 23.0996 8.66903 22.8626 9.09099 22.4406C9.51295 22.0186 9.75 21.4463 9.75 20.8496V17.0996H12.75L11.25 12.5996V11.0996C11.25 10.304 10.9339 9.5409 10.3713 8.97829C9.80871 8.41568 9.04565 8.09961 8.25 8.09961H6.75C5.95435 8.09961 5.19129 8.41568 4.62868 8.97829C4.06607 9.5409 3.75 10.304 3.75 11.0996V12.5996L2.25 17.0996H5.25V20.8496Z'
          stroke={color || 'currentColor'}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M21.75 23.0996H15.75L16.25 19.0706C16.3178 18.5264 16.582 18.0258 16.9931 17.6628C17.4041 17.2998 17.9336 17.0995 18.482 17.0996H19.01C19.5584 17.0995 20.0879 17.2998 20.4989 17.6628C20.91 18.0258 21.1742 18.5264 21.242 19.0706L21.75 23.0996Z'
          stroke={color || 'currentColor'}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M18.75 17.0996V5.09961'
          stroke={color || 'currentColor'}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0'>
          <rect width='24' height='24' fill='white' transform='translate(0 0.0366211)' />
        </clipPath>
      </defs>
    </svg>
  )
}

export { JanitorIcon }
