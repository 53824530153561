import React from 'react'

import { SvgIconMediumViewBox, SvgIconProps } from '../../../SvgIcon'

export const MyInvestmentsIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIconMediumViewBox {...props}>
      <path
        d='M23.25 12.75V6H16.5'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M23.25 6L15.311 13.939C15.0297 14.2205 14.648 14.3787 14.25 14.3787C13.852 14.3787 13.4703 14.2205 13.189 13.939L10.061 10.811C9.77967 10.5295 9.39799 10.3713 9 10.3713C8.60201 10.3713 8.22033 10.5295 7.939 10.811L0.75 18'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIconMediumViewBox>
  )
}
