import React from 'react'

import { SvgIconMediumViewBox, SvgIconProps } from '../../../SvgIcon'

const RealEstateIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIconMediumViewBox {...props}>
      <path
        d='M10.5 18V22.5C10.5 22.9142 10.1642 23.25 9.75 23.25H1.5C1.08579 23.25 0.75 22.9142 0.75 22.5V2.251C0.750777 2.0055 0.871656 1.77592 1.07363 1.63636C1.2756 1.49679 1.53309 1.46491 1.763 1.551L7.5 4.517'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M3 6.001H0.75' stroke='black' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M4.5 9.001H0.75' stroke='black' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M6.75 4.129V1.501' stroke='black' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.25 0.75C13.7095 0.745999 11.3669 2.1209 10.1317 4.3409C8.89649 6.56091 8.96323 9.27635 10.306 11.433L8.25 15.75L12.567 13.694C15.0912 15.264 18.3317 15.0686 20.6489 13.2065C22.966 11.3445 23.8545 8.22208 22.8646 5.4191C21.8748 2.61612 19.2226 0.744004 16.25 0.75Z'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.625 4.5H15.592C14.9494 4.50045 14.3973 4.95639 14.2753 5.58734C14.1534 6.21828 14.4958 6.84712 15.092 7.087L17.156 7.913C17.7522 8.15288 18.0946 8.78172 17.9727 9.41266C17.8507 10.0436 17.2986 10.4996 16.656 10.5H14.625'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M16.125 4.5V3.75' stroke='black' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M16.125 11.25V10.5' stroke='black' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    </SvgIconMediumViewBox>
  )
}

export { RealEstateIcon }
