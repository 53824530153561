import React from 'react'

import { useLayoutContext } from '../../../hooks'
import { H4 } from '../../Data Display'
import { PageSection, PageSectionProps } from '../PageSection/PageSection'

type FormSection3Props = Omit<PageSectionProps, 'TitleComponent' | 'titleMb' | 'spacing'>

const FormSection3: React.FC<FormSection3Props> = (props) => {
  const { isMobile } = useLayoutContext()

  return <PageSection TitleComponent={H4} titleMb={4} spacing={isMobile ? 3 : 6} {...props} />
}

const FormSubSection = FormSection3

export { FormSubSection, FormSection3 }
