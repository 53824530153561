import { useContext } from 'react'

export const generateUseContext = <T>(context: React.Context<T>) => {
  return ({ shouldThrowIfUndefined = true }: { shouldThrowIfUndefined?: boolean } = {}) => {
    const _context = useContext(context)

    if (shouldThrowIfUndefined && _context === undefined) {
      throw new Error(`${context.displayName} can only be used inside ${context.Provider.name}`)
    }

    return _context ?? ({} as T)
  }
}
