import { useLayoutEffect, useState } from 'react'

type Options = {
  noSsr?: boolean
  ssrMatchMedia?: (query: string) => { matches: boolean }
  defaultMatches?: boolean
}

export function useMediaQuery(queryInput: string, options: Options = {}) {
  const query = queryInput.replace(/^@media( ?)/m, '')
  const { noSsr, ssrMatchMedia, defaultMatches } = options

  const supportMatchMedia = typeof window !== 'undefined' && typeof window.matchMedia !== 'undefined'

  const [match, setMatch] = useState(() => {
    if (noSsr && supportMatchMedia) {
      return matchMedia(query).matches
    }

    if (ssrMatchMedia) {
      return ssrMatchMedia(query).matches
    }

    return defaultMatches
  })

  useLayoutEffect(() => {
    let active = true

    if (!supportMatchMedia) {
      return undefined
    }

    const queryList = matchMedia(query)

    const updateMatch = () => {
      // Workaround Safari wrong implementation of matchMedia
      // TODO can we remove it?
      // https://github.com/mui-org/material-ui/pull/17315#issuecomment-528286677
      if (active) {
        setMatch(queryList.matches)
      }
    }

    updateMatch()
    queryList.addListener(updateMatch)
    return function () {
      active = false
      queryList.removeListener(updateMatch)
    }
  }, [query, matchMedia, supportMatchMedia])

  return match
}
