import React from 'react'

import { SvgIconMediumViewBox, SvgIconProps } from '../../../SvgIcon'

export const PaymentIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIconMediumViewBox {...props}>
      <path
        d='M4.5 10.875C4.70711 10.875 4.875 11.0429 4.875 11.25C4.875 11.4571 4.70711 11.625 4.5 11.625C4.29289 11.625 4.125 11.4571 4.125 11.25C4.125 11.0429 4.29289 10.875 4.5 10.875'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M19.5 18.375C19.7071 18.375 19.875 18.5429 19.875 18.75C19.875 18.9571 19.7071 19.125 19.5 19.125C19.2929 19.125 19.125 18.9571 19.125 18.75C19.125 18.5429 19.2929 18.375 19.5 18.375'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.75 9C0.75 8.17157 1.42157 7.5 2.25 7.5H21.75C22.5784 7.5 23.25 8.17157 23.25 9V21C23.25 21.8284 22.5784 22.5 21.75 22.5H2.25C1.42157 22.5 0.75 21.8284 0.75 21V9Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 18C13.6569 18 15 16.6569 15 15C15 13.3431 13.6569 12 12 12C10.3431 12 9 13.3431 9 15C9 16.6569 10.3431 18 12 18Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M2.25 4.5H21.75' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M5.25 1.5H18.75' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    </SvgIconMediumViewBox>
  )
}
