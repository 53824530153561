import { get as performGet, Response } from 'request'
import zlib from 'zlib'

type PrerenderRequest = {
  headers: { [name: string]: string }
  httpMethod: string
  path: string
  queryStringParameters: { [name: string]: string } | null
  requestContext: {
    protocol: string
  }
}

type Prerender = {
  request: PrerenderRequest
  token: string
  host?: string // to overwrite forward host
  prerenderUrl?: string // to overwrite for prerender caching host
}

const insensitiveGet = (dict: { [key: string]: string } | undefined, searchKey: string): string | undefined => {
  if (!dict) {
    return
  }

  return dict[Object.keys(dict).find((key) => key.toLowerCase() === searchKey.toLowerCase())]
}

export const crawlerUserAgents = [
  'googlebot',
  'yahoo',
  'bingbot',
  'baiduspider',
  'facebookexternalhit',
  'twitterbot',
  'rogerbot',
  'linkedinbot',
  'embedly',
  'quora link preview',
  'showyoubot',
  'outbrain',
  'pinterest/0.',
  'developers.google.com/+/web/snippet',
  'slackbot',
  'vkShare',
  'W3C_Validator',
  'redditbot',
  'Applebot',
  'WhatsApp',
  'flipboard',
  'tumblr',
  'bitlybot',
  'SkypeUriPreview',
  'nuzzel',
  'Discordbot',
  'Google Page Speed',
  'Qwantify',
  'pinterestbot',
  'semrushbot',
]

export const isCrawlerUserAgent = (userAgent: string, includePrerender = false) => {
  const blacklist = crawlerUserAgents
  includePrerender && blacklist.push('prerender')
  return blacklist.some((agent) => userAgent.toLowerCase().indexOf(agent.toLowerCase()) !== -1)
}

export const prerender = ({
  prerenderUrl = process.env.PRERENDER_SERVICE_URL || 'https://service.prerender.io/',
  token = process.env.PRERENDER_TOKEN,
  host,
  request,
}: Prerender) => {
  const extensionsToIgnore = [
    '.js',
    '.css',
    '.xml',
    '.less',
    '.png',
    '.jpg',
    '.jpeg',
    '.gif',
    '.pdf',
    '.doc',
    '.txt',
    '.ico',
    '.rss',
    '.zip',
    '.mp3',
    '.rar',
    '.exe',
    '.wmv',
    '.doc',
    '.avi',
    '.ppt',
    '.mpg',
    '.mpeg',
    '.tif',
    '.wav',
    '.mov',
    '.psd',
    '.ai',
    '.xls',
    '.mp4',
    '.m4a',
    '.swf',
    '.dat',
    '.dmg',
    '.iso',
    '.flv',
    '.m4v',
    '.torrent',
    '.woff',
    '.ttf',
    '.svg',
  ]

  const whitelist: string[] = []
  const blacklist: string[] = []

  const shouldShowPrerenderedPage = () => {
    const { headers, path, httpMethod, queryStringParameters } = request
    const userAgent = insensitiveGet(headers, 'user-agent')
    const bufferAgent = insensitiveGet(headers, 'x-bufferbot')
    let isRequestingPrerenderedPage = false

    if (!userAgent) return false
    if (httpMethod !== 'GET' && httpMethod !== 'HEAD') return false

    //if it contains _escaped_fragment_, show prerendered page
    if (queryStringParameters && queryStringParameters['_escaped_fragment_'] !== undefined)
      isRequestingPrerenderedPage = true

    //if it is a bot...show prerendered page
    if (isCrawlerUserAgent(userAgent)) isRequestingPrerenderedPage = true

    //if it is BufferBot...show prerendered page
    if (bufferAgent) isRequestingPrerenderedPage = true

    //if it is a bot and is requesting a resource...dont prerender
    if (extensionsToIgnore.some((ext) => path.toLowerCase().indexOf(ext) !== -1)) return false

    //if it is a bot and not requesting a resource and is not whitelisted...dont prerender
    if (whitelist.length > 0 && whitelist.every((whitelisted) => new RegExp(whitelisted).test(path) === false))
      return false

    //if it is a bot and not requesting a resource and is not blacklisted(url or referer)...dont prerender
    if (
      blacklist.length > 0 &&
      blacklist.some((blacklisted) => {
        let blacklistedUrl = false
        let blacklistedReferer = false
        const regex = new RegExp(blacklisted)

        blacklistedUrl = regex.test(path) === true
        if (insensitiveGet(headers, 'referer'))
          blacklistedReferer = regex.test(insensitiveGet(headers, 'referer')) === true

        return blacklistedUrl || blacklistedReferer
      })
    )
      return false

    return isRequestingPrerenderedPage
  }

  const buildApiUrl = () => {
    const { requestContext, headers, path, queryStringParameters = {} } = request
    let { protocol } = requestContext

    if (insensitiveGet(headers, 'cf-visitor')) {
      const match = insensitiveGet(headers, 'cf-visitor').match(/"scheme":"(http|https)"/)
      if (match) protocol = match[1]
    }
    if (insensitiveGet(headers, 'x-forwarded-proto')) {
      protocol = insensitiveGet(headers, 'x-forwarded-proto').split(',')[0]
    }

    const queryStrings = []
    for (const key in queryStringParameters) {
      queryStrings.push(`${key}=${queryStringParameters[key]}`)
    }

    const parsedProtocol = (protocol.split('/')[0] || 'http').toLowerCase()
    const fullUrl =
      parsedProtocol +
      '://' +
      (host || insensitiveGet(headers, 'x-forwarded-host') || insensitiveGet(headers, 'host')) +
      path
    return fullUrl + (queryStrings.length > 0 ? `?${queryStrings.join('&')}` : '')
  }

  const getPrerenderedPage = async () => {
    const forwardSlash = prerenderUrl.indexOf('/', prerenderUrl.length - 1) !== -1 ? '' : '/'

    const options = {
      uri: prerenderUrl + forwardSlash + buildApiUrl(),
      followRedirect: false,
      headers: {
        'user-agent': insensitiveGet(request.headers, 'user-agent'),
        'accept-encoding': 'gzip',
        'X-Prerender-Token': token,
      },
    }

    const response: Response = await new Promise((resolve, reject) => {
      performGet(options)
        .on('response', (res) => {
          if (res.headers['content-encoding'] && res.headers['content-encoding'] === 'gzip') {
            gunzipResponse(res).then(resolve).catch(reject)
          } else {
            plainResponse(res).then(resolve).catch(reject)
          }
        })
        .on('error', reject)
    })

    return response
  }

  const gunzipResponse = (res: Response): Promise<Response> => {
    return new Promise((resolve, reject) => {
      const gunzip = zlib.createGunzip()
      let body = ''

      gunzip.on('error', (err) => reject(err))
      gunzip.on('data', (data) => (body += data.toString()))
      gunzip.on('end', () => {
        res.body = body
        delete res.headers['content-encoding']
        delete res.headers['content-length']
        resolve(res)
      })

      res.pipe(gunzip)
    })
  }

  const plainResponse = (res: Response): Promise<Response> => {
    return new Promise((resolve, reject) => {
      let content = ''

      res.on('error', (err) => reject(err))
      res.on('data', (chunk) => (content += chunk))
      res.on('end', () => {
        res.body = content
        resolve(res)
      })
    })
  }

  return {
    whitelist,
    blacklist,
    crawlerUserAgents,
    extensionsToIgnore,
    shouldShowPrerenderedPage,
    isCrawlerUserAgent,
    getPrerenderedPage,
  }
}
