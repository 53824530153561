import { useMemo } from 'react'

import { useParams } from 'react-router'

import { getLocationService } from '@guiker/base-entity'

export { getLocationService }

export const useLocationFromPath = () => {
  const { countryCode, citySlug: cityWithStateSlug, neighbourhoodSlug } = useParams()

  const splittedCitySlug = cityWithStateSlug?.split('-')
  const stateCode = splittedCitySlug?.pop()
  const citySlug = splittedCitySlug?.join('-')

  const locationService = getLocationService()

  return useMemo(
    () => ({
      countryCode,
      citySlug,
      neighbourhoodSlug,
      state: locationService.getState(countryCode, stateCode),
      city: locationService.getCity(countryCode, stateCode, citySlug),
      neighbourhood: locationService.getNeighbourhood(countryCode, stateCode, citySlug, neighbourhoodSlug),
      country: locationService.getCountry(countryCode),
    }),
    [countryCode, stateCode, citySlug, neighbourhoodSlug],
  )
}
