import React, { Children, cloneElement, PropsWithChildren, ReactElement } from 'react'

import { TooltipProps as MuiToolTipProps } from '@material-ui/core/Tooltip'

import { useLayoutContext } from '../../../hooks'
import { clsx, makeStyles, theme, toPx } from '../../../styles'
import { Flex, IconTooltip, PSmall } from '../..'
import { BaseTooltip } from './BaseTooltip'

export type TooltipUnderlineProps = PropsWithChildren &
  Omit<MuiToolTipProps, 'children' | 'arrow'> & {
    backgroundColor?: string
    infoIconColor?: string
    color?: string
  }

const useStyles = makeStyles({
  tip: {
    textUnderlineOffset: toPx(theme.spacing(0.5)),
    textDecoration: 'underline',
    textDecorationStyle: 'dashed',
    textDecorationThickness: 1,
  },
  icon: {
    color: ({ infoIconColor }: { infoIconColor: string }) =>
      infoIconColor ? `${infoIconColor}` : `${theme.palette.text.primary.main}`,
  },
})

const TooltipUnderline: React.FC<TooltipUnderlineProps> = ({
  className,
  backgroundColor,
  title,
  color,
  infoIconColor,
  children,
  ...props
}) => {
  const classes = useStyles({ infoIconColor })
  const { isAtMostTablette } = useLayoutContext()
  const [open, setOpen] = React.useState(false)
  const handleTooltipClose = () => setOpen(false)
  const handleTooltipOpen = () => setOpen(true)
  const handleTooltipClick = () => setOpen((curr) => !curr)

  return isAtMostTablette ? (
    <Flex gap={1} alignItems='center'>
      <PSmall color={60} textAlign='left' mb={0}>
        {children}
      </PSmall>
      <IconTooltip title={title} infoIconColor={infoIconColor} color={color} backgroundColor={backgroundColor} />
    </Flex>
  ) : (
    <BaseTooltip
      title={title}
      arrow={true}
      backgroundColor={backgroundColor}
      color={color}
      className={className}
      onClose={handleTooltipClose}
      onOpen={handleTooltipOpen}
      open={open}
      {...props}
    >
      <span onClick={handleTooltipClick}>
        {Children.toArray(children).map((child: ReactElement) =>
          cloneElement(child, { className: clsx(child.props.className, classes.tip) }),
        )}
      </span>
    </BaseTooltip>
  )
}

export { TooltipUnderline }
