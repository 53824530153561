import React from 'react'

import { ColumnGridLayout, ColumnGridLayoutProps } from '../ColumnGridLayout'

type OneThirdTwoThirdColumnsGridLayoutProps = ColumnGridLayoutProps

const OneThirdTwoThirdsColumnsGridLayout: React.FC<OneThirdTwoThirdColumnsGridLayoutProps> = ({
  gap,
  xs,
  children,
  ...props
}) => {
  return (
    <ColumnGridLayout
      gridTemplateColumns={[1, 2]}
      gap={gap}
      sm={{ gridTemplateColumns: [1], gap: 2, ...xs }}
      {...props}
    >
      {children}
    </ColumnGridLayout>
  )
}

export { OneThirdTwoThirdsColumnsGridLayout }
