import React from 'react'

import { clsx } from '@guiker/clsx'
import { isMobile, makeStyles, theme, toPx } from '@guiker/components-core'
import { CollapseProps, default as Collapse } from '@material-ui/core/Collapse'

import { Box, BoxProps } from '../Box'
import { SlidingPanelButton } from './SlidingPanelButton'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    overflow: 'hidden',
    maxHeight: typeof window !== 'undefined' ? window.innerHeight : '100vh',
    paddingTop: ({ isTakeover }: { isTakeover: boolean }) =>
      isTakeover ? toPx(theme.dimensions.appBar.height.mobile) : 0,
  },
  content: {
    height: ({ buttonHeight }: { buttonHeight: number }) =>
      `calc(100vh - ${theme.dimensions.appBar.height.desktop}px - ${buttonHeight}px)`,
    overflow: 'scroll',
    overflowX: 'hidden',
    backgroundColor: theme.palette.common.white,
    [isMobile]: {
      height: ({ buttonHeight }: { buttonHeight: number }) =>
        `calc(100vh - ${theme.dimensions.appBar.height.mobile}px - ${buttonHeight}px)`,
    },
  },
})

type TopSlidingPanelProps = CollapseProps &
  BoxProps & {
    topIn: boolean
    topButtonLabel: string
    buttonHeight?: number
    bottomButtonLabel?: string
    topContent: React.ReactNode
    bottomContent: React.ReactNode
    buttonClassNames?: string
    buttonLabelClassNames?: string
    onClick: any
    topContentClassName?: string
    bottomContentClassName?: string
    isTakeover?: boolean
  }

export const MobileVerticalSlidingPanels: React.FC<TopSlidingPanelProps> = ({
  topIn = false,
  topButtonLabel,
  buttonHeight = 42,
  bottomButtonLabel = topButtonLabel,
  buttonClassNames,
  buttonLabelClassNames,
  onClick,
  topContent,
  bottomContent,
  topContentClassName,
  bottomContentClassName,
  isTakeover,
  ...props
}) => {
  const classes = useStyles({ buttonHeight, isTakeover })

  return (
    <>
      <Collapse in={topIn} mountOnEnter>
        <Box className={clsx([classes.root])} {...props}>
          <Box className={clsx([classes.content, topContentClassName])}>{topContent}</Box>
          <SlidingPanelButton onClick={onClick}>{topButtonLabel}</SlidingPanelButton>
        </Box>
      </Collapse>
      <Collapse in={!topIn} mountOnEnter>
        <Box className={classes.root} {...props}>
          <SlidingPanelButton onClick={onClick}>{bottomButtonLabel}</SlidingPanelButton>
          <Box className={clsx([classes.content, bottomContentClassName])}>{bottomContent}</Box>
        </Box>
      </Collapse>
    </>
  )
}
