import React, { useState } from 'react'

type Callback<T> = () => T
type SetState<T> = (newState: T) => void

export const useTimeout = <T,>(callback: Callback<T>, delay: number, dependencies: unknown[] = []) => {
  const savedCallback = React.useRef<() => unknown>()

  React.useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  React.useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      const id = setTimeout(tick, delay)
      return () => clearTimeout(id)
    }
  }, [delay, ...dependencies])
}

export const delayedSetState = <T,>(
  initialValue: T,
  callback: (state: T, setState: SetState<T>) => unknown,
  delay: number,
): [T, SetState<T>] => {
  const [state, setState] = useState(initialValue)
  useTimeout(() => callback(state, setState), delay)

  return [state, setState]
}
