export const keywords = [
  'homes',
  'real estate',
  'apartments for rent',
  'apartment',
  'homes for rent',
  'rent',
  'rentals',
  'toronto rentals',
  'tenants',
  'real estate agents',
  'condo rentals',
  'property management',
  'montreal real estate',
  'background check',
  'landlord',
  'renter application',
  'guiker',
  'manage rentals',
  'rental listing',
  'rental deposit',
  'apartment rental finder',
  'lease signing',
]
