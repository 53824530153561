import React from 'react'

import { clsx } from '@guiker/clsx'
import { makeStyles } from '@guiker/components-core'
import { CheckmarkSmallIcon, OutlinedAlertIcon } from '@guiker/icons'
import { Link } from '@guiker/router'

const useStyles = makeStyles(
  (theme) => ({
    icon: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      height: '100%',
      width: '100%',
      padding: 2,
    },
    checkedIcon: {},
    failedIcon: {
      color: theme.palette.status.alert.main,
      borderStyle: 'none',
    },
  }),
  { name: 'TrackerItem' },
)

const CheckedIcon: React.FC = () => {
  const classes = useStyles()

  return (
    <div className={clsx(classes.icon, classes.checkedIcon)}>
      <CheckmarkSmallIcon />
    </div>
  )
}

const FailedIcon: React.FC = () => {
  const classes = useStyles()

  return (
    <div className={clsx(classes.icon, classes.failedIcon)}>
      <OutlinedAlertIcon />
    </div>
  )
}

const LinkWrapper: React.FC<
  React.PropsWithChildren & {
    link: string
    isExternalLink: boolean
    isDisabled: boolean
  }
> = ({ children, link, isDisabled, isExternalLink = false }) => {
  if (link) {
    return (
      <Link to={link} disabled={isDisabled} isExternalLink={isExternalLink} target='_top'>
        {children}
      </Link>
    )
  } else {
    return <>{children}</>
  }
}

export { CheckedIcon, FailedIcon, LinkWrapper }
