import React, { CSSProperties } from 'react'

import { CellRenderable, StringLike } from './cell-renderable'

export type ColumnConfig<T, CV extends object = object> = {
  label?: string
  headerOptions?: {
    textAlign: CSSProperties['textAlign']
  }
  name?: string
  sortable?: boolean
  size?: number
  colSpan?: number
  verticalAlign?: 'top' | 'middle' | 'bottom'
  renderValue:
    | React.FC<T & { index: number }>
    | ((props: T & { index: number }, context?: any) => CellRenderable | [CellRenderable, CellRenderable])
  collapsibleOptions?: {
    verticalAlign?: 'top' | 'middle' | 'bottom'
    colSpan?: number
  }
  collapsibleValues?: (entity: T) => CV[]
  renderCollapsibleValue?:
    | React.FC<CV & { index?: number; parent?: T }>
    | ((props: CV & { index?: number; parent?: T }, context?: any) => StringLike)
    | ((props: CV & { index?: number; parent?: T }, context?: any) => React.ReactElement[])
  renderCollapsibleFooter?: React.FC<T> | ((props: T, context?: any) => StringLike)
}

export const getColumnsBuilder =
  <C, CV extends object = object>() =>
  <T extends ColumnConfig<C, CV>, Args extends Record<string, any>>(
    useGetColumnsCallback: (callbackArgs: Args) => readonly T[],
  ) => {
    return (args?: { columns?: readonly T['name'][]; callbackArgs?: Args }): ColumnConfig<C, CV>[] => {
      const { columns = null, callbackArgs } = { ...args }
      const colConfigs = useGetColumnsCallback(callbackArgs)

      if (!columns) return [...colConfigs]

      return columns.map((col) => colConfigs.find((colConfig) => colConfig.name === col))
    }
  }
