import React from 'react'

import { useLayoutContext } from '../../../hooks'
import { H5 } from '../../Data Display'
import { PageSection, PageSectionProps } from '../PageSection/PageSection'

type FormSubSectionProps = Omit<PageSectionProps, 'TitleComponent' | 'titleMb' | 'spacing'>

const FormSection4: React.FC<FormSubSectionProps> = (props) => {
  const { isMobile } = useLayoutContext()

  return <PageSection TitleComponent={H5} titleMb={3} titleSubtitleGap={1} spacing={isMobile ? 1.5 : 3} {...props} />
}

export { FormSection4 }
