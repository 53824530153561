import React from 'react'

import { InfoMiniIcon } from '@guiker/icons'
import { ClickAwayListener } from '@material-ui/core'
import { TooltipProps as MuiToolTipProps } from '@material-ui/core/Tooltip'

import { makeStyles, theme } from '../../../styles'
import { BaseTooltip } from './BaseTooltip'

export type IconTooltipProps = Omit<MuiToolTipProps, 'children' | 'arrow'> & {
  icon?: React.ReactNode
  backgroundColor?: string
  infoIconColor?: string
  color?: string
}

const useStyles = makeStyles({
  icon: {
    color: ({ infoIconColor }: { infoIconColor: string }) =>
      infoIconColor ? `${infoIconColor}` : `${theme.palette.text.primary.main}`,
  },
})

const IconTooltip: React.FC<IconTooltipProps> = ({
  className,
  backgroundColor,
  title,
  icon,
  color,
  infoIconColor,
  ...props
}) => {
  const classes = useStyles({ infoIconColor })
  const [open, setOpen] = React.useState(false)

  const handleTooltipClose = () => {
    setOpen(false)
  }

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <BaseTooltip
          title={title}
          arrow={true}
          backgroundColor={backgroundColor}
          color={color}
          className={className}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableTouchListener
          {...props}
        >
          <div onClick={() => setOpen(!open)}>{icon ? icon : <InfoMiniIcon className={classes.icon} />}</div>
        </BaseTooltip>
      </div>
    </ClickAwayListener>
  )
}

export { IconTooltip }
