import React from 'react'

import { SvgIconMediumViewBox, SvgIconProps } from '../../../SvgIcon'

type RecreationRoomIconProps = SvgIconProps

const RecreationRoomIcon: React.FC<RecreationRoomIconProps> = (props) => {
  return (
    <SvgIconMediumViewBox {...props}>
      <path
        d='M14.25 17.5H2.25C1.85218 17.5 1.47064 17.342 1.18934 17.0607C0.908035 16.7794 0.75 16.3978 0.75 16V4C0.75 3.60218 0.908035 3.22064 1.18934 2.93934C1.47064 2.65804 1.85218 2.5 2.25 2.5H21.75C22.1478 2.5 22.5294 2.65804 22.8107 2.93934C23.092 3.22064 23.25 3.60218 23.25 4V16C23.25 16.3978 23.092 16.7794 22.8107 17.0607C22.5294 17.342 22.1478 17.5 21.75 17.5'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.25 11.875C5.25 11.7755 5.28951 11.6802 5.35984 11.6098C5.43016 11.5395 5.52554 11.5 5.625 11.5'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.625 12.25C5.52554 12.25 5.43016 12.2105 5.35984 12.1402C5.28951 12.0698 5.25 11.9745 5.25 11.875'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6 11.875C6 11.9745 5.96049 12.0698 5.89016 12.1402C5.81984 12.2105 5.72446 12.25 5.625 12.25'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.625 11.5C5.72446 11.5 5.81984 11.5395 5.89016 11.6098C5.96049 11.6802 6 11.7755 6 11.875'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.25 7.375C5.25 7.27554 5.28951 7.18016 5.35984 7.10984C5.43016 7.03951 5.52554 7 5.625 7'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.625 7.75C5.52554 7.75 5.43016 7.71049 5.35984 7.64016C5.28951 7.56984 5.25 7.47446 5.25 7.375'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6 7.375C6 7.47446 5.96049 7.56984 5.89016 7.64016C5.81984 7.71049 5.72446 7.75 5.625 7.75'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.625 7C5.72446 7 5.81984 7.03951 5.89016 7.10984C5.96049 7.18016 6 7.27554 6 7.375'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.625 7.75C17.7245 7.75 17.8198 7.78951 17.8902 7.85984C17.9605 7.93016 18 8.02554 18 8.125'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.25 8.125C17.25 8.02554 17.2895 7.93016 17.3598 7.85984C17.4302 7.78951 17.5255 7.75 17.625 7.75'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.625 8.5C17.5255 8.5 17.4302 8.46049 17.3598 8.39017C17.2895 8.31984 17.25 8.22446 17.25 8.125'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18 8.125C18 8.22446 17.9605 8.31984 17.8902 8.39017C17.8198 8.46049 17.7245 8.5 17.625 8.5'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.25 9.625C8.25 9.52554 8.28951 9.43016 8.35983 9.35983C8.43016 9.28951 8.52554 9.25 8.625 9.25'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.625 10C8.52554 10 8.43016 9.96049 8.35983 9.89017C8.28951 9.81984 8.25 9.72446 8.25 9.625'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9 9.625C9 9.72446 8.96049 9.81984 8.89017 9.89017C8.81984 9.96049 8.72446 10 8.625 10'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.625 9.25C8.72446 9.25 8.81984 9.28951 8.89017 9.35983C8.96049 9.43016 9 9.52554 9 9.625'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M12 11.5L22.5 22' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    </SvgIconMediumViewBox>
  )
}

export { RecreationRoomIcon }
