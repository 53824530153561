import React from 'react'

import { BrowserRouterProps } from '.'
import { RouterContext, RouterContextProps } from './RouterContext'

type RouterProps = React.PropsWithChildren & BrowserRouterProps & RouterContextProps

export const RouterContextProvider: React.FC<RouterProps> = ({ children, ...props }) => {
  return <RouterContext.Provider value={props}>{children}</RouterContext.Provider>
}

RouterContextProvider.displayName = 'RouterContextProvider'
