import React from 'react'

import { SvgIconMediumViewBox, SvgIconProps } from '../../SvgIcon'

export const RentalProcessIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIconMediumViewBox viewBox='1 1 24 26' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.75 4.25C0.75 3.42157 1.42157 2.75 2.25 2.75H21.75C22.5784 2.75 23.25 3.42157 23.25 4.25V22.25C23.25 23.0784 22.5784 23.75 21.75 23.75H2.25C1.42157 23.75 0.75 23.0784 0.75 22.25V4.25Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M5.25 1.25V4.25' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M18.75 1.25V4.25' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M12.75 18.5H17.25'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M12.75 11H17.25' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M9.9 15.8L6.75 20L4.5 17.75'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.9 8L6.75 12.2L4.5 9.95'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIconMediumViewBox>
  )
}
