import * as React from 'react'

import { clsx } from '@guiker/clsx'
import { makeStyles, theme } from '@guiker/components-core'

import { useScreenSize } from '../../../hooks'
import { Flex, Link, P } from '../..'

const useStyles = makeStyles(
  {
    textItem: {
      whiteSpace: 'nowrap',
    },
    gapped: {
      display: 'flex',
      columnGap: theme.spacing(2),
      flexWrap: 'wrap',
    },
  },
  { name: 'Breadcrumb' },
)

export type BreadcrumbItem = {
  to?: string
  label: string
  prefixWithLocale?: boolean
}

export type BreadcrumbProps = {
  className?: string
  items: BreadcrumbItem[]
  ItemComponent?: React.FC<React.PropsWithChildren & { className: string } & Omit<BreadcrumbItem, 'label'>>
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({
  className,
  items,
  ItemComponent = (props) => <Link underline={false} {...props} />,
}) => {
  const classes = useStyles()

  if (items.length < 1) {
    return null
  }

  const { isMd } = useScreenSize()
  let itemsToDisplay: BreadcrumbItem[]

  if (isMd) {
    itemsToDisplay = items
  } else {
    if (items.length >= 2) {
      itemsToDisplay = items.slice(items.length - 2, items.length)
    } else {
      itemsToDisplay = items
    }

    if (items.length >= 3) {
      itemsToDisplay = [
        {
          to: items[items.length - 3].to,
          label: '...',
        },
        ...itemsToDisplay,
      ]
    }
  }

  return (
    <Flex className={className} flexWrap='wrap'>
      <P component='span' color={60} className={clsx(classes.gapped)} whiteSpace='nowrap'>
        {itemsToDisplay.map(({ label, ...item }, index) => {
          const isLast = index >= itemsToDisplay.length - 1

          return (
            <React.Fragment key={index}>
              {!item.to || isLast ? (
                <span className={classes.textItem}>{label}</span>
              ) : (
                <ItemComponent className={classes.textItem} {...item}>
                  {label}
                </ItemComponent>
              )}
              {!isLast && <span className={classes.textItem}>/</span>}
            </React.Fragment>
          )
        })}
      </P>
    </Flex>
  )
}

export { Breadcrumb }
