import React from 'react'

const DiscoverIcon: React.FC = () => {
  return (
    <svg width='38' height='26' viewBox='0 0 38 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.753784 2.60547C0.753784 1.77704 1.42536 1.10547 2.25378 1.10547H35.2538C36.0822 1.10547 36.7538 1.77704 36.7538 2.60547V23.6055C36.7538 24.4339 36.0822 25.1055 35.2538 25.1055H2.25378C1.42536 25.1055 0.753784 24.4339 0.753784 23.6055V2.60547Z'
        fill='white'
        stroke='#D7D7D7'
        strokeWidth='0.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.4998 13.1056C16.4998 14.6018 17.7602 15.8147 19.315 15.8147C20.8699 15.8147 22.1303 14.6018 22.1303 13.1056C22.1303 11.6093 20.8699 10.3964 19.315 10.3964C17.7602 10.3964 16.4998 11.6093 16.4998 13.1056Z'
        fill='url(#paint0_linear_11_353)'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.91016 14.6912C2.90976 14.7604 2.92486 14.7826 3.00123 14.7816C3.2091 14.779 3.4173 14.7811 3.62392 14.7589C4.0012 14.7183 4.35503 14.6145 4.63123 14.3495C5.09547 13.9042 5.26605 13.3625 5.13714 12.7497C5.00732 12.1325 4.63682 11.6975 3.99441 11.5066C3.68148 11.4137 3.35612 11.4195 3.03232 11.4156C2.94007 11.4144 2.90809 11.4319 2.90917 11.5291C2.91488 12.0527 2.91327 14.1608 2.91016 14.6912ZM1.8723 10.6527C1.87199 10.569 1.88489 10.5386 1.98385 10.54C2.49124 10.5469 2.99913 10.5333 3.50614 10.5484C4.2521 10.5706 4.92992 10.7791 5.48281 11.2843C5.86334 11.632 6.10255 12.0576 6.1967 12.5515C6.38427 13.5354 6.09497 14.3703 5.31335 15.0378C4.88666 15.4021 4.37528 15.5853 3.81045 15.629C3.18789 15.6772 2.56356 15.6389 1.94019 15.6519C1.86564 15.6535 1.87395 15.6148 1.87398 15.5699C1.8744 15.0664 1.87552 11.4671 1.8723 10.6527Z'
        fill='#373535'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M32.1816 12.1248C32.1816 12.3508 32.1858 12.577 32.1793 12.8029C32.177 12.8822 32.2032 12.8977 32.279 12.8947C32.4335 12.8886 32.5881 12.8919 32.7426 12.8763C33.195 12.8305 33.4522 12.5798 33.4858 12.1452C33.5128 11.7954 33.3264 11.5307 32.9793 11.4215C32.751 11.3498 32.5156 11.3576 32.2804 11.3532C32.2035 11.3518 32.1774 11.3678 32.1794 11.4466C32.1855 11.6725 32.1816 11.8987 32.1816 12.1248ZM33.3621 13.5091C33.9201 14.2247 34.4692 14.929 35.0318 15.6504H34.8073C34.4935 15.6504 34.1796 15.6457 33.866 15.6532C33.771 15.6555 33.7231 15.6216 33.6739 15.5508C33.2389 14.9255 32.8002 14.3027 32.3626 13.6792C32.3567 13.6708 32.3495 13.6628 32.3456 13.6536C32.3192 13.5913 32.2624 13.5954 32.212 13.6054C32.1564 13.6164 32.1829 13.6664 32.1828 13.6979C32.1811 14.3098 32.1787 14.9217 32.1844 15.5336C32.1853 15.6353 32.1538 15.6551 32.0552 15.6527C31.7804 15.646 31.5052 15.6484 31.2303 15.6518C31.161 15.6526 31.1328 15.6405 31.1329 15.565C31.1352 13.9186 31.1349 12.2722 31.1338 10.6258C31.1337 10.57 31.1399 10.5398 31.2119 10.5413C31.8038 10.5531 32.3965 10.5186 32.9877 10.5621C33.3983 10.5923 33.7844 10.6988 34.1001 10.9672C34.4788 11.2892 34.5853 11.7116 34.5596 12.1769C34.5218 12.8613 34.1328 13.3132 33.4398 13.4816C33.4164 13.4873 33.3942 13.4976 33.3621 13.5091Z'
        fill='#373535'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M27.5104 10.6419C27.5101 10.5652 27.5264 10.5402 27.6126 10.5405C28.529 10.5439 29.4455 10.5434 30.362 10.5411C30.4333 10.5409 30.4591 10.5543 30.4575 10.6284C30.4523 10.8649 30.4524 11.1016 30.4575 11.3381C30.4591 11.4095 30.4315 11.419 30.3672 11.4188C29.7997 11.4167 29.2322 11.4201 28.6647 11.4151C28.5682 11.4142 28.549 11.4412 28.5506 11.528C28.5562 11.8355 28.5556 12.1432 28.5508 12.4507C28.5496 12.5276 28.5721 12.5461 28.6508 12.5457C29.1971 12.5423 29.7435 12.546 30.2899 12.5417C30.3762 12.541 30.3927 12.5665 30.391 12.6425C30.3859 12.8689 30.386 13.0955 30.3911 13.3218C30.3926 13.3934 30.3714 13.4104 30.2981 13.41C29.7553 13.4069 29.2124 13.4112 28.6696 13.4058C28.5683 13.4048 28.5496 13.4355 28.5507 13.5244C28.5556 13.9097 28.555 14.2951 28.551 14.6804C28.5502 14.757 28.5668 14.7822 28.6531 14.7816C29.2206 14.7774 29.7881 14.7814 30.3556 14.7774C30.4403 14.7768 30.4593 14.7999 30.4575 14.8773C30.4522 15.1104 30.4539 15.3438 30.4568 15.5769C30.4575 15.6328 30.4432 15.6518 30.3814 15.6516C29.4508 15.6498 28.5202 15.6491 27.5897 15.6525C27.5049 15.6528 27.5117 15.6131 27.5117 15.56C27.5123 14.7352 27.5132 11.4565 27.5104 10.6419Z'
        fill='#373535'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.82501 14.1746C8.96523 14.426 9.1502 14.6308 9.42243 14.7456C9.71508 14.8689 10.0164 14.9119 10.3202 14.7816C10.5962 14.6634 10.7453 14.4523 10.7572 14.1649C10.769 13.8802 10.6102 13.6895 10.353 13.5664C10.0574 13.4249 9.73768 13.3408 9.43556 13.215C9.14036 13.0919 8.85911 12.9497 8.65525 12.6978C8.5307 12.5439 8.46159 12.368 8.43743 12.1797C8.32978 11.3409 8.80891 10.7409 9.5356 10.5437C10.2946 10.3377 10.9781 10.5187 11.5904 10.9802C11.6356 11.0142 11.6512 11.0383 11.6079 11.0903C11.452 11.2776 11.2996 11.4676 11.1523 11.661C11.1075 11.7199 11.088 11.7032 11.0472 11.6614C10.8806 11.491 10.6894 11.3529 10.4416 11.319C10.129 11.2761 9.8331 11.3144 9.61545 11.563C9.42082 11.7853 9.46403 12.0651 9.70986 12.2326C9.92988 12.3824 10.1863 12.4579 10.4345 12.5499C10.6671 12.6361 10.8943 12.7325 11.1118 12.8507C11.5555 13.092 11.7994 13.4459 11.8312 13.9418C11.8848 14.779 11.4258 15.4861 10.5708 15.6968C9.65865 15.9215 8.76734 15.6726 8.18219 14.8443C8.15804 14.8101 8.15541 14.7901 8.18869 14.7598C8.40063 14.5671 8.61051 14.3723 8.82501 14.1746Z'
        fill='#373536'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M21.9299 10.5426C22.2915 10.5426 22.6368 10.5448 22.9821 10.5407C23.0512 10.5399 23.0781 10.5637 23.102 10.6217C23.4198 11.3931 24.3356 13.6023 24.4762 13.9432C24.5404 13.8752 25.4554 11.65 25.8867 10.6181C25.9039 10.577 25.9163 10.5406 25.979 10.5412C26.3312 10.5442 26.6835 10.5426 27.0507 10.5426C26.9669 10.7356 25.458 14.1687 24.7865 15.7017C24.7599 15.7624 24.7305 15.7822 24.6644 15.7798C24.5307 15.775 24.3966 15.7753 24.2628 15.7798C24.2033 15.7818 24.1764 15.761 24.1543 15.7096C23.7404 14.747 22.2884 11.3832 21.9781 10.6632C21.9623 10.6264 21.9484 10.5889 21.9299 10.5426Z'
        fill='#373536'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.2641 11.9238C16.0677 11.7436 15.8793 11.5922 15.6544 11.4925C14.7694 11.1 13.704 11.5216 13.3628 12.4217C13.1001 13.1149 13.1875 13.7741 13.7154 14.3372C14.0684 14.7136 14.5348 14.8509 15.0549 14.8457C15.4675 14.8416 15.8089 14.6806 16.1107 14.425C16.1551 14.3873 16.198 14.3481 16.2412 14.3101C16.2787 14.3297 16.2632 14.3602 16.2633 14.3839C16.2645 14.7186 16.2635 15.0533 16.2649 15.3879C16.2651 15.4387 16.2572 15.4697 16.1997 15.4963C15.131 15.9897 13.779 15.7944 12.9428 14.9737C12.4254 14.4658 12.1705 13.8526 12.1544 13.1428C12.1272 11.9418 12.9617 10.8944 14.1226 10.558C14.8349 10.3516 15.5304 10.3912 16.2013 10.714C16.2374 10.7314 16.2655 10.7463 16.2652 10.7942C16.2632 11.1624 16.2641 11.5306 16.2641 11.9238Z'
        fill='#373536'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.76878 15.539C7.7691 15.6229 7.75609 15.656 7.65599 15.6532C7.3742 15.6452 7.09197 15.6483 6.81 15.6518C6.74156 15.6527 6.72052 15.6359 6.7206 15.5681C6.72253 13.9217 6.72261 12.2754 6.72037 10.629C6.72026 10.5548 6.74582 10.5402 6.8167 10.5411C7.09514 10.5447 7.37378 10.5466 7.65214 10.5404C7.74433 10.5383 7.76977 10.5595 7.76931 10.6507C7.76523 11.4688 7.76569 14.7276 7.76878 15.539Z'
        fill='#373536'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M35.2278 11.2229C35.0505 11.2229 34.9067 11.0791 34.9067 10.9018C34.9067 10.7245 35.0505 10.5807 35.2278 10.5807C35.4053 10.5807 35.549 10.7245 35.549 10.9018C35.549 11.0791 35.4053 11.2229 35.2278 11.2229ZM35.2278 10.5074C35.0028 10.5074 34.8205 10.684 34.8205 10.9018C34.8205 11.1196 35.0028 11.2961 35.2278 11.2961C35.4528 11.2961 35.6353 11.1196 35.6353 10.9018C35.6353 10.684 35.4528 10.5074 35.2278 10.5074Z'
        fill='#383637'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M35.1568 10.8171C35.1568 10.8375 35.1573 10.8579 35.1567 10.8783C35.1564 10.8855 35.1588 10.8869 35.1657 10.8866C35.1796 10.886 35.1935 10.8864 35.2075 10.8849C35.2484 10.8808 35.2716 10.8582 35.2746 10.8189C35.277 10.7874 35.2602 10.7634 35.2289 10.7536C35.2083 10.7472 35.187 10.7478 35.1658 10.7474C35.1589 10.7473 35.1564 10.7488 35.1567 10.7559C35.1572 10.7763 35.1568 10.7967 35.1568 10.8171ZM35.2634 10.9421C35.3138 11.0067 35.3634 11.0702 35.4142 11.1354H35.3939C35.3655 11.1354 35.3372 11.1349 35.3089 11.1356C35.3004 11.1359 35.296 11.1328 35.2916 11.1264C35.2523 11.0699 35.2127 11.0137 35.1732 10.9574C35.1727 10.9567 35.172 10.9559 35.1717 10.9552C35.1693 10.9495 35.1642 10.9498 35.1596 10.9508C35.1546 10.9517 35.157 10.9563 35.157 10.9591C35.1568 11.0143 35.1566 11.0696 35.1571 11.1248C35.1572 11.134 35.1544 11.1358 35.1454 11.1356C35.1206 11.135 35.0958 11.1352 35.071 11.1355C35.0647 11.1356 35.0622 11.1345 35.0622 11.1277C35.0624 10.979 35.0624 10.8304 35.0623 10.6818C35.0623 10.6767 35.0629 10.6741 35.0693 10.6741C35.1227 10.6752 35.1763 10.6721 35.2296 10.676C35.2667 10.6787 35.3015 10.6884 35.33 10.7126C35.3643 10.7416 35.3739 10.7798 35.3715 10.8218C35.3681 10.8836 35.333 10.9244 35.2704 10.9396C35.2683 10.9401 35.2663 10.941 35.2634 10.9421Z'
        fill='#373535'
      />
      <defs>
        <linearGradient
          id='paint0_linear_11_353'
          x1='18.1215'
          y1='10.6512'
          x2='20.3485'
          y2='15.6315'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#E46539' />
          <stop offset='0.383085' stop-color='#E46539' />
          <stop offset='1' stop-color='#F9A336' />
        </linearGradient>
      </defs>
    </svg>
  )
}

export { DiscoverIcon }
