import React, { useEffect, useState } from 'react'

import { NumericFormat, NumericFormatProps } from 'react-number-format'

import { TextField, TextFieldProps } from '../'
import { Formatter } from './formatter'

export type NumberFormatProps = Omit<NumericFormatProps, 'onChange'> &
  Omit<TextFieldProps, 'type' | 'onChange'> & {
    ref?: React.Ref<any>
    onChange?: (value: any) => void
    value?: number | string
    formatter?: Formatter
  }

const defaultFormatter: Formatter = {
  toDisplay: (value: string) => Number(value),
  toActual: (value: string) => Number(value),
}

const NumberFormat: React.FC<NumberFormatProps> = ({
  value: stateValue,
  onChange,
  formatter = defaultFormatter,
  defaultValue,
  name,
  thousandSeparator = true,
  ...props
}) => {
  const [displayValue, setDisplayValue] = useState(formatter.toDisplay(defaultValue))

  useEffect(() => {
    setDisplayValue(formatter.toDisplay(stateValue))
  }, [stateValue])

  return (
    <NumericFormat
      customInput={TextField}
      value={displayValue}
      onValueChange={({ floatValue }) => {
        if (floatValue !== stateValue) {
          setDisplayValue(floatValue)
          onChange(formatter.toActual(floatValue))
        }
      }}
      name={name}
      defaultValue={defaultValue}
      thousandSeparator={thousandSeparator}
      {...props}
    />
  )
}

export { NumberFormat }
