import React, { ReactNode } from 'react'

import { makeStyles } from '@guiker/components-core'
import { Flex, PBold, PSmall } from '@guiker/components-library'

type StatusFilterCardProps = {
  selected?: boolean
  label: string
  value: string | number
  valueAdornment?: ReactNode
  onClick?: () => void
}

const useStyles = makeStyles(
  (theme) => ({
    root: {
      borderRadius: 8,
      borderColor: ({ selected }: StatusFilterCardProps) =>
        selected ? theme.palette.primary.main : theme.palette.grey[10],
      borderStyle: 'solid',
      borderWidth: ({ selected }: StatusFilterCardProps) => (selected ? '0.1rem' : '0.0725rem'),
      cursor: 'pointer',
      '&:hover': {
        borderColor: ({ selected }: StatusFilterCardProps) => !selected && theme.palette.grey[30],
      },
    },
    text: {
      color: ({ selected }: StatusFilterCardProps) => (selected ? theme.palette.primary.main : theme.palette.grey[80]),
    },
  }),
  { name: 'StatusFilterCard' },
)

export const StatusFilterCard: React.FC<StatusFilterCardProps> = ({
  onClick,
  selected,
  label,
  valueAdornment,
  value,
}) => {
  const classes = useStyles({ selected, onClick })
  const handleClick = () => onClick?.()

  return (
    <Flex
      flexDirection='column'
      justifyContent='space-between'
      onClick={() => handleClick()}
      className={classes.root}
      px={1.5}
      py={1.5}
    >
      <Flex flexDirection='column' gap={0.5}>
        <PSmall mb={0} fontWeight={selected ? 500 : 400} className={classes.text}>
          {label}
        </PSmall>
        <Flex gap={1} alignItems='center'>
          {valueAdornment}
          <PBold mb={0} className={classes.text}>
            {value}
          </PBold>
        </Flex>
      </Flex>
    </Flex>
  )
}
