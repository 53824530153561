import React from 'react'

import { Switch, SwitchProps as CLSwitchProps } from '@guiker/components-library'
import { useTranslation } from '@guiker/i18n'
import { Controller, ErrorMessages, FieldPath, PathValue, useFormInput } from '@guiker/react-hook-form'

export type SwitchProps<T extends object, P extends FieldPath<T>> = Omit<CLSwitchProps, 'name' | 'defaultValue'> &
  ErrorMessages & {
    name: P
    defaultValue?: PathValue<T, P>
  }

export const RHFSwitch = <T extends object, P extends FieldPath<T>>({
  defaultValue,
  options,
  name,
  ...props
}: SwitchProps<T, P>) => {
  const { t } = useTranslation()
  const { control, errorMessage } = useFormInput<T, P>({ name, t })

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || (options[0].value as PathValue<T, P>)}
      render={({ field: { onChange, value, ref, ...renderProps }, fieldState }) => {
        return (
          <Switch
            checked={value === options[1].value}
            value={value === options[1].value ? options[1].value : options[0].value}
            onChange={(e: React.ChangeEvent<{ checked?: boolean }>) => {
              onChange(e.target.checked ? options[1].value : options[0].value)
            }}
            options={options}
            error={!!fieldState.error}
            errorMessage={fieldState.error && errorMessage}
            inputRef={ref}
            {...renderProps}
            {...props}
          />
        )
      }}
    />
  )
}
