import { Dispatch, SetStateAction, useEffect, useState } from 'react'

export const useStateWithDependencies = <T>(
  defaultValue: T,
  dependencies: unknown[] = [],
): [T, Dispatch<SetStateAction<T>>] => {
  const state = useState(defaultValue)
  const [getter, setter] = state

  useEffect(() => {
    setter(defaultValue)
  }, [defaultValue, ...dependencies])

  return [getter, setter]
}
