import React from 'react'

import { SvgIconMediumViewBox, SvgIconProps } from '../../SvgIcon'

type FileGiveHandIconProps = SvgIconProps

const FileGiveHandIcon: React.FC<FileGiveHandIconProps> = (props) => {
  return (
    <SvgIconMediumViewBox {...props}>
      <path
        d='M11.25 12.75V2.083C11.25 1.3468 11.8468 0.75 12.583 0.75H19.364C19.7178 0.749708 20.0572 0.890419 20.307 1.141L22.859 3.693C23.1096 3.94281 23.2503 4.28217 23.25 4.636V14.417C23.25 15.1532 22.6532 15.75 21.917 15.75H16.5'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M0.75 14.25H3.75C4.57843 14.25 5.25 14.9216 5.25 15.75V21.75C5.25 22.5784 4.57843 23.25 3.75 23.25H0.75'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.25 21.75C20.25 20.0931 18.9069 18.75 17.25 18.75H13.5C13.5 17.0931 12.1569 15.75 10.5 15.75H5.25V21.75H20.25Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M10.5 18.75H13.5' fill='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M2.625 19.5C2.83211 19.5 3 19.6679 3 19.875C3 20.0821 2.83211 20.25 2.625 20.25C2.41789 20.25 2.25 20.0821 2.25 19.875C2.25 19.6679 2.41789 19.5 2.625 19.5'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIconMediumViewBox>
  )
}

export { FileGiveHandIcon }
