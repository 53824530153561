import React from 'react'

import { clsx } from '@guiker/clsx'

import { makeStyles } from '../../../styles'

const useStyles = makeStyles(
  (theme) => ({
    shiftedContent: {
      marginRight: theme.dimensions.contentNavigation.width.desktop,
      flexGrow: 1,
      transition: theme.transitions.create('margin-right', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    unshiftedContent: {
      transition: theme.transitions.create('margin-right', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  }),
  { name: 'ShiftedDrawerContent' },
)

type ShiftedDrawerContentProps = React.PropsWithChildren & {
  isDrawerOpen: boolean
}

export const ShiftedDrawerContent: React.FC<ShiftedDrawerContentProps> = ({ children, isDrawerOpen }) => {
  const classes = useStyles()

  return (
    <div
      className={clsx({
        [classes.shiftedContent]: isDrawerOpen,
        [classes.unshiftedContent]: !isDrawerOpen,
      })}
    >
      {children}
    </div>
  )
}
