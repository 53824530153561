import TagManager from 'react-gtm-module'

import { useConfig } from '@guiker/config-context'

import { DataLayer } from './data-layer'

type GoogleTagManager = {
  debug: boolean
  enabled: boolean
  dataLayer: DataLayer | undefined
}

export const useGoogleTagManager = (): GoogleTagManager => {
  const {
    analytics: { debug, enabled, gtmId },
  } = useConfig()

  if (!enabled) {
    return { debug, enabled, dataLayer: undefined }
  }

  TagManager.initialize({ gtmId })
  const dataLayer: DataLayer = (window as any).dataLayer

  return { debug, enabled, dataLayer }
}
