import * as React from 'react'

import { useMediaQuery } from '../../../hooks'
import { theme } from '../../../styles'
import { Drawer, DrawerProps } from '../Drawer'
import { Popover, PopoverProps } from '../Popover'

export type ResponsiveMenuProps = React.PropsWithChildren &
  PopoverProps &
  React.PropsWithChildren & {
    forceDrawer?: boolean
    drawerProps: DrawerProps
  }

const ResponsiveMenu: React.FC<ResponsiveMenuProps> = ({ children, forceDrawer, drawerProps, ...props }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))

  if (isMobile || forceDrawer) {
    const { anchorEl, anchorOrigin, transformOrigin, transitionDuration, ...otherDrawerProps } = props

    return (
      <Drawer {...drawerProps} {...otherDrawerProps}>
        {children}
      </Drawer>
    )
  } else {
    const { ...popoverProps } = props

    return <Popover {...popoverProps}>{children}</Popover>
  }
}

export { ResponsiveMenu }
