import React from 'react'

import { clsx } from '@guiker/clsx'
import { isAtMostTablette, padding, toPx } from '@guiker/components-core'
import { ChevronDownIcon, CloseIcon } from '@guiker/icons'
import { isString } from '@guiker/lodash'

import { useMediaQuery } from '../../../hooks'
import { makeStyles, theme } from '../../../styles'
import { flattenChildren } from '../../../utils'
import { Divider, H4, P } from '../..'
import { BaseModal, BaseModalProps } from '../BaseModal'

export type ModalProps = React.PropsWithChildren &
  Omit<BaseModalProps, 'className' | 'title'> & {
    actions?: React.ReactNode
    illustration?: React.ReactNode
    subtitle?: string | React.ReactNode
    title?: string | React.ReactNode
    showCompact?: boolean
  }

const useStyles = makeStyles(
  {
    actions: {
      display: 'flex',
      flexDirection: 'row',
      gap: toPx(theme.spacing(2)),
      justifyContent: 'flex-end',
      padding: theme.spacing(3),
      paddingTop: theme.spacing(1),
      [isAtMostTablette]: {
        flexDirection: 'column',
      },
    },
    bottomPadding: {
      paddingBottom: theme.spacing(2),
    },
    closeButton: {
      marginTop: theme.spacing(1),
    },
    content: {
      overflow: 'scroll',
      padding: padding(2, 4),
      [isAtMostTablette]: {
        padding: theme.spacing(3),
      },
    },
    contentCompact: {
      paddingTop: theme.spacing(1),
    },
    header: {
      paddingBottom: theme.spacing(2),
      padding: theme.spacing(4),
      [isAtMostTablette]: {
        padding: theme.spacing(3),
      },
    },
    headerCompact: {
      padding: padding(1, 3),
      textAlign: 'center',
    },
    headerFirstLine: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      gap: theme.spacing(2),
    },
    illustration: {
      maxHeight: 200,
    },
  },
  { name: 'Modal' },
)

const Modal: React.FC<ModalProps> = ({
  showCompact = false,
  actions,
  children,
  illustration,
  onClose,
  subtitle,
  title,
  ...props
}) => {
  const classes = useStyles()
  const isAtMostTablette = useMediaQuery(theme.breakpoints.down('sm'))
  const toCompact = showCompact && onClose

  return (
    <BaseModal onClose={onClose} {...props}>
      {!isAtMostTablette && illustration && <div className={classes.illustration}> {illustration}</div>}
      {title && (
        <>
          <div className={classes.header}>
            <div className={clsx(classes.headerFirstLine, subtitle && classes.bottomPadding)}>
              {isString(title) ? <H4 mb={0}>{title}</H4> : title}
              {onClose && <CloseIcon className={classes.closeButton} onClick={onClose} />}
            </div>
            {subtitle && isString(subtitle) ? <P mb={0}>{subtitle}</P> : subtitle}
          </div>
          {isAtMostTablette && <Divider m={0} mb={1} />}
        </>
      )}
      {toCompact && (
        <div className={classes.headerCompact}>
          <ChevronDownIcon className={classes.closeButton} onClick={onClose} />
        </div>
      )}
      {isAtMostTablette && illustration && <div className={classes.illustration}> {illustration}</div>}
      <div className={clsx(classes.content, showCompact && classes.contentCompact)}>{children}</div>
      {actions && <div className={classes.actions}>{flattenChildren(actions)}</div>}
    </BaseModal>
  )
}

export { Modal }
