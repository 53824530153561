import React from 'react'

import { SvgIconMediumViewBox, SvgIconProps } from '../../SvgIcon'

export const InvestmentPerformanceIcon: React.FC<SvgIconProps> = ({ width = 24, height = 24, ...props }) => {
  return (
    <SvgIconMediumViewBox width={width} height={height} viewBox='1 1 24 26' {...props}>
      <path
        d='M11.7998 8.75L19.7198 1.25'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.4004 1.25H19.7204V5.75'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M23.3203 4.25V23.75' stroke='black' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M19.7197 9.5V23.75' stroke='black' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M16.1201 14.75V23.75' stroke='black' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M23.3198 23.75H11.7998' stroke='black' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.7998 23.75H6.7598L6.7598 16C6.7598 15.7239 6.97471 15.5 7.2398 15.5H11.3198C11.5849 15.5 11.7998 15.7239 11.7998 16L11.7998 23.75Z'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.75973 23.75H1.71973V11.75C1.71973 11.3358 2.04208 11 2.43973 11H8.19973C8.59737 11 8.91973 11.3358 8.91973 11.75V15.5'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M3.87973 15.5H1.71973' stroke='black' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M3.87973 18.5H1.71973' stroke='black' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    </SvgIconMediumViewBox>
  )
}
