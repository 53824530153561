import { Event, EventType } from '@guiker/event'

export type EventEntry = {
  status: EventType
  emittedAt: string
}

export const extractEvent = (event: Event): EventEntry => {
  return {
    status: event.type,
    emittedAt: event.emittedAt,
  }
}
