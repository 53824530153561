import React from 'react'

import { PSmaller } from '../Typography'

export const Source: React.FC<{ content: string }> = ({ content }) => {
  return (
    <PSmaller mb={0} color={60}>
      {content}
    </PSmaller>
  )
}
