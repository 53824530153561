import React from 'react'

import { SvgIconMediumViewBox, SvgIconProps } from '../../../SvgIcon'

type EyeIconProps = SvgIconProps

const EyeIcon: React.FC<EyeIconProps> = (props) => {
  return (
    <SvgIconMediumViewBox {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 13.5C12.8284 13.5 13.5 12.8284 13.5 12C13.5 11.1716 12.8284 10.5 12 10.5C11.1716 10.5 10.5 11.1716 10.5 12C10.5 12.8284 11.1716 13.5 12 13.5Z'
        stroke='currentColor'
        strokeWidth='1.5'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.999 4.5001C7.96804 4.4251 3.79904 7.5571 1.17804 10.7601C0.607321 11.4857 0.607321 12.5075 1.17804 13.2331C3.74104 16.3721 7.89904 19.5751 11.999 19.5001C16.099 19.5771 20.256 16.3741 22.822 13.2361C23.3928 12.5105 23.3928 11.4887 22.822 10.7631C20.199 7.5581 16.03 4.4261 11.999 4.5001Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 17.25C14.8995 17.25 17.25 14.8995 17.25 12C17.25 9.10051 14.8995 6.75 12 6.75C9.10051 6.75 6.75 9.10051 6.75 12C6.75 14.8995 9.10051 17.25 12 17.25Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIconMediumViewBox>
  )
}

export { EyeIcon }
