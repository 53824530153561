import * as React from 'react'

import { clsx } from '@guiker/clsx'
import { fade, makeStyles, paddingPx, useTheme } from '@guiker/components-core'

import { Button, ButtonProps } from '../Button'

export type SecondaryButtonProps = ButtonProps

const useStyles = makeStyles(
  (theme) => ({
    root: ({ isIcon, isDarkMode }: { isIcon: boolean; isDarkMode: boolean }) => ({
      padding: isIcon ? theme.spacing(2.5) : paddingPx(theme.spacing(1.5) - 1, theme.spacing(2.5) - 1),
      background: isDarkMode ? theme.palette.grey[90] : theme.palette.getColor('secondary'),
      color: theme.palette.getColor('primary'),
      border: fade(theme.palette.getColor('primary'), 0.2),
      borderStyle: isDarkMode ? 'none' : 'solid',
      borderWidth: 1,
      '&:hover': {
        background: isDarkMode ? theme.palette.grey[80] : theme.palette.getColor('secondary', 'hover'),
      },
      '&:active': {
        background: isDarkMode ? theme.palette.grey[75] : theme.palette.getColor('secondary', 'active'),
      },
      '&:disabled': {
        backgroundColor: theme.palette.getColor('secondary'),
        color: fade(theme.palette.getColor('primary'), 0.5),
        opacity: 0.5,
      },
    }),
    sizeSmall: {
      padding: ({ isIcon }: { isIcon: boolean }) =>
        isIcon ? theme.spacing(1.5) : paddingPx(theme.spacing(1) - 1, theme.spacing(1.5) - 1),
    },
    sizeLarge: {
      padding: ({ isIcon }: { isIcon: boolean }) =>
        isIcon ? theme.spacing(4) : paddingPx(theme.spacing(2.5) - 1, theme.spacing(4) - 1),
    },
  }),
  { name: 'SecondaryButton' },
)

const SecondaryButton: React.FC<SecondaryButtonProps> = ({ className, ...otherProps }) => {
  const { isDarkMode } = useTheme()
  const isIcon = !otherProps.children && (otherProps.startIcon || otherProps.endIcon)
  const classes = useStyles({ isIcon, isDarkMode })

  return (
    <Button
      className={clsx(classes.root, className)}
      classes={{ sizeSmall: classes.sizeSmall, sizeLarge: classes.sizeLarge }}
      {...otherProps}
    />
  )
}

export { SecondaryButton }
