import React, { useCallback } from 'react'

import { useResizeObserver } from './use-resize-observer'

export const useIsOverflowing = <T extends Element>(ref: React.RefObject<T>) => {
  const [isOverflowing, setIsOverflowing] = React.useState(undefined)

  const detectOverflowing = useCallback(() => {
    if (!ref.current) return

    const { current } = ref

    if (current) {
      const hasOverflow = current.scrollHeight > current.clientHeight

      setIsOverflowing(hasOverflow)
    }
  }, [ref])

  useResizeObserver(ref, detectOverflowing)

  React.useEffect(detectOverflowing, [ref])

  return isOverflowing
}
