import React from 'react'

import { clsx } from '@guiker/clsx'
import { ThemeContextProvider } from '@guiker/components-core'
import { useTranslation } from '@guiker/i18n'
import { ArrowLeftIcon, CloseIcon } from '@guiker/icons'
import { isString } from '@guiker/lodash'

import { makeStyles, useMediaQuery } from '../../../hooks'
import { isMobile, theme } from '../../../styles'
import { Box, Flex, H4, IconButton, P, Progress, TextButton, TypographyProps } from '../..'
import { BaseAppBar } from '../../Layout/AppBar'

const useStyles = makeStyles(
  (theme) => ({
    buttons: {
      paddingRight: 0,
      display: 'flex',
      alignItems: 'center',
      minWidth: 24,
      color: theme.palette.text.primary.main,
      '&:hover': {
        color: theme.palette.grey[50],
        cursor: 'pointer',
      },
      '&:active': {
        color: theme.palette.text.primary.main,
      },
    },
    navContentContainer: {
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      [isMobile]: {
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1),
      },
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    title: {
      [isMobile]: {
        ...theme.typography.variants.h5,
      },
    },
    subtitle: {
      [isMobile]: {
        ...theme.typography.variants.bodySmall,
      },
    },
    progressBarContainer: {
      width: '100%',
      position: 'fixed',
      zIndex: 901,
      top: theme.dimensions.appBar.height.desktop,
      [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
        top: theme.dimensions.appBar.height.mobile,
      },
    },
  }),
  { name: 'TakeOverHeader' },
)

type CloseButtonProps = {
  onClose?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  title?: string | React.ReactNode
}
export type TakeOverHeaderProps = CloseButtonProps & {
  onBack?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  titleProps?: TypographyProps
  subtitle?: string | React.ReactNode
  subtitleProps?: TypographyProps
  hasBorder?: boolean
  hasProgressBar?: boolean
  progressBarProps?: {
    percentage?: number
  }
}

const CloseButton: React.FC<CloseButtonProps> = ({ onClose, title }) => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))

  if (isMobile) {
    return (
      <IconButton onClick={onClose}>
        <CloseIcon size='small' />
      </IconButton>
    )
  }

  return (
    <TextButton startIcon={<CloseIcon size='smaller' />} size='small' onClick={onClose}>
      {!(isMobile && title) && (t('common:actions.close') as string)}
    </TextButton>
  )
}

export const TakeOverHeader: React.FC<TakeOverHeaderProps> = ({
  onBack,
  onClose,
  subtitle,
  subtitleProps,
  title,
  titleProps,
  hasProgressBar,
  progressBarProps,
}) => {
  const classes = useStyles()

  return (
    <ThemeContextProvider isDarkMode={true}>
      <BaseAppBar asTakeover>
        <Flex fullWidth alignItems='center'>
          <div className={classes.navContentContainer}>
            <Box className={classes.buttons} onClick={onBack}>
              {onBack && <ArrowLeftIcon color='white' />}
            </Box>
            <Flex flexDirection='column' alignItems='center'>
              {title && isString(title) ? (
                <H4 mb={0} color='white' {...titleProps} className={clsx(titleProps?.className, classes.title)}>
                  {title}
                </H4>
              ) : (
                title
              )}
              {subtitle && isString(subtitle) ? (
                <P mb={0} color='white' {...subtitleProps} className={clsx(subtitleProps?.className, classes.subtitle)}>
                  {subtitle}
                </P>
              ) : (
                subtitle
              )}
            </Flex>
            <div>{onClose && <CloseButton title={title} onClose={onClose} />}</div>
          </div>
        </Flex>
      </BaseAppBar>
      {hasProgressBar && progressBarProps?.percentage != null && (
        <Progress
          withBackgroundColor={false}
          value={progressBarProps?.percentage}
          className={classes.progressBarContainer}
          {...progressBarProps}
        />
      )}
    </ThemeContextProvider>
  )
}
