import React from 'react'

import { SvgIconMediumViewBox, SvgIconProps } from '../../SvgIcon'

export const LogOutIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIconMediumViewBox {...props}>
      <path
        d='M0.875 12.0039H16.625'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.875 15.7539L16.625 12.0039L12.875 8.25391'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.42969 16.6C4.46173 18.7202 6.1774 20.4312 8.30033 21.4576C10.4233 22.4839 12.8299 22.7658 15.1325 22.2579C17.4352 21.7499 19.4999 20.4816 20.9939 18.6573C22.488 16.833 23.3245 14.5589 23.3687 12.2013C23.413 9.84373 22.6623 7.53986 21.2377 5.66082C19.8131 3.78178 17.7975 2.43696 15.5155 1.84298C13.2335 1.249 10.818 1.44044 8.65809 2.38646C6.49816 3.33247 4.71952 4.978 3.60869 7.05798'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIconMediumViewBox>
  )
}
