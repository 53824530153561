import { useMemo } from 'react'

import { isFunction } from '@guiker/lodash'
import {
  MutationFunction,
  QueryFunctionContext,
  QueryKey,
  useMutation as _useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery as _useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query'

type Context<TData> = { previousQueryData: TData; newQueryData: TData }

export type UseQueryMutation<QueryData, ApiClient = unknown, TQueryKey extends QueryKey = QueryKey> = ({
  queryKey,
  apiClient,
}: {
  queryKey: TQueryKey
  apiClient?: ApiClient
}) => {
  useQuery: <TError = unknown>(
    func: (apiClient: ApiClient, context?: QueryFunctionContext<TQueryKey>) => QueryData | Promise<QueryData>,
    options?: Omit<UseQueryOptions<QueryData, TError, QueryData, TQueryKey>, 'queryKey' | 'queryFn'>,
  ) => UseQueryResult<QueryData, TError>
  useMutation: <MutationData, TError = unknown, TVariables = void>(
    func: (variables: TVariables, apiClient?: ApiClient) => Promise<MutationData>,
    {
      onOptimisticUpdate,
      queryKeys = [],
      ...options
    }?: Omit<
      UseMutationOptions<MutationData, TError, TVariables, Context<QueryData>>,
      'mutationFn' | 'onMutate' | 'onError' | 'onSettled'
    > & {
      queryKeys?: QueryKey[]
      onOptimisticUpdate?: (vars: TVariables) => QueryData
      onMutate?: (vars: TVariables) => Promise<Context<QueryData>>
      onError?: (error: TError, variables: TVariables, context: Context<QueryData>) => unknown
      onSettled?: (data: MutationData, error: TError, variables: TVariables, context: Context<QueryData>) => unknown
    },
  ) => UseMutationResult<MutationData, TError, TVariables, unknown>
  setQueryData: (data: QueryData | ((curr: QueryData) => QueryData)) => QueryData
}

export const useQueryMutation = <QueryData, ApiClient = unknown, TQueryKey extends QueryKey = QueryKey>({
  queryKey,
  apiClient,
}: {
  queryKey: QueryKey
  apiClient?: ApiClient
}) => {
  const queryClient = useQueryClient()
  const useQuery = (<TError = unknown>(
    func: (apiClient: ApiClient, context: QueryFunctionContext<TQueryKey>) => QueryData | Promise<QueryData>,
    options: Omit<UseQueryOptions<QueryData, TError, QueryData, TQueryKey>, 'queryKey' | 'queryFn'> = {},
  ) => {
    return _useQuery(queryKey, (context: QueryFunctionContext<TQueryKey>) => func(apiClient, context), options)
  }) as ReturnType<UseQueryMutation<QueryData, ApiClient, TQueryKey>>['useQuery']

  const useMutation = (<MutationData, TError = unknown, TVariables = void>(
    func: MutationFunction<MutationData, TVariables>,
    {
      onOptimisticUpdate,
      queryKeys = [],
      ...options
    }: Omit<
      UseMutationOptions<MutationData, TError, TVariables, Context<QueryData>>,
      'mutationFn' | 'onMutate' | 'onError' | 'onSettled'
    > & {
      queryKeys?: QueryKey[]
      onOptimisticUpdate?: (vars: TVariables) => QueryData
      onMutate?: (vars: TVariables) => Promise<Context<QueryData>>
      onError?: (error: TError, variables: TVariables, context: Context<QueryData>) => unknown
      onSettled?: (data: MutationData, error: TError, variables: TVariables, context: Context<QueryData>) => unknown
    } = {},
  ) =>
    _useMutation(func, {
      ...options,
      onMutate: async (vars: TVariables) => {
        await queryClient.cancelQueries({ queryKey })
        const previousQueryData = queryClient.getQueryData(queryKey)
        const newQueryData = onOptimisticUpdate && onOptimisticUpdate(vars)
        queryClient.setQueryData(queryKey, newQueryData)

        for (const key of queryKeys) {
          await queryClient.cancelQueries({ queryKey: key })
        }

        return { previousQueryData, newQueryData } as Context<QueryData>
      },
      onError: (err, newData, context) => {
        queryClient.setQueryData(queryKey, context?.previousQueryData)
        options.onError?.(err, newData, context)
      },
      onSettled: (...args) => {
        queryClient.invalidateQueries({ queryKey })
        options?.onSettled?.(...args)
      },
    })) as ReturnType<UseQueryMutation<QueryData, ApiClient, TQueryKey>>['useMutation']

  return useMemo(
    () => ({
      useQuery,
      useMutation,
      setQueryData: (data: QueryData | ((curr: QueryData) => QueryData)) => {
        if (isFunction(data)) {
          const curr: QueryData = queryClient.getQueryData(queryKey)
          const newData = data(curr)
          queryClient.setQueryData(queryKey, newData)
          return newData
        } else {
          queryClient.setQueryData(queryKey, data)
          return data
        }
      },
    }),
    queryKey,
  ) as ReturnType<UseQueryMutation<QueryData, ApiClient, TQueryKey>>
}
