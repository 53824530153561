import React from 'react'

import { SvgIconProps, SvgIconSmallViewBox } from '../../../SvgIcon'

type ChevronUpIconProps = SvgIconProps
const ChevronUpIcon: React.FC<ChevronUpIconProps> = (props) => {
  return (
    <SvgIconSmallViewBox {...props}>
      <path
        d='M1 11.5L8 4.5L15 11.5'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIconSmallViewBox>
  )
}

export { ChevronUpIcon }
