import React from 'react'

import { Area, AreaChart as BaseAreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'

import { theme } from '@guiker/react-framework'

export type AreaChartKpi<K extends string> = {
  [key in K]: number
} & {
  label: string
}

export type AreaChartProps<K extends string = any> = {
  dataset: AreaChartKpi<K>[]
  dataKey: K
  width?: number
  height?: number
}

export const AreaChart = <K extends string>({ dataset, dataKey, width = 200, height = 200 }: AreaChartProps<K>) => {
  const color = theme.palette.graphs.graph0.main

  return (
    <ResponsiveContainer width='100%' height='100%'>
      <BaseAreaChart width={width} height={height} data={dataset} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='label' />
        <YAxis />
        <Tooltip />
        <Area type='monotone' dataKey={dataKey} stroke={color} fill={color} />
      </BaseAreaChart>
    </ResponsiveContainer>
  )
}
