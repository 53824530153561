import React from 'react'

import { SvgIconLargerViewBox, SvgIconProps } from '../../SvgIcon'

type BusinessContractIconProps = SvgIconProps

const BusinessContractIcon: React.FC<BusinessContractIconProps> = (props) => {
  return (
    <SvgIconLargerViewBox {...props}>
      <path
        d='M2 5.91196L6 10.9573V22.8693C5.89333 27.3706 11.112 31.4026 15.9973 35.5413'
        stroke={'currentColor'}
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.9493 19.6213L18.6666 24.4533C19.4764 25.2846 20.6682 25.6206 21.793 25.335C22.9177 25.0493 23.8047 24.1852 24.1196 23.0683C24.4346 21.9514 24.1298 20.7512 23.32 19.92L15.984 12.4347V8.53333C15.5865 6.1956 14.6976 3.9688 13.376 2'
        stroke={'currentColor'}
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16 21.7014V54C16 56.2092 17.7909 58 20 58H40'
        stroke={'currentColor'}
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M62.0004 58.0854L58.0004 53.04V41.1307C58.0937 37.24 54.211 33.7014 50.0004 30.144V44.3254L45.3337 39.5467C44.0819 38.2617 42.0254 38.2349 40.7404 39.4867C39.4554 40.7386 39.4285 42.7951 40.6804 44.08L48.027 51.568V55.4667C48.4265 57.8006 49.3153 60.0235 50.635 61.9894'
        stroke={'currentColor'}
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M34.608 48H24' stroke={'currentColor'} strokeWidth='3' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M28 38H24' stroke={'currentColor'} strokeWidth='3' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M54 14C54 20.6274 48.6274 26 42 26C35.3726 26 30 20.6274 30 14C30 7.37258 35.3726 2 42 2'
        stroke={'currentColor'}
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M54.0001 4.99738L43.0668 15.9307C42.7845 16.2147 42.4005 16.3744 42.0001 16.3744C41.5996 16.3744 41.2157 16.2147 40.9334 15.9307L37.4961 12.4907'
        stroke={'currentColor'}
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIconLargerViewBox>
  )
}

export { BusinessContractIcon }
