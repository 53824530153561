import React, { PropsWithChildren } from 'react'

import { math } from '@guiker/lodash'

import { makeStyles, theme } from '../../../styles'
import { ColumnConfig } from './column-config'
import { Sort } from './sort'
import { TH } from './TH'

type TableHeaderProps<T> = {
  showLabel?: boolean
  columns: ColumnConfig<T>[]
  sort?: Sort
}

const useStyles = makeStyles(
  {
    headerRow: {
      borderStyle: ({ showLabel }: { showLabel: boolean }) => (showLabel ? 'solid' : 'none'),
      borderWidth: 0,
      borderTopWidth: 1,
      borderBottomWidth: 1,
      borderColor: theme.palette.grey[10],
    },
  },
  { name: 'TableHeader' },
)

export const BaseTableHeader: React.FC<PropsWithChildren & { showLabel?: boolean }> = ({ children, showLabel }) => {
  const classes = useStyles({ showLabel })

  return (
    <thead>
      <tr className={classes.headerRow}>{children}</tr>
    </thead>
  )
}

const TableHeader = <T extends object>({ columns, sort, showLabel = true }: TableHeaderProps<T>) => {
  const fullWeight = columns.reduce((acc, value) => acc + (value.size || 1), 0)

  return (
    <BaseTableHeader showLabel={showLabel}>
      {columns.map((column, index) => {
        const percent = math.decimal.round((column.size || 1) / fullWeight, 4) * 100

        return (
          <TH
            showLabel={showLabel}
            key={`header-${index}`}
            column={column}
            sort={sort}
            width={`${percent}%`}
            textAlign={column.headerOptions?.textAlign}
          />
        )
      })}
    </BaseTableHeader>
  )
}

export { TableHeader }
