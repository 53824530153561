import React from 'react'

import { SvgIconProps, SvgIconSmallViewBox } from '../../../SvgIcon'

const EditIcon: React.FC<Omit<SvgIconProps, 'small'>> = (props) => {
  return (
    <SvgIconSmallViewBox {...props}>
      <g clipPath='url(#clip0)'>
        <path
          d='M15.4935 2.54735L13.4478 0.503924C13.123 0.181008 12.6836 -0.000244141 12.2255 -0.000244141C11.7675 -0.000244141 11.3281 0.181008 11.0033 0.503924L1.60554 9.91193C1.53596 9.98085 1.48554 10.0667 1.45926 10.1611L0.0215445 15.2731C-0.00247191 15.358 -0.00647188 15.4474 0.00985842 15.5342C0.0261887 15.621 0.062405 15.7028 0.115665 15.7732C0.168926 15.8436 0.237781 15.9007 0.316828 15.9401C0.395875 15.9794 0.482963 15.9999 0.571259 15.9999C0.623454 16 0.675398 15.9927 0.725544 15.9782L5.84554 14.5428C5.93984 14.5156 6.02587 14.4653 6.09583 14.3965L15.4935 4.98964C15.6541 4.82936 15.7814 4.63901 15.8683 4.42947C15.9552 4.21994 15.9999 3.99533 15.9999 3.7685C15.9999 3.54166 15.9552 3.31705 15.8683 3.10752C15.7814 2.89798 15.6541 2.70763 15.4935 2.54735Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0'>
          <rect width='16' height='16' fill='currentColor' />
        </clipPath>
      </defs>
    </SvgIconSmallViewBox>
  )
}

export { EditIcon }
