import React from 'react'

import { AnyColor, theme } from '@guiker/components-core'

type AgentIconProps = {
  color?: AnyColor
}

const AgentIcon: React.FC<AgentIconProps> = ({ color: colorKey }) => {
  const color = theme.palette.getColor(colorKey)

  return (
    <svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M20.9007 23.5C19.9555 20.8086 17.4162 19.0054 14.5637 19H14.5577C11.7054 19.0056 9.1665 20.8088 8.22168 23.5'
        stroke={color || 'currentColor'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.0039 11.281C12.9849 13.3336 16.0299 13.9453 18.6499 12.817'
        stroke={color || 'currentColor'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.5581 17.5C16.8363 17.5 18.6831 15.6532 18.6831 13.375C18.6831 11.0968 16.8363 9.25 14.5581 9.25C12.2799 9.25 10.4331 11.0968 10.4331 13.375C10.4331 15.6532 12.2799 17.5 14.5581 17.5Z'
        stroke={color || 'currentColor'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.2251 19C6.88195 19 8.2251 17.6569 8.2251 16C8.2251 14.3431 6.88195 13 5.2251 13C3.56824 13 2.2251 14.3431 2.2251 16C2.2251 17.6569 3.56824 19 5.2251 19Z'
        stroke={color || 'currentColor'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.114 20.588C3.92157 20.1469 1.73944 21.3894 1 23.5'
        stroke={color || 'currentColor'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.561 19V23.5'
        stroke={color || 'currentColor'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M23.5 23.5V1H10.75L13.75 4L10.75 7H23.5'
        stroke={color || 'currentColor'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export { AgentIcon }
