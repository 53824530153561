const navArrow = {
  fontSize: 0,
  lineHeight: 0,
  position: 'absolute',
  top: '50%',
  display: 'block',
  border: 'none',
  outline: 'none',
  background: 'transparent',
}

export const slickSliderBaseStyles = {
  '& .slick-slider': {
    position: 'relative',
    display: 'block',
    boxSizing: 'border-box',
    userSelect: 'none',
    '-webkit-touch-callout': 'none',
    '-khtml-user-select': 'none',
    touchAction: 'pan-y',
    '-webkit-tap-highlight-color': 'transparent',
    '& .slick-list': {
      transform: 'translate3d(0, 0, 0)',
    },
  },
  '& .slick-track': {
    transform: 'translate3d(0, 0, 0)',
    position: 'relative',
    top: 0,
    left: 0,
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    '&:before': {
      display: 'table',
      content: '',
    },
    '&:after': {
      display: 'table',
      content: '',
      clear: 'both',
    },
  },
  '& .slick-list': {
    position: 'relative',
    display: 'block',
    overflow: 'hidden',
    margin: 0,
    padding: 0,
    '&:focus': {
      outline: 'none',
    },
    '&.dragging': {
      cursor: 'hand',
    },
  },
  '& .slick-slide': {
    display: 'none',
    float: 'left',
    height: '100%',
    minHeight: '1px',
    '& img': {
      display: 'block',
    },
  },
  '& .slick-initialized .slick-slide': {
    display: 'inline-block',
  },
  '& .slick-loading .slick-slide': {
    visibility: 'hidden',
  },
  '& .slick-prev': navArrow,
  '& .slick-next': navArrow,
}
