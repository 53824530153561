import React from 'react'

import { FieldPath } from '@guiker/react-hook-form'

import { RHFBlockEditor, RHFBlockEditorProps } from './RHFBlockEditor'

export type BlockEditorProps<T extends object = any, P extends FieldPath<T> = any> = RHFBlockEditorProps<T, P>

export const BlockEditor = <T extends object = any, P extends FieldPath<T> = any>({
  defaultValue,
  ...props
}: BlockEditorProps<T, P>) => {
  return <RHFBlockEditor defaultValue={defaultValue} {...props} />
}
