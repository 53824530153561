import React from 'react'

import { SvgIconMediumViewBox, SvgIconProps } from '../../../SvgIcon'

type QuestionMarkIconProps = SvgIconProps

const QuestionMarkIcon: React.FC<QuestionMarkIconProps> = (props) => {
  return (
    <SvgIconMediumViewBox {...props}>
      <path
        d='M9 8.99962C9.00029 7.47405 10.1455 6.19163 11.6613 6.01938C13.1771 5.84713 14.5808 6.8399 14.9234 8.32651C15.266 9.81312 14.4383 11.3201 13 11.8286C12.4004 12.0406 11.9997 12.6077 12 13.2436V14.2496'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12 17.25C11.7929 17.25 11.625 17.4179 11.625 17.625C11.625 17.8321 11.7929 18 12 18C12.2071 18 12.375 17.8321 12.375 17.625C12.375 17.4179 12.2071 17.25 12 17.25V17.25'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 23.25C18.2132 23.25 23.25 18.2132 23.25 12C23.25 5.7868 18.2132 0.75 12 0.75C5.7868 0.75 0.75 5.7868 0.75 12C0.75 18.2132 5.7868 23.25 12 23.25Z'
        stroke='currentColor'
        strokeWidth='1.5'
      />
    </SvgIconMediumViewBox>
  )
}

export { QuestionMarkIcon }
