import React from 'react'

import { SvgIconMediumViewBox, SvgIconProps } from '../../../SvgIcon'

type DenIconProp = SvgIconProps

const DenIcon: React.FC<DenIconProp> = (props) => {
  return (
    <SvgIconMediumViewBox {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.75 2.25C3.75 1.42157 4.42157 0.75 5.25 0.75H18.75C19.5784 0.75 20.25 1.42157 20.25 2.25V21.75C20.25 22.5784 19.5784 23.25 18.75 23.25H5.25C4.42157 23.25 3.75 22.5784 3.75 21.75V2.25Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.25 5.25C8.25 4.42157 8.92157 3.75 9.75 3.75H14.25C15.0784 3.75 15.75 4.42157 15.75 5.25V6.75C15.75 7.57843 15.0784 8.25 14.25 8.25H9.75C8.92157 8.25 8.25 7.57843 8.25 6.75V5.25Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M3.75 8.25H20.25' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M3.75 12.75H20.25'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIconMediumViewBox>
  )
}

export { DenIcon }
