import React from 'react'

import { SvgIconProps, SvgIconSmallViewBox } from '../../../SvgIcon'

type UploadIconProps = SvgIconProps

const UploadIcon: React.FC<UploadIconProps> = (props) => {
  return (
    <SvgIconSmallViewBox {...props}>
      <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M4.5363 4.31996C4.61581 4.39958 4.71023 4.46274 4.81416 4.50584C4.91809 4.54893 5.0295 4.57111 5.14202 4.57111C5.25453 4.57111 5.36594 4.54893 5.46988 4.50584C5.57381 4.46274 5.66823 4.39958 5.74773 4.31996L7.14202 2.92682V9.42853C7.14202 9.65586 7.23232 9.87388 7.39307 10.0346C7.55381 10.1954 7.77183 10.2857 7.99916 10.2857C8.22649 10.2857 8.44451 10.1954 8.60525 10.0346C8.766 9.87388 8.8563 9.65586 8.8563 9.42853V2.92682L10.2506 4.31996C10.4131 4.47137 10.628 4.5538 10.85 4.54988C11.0721 4.54596 11.284 4.456 11.441 4.29896C11.5981 4.14192 11.688 3.93005 11.6919 3.70799C11.6959 3.48593 11.6134 3.27102 11.462 3.10853L8.60488 0.251392C8.52537 0.171776 8.43095 0.108614 8.32702 0.0655199C8.22309 0.0224256 8.11168 0.000244141 7.99916 0.000244141C7.88665 0.000244141 7.77524 0.0224256 7.6713 0.0655199C7.56737 0.108614 7.47295 0.171776 7.39345 0.251392L4.5363 3.10853C4.45669 3.18804 4.39353 3.28246 4.35043 3.38639C4.30734 3.49032 4.28516 3.60173 4.28516 3.71425C4.28516 3.82676 4.30734 3.93817 4.35043 4.04211C4.39353 4.14604 4.45669 4.24046 4.5363 4.31996Z'
          fill='currentColor'
        />
        <path
          d='M14.8571 10.8572C14.554 10.8572 14.2633 10.9776 14.049 11.1919C13.8347 11.4062 13.7143 11.6969 13.7143 12V13.4286C13.7143 13.5044 13.6842 13.5771 13.6306 13.6306C13.577 13.6842 13.5043 13.7143 13.4286 13.7143H2.57143C2.49565 13.7143 2.42298 13.6842 2.3694 13.6306C2.31582 13.5771 2.28571 13.5044 2.28571 13.4286V12C2.28571 11.6969 2.16531 11.4062 1.95098 11.1919C1.73665 10.9776 1.44596 10.8572 1.14286 10.8572C0.839753 10.8572 0.549062 10.9776 0.334735 11.1919C0.120408 11.4062 0 11.6969 0 12L0 13.7143C0 14.3205 0.240816 14.9019 0.66947 15.3306C1.09812 15.7592 1.67951 16 2.28571 16H13.7143C14.3205 16 14.9019 15.7592 15.3305 15.3306C15.7592 14.9019 16 14.3205 16 13.7143V12C16 11.6969 15.8796 11.4062 15.6653 11.1919C15.4509 10.9776 15.1602 10.8572 14.8571 10.8572Z'
          fill='currentColor'
        />
      </svg>
    </SvgIconSmallViewBox>
  )
}

export { UploadIcon }
