import React from 'react'

import { Grid, GridProps } from '../../Layout'
import { MuiRadioGroup, MuiRadioGroupProps } from '../'
import { Input, InputProps } from '../Input'
import { extractInputProps } from '../Input/extract-input-props'
import { Radio, RadioProps } from './Radio'

type DisplayDirection = 'row' | 'column'
type AdornmentPosition = 'start' | 'end'

type OptionsProps = RadioProps

type RadioGroupContentProps = {
  mb?: number
  adornmentPosition?: AdornmentPosition
  className?: string
  condensed?: boolean
  direction?: DisplayDirection
  gridContainerProps?: GridProps
  maxWidth?: string | number
  options: (OptionsProps & { gridItemProps?: GridProps })[]
}

type RadioGroupProps = MuiRadioGroupProps & InputProps & RadioGroupContentProps

const RadioGroup: React.FC<RadioGroupProps> = (props) => {
  const { inputProps, otherProps } = extractInputProps(props)

  const { defaultValue, disabled, name, readOnly } = inputProps

  const {
    adornmentPosition,
    condensed = false,
    direction = 'column',
    gridContainerProps,
    maxWidth,
    options,
    ...other
  } = otherProps

  return (
    <Input {...inputProps} mb={0}>
      <MuiRadioGroup aria-label={name} {...inputProps} {...other}>
        <Grid container spacing={0} direction={direction} {...gridContainerProps}>
          {options
            .filter((o) => !!o)
            .map(({ label, gridItemProps, value: radioPropsValue, ...radioProps }, index) => {
              const value = radioPropsValue
              if (condensed && value !== defaultValue) {
                return
              }

              return (
                <Grid item key={`radio-${index}`} {...gridItemProps}>
                  <Radio
                    maxWidth={maxWidth}
                    disabled={disabled || radioProps.disabled}
                    readOnly={readOnly || radioProps.readOnly}
                    condensed={condensed}
                    label={label as string}
                    name={name}
                    adornmentPosition={adornmentPosition}
                    value={value.toString()}
                    {...radioProps}
                  />
                </Grid>
              )
            })}
        </Grid>
      </MuiRadioGroup>
    </Input>
  )
}

export { RadioGroup, RadioGroupProps }
