import React, { ReactNode, useEffect } from 'react'

import { useConfig } from '@guiker/config-context'
import { FormProvider, useForm, useFormContext, UseFormReturn } from '@guiker/react-hook-form'

import { BeforeValidation, ContextConsumerNode } from '../../types'
import { useFormEvent } from '../../utils'
import { ApiFormContextProvider } from './ApiFormContext'
import { BaseForm, BaseFormProps } from './BaseForm'

export type ApiFormProps<TFormValues, TResult, TContext> = Omit<
  BaseFormProps<TFormValues, TResult, TContext>,
  'formOptions'
> & {
  beforeValidation?: BeforeValidation<TFormValues>
  formName: string
  skipIfIsNotDirty?: boolean
  formOptions?: BaseFormProps<TFormValues, TResult, TContext>['formOptions'] & { readOnly?: boolean }
  formMethods?: UseFormReturn<TFormValues>
  readOnly?: boolean
  children?: ReactNode | ContextConsumerNode<TResult>
}

export const ApiForm = <TResult, TContext, TFormValues extends Record<string, any> = Record<string, any>>({
  ...props
}: ApiFormProps<TFormValues, TResult, TContext>) => {
  const formMethods = useFormContext<TFormValues>()

  if (formMethods) {
    return <WrappedApiForm formMethods={formMethods} {...props} />
  } else {
    return <NotInitializedApiForm {...props} />
  }
}

const NotInitializedApiForm = <TResult, TContext, TFormValues extends Record<string, any> = Record<string, any>>({
  formOptions,
  ...props
}: ApiFormProps<TFormValues, TResult, TContext>) => {
  const { reValidateMode = 'onBlur', mode = 'onSubmit', readOnly, ...otherFormOptions } = formOptions || {}
  const formMethods = useForm<TFormValues>({ shouldUnregister: false, ...otherFormOptions, mode, reValidateMode })

  return <WrappedApiForm formMethods={formMethods} readOnly={readOnly} {...props} />
}

const WrappedApiForm = <TResult, TContext, TFormValues extends Record<string, any> = Record<string, any>>({
  beforeValidation,
  onSubmit,
  formMethods,
  apiOptions = {},
  readOnly = false,
  skipIfIsNotDirty = true,
  formName,
  ...props
}: ApiFormProps<TFormValues, TResult, TContext> & { readOnly?: boolean }) => {
  const { sendFormEvent } = useFormEvent(formName)
  const { logger } = useConfig()
  const {
    formState: { submitCount, errors },
  } = formMethods

  useEffect(() => {
    if (submitCount >= 1 && errors && Object.keys(errors).length > 0) {
      try {
        const formErrorDetails = JSON.stringify(errors)
        sendFormEvent({ event: 'formError', formName, formErrorDetails })
      } catch {
        logger.log(errors)
      }
    }
  }, [submitCount, errors, formName])

  useEffect(() => {
    sendFormEvent({ event: 'formStart', formName })
  }, [formName])

  return (
    <FormProvider {...formMethods} {...props}>
      <ApiFormContextProvider
        formName={formName}
        readOnly={readOnly}
        beforeValidation={beforeValidation}
        onSubmit={onSubmit}
        skipIfIsNotDirty={skipIfIsNotDirty}
        apiOptions={apiOptions}
      >
        <BaseForm apiOptions={apiOptions} onSubmit={onSubmit} {...props} />
      </ApiFormContextProvider>
    </FormProvider>
  )
}
