import React from 'react'

import { ColumnGridLayout, ColumnGridLayoutProps } from '../ColumnGridLayout'

type ThreeColumnsGridLayoutProps = Omit<ColumnGridLayoutProps, 'gridTemplateColumns' | 'gridColumn'>

const ThreeColumnsGridLayout: React.FC<ThreeColumnsGridLayoutProps> = ({ children, gap, sm, ...props }) => {
  return (
    <ColumnGridLayout
      gap={gap}
      gridColumn={{ start: 'auto', span: 1 }}
      gridTemplateColumns={3}
      sm={{ gridTemplateColumns: 1, gridColumn: { span: 3 }, ...sm }}
      {...props}
    >
      {children}
    </ColumnGridLayout>
  )
}

export { ThreeColumnsGridLayout }
