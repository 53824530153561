import { Pagination } from '../components'

export const toFetcher = <T>(data: T[]) => {
  return (paginate: Pagination) =>
    Promise.resolve({
      meta: {
        total: data.length,
        totalPages: Math.ceil(data.length / paginate.perPage),
        page: paginate.page,
        perPage: paginate.perPage,
        countByStatus: {} as any,
      },
      data: data.slice((paginate.page - 1) * paginate.perPage, paginate.page * paginate.perPage),
    })
}
