import * as React from 'react'

import { clsx } from '@guiker/clsx'
import { AnyColor, fade, makeStyles } from '@guiker/components-core'

import { BaseButton, BaseButtonProps } from '../BaseButton'

export type TextButtonProps = BaseButtonProps & { color?: AnyColor; underline?: boolean }

const useStyles = makeStyles(
  (theme) => ({
    root: ({ color: colorProps }: { color: AnyColor }) => {
      const color = theme.palette.getColor(colorProps)

      return {
        padding: 0,
        color: color,
        background: 'transparent',
        fontWeight: 400,
        fontSize: ({ size }: { size: TextButtonProps }) => (size === 'small' ? 12 : 16),
        lineHeight: ({ size }: { size: TextButtonProps }) => (size === 'small' ? 12 : 16),
        '&:hover': {
          background: 'transparent',
          textDecoration: 'none !important',
          '& + *': {
            borderColor: color,
          },
        },
        '&:active': {
          borderColor: color,
          background: 'transparent',
        },
        '&:disabled': {
          borderColor: color,
          background: 'transparent',
          color: color,
          opacity: 0.5,
        },
      }
    },
    container: ({ color: colorProps }: { color: AnyColor }) => ({
      maxWidth: 'fit-content',
      borderColor: fade(theme.palette.getColor(colorProps), 0.2),
    }),
    divider: {
      position: 'relative',
      top: ({ size }: { size: TextButtonProps }) => (size === 'small' ? 0 : 4),
      borderBottomStyle: 'solid',
      borderColor: 'inherit',
      borderWidth: 1,
    },
  }),
  { name: 'TextButton' },
)

const TextButton: React.FC<TextButtonProps> = (props) => {
  const { className, color = 'primary', size, children, underline = true, ...other } = props
  const classes = useStyles({ color, size })

  return (
    <div className={classes.container}>
      <BaseButton className={clsx(classes.root, className)} size={size} {...other}>
        {children}
      </BaseButton>
      {underline && <div className={classes.divider}></div>}
    </div>
  )
}

export { TextButton }
