import React from 'react'

import { theme } from '@guiker/components-core'
import { isString } from '@guiker/lodash'

import { ButtonProps, H4 } from '../../..'
import { useLayoutContext } from '../../..'
import { DesktopInquiryCard } from './DesktopInquiryCard'
import { MobileInquiryCard } from './MobileInquiryCard'

export type InquiryCardProps = {
  title?: string | React.ReactElement
  open: boolean
  onOpen: () => void
  onClose: () => void
  isLoading?: boolean
  drawerBleeding?: number
  buttonLabel?: string
  SubmitButton: React.FC<ButtonProps & React.PropsWithChildren>
  isSwipable?: boolean
} & React.PropsWithChildren

export const InquiryCard: React.FC<InquiryCardProps> = ({
  title,
  isSwipable,
  drawerBleeding = theme.spacing(8),
  ...props
}) => {
  const { isAtMostTablette } = useLayoutContext()
  const parsedTitle = title && isString(title) ? <H4>{title}</H4> : title

  return !isAtMostTablette ? (
    <DesktopInquiryCard title={parsedTitle} {...props} />
  ) : (
    <MobileInquiryCard isSwipable={isSwipable} drawerBleeding={drawerBleeding} title={parsedTitle} {...props} />
  )
}
