import React from 'react'

import { SvgIconMediumViewBox, SvgIconProps } from '../../../SvgIcon'

type EllipsisIconProps = SvgIconProps

const EllipsisIcon: React.FC<EllipsisIconProps> = (props) => {
  return (
    <SvgIconMediumViewBox {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.375 14.648C4.82475 14.648 6 13.4728 6 12.023C6 10.5733 4.82475 9.39801 3.375 9.39801C1.92525 9.39801 0.75 10.5733 0.75 12.023C0.75 13.4728 1.92525 14.648 3.375 14.648Z'
        fill='currentColor'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.625 14.648C22.0747 14.648 23.25 13.4728 23.25 12.023C23.25 10.5733 22.0747 9.39801 20.625 9.39801C19.1753 9.39801 18 10.5733 18 12.023C18 13.4728 19.1753 14.648 20.625 14.648Z'
        fill='currentColor'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 14.648C13.4497 14.648 14.625 13.4728 14.625 12.023C14.625 10.5733 13.4497 9.39801 12 9.39801C10.5503 9.39801 9.375 10.5733 9.375 12.023C9.375 13.4728 10.5503 14.648 12 14.648Z'
        fill='currentColor'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIconMediumViewBox>
  )
}

export { EllipsisIcon }
