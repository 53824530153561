import React from 'react'

import { SvgIconProps, SvgIconSmallViewBox } from '../../../SvgIcon'

const InfoTriangle: React.FC<Omit<SvgIconProps, 'small'>> = (props) => {
  return (
    <SvgIconSmallViewBox height='14' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.29719 1.66211C7.11617 0.597001 8.88377 0.597006 9.70275 1.66212L10.0267 2.08341C12.0562 4.72289 13.7999 7.5273 15.2345 10.4591L15.3021 10.5974C15.8077 11.6305 15.0439 12.8037 13.7843 12.9288C9.94003 13.3107 6.0599 13.3107 2.21568 12.9288C0.956023 12.8037 0.19225 11.6305 0.697792 10.5974L0.765453 10.4591C2.2 7.5273 3.94372 4.72289 5.97325 2.08341L6.29719 1.66211ZM8.74999 5.00036C8.74999 5.36855 8.4142 5.66703 7.99999 5.66703C7.58577 5.66703 7.24999 5.36855 7.24999 5.00036C7.24999 4.63217 7.58577 4.33369 7.99999 4.33369C8.4142 4.33369 8.74999 4.63217 8.74999 5.00036ZM7.99999 6.83369C8.31065 6.83369 8.56249 7.05755 8.56249 7.33369V10.667C8.56249 10.9432 8.31065 11.167 7.99999 11.167C7.68933 11.167 7.43749 10.9432 7.43749 10.667V7.33369C7.43749 7.05755 7.68933 6.83369 7.99999 6.83369Z'
        fill='currentColor'
      />
    </SvgIconSmallViewBox>
  )
}

export { InfoTriangle }
