import React, { createContext, useLayoutEffect, useState } from 'react'

import { generateUseContext } from '@guiker/react-context'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'

import { darkTheme, Theme, theme as lightTheme } from './theme'

const muiTheme = createMuiTheme({
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '.grecaptcha-badge': { visibility: 'hidden' },
        body: {
          ...darkTheme.typography.variants.body,
          backgroundColor: '#ffffff',
        },
      },
    },
  },
})

export type ThemeContextProps = React.PropsWithChildren & {
  isDarkMode?: boolean
  palette?: Partial<Theme['palette']>
}

export type ThemeContext = {
  isDarkMode: boolean
  setIsDarkMode: (value: boolean) => void
  theme: Theme
}

export const ThemeContext = createContext<ThemeContext>(null)

export const ThemeContextProvider: React.FC<ThemeContextProps> = ({ children, ...props }) => {
  const [isDarkMode, setIsDarkMode] = useState(props.isDarkMode)
  const [theme, setTheme] = useState(() => {
    const _theme = isDarkMode ? darkTheme : lightTheme
    return { ..._theme, palette: { ..._theme.palette, ...props.palette } }
  })

  useLayoutEffect(() => {
    const _theme = isDarkMode ? darkTheme : lightTheme
    setTheme({ ..._theme, palette: { ..._theme.palette, ...props.palette } })
  }, [isDarkMode])

  const value = {
    isDarkMode,
    setIsDarkMode,
    theme,
  }

  return (
    <MuiThemeProvider theme={muiTheme}>
      <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
    </MuiThemeProvider>
  )
}

export const useTheme = generateUseContext(ThemeContext)
