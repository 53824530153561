import React, { createContext, useCallback, useEffect, useRef, useState } from 'react'

import { theme } from '@guiker/components-core'
import { useConfig } from '@guiker/config-context'
import { safelyGetWindow } from '@guiker/react-utils'
import { GoogleMap as BaseGoogleMap, LoadScript } from '@react-google-maps/api'

type Geocoordinates = {
  lat: number
  lng: number
}

type Center = Geocoordinates
type Geobox = {
  bottomLeft: Geocoordinates
  topRight: Geocoordinates
}

export type GoogleMapProps = React.PropsWithChildren & {
  height?: number | string
  width?: number | string
  center: Center
  zoom?: number
  onCenterChanged?: (args: { center: Center; bounds: Geobox }) => void
}

const GoogleMapContext = createContext(null)

/**
 * @description LoadScript has to run only once per app
 * https://github.com/JustFly1984/react-google-maps-api/issues/70
 */
export const GoogleMapProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [libraries] = useState(['places', 'visualization'])
  const { google } = useConfig()

  return (
    <GoogleMapContext.Provider value={{}}>
      {google.mapApiKey && (
        <LoadScript
          googleMapsApiKey={google.mapApiKey}
          libraries={libraries as ['places', 'visualization']}
          loadingElement={<></>}
        />
      )}
      {children}
    </GoogleMapContext.Provider>
  )
}

const styles = [
  {
    featureType: 'poi',
    elementType: 'labels',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [
      {
        color: theme.palette.grey[10],
      },
    ],
  },
  {
    featureType: 'administrative.neighborhood',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: theme.palette.grey[30],
      },
    ],
  },
  {
    featureType: 'administrative.locality',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: theme.palette.grey[50],
      },
    ],
  },
  {
    featureType: 'landscape',
    elementType: 'all',
    stylers: [
      {
        color: theme.palette.grey[5],
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: theme.palette.common.white,
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road.highway.controlled_access',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: theme.palette.grey[30],
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: theme.palette.grey[30],
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: theme.palette.grey[30],
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: theme.palette.grey[10],
      },
    ],
  },
  {
    featureType: 'transit',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        color: theme.palette.grey[30],
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels',
    stylers: [
      {
        color: theme.palette.grey[50],
      },
    ],
  },
]

const hasCenterMoved = (prev: Center, next: Center) => {
  return prev?.lat !== next.lat || prev?.lng !== next.lng
}

export const GoogleMap: React.FC<GoogleMapProps> = ({ children, ...props }) => {
  const { height = 400, width = 400, center: propCenter, zoom = 9, onCenterChanged } = props
  const safeWindow = safelyGetWindow()
  const containerStyle = { height, width }

  const { google } = useConfig()
  const mapRef = useRef<google.maps.Map>()
  const [currentCenter, setCurrentCenter] = useState<Center>()

  const getMapBounds = useCallback(() => {
    const bounds = mapRef.current.getBounds()
    return {
      bottomLeft: bounds.getSouthWest().toJSON(),
      topRight: bounds.getNorthEast().toJSON(),
    }
  }, [mapRef])

  const handleCenterChanged = useCallback(() => {
    if (mapRef.current) {
      const bounds = getMapBounds()
      const center = mapRef.current.getCenter().toJSON()
      hasCenterMoved(currentCenter, center) && onCenterChanged?.({ center, bounds })
    }
  }, [mapRef])

  useEffect(() => {
    hasCenterMoved(currentCenter, propCenter) && setCurrentCenter(propCenter)
  }, [propCenter])

  if (!google.mapApiKey || !safeWindow?.google?.maps) {
    return <></>
  }

  return (
    <BaseGoogleMap
      onLoad={(map) => {
        mapRef.current = map
      }}
      onIdle={handleCenterChanged}
      mapContainerStyle={containerStyle}
      center={currentCenter}
      zoom={zoom}
      options={{ mapTypeControl: false, streetViewControl: false, zoomControl: false, styles }}
    >
      {children}
    </BaseGoogleMap>
  )
}
