import React from 'react'

type GuikerLogoProps = {}

const GuikerLogo: React.FC<GuikerLogoProps> = () => {
  return (
    <svg width='34' height='34' viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fill='currentColor'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.37095 33.4889C7.42123 33.4911 12.4413 33.6934 17 33.6934C21.5581 33.6934 26.5788 33.4911 26.6291 33.4889C27.264 33.4633 27.8159 33.0451 28.0123 32.4407L33.9255 14.2385C34.1219 13.634 33.9214 12.9711 33.4229 12.5768L33.42 12.5745C33.2925 12.4738 29.3984 9.39895 25.7527 6.7501C22.0648 4.07025 17.8841 1.28264 17.8421 1.25501C17.3322 0.914998 16.6678 0.914998 16.1579 1.25501C16.1159 1.28264 11.9346 4.07025 8.24732 6.7501C4.60089 9.39946 0.706023 12.475 0.579974 12.5745L0.577126 12.5768C0.0785842 12.9711 -0.121941 13.634 0.0744519 14.2385L5.98774 32.4407C6.18409 33.0451 6.73598 33.4633 7.37095 33.4889ZM9.20699 30.3808C9.24769 30.3825 13.3106 30.5462 17.0001 30.5462C20.6891 30.5462 24.7525 30.3825 24.7932 30.3808C25.3071 30.3601 25.7538 30.0216 25.9127 29.5324L30.6985 14.8008C30.8574 14.3116 30.6951 13.7751 30.2917 13.4559L30.2894 13.4541C30.1881 13.3742 27.0355 10.8847 24.0839 8.74019C21.0992 6.5713 17.7156 4.3152 17.6816 4.29283C17.2689 4.01765 16.7312 4.01765 16.3185 4.29283C16.2846 4.3152 12.9005 6.5713 9.91627 8.74019C6.96489 10.8846 3.81243 13.3739 3.71081 13.4541L3.70853 13.4559C3.30504 13.7751 3.14275 14.3116 3.3017 14.8008L8.08752 29.5324C8.24643 30.0216 8.69309 30.3601 9.20699 30.3808ZM11.9414 26.8508H22.043C22.4584 26.8508 22.8265 26.5834 22.955 26.1884L26.0841 16.5685C26.2125 16.1737 26.0723 15.7412 25.7368 15.4967L17.5778 9.55233C17.2412 9.30713 16.7849 9.30713 16.4483 9.55233L8.28711 15.4983C7.95264 15.742 7.81221 16.1727 7.93876 16.5667L11.0284 26.1851C11.1558 26.5818 11.5248 26.8508 11.9414 26.8508Z'
      />
    </svg>
  )
}

export { GuikerLogo }
