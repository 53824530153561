import React from 'react'

import { makeStyles } from '../../../styles'
import { Spinner } from '../../Feedback'
import { TableProps } from '..'
import { TableRow } from './TableRow'

const useStyles = makeStyles(() => ({
  loader: {
    height: 250,
  },
}))

export type TableBodyProps<T> = Pick<
  TableProps<T>,
  | 'columns'
  | 'condensed'
  | 'data'
  | 'isLoading'
  | 'isRowCollapsible'
  | 'collapsibleComponent'
  | 'collapsibleOptions'
  | 'onRowClick'
>

const TableBody = <T extends object>({
  columns,
  condensed,
  data,
  isRowCollapsible,
  collapsibleComponent,
  collapsibleOptions,
  isLoading,
  onRowClick,
}: TableBodyProps<T>) => {
  const classes = useStyles()

  if (isLoading) {
    return (
      <tr>
        <td colSpan={columns.length}>
          <Spinner className={classes.loader} />
        </td>
      </tr>
    )
  }

  return (
    <>
      {(data || []).map((item, index) => (
        <TableRow
          condensed={condensed}
          columns={columns}
          isRowCollapsible={isRowCollapsible}
          collapsibleComponent={collapsibleComponent}
          collapsibleOptions={collapsibleOptions}
          item={item}
          index={index}
          key={index}
          onClick={onRowClick}
        />
      ))}
    </>
  )
}

export { TableBody }
