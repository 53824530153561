import React, { CSSProperties } from 'react'

import { clsx } from '@guiker/clsx'
import { AnyColor, buildStyleProps, makeStyles, theme, TypographyVariants } from '@guiker/components-core'

import { marginKeyProps, paddingKeyProps, SpacingProps, useEmptyStyles, useUtilityStyle } from '../../../styles'

type HtmlTextElement = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'p' | 'span' | 'div' | 'legend' | 'li'

export type TypographyProps = React.PropsWithChildren &
  SpacingProps &
  React.PropsWithChildren & {
    alt?: string
    title?: string
    className?: string
    textAlign?: CSSProperties['textAlign']
    color?: AnyColor | 'inherit'
    component?: HtmlTextElement | React.FC
    id?: string
    name?: string
    onClick?: (event: unknown) => void
    variant?: TypographyVariants
    breakline?: boolean
    whiteSpace?: CSSProperties['whiteSpace']
    wordWrap?: CSSProperties['wordWrap']
    fontStyle?: CSSProperties['fontStyle']
    fontWeight?: CSSProperties['fontWeight']
    mb?: -2 | -1 | 0 | 1 | 2 | 3 | 4 | 5 | 6
  }

const variantMapping: { [key in TypographyVariants]: HtmlTextElement } = {
  display1: 'h1',
  display2: 'h2',
  display3: 'h3',
  display4: 'h4',
  displayBodyBig: 'p',
  displayBody: 'p',
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  quote: 'p',
  body: 'p',
  bodyBig: 'p',
  bodySmall: 'p',
  bodySmaller: 'p',
  bodySmallest: 'p',
  bodyBold: 'p',
  bodyMedium: 'p',
  button: 'span',
  buttonLarge: 'span',
  caption: 'span',
  tag: 'span',
  inline: 'span',
}

const useTypographyVariableStyle = makeStyles(
  (theme) => ({
    color: {
      color: ({ color }: { color: AnyColor }) => theme.palette.getColor(color),
    },
  }),
  { name: 'TypographyVariable' },
)

const useStyles = makeStyles(() => theme.typography.variants as { [key in TypographyVariants]: any }, {
  name: 'Typography',
})

const useStyleTextAlign = makeStyles(
  buildStyleProps({
    css: 'textAlign',
    props: ['initial', 'inherit', 'left', 'right', 'center', 'start', 'end'],
  }),
)
const useStyleWhiteSpace = makeStyles(
  buildStyleProps({
    css: 'whiteSpace',
    props: ['initial', 'break-spaces', 'pre', 'pre-line', 'nowrap'],
  }),
)
const useStyleWordWrap = makeStyles(
  buildStyleProps({
    css: 'wordWrap',
    props: ['initial', 'break-word', 'normal'],
  }),
)
const useStyleFontStyle = makeStyles(
  buildStyleProps({
    css: 'fontStyle',
    props: ['initial', 'inherit', 'italic', 'normal', 'oblique'],
  }),
)
const useStyleFontWeight = makeStyles(
  buildStyleProps({
    css: 'fontWeight',
    props: ['initial', 'inherit', 200, 300, 400, 500, 600, 'bold', 'lighter', 'normal'],
  }),
)
const useStyleMarginBottom = makeStyles(
  buildStyleProps({
    css: 'marginBottom',
    props: [0, 1, 2, 3, 4, 5].map((mb) => theme.spacing(mb)),
  }),
)
const useStyleMarginTop = makeStyles(buildStyleProps({ css: 'marginTop', props: [0] }))

const useTypographyStyles = () => ({
  textAlign: useStyleTextAlign(),
  whiteSpace: useStyleWhiteSpace(),
  wordWrap: useStyleWordWrap(),
  fontStyle: useStyleFontStyle(),
  fontWeight: useStyleFontWeight(),
  marginBottom: useStyleMarginBottom(),
  marginTop: useStyleMarginTop(),
})

const spacingProps = [...marginKeyProps, ...paddingKeyProps]

const Typography: React.FC<TypographyProps> = (props) => {
  const {
    breakline = false,
    children,
    className,
    color = 'textPrimary',
    component,
    fontStyle = 'normal',
    fontWeight,
    mb = 0,
    onClick,
    textAlign,
    whiteSpace: whiteSpaceProp,
    wordWrap,
    variant = 'body',
    ...otherProps
  } = props

  const classes = useStyles()
  const hasVariable = [color].some((v) => v != null) // extend if necessary
  const hasSpacing = spacingProps.some((sp) => sp !== 'mb' && otherProps[sp] != null)
  const utilityStyles = hasSpacing ? useUtilityStyle(otherProps) : useEmptyStyles(useUtilityStyle)
  const typographyVariableClasses = hasVariable
    ? useTypographyVariableStyle({ color })
    : useEmptyStyles(useTypographyVariableStyle)
  const typographyClasses = useTypographyStyles()
  const whiteSpace = whiteSpaceProp ?? (breakline ? 'pre-line' : 'initial')

  const Component = component || variantMapping[variant]
  const classNames = clsx(
    className,
    classes[variant],
    hasSpacing ? utilityStyles.spacing : undefined,
    color !== undefined ? typographyVariableClasses.color : undefined,
    textAlign !== undefined ? typographyClasses.textAlign[textAlign] : undefined,
    fontStyle !== undefined ? typographyClasses.fontStyle[fontStyle] : undefined,
    fontWeight !== undefined ? typographyClasses.fontWeight[fontWeight] : undefined,
    wordWrap !== undefined ? typographyClasses.wordWrap[wordWrap] : undefined,
    mb !== undefined ? typographyClasses.marginBottom[theme.spacing(mb)] : undefined,
    typographyClasses.marginTop[0],
    typographyClasses.whiteSpace[whiteSpace],
  )

  return (
    <Component className={classNames} onClick={onClick} {...otherProps}>
      {children}
    </Component>
  )
}

type TypographyVariantProps = Omit<TypographyProps, 'variant'>

// const isMobile = () => useMediaQuery(theme.breakpoints.down('xs')) // to be revisited upon new spec
const Caption: React.FC<TypographyVariantProps> = (props) => <Typography variant='caption' {...props} />
const P: React.FC<TypographyVariantProps> = (props) => <Typography variant='body' {...props} />
const PBig: React.FC<TypographyVariantProps> = (props) => <Typography variant='bodyBig' {...props} />
const PBold: React.FC<TypographyVariantProps> = (props) => <Typography variant='bodyBold' {...props} />
const PMedium: React.FC<TypographyVariantProps> = (props) => <Typography variant='bodyMedium' {...props} />
const PSmall: React.FC<TypographyVariantProps> = (props) => <Typography variant='bodySmall' {...props} />
const PSmaller: React.FC<TypographyVariantProps> = (props) => <Typography variant='bodySmaller' {...props} />
const PSmallest: React.FC<TypographyVariantProps> = (props) => <Typography variant='bodySmallest' {...props} />
const Quote: React.FC<TypographyVariantProps> = (props) => <Typography variant='quote' {...props} />
const TagTypography: React.FC<TypographyVariantProps> = (props) => <Typography variant='tag' {...props} />
const Inline: React.FC<TypographyVariantProps> = (props) => <Typography variant='inline' {...props} />

const Display1: React.FC<TypographyVariantProps> = (props) => <Typography mb={5} variant='display1' {...props} />
const Display2: React.FC<TypographyVariantProps> = (props) => <Typography mb={4} variant='display2' {...props} />
const Display3: React.FC<TypographyVariantProps> = (props) => <Typography mb={3} variant='display3' {...props} />
const Display4: React.FC<TypographyVariantProps> = (props) => <Typography mb={2} variant='display4' {...props} />
const DisplayBodyBig: React.FC<TypographyVariantProps> = (props) => (
  <Typography mb={0} variant='displayBodyBig' {...props} />
)
const DisplayBody: React.FC<TypographyVariantProps> = (props) => <Typography mb={0} variant='displayBody' {...props} />

const H1: React.FC<TypographyVariantProps> = (props) => <Typography variant='h1' mb={3} {...props} />
const H2: React.FC<TypographyVariantProps> = (props) => <Typography variant='h2' mb={3} {...props} />
const H3: React.FC<TypographyVariantProps> = (props) => <Typography variant='h3' mb={3} {...props} />
const H4: React.FC<TypographyVariantProps> = (props) => <Typography variant='h4' mb={2} {...props} />
const H5: React.FC<TypographyVariantProps> = (props) => <Typography variant='h5' mb={2} {...props} />

export {
  Caption,
  Display1,
  Display2,
  Display3,
  Display4,
  DisplayBodyBig,
  DisplayBody,
  H1,
  H2,
  H3,
  H4,
  H5,
  P,
  PBig,
  PBold,
  PMedium,
  PSmall,
  PSmaller,
  PSmallest,
  Quote,
  Inline,
  TagTypography,
  Typography,
}
