import React from 'react'

import { SvgIconProps, SvgIconSmallViewBox } from '../../../SvgIcon'

const ExclamationIcon: React.FC<Omit<SvgIconProps, 'small'>> = (props) => {
  return (
    <SvgIconSmallViewBox {...props}>
      <path
        d='M11.8101 6.65608C11.8101 9.77927 9.27823 12.3111 6.15504 12.3111C3.03185 12.3111 0.5 9.77927 0.5 6.65608C0.5 3.53289 3.03185 1.00104 6.15504 1.00104C9.27823 1.00104 11.8101 3.53289 11.8101 6.65608Z'
        stroke='#BE2E36'
      />
      <rect x='5.49023' y='2.90176' width='1.33' height='4.98529' fill='#BE2E36' />
      <rect x='5.49023' y='9.08038' width='1.33' height='1.33' fill='#BE2E36' />
    </SvgIconSmallViewBox>
  )
}

export { ExclamationIcon }
