import React from 'react'

import { Currency, currency as baseCurrency } from '@guiker/money'

import { InputLabelProps, NumberFormat, NumberFormatProps, numberFormatter } from '../'

export type CurrencyTextFieldProps = NumberFormatProps & {
  currency?: Currency
  onChange?: (value: any) => void
  labelAdornment?: InputLabelProps['adornment']
}

const CurrencyTextField: React.FC<CurrencyTextFieldProps> = ({ currency = baseCurrency.CAD, ...props }) => {
  return (
    <NumberFormat
      decimalScale={currency.decimalPlace}
      formatter={numberFormatter.currency(currency)}
      prefix={`${currency.sign || '$'} `}
      thousandSeparator
      valueIsNumericString
      {...props}
    />
  )
}

export { CurrencyTextField }
