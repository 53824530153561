import { useContext } from 'react'

import { DeviceContext } from './DeviceContext'

export const useDeviceContext = () => {
  const context = useContext(DeviceContext)

  if (context === undefined) {
    throw new Error('useDeviceContext can only be used inside useDeviceContextProvider')
  }

  return context
}
