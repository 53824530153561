import React from 'react'

import { Toggle as CLToggle } from '@guiker/components-library'
import { FieldPath } from '@guiker/react-hook-form'

import { useApiFormContext } from '../Form'
import { RHFToggle, ToggleProps as RHFToggleProps } from './RHFToggle'

export type ToggleProps<T extends object, P extends FieldPath<T>> = RHFToggleProps<T, P>

export const Toggle = <T extends object = any, P extends FieldPath<T> = any>(props: ToggleProps<T, P>) => {
  const { readOnly: contextReadOnly } = useApiFormContext({ shouldThrowIfUndefined: false })
  if (props.readOnly) {
    const defaultChecked =
      props.defaultValue === null || props.defaultValue === undefined
        ? false
        : Boolean(JSON.parse(props.defaultValue as unknown as string))
    return <CLToggle defaultChecked={defaultChecked} readOnly={contextReadOnly} {...props} />
  } else {
    return <RHFToggle {...props} />
  }
}
