import { useLayoutEffect, useState } from 'react'

import { safelyGetWindow } from '@guiker/react-utils'

export const useWindowHeight = () => {
  const [height, setHeight] = useState(0)
  const _window = safelyGetWindow()

  useLayoutEffect(() => {
    const updateWindowDimensions = () => {
      setHeight(_window?.innerHeight ?? 0)
    }

    _window.addEventListener('resize', updateWindowDimensions)
    return () => _window.removeEventListener('resize', updateWindowDimensions)
  }, [])

  return { height }
}
