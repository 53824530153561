import React, { createContext } from 'react'

import { theme } from '../../../styles'

export type PageLayoutContextProps = React.PropsWithChildren & PageLayoutContext

export type PageLayoutContext = {
  maxWidth:
    | typeof theme.dimensions.pageLayout.maxWidth.desktop
    | typeof theme.dimensions.narrowPageLayout.maxWidth.desktop
    | '100%'
}

export const PageLayoutContext = createContext<PageLayoutContext>(null)

export const PageLayoutContextProvider: React.FC<PageLayoutContextProps> = ({ children, maxWidth }) => {
  const value = {
    maxWidth,
  }

  return <PageLayoutContext.Provider value={value}>{children}</PageLayoutContext.Provider>
}
