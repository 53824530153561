import { useLocation, useNavigate as baseUseNavigation } from 'react-router'

import { isNumber } from '@guiker/lodash'

export const navigateToExternalUrl = ({ url, openInNewTab }: { url: string; openInNewTab?: boolean }) => {
  if (openInNewTab) {
    window.open(url, '_blank', 'noopener,noreferrer')
  } else {
    window.top.location.href = url
  }
}

export const useNavigate = () => {
  const navigate = baseUseNavigation()
  const location = useLocation()

  return (
    to: string | -1,
    options?: {
      replace?: boolean
      fallback?: string
      state?: object
      isExternalLink?: boolean
      openInNewTab?: boolean
    },
  ) => {
    const state = { from: location.pathname, ...options?.state }

    if (isNumber(to)) {
      if ((location.state as { from: string })?.from === '') {
        navigate((location.state as { from: string }).from, { replace: options?.replace, state })
      } else if (window.history.length > 2) {
        navigate(to as any, { replace: options?.replace, state })
      } else if (options.fallback) {
        navigate(options.fallback, { replace: options?.replace, state })
      }
    } else if (options?.isExternalLink) {
      navigateToExternalUrl({ url: to as string, openInNewTab: options?.openInNewTab })
    } else {
      navigate(to, { replace: options?.replace, state })
    }
  }
}
