import React from 'react'

const MastercardIcon: React.FC = () => {
  return (
    <svg width='37' height='25' viewBox='0 0 37 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.331345 1.78674C0.331345 0.958316 1.00292 0.286743 1.83134 0.286743H34.8313C35.6598 0.286743 36.3313 0.958316 36.3313 1.78674V22.7867C36.3313 23.6152 35.6598 24.2867 34.8313 24.2867H1.83135C1.00292 24.2867 0.331345 23.6152 0.331345 22.7867V1.78674Z'
        fill='#F1EFEB'
      />
      <path d='M21.5236 6.62024H15.1394V17.9532H21.5236V6.62024Z' fill='#FF5F00' />
      <path
        d='M15.5448 12.2868C15.5438 11.1953 15.7941 10.118 16.2769 9.13623C16.7598 8.1545 17.4623 7.29415 18.3315 6.6203C17.2552 5.78456 15.9625 5.26483 14.6013 5.12051C13.24 4.97619 11.8651 5.21309 10.6338 5.80415C9.40239 6.39521 8.3642 7.31658 7.63786 8.46294C6.91151 9.60929 6.52634 10.9344 6.52634 12.2868C6.52634 13.6392 6.91151 14.9643 7.63786 16.1106C8.3642 17.257 9.40239 18.1783 10.6338 18.7694C11.8651 19.3605 13.24 19.5974 14.6013 19.453C15.9625 19.3087 17.2552 18.789 18.3315 17.9533C17.4623 17.2794 16.7598 16.419 16.277 15.4373C15.7941 14.4556 15.5438 13.3782 15.5448 12.2868Z'
        fill='#EB001B'
      />
      <path
        d='M30.1363 12.2868C30.1364 13.6391 29.7512 14.9642 29.0249 16.1106C28.2986 17.2569 27.2605 18.1783 26.0291 18.7694C24.7978 19.3604 23.4229 19.5974 22.0617 19.453C20.7005 19.3087 19.4078 18.789 18.3315 17.9532C19.1999 17.2787 19.902 16.4182 20.3847 15.4366C20.8674 14.4551 21.1183 13.378 21.1183 12.2868C21.1183 11.1955 20.8674 10.1185 20.3847 9.1369C19.902 8.15532 19.1999 7.29483 18.3315 6.62029C19.4078 5.78456 20.7005 5.26483 22.0617 5.12051C23.4229 4.97619 24.7978 5.2131 26.0291 5.80416C27.2605 6.39523 28.2986 7.3166 29.0249 8.46296C29.7512 9.60932 30.1364 10.9344 30.1363 12.2868Z'
        fill='#F79E1B'
      />
      <path
        d='M29.4402 16.7529V16.5209H29.5349V16.4736H29.2937V16.5209H29.3885V16.7529H29.4402ZM29.9085 16.7529V16.4731H29.8346L29.7495 16.6656L29.6645 16.4731H29.5905V16.7529H29.6427V16.5419L29.7225 16.7238H29.7766L29.8564 16.5414V16.7529H29.9085Z'
        fill='#F79E1B'
      />
    </svg>
  )
}

export { MastercardIcon }
