import React from 'react'

import { Switch as CLSwitch } from '@guiker/components-library'
import { FieldPath } from '@guiker/react-hook-form'

import { useApiFormContext } from '../Form'
import { RHFSwitch, SwitchProps as RHFSwitchProps } from './RHFSwitch'

export type SwitchProps<T extends object, P extends FieldPath<T>> = RHFSwitchProps<T, P>

export const Switch = <T extends object = any, P extends FieldPath<T> = any>(props: SwitchProps<T, P>) => {
  const { readOnly: contextReadOnly } = useApiFormContext({ shouldThrowIfUndefined: false })
  if (props.readOnly) {
    return <CLSwitch {...props} />
  } else {
    return <RHFSwitch readOnly={contextReadOnly} {...props} />
  }
}
