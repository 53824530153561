import { QueryKey, UseQueryMutation, useQueryMutation } from '@guiker/react-query'

export type UseDataContextProviderProps<T, Mutations, TQueryKey extends QueryKey = QueryKey> = {
  data: T
} & (
  | {
      useMutation: ReturnType<UseQueryMutation<T, Mutations, QueryKey>>['useMutation']
    }
  | {
      queryKey: TQueryKey
      apiClient: Mutations
    }
)

export const useDataContextProvider = <T, Mutations, TQueryKey extends QueryKey = QueryKey>({
  data,
  ...args
}: {
  data: T
} & (
  | {
      useMutation: ReturnType<UseQueryMutation<T, Mutations, TQueryKey>>['useMutation']
    }
  | {
      queryKey: TQueryKey
      apiClient: Mutations
    }
)) => {
  const { useMutation, queryKey, apiClient } = args as {
    useMutation: ReturnType<UseQueryMutation<T, Mutations, TQueryKey>>['useMutation']
    queryKey: TQueryKey
    apiClient: Mutations
  }

  const defaultUseMutation = useQueryMutation<T, Mutations, QueryKey>({ queryKey, apiClient }).useMutation

  return {
    data,
    useMutation: useMutation || defaultUseMutation,
  }
}
