import React from 'react'

import { clsx } from '@guiker/clsx'
import { AnyColor, makeStyles } from '@guiker/components-core'

export type SvgIconProps = React.PropsWithChildren & {
  className?: string
  color?: AnyColor
  height?: string | number
  onClick?: (event?: React.SyntheticEvent) => void
  size?: 'smallest' | 'smaller' | 'small' | 'medium' | 'large' | 'larger'
  strokeWidth?: number
  // eslint-disable-next-line prettier/prettier
  viewBox?: `${number} ${number} ${number} ${number}`
  width?: string | number
}

const SIZE_MAP = {
  smallest: 8,
  smaller: 12,
  small: 16,
  medium: 24,
  large: 40,
  larger: 64,
}

const useStyles = makeStyles((theme) => ({
  root: ({ color, height, width, onClick }: SvgIconProps) => ({
    height,
    minHeight: height,
    width,
    minWidth: width,
    color: theme.palette.getColor(color) || 'currentColor',
    cursor: onClick ? 'pointer' : 'inherit',
  }),
}))

const SvgIcon: React.FC<SvgIconProps> = ({
  children,
  className,
  color,
  onClick,
  size,
  width = SIZE_MAP[size],
  height = SIZE_MAP[size],
  viewBox = `0 0 ${SIZE_MAP[size]} ${SIZE_MAP[size]}`,
}) => {
  const classes = useStyles({ color, onClick, width, height })

  return (
    <svg
      className={clsx(className, classes.root)}
      width={`${width}`}
      height={`${height}`}
      viewBox={viewBox}
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
      fill='none'
    >
      {children}
    </svg>
  )
}

export const SvgIconMediumViewBox: React.FC<Omit<SvgIconProps, 'viewBox'>> = (props) => (
  <SvgIcon size='medium' viewBox={`0 0 ${SIZE_MAP.medium} ${SIZE_MAP.medium}`} {...props} />
)

export const SvgIconSmallViewBox: React.FC<Omit<SvgIconProps, 'viewBox'>> = (props) => (
  <SvgIcon size='small' viewBox={`0 0 ${SIZE_MAP.small} ${SIZE_MAP.small}`} {...props} />
)

export const SvgIconLargeViewBox: React.FC<Omit<SvgIconProps, 'viewBox'>> = (props) => (
  <SvgIcon size='large' viewBox={`0 0 ${SIZE_MAP.large} ${SIZE_MAP.large}`} {...props} />
)

export const SvgIconLargerViewBox: React.FC<Omit<SvgIconProps, 'viewBox'>> = (props) => (
  <SvgIcon size='larger' viewBox={`0 0 ${SIZE_MAP.larger} ${SIZE_MAP.larger}`} {...props} />
)

export const SvgIconCustomViewBox: React.FC<Omit<SvgIconProps, 'viewBox'>> = (props) => <SvgIcon {...props} />

export { SvgIcon }
