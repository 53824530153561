import React, { useState } from 'react'

import { usePersistancyContext } from '@guiker/react-persistance'

import { useT } from '../../../hooks/i18n'
import { TextButton } from '../../Inputs'
import { Flex } from '../../Layout'
import { Collapse } from '../Collapse'
import { Note } from '../Note'
import { DisplayBody, H4 } from '../Typography'

type NoteProps = React.PropsWithChildren & {
  noteKey: string
  title?: string
  cta?: React.ReactNode
}

type PersiatedState<T extends string> = {
  layout: {
    [key in T]: {
      isHidden: boolean
    }
  }
}

export const CollapsibleNote: React.FC<NoteProps> = ({ cta, title, children, noteKey }) => {
  const { tBase } = useT()
  const { data, setData } = usePersistancyContext<PersiatedState<typeof noteKey>>()

  const [isHidden, setIsHidden] = useState(false)

  const hideNote = () => {
    setIsHidden(true)
  }

  const commitStateChange = (_node: HTMLElement, done: () => void) => {
    setData({ layout: { [noteKey]: { isHidden: true } } })
    done()
  }

  if (!data?.layout?.[noteKey]?.isHidden === false) {
    return
  }

  return (
    <Collapse in={!isHidden} addEndListener={commitStateChange}>
      <Note>
        <Flex flexDirection='column' gap={2}>
          <Flex flexGrow={1} justifyContent='space-between'>
            <H4 fontWeight={500} mb={0}>
              {title}
            </H4>
            <div>
              <TextButton size='small' onClick={hideNote}>
                {tBase('actions.hide')}
              </TextButton>
            </div>
          </Flex>
          <DisplayBody mb={0}>{children}</DisplayBody>
          {cta && (
            <Flex flexGrow={1} justifyContent='flex-end'>
              {cta}
            </Flex>
          )}
        </Flex>
      </Note>
    </Collapse>
  )
}
