import React, { createContext } from 'react'

import { AuthLevel } from '@guiker/api-definition'
import { FullScreenSpinner } from '@guiker/components-library'
import { PageNotFound } from '@guiker/error-pages'
import { generateUseContext } from '@guiker/react-context'
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  UseQueryMutation,
  useQueryMutation,
} from '@guiker/react-query'
import { Outlet, useParams } from '@guiker/router'

import {
  ApiClientGenerator,
  RecordRouteDefinition,
  useAuthenticatedApiClient,
  usePublicApiClient,
} from './use-api-client'

export { AuthLevel }

const isChildrenRoute = (children: React.ReactNode) => {
  return (
    React.Children.count(children) === 0 ||
    !!React.Children.toArray(children).find((child: React.ReactElement) => {
      const childType = (child as unknown as React.ReactElement<React.FunctionComponent>).type
      const componentName = (childType as React.FunctionComponent)?.displayName
      return ['Route', 'Routes', 'Router', 'Outlet'].includes(componentName)
    })
  )
}

export const generateBaseApiContext = <T, P, R extends RecordRouteDefinition, QueryKey extends string>({
  authLevel,
  apiClientGen,
  fetcher,
  cacheKey,
  renderNotFound = true,
  renderLoadingSpinner = true,
  DataAccessContextProvider,
}: {
  authLevel: AuthLevel
  apiClientGen: ApiClientGenerator<R>
  fetcher?: (client: ReturnType<typeof apiClientGen>, args?: P) => Promise<T>
  cacheKey?: QueryKey | ((args?: P) => QueryKey)
  renderNotFound?: boolean
  renderLoadingSpinner?: boolean
  DataAccessContextProvider?: React.FC<
    React.PropsWithChildren & {
      data: T
      useMutation?: ReturnType<UseQueryMutation<T, ReturnType<typeof apiClientGen>>>['useMutation']
    }
  >
}) => {
  type Fetcher = (client: ReturnType<typeof apiClientGen>, args?: P) => Promise<T>
  type NeverIfNoFetcher<R> = typeof fetcher extends Fetcher ? R : never

  type Context = P & {
    data: NeverIfNoFetcher<T>
    refetch: NeverIfNoFetcher<
      <TPageData>(options?: RefetchOptions & RefetchQueryFilters<TPageData>) => Promise<QueryObserverResult<T, unknown>>
    >
    apiClient: ReturnType<typeof apiClientGen>
    isFetching: NeverIfNoFetcher<boolean>
    error: NeverIfNoFetcher<unknown>
    useMutation?: ReturnType<UseQueryMutation<T, ReturnType<typeof apiClientGen>>>['useMutation']
  }

  const Context = createContext<Context>(null)

  type RenderChildren = (context: Context) => React.ReactNode

  const ContextProvider: React.FC<React.PropsWithChildren & P> = ({ children, ...props }) => {
    const useApiClient =
      authLevel === AuthLevel.PUBLIC ? usePublicApiClient(apiClientGen) : useAuthenticatedApiClient(apiClientGen)

    const apiClient = useApiClient()

    const { useQuery, useMutation } = useQueryMutation<T, ReturnType<typeof apiClientGen>>({
      queryKey: [typeof cacheKey === 'function' ? (cacheKey as (args?: P) => string)(props as P) : cacheKey],
      apiClient,
    })

    const { isLoading, error, data, refetch } = fetcher
      ? useQuery((_) => fetcher(apiClient, props as P), {
          retry: 1,
        })
      : { data: undefined, isLoading: undefined, error: undefined, refetch: undefined }

    const value = {
      ...(props as P),
      apiClient,
      data,
      refetch,
      isFetching: isLoading,
      error,
      useMutation,
    } as Context

    return (
      <Context.Provider value={value}>
        {typeof children === 'function' ? (children as RenderChildren)(value) : children}
      </Context.Provider>
    )
  }

  const useContext = generateUseContext(Context)

  type ContentProps = React.PropsWithChildren & {
    renderNotFound?: boolean
    renderLoadingSpinner?: boolean
  }

  const Content: React.FC<ContentProps> = ({ children, ...props }) => {
    const { isFetching, data, error, useMutation } = useContext()
    const flag = {
      renderNotFound: props.renderNotFound ?? renderNotFound,
      renderLoadingSpinner: props.renderLoadingSpinner ?? renderLoadingSpinner,
    }

    if (error && flag.renderNotFound) {
      return <PageNotFound />
    }

    if (flag.renderLoadingSpinner && (isFetching || (fetcher && !data))) {
      return <FullScreenSpinner />
    }

    const nestedChild = isChildrenRoute(children) ? <Outlet /> : <>{children}</>

    if (DataAccessContextProvider) {
      return (
        <DataAccessContextProvider data={data} useMutation={useMutation}>
          {nestedChild}
        </DataAccessContextProvider>
      )
    } else {
      return nestedChild
    }
  }

  const NestedRouter: React.FC<Omit<P, 'children'> & ContentProps> = ({ children, ...props }) => {
    const params = useParams()

    return (
      <ContextProvider {...params} {...(props as P)}>
        <Content renderNotFound={props.renderNotFound} renderLoadingSpinner={props.renderLoadingSpinner}>
          {children}
        </Content>
      </ContextProvider>
    )
  }

  NestedRouter.displayName = 'NestedRouter'

  return {
    Context,
    ContextProvider,
    NestedRouter,
    useContext,
  }
}
