import React from 'react'

const AmericanExpressIcon: React.FC = () => {
  return (
    <svg width='37' height='25' viewBox='0 0 37 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.146759 2.25513C0.146759 1.4267 0.818332 0.755127 1.64676 0.755127H34.6468C35.4752 0.755127 36.1468 1.4267 36.1468 2.25513V23.2551C36.1468 24.0836 35.4752 24.7551 34.6468 24.7551H1.64676C0.818333 24.7551 0.146759 24.0836 0.146759 23.2551V2.25513Z'
        fill='#006FCF'
      />
      <path
        d='M35.1357 19.6758V14.1057H34.8174H14.0487L13.4917 14.9014L12.9347 14.1057H6.88711V20.0737H12.9347L13.4917 19.2779L14.0487 20.0737H17.8683V18.8005H17.7887C18.2661 18.8005 18.664 18.7209 19.0619 18.5618V20.0737H21.6878V19.1984L22.3244 20.0737H33.783C34.1013 20.0737 34.4196 19.9941 34.6583 19.9145C34.897 19.835 35.0562 19.7554 35.1357 19.6758ZM30.9979 12.0367H33.5443V6.06873H30.8388V7.02361L30.2022 6.06873H27.815V7.26233L27.2579 6.06873H23.3588C23.1997 6.06873 22.961 6.06873 22.8018 6.1483C22.6427 6.1483 22.4835 6.22787 22.3244 6.22787C22.2448 6.22787 22.0857 6.30745 22.0061 6.38702C21.8469 6.46659 21.7674 6.54617 21.6878 6.54617V6.30745V6.06873H8.95602L8.63772 7.10318L8.31943 6.06873H5.37521V7.26233L4.81819 6.06873H2.35141L1.15781 8.69466V11.5593V12.1163H2.90843L3.22672 11.241H3.86331L4.1816 12.1163H17.3908V11.241L18.0274 12.1163H21.6878V11.9572V11.7185C21.7674 11.798 21.8469 11.798 22.0061 11.8776C22.0857 11.9572 22.2448 11.9572 22.3244 12.0367C22.4835 12.1163 22.5631 12.1163 22.7223 12.1163C22.961 12.1163 23.1201 12.1959 23.3588 12.1959H25.5869L25.9052 11.3206H26.5418L26.8601 12.1959H30.5205V11.3206L30.9979 12.0367Z'
        fill='white'
      />
      <path
        d='M11.1045 15.8563V15.0605H7.92157V19.0392H11.1045V18.2435H8.87645V17.4477H11.1045V16.652H8.87645V15.8563H11.1045ZM14.4466 19.0392H15.6402L14.0487 17.0499L15.6402 15.0605H14.4466L13.4917 16.3337L12.5368 15.0605H11.3432L12.9347 17.0499L11.3432 19.0392H12.5368L13.4917 17.766L14.4466 19.0392ZM15.7994 15.0605V19.0392H16.7542V17.6865H17.9478C18.8232 17.6865 19.3802 17.1294 19.3802 16.3337C19.3802 15.538 18.8232 14.981 18.0274 14.981H15.7994V15.0605ZM18.4253 16.4133C18.4253 16.652 18.2661 16.8907 17.9478 16.8907H16.8338V15.9358H17.9478C18.2661 15.9358 18.4253 16.095 18.4253 16.4133ZM20.8921 17.6069H21.3695L22.5631 19.0392H23.7567L22.404 17.5273C23.1201 17.3682 23.518 16.8907 23.518 16.2541C23.518 15.538 22.961 14.981 22.1652 14.981H20.0168V18.9596H20.9716V17.6069H20.8921ZM22.0061 15.8563C22.3244 15.8563 22.5631 16.095 22.5631 16.3337C22.5631 16.5724 22.404 16.8111 22.0061 16.8111H20.8921V15.8563H22.0061ZM27.4171 15.8563V15.0605H24.2342V19.0392H27.4171V18.2435H25.189V17.4477H27.4171V16.652H25.189V15.8563H27.4171ZM30.043 18.2435H27.9741V19.0392H29.9635C30.8388 19.0392 31.3162 18.4822 31.3162 17.766C31.3162 17.0499 30.8388 16.652 30.043 16.652H29.0881C28.8494 16.652 28.6903 16.4929 28.6903 16.2541C28.6903 16.0154 28.8494 15.8563 29.0881 15.8563H30.8388L31.1571 15.0605H29.0881C28.2128 15.0605 27.7354 15.6175 27.7354 16.3337C27.7354 17.0499 28.2128 17.5273 29.0086 17.5273H29.9635C30.2022 17.5273 30.3613 17.6865 30.3613 17.9252C30.4409 18.0843 30.2817 18.2435 30.043 18.2435ZM33.8626 18.2435H31.7936V19.0392H33.783C34.6583 19.0392 35.1357 18.4822 35.1357 17.766C35.1357 17.0499 34.6583 16.652 33.8626 16.652H32.9873C32.7485 16.652 32.5894 16.4929 32.5894 16.2541C32.5894 16.0154 32.7485 15.8563 32.9873 15.8563H34.7379L35.0562 15.0605H32.9873C32.1119 15.0605 31.6345 15.6175 31.6345 16.3337C31.6345 17.0499 32.1119 17.5273 32.9077 17.5273H33.8626C34.1013 17.5273 34.2604 17.6865 34.2604 17.9252C34.2604 18.0843 34.0217 18.2435 33.8626 18.2435Z'
        fill='#006FCF'
      />
      <path
        d='M4.81821 11.0818H5.93224L4.26119 7.10313H2.98801L1.2374 11.0818H2.27185L2.59015 10.2065H4.49991L4.81821 11.0818ZM3.22674 8.61503L3.54503 7.8193L3.86332 8.53546L4.18162 9.41077H2.90844L3.22674 8.61503ZM7.04627 8.37631V7.26228L8.1603 11.0818H9.03561L10.1496 7.26228V8.29674V11.0022H11.1045V7.02356H9.43348L8.63774 9.88821L7.842 7.02356H6.17096V11.0022H7.12584V8.37631H7.04627ZM15.0036 7.89887V7.10313H11.8207V11.0818H15.0036V10.2861H12.7756V9.49034H15.0036V8.69461H12.7756V7.89887H15.0036ZM16.6747 9.64949H17.1521L18.3457 11.0818H19.5393L18.1866 9.56992C18.9027 9.41077 19.3006 8.93333 19.3006 8.29674C19.3006 7.58058 18.7436 7.02356 17.9479 7.02356H15.7994V11.0022H16.7542V9.64949H16.6747ZM17.7887 7.89887C18.107 7.89887 18.3457 8.13759 18.3457 8.37631C18.3457 8.61503 18.1866 8.85375 17.7887 8.85375H16.6747V7.89887H17.7887ZM19.778 11.0818H20.7329V9.33119V7.10313H19.778V9.33119V11.0818ZM23.1997 11.0818L23.6772 10.2065H23.3589C22.7223 10.2065 22.3244 9.80864 22.3244 9.09247V9.0129C22.3244 8.37631 22.6427 7.89887 23.3589 7.89887H24.3933V7.02356H23.2793C22.0061 7.02356 21.3695 7.8193 21.3695 9.0129V9.09247C21.3695 10.2861 22.0857 11.0818 23.1997 11.0818ZM27.4171 11.0818H28.5311L26.8601 7.10313H25.5869L23.8363 11.0818H24.8708L25.1891 10.2065H27.0988L27.4171 11.0818ZM25.8256 8.61503L26.1439 7.89887L26.4622 8.61503L26.7805 9.49034H25.5073L25.8256 8.61503ZM29.6452 8.69461V8.37631L29.8839 8.69461L31.3958 11.0818H32.5098V7.10313H31.5549V9.41077V9.72906L31.3958 9.41077L29.8839 7.10313H28.6903V11.0818H29.6452V8.69461Z'
        fill='#006FCF'
      />
    </svg>
  )
}

export { AmericanExpressIcon }
