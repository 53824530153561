import { useMemo } from 'react'

import { RouteObject } from 'react-router'
import { matchRoutes, useLocation } from 'react-router-dom'

import { RouteConfig } from './route-config'

type RouterConfig = { [key: string]: RouteConfig }

const flattenRoutes = (routes: RouterConfig, prefix?: string): RouteConfig[] => {
  return Object.values(routes).reduce((curr, _route) => {
    const { routes: nestedRoutes, ...route } = _route

    const pathWithoutSlashDuplication = route.path === '/' ? '' : route.path
    const path = prefix ? `${prefix}${pathWithoutSlashDuplication}` : pathWithoutSlashDuplication
    const flattenedNestedRoutes: RouteConfig[] = nestedRoutes ? flattenRoutes(nestedRoutes, path) : []

    return [
      ...curr,
      {
        ...route,
        path,
      },
      ...flattenedNestedRoutes,
    ]
  }, [])
}

export const useCurrentRoute = (routes: RouterConfig, baseName?: string) => {
  const location = useLocation()
  const routeObjects = useMemo(() => flattenRoutes(routes), [routes])

  const match = matchRoutes(routeObjects as RouteObject[], location, baseName)

  return match ? match[0] : null
}
