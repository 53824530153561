import { useLayoutEffect } from 'react'

import { ResizeObserver } from 'resize-observer'

import { safelyGetWindow } from '@guiker/react-utils'

export const useResizeObserver = <T extends Element>(ref: React.RefObject<T>, callback: () => void) => {
  useLayoutEffect(() => {
    if (!ref.current) return

    const resizeObserver = new ResizeObserver(callback)
    resizeObserver.observe(ref.current)

    return () => {
      resizeObserver?.unobserve(ref.current)
    }
  }, [ref, callback])
}

export const useScreenResizeObserver = (callback: () => void) => {
  useLayoutEffect(() => {
    const _window = safelyGetWindow()
    _window.addEventListener('resize', callback)

    return () => {
      _window.removeEventListener('resize', callback)
    }
  }, [callback])
}
