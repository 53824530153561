import React from 'react'

import { SvgIconProps, SvgIconSmallViewBox } from '../../../SvgIcon'

type ChevronDownIconProps = SvgIconProps

const ChevronDownIcon: React.FC<ChevronDownIconProps> = (props) => {
  return (
    <SvgIconSmallViewBox {...props}>
      <path
        d='M1 4.5L8 11.5L15 4.5'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIconSmallViewBox>
  )
}

export { ChevronDownIcon }
