import React from 'react'

import { Route as BaseRoute, useParams } from 'react-router'

import { useAuthenticationContext } from '@guiker/authentication-context'
import { Base64 } from '@guiker/base64'
import { useClaimsAuthenticationContext } from '@guiker/claims-authentication-context'
import { useDeviceContext } from '@guiker/react-use-device'

import { Redirect, RouteConfig } from '.'
import { Outlet } from './Outlet'
import { useRouter } from './use-router'

type RouteProps = Omit<RouteConfig, 'path'> & {
  [key: string]: unknown
  path: string
}

const Route: React.FC<RouteProps> = ({ Component, path, ...props }) => {
  const router = useRouter()

  const { requireAuthentication, requireAccountActivation, authenticationMethod } = props
  const { user } = useAuthenticationContext() || {}
  const { claims } = useClaimsAuthenticationContext() || {}
  const { isBrowser } = useDeviceContext() || {}
  const params = useParams()

  if (requireAccountActivation && user && user?.status !== 'ACTIVE') {
    const redirect = isBrowser ? location.pathname : '/'
    const code = isBrowser ? Base64.encode(JSON.stringify({ userId: user?.id, redirect })) : ''
    return <Redirect reload path={`${router.accountActivationRedirectPath}?code=${code}`} />
  } else if (requireAuthentication) {
    if ((authenticationMethod === 'user' && !user) || (authenticationMethod === 'token' && !claims)) {
      const redirect = isBrowser ? `${location.pathname}${encodeURIComponent(location.search)}` : '/'
      return <Redirect path={`${router.unauthenticatedRedirectPath}?redirect=${redirect}`} />
    }
  }

  return <BaseRoute {...props} path={path} element={Component ? <Component {...params} {...props} /> : <Outlet />} />
}

export { Route }

Route.displayName = 'Route'
