import React from 'react'

import { isAtMostTablette, makeStyles, theme } from '@guiker/components-core'
import { Link, LinkProps } from '@guiker/router'

import { DrawerProps, Flex, GuikerLogoWithText, P, useMediaQuery } from '../../../'
import { BaseAppBar } from '../../Layout/AppBar'
import { AppBarMenu } from './AppBarMenu'
import { NavigationItemProps } from './NavigationItemProps'

export type AppBarProps = {
  bottomMenuItems?: NavigationItemProps[]
  taskMenu?: React.ReactNode
  notificationMenu?: React.ReactNode
  darkMode?: boolean
  drawerProps?: DrawerProps
  hasBorder?: boolean
  menuIsDrawer?: boolean
  navBarItems: NavigationItemProps[]
  prefixLogoLinkWithLocale?: boolean
  subTitle?: string
  subTitleUrl?: string
  incompleteTasksCount?: number
  topMenuItems: NavigationItemProps[]
  user?: {
    firstName: string
    lastName: string
    avatar: string
  } | null
}

type DefaultNavBarItemProps = NavigationItemProps &
  React.PropsWithChildren & {
    darkMode?: boolean
    className?: string
  }

const useStyles = makeStyles({
  itemsListContainer: ({ isAuthenticated }: { isAuthenticated: boolean }) => ({
    justifyContent: isAuthenticated ? 'center' : 'flex-end',
    [isAtMostTablette]: {
      width: '100%',
      justifyContent: 'flex-end',
    },
  }),
  item: {
    paddingTop: 12,
    paddingBottom: 12,
  },
})

const DefaultNavBarItem: React.FC<DefaultNavBarItemProps & LinkProps> = ({ children, darkMode, ...props }) => {
  return (
    <Link {...props}>
      <P mb={0}>{children}</P>
    </Link>
  )
}

const AppBar: React.FC<AppBarProps> = ({
  darkMode = true,
  bottomMenuItems,
  taskMenu,
  notificationMenu,
  drawerProps,
  menuIsDrawer,
  navBarItems,
  prefixLogoLinkWithLocale,
  subTitle,
  subTitleUrl,
  topMenuItems,
  user,
}) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const classes = useStyles({ isMobile, isAuthenticated: !!user })

  return (
    <BaseAppBar>
      <Flex
        alignItems='center'
        justifyContent='space-between'
        maxWidth={theme.dimensions.pageLayout.maxWidth.desktop}
        gap={4}
      >
        <Link to='/' prefixWithLocale={prefixLogoLinkWithLocale} isRelative={false}>
          <GuikerLogoWithText subTitle={subTitle} subTitleUrl={subTitleUrl} isLogoOnly={!!user} />
        </Link>
        <Flex flexWrap='nowrap' alignItems={'center'} className={classes.itemsListContainer} flex={1} gap={4}>
          {navBarItems.map(({ Component, text, ...props }, index) =>
            Component ? (
              <Component darkMode={darkMode} key={index} className={classes.item} {...props}>
                {text}
              </Component>
            ) : (
              <DefaultNavBarItem darkMode={darkMode} key={index} className={classes.item} {...props}>
                {text}
              </DefaultNavBarItem>
            ),
          )}
        </Flex>
        <AppBarMenu
          drawer={menuIsDrawer}
          darkMode={darkMode}
          drawerProps={drawerProps}
          user={user}
          topMenuItems={topMenuItems}
          bottomMenuItems={bottomMenuItems}
          taskMenu={taskMenu}
          notificationMenu={notificationMenu}
        />
      </Flex>
    </BaseAppBar>
  )
}

export { AppBar, DefaultNavBarItem }
