import React from 'react'

import { SvgIconMediumViewBox, SvgIconProps } from '../../../SvgIcon'

export const TrendingIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIconMediumViewBox {...props}>
      <path
        d='M16.846 14.222C16.9373 16.6102 15.0799 18.6224 12.692 18.722'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 23.222C7.385 23.222 3 19.864 3 15.722C2.9464 12.4947 4.92218 9.58018 7.94 8.43501C8.06136 8.38551 8.19976 8.40297 8.30503 8.48105C8.41029 8.55913 8.46716 8.68651 8.455 8.81701C8.244 10.431 8.86285 12.0422 10.1 13.1C10.3886 13.3444 10.8141 13.3354 11.092 13.079C12.412 11.862 15.649 7.84201 9.869 1.40301C9.7645 1.28678 9.74305 1.11792 9.81516 0.979255C9.88728 0.840587 10.0378 0.76119 10.193 0.780006C15.621 1.44701 21 7.57101 21 13.472C21 18.472 18 23.222 12 23.222Z'
        stroke='currentColor'
        fill='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIconMediumViewBox>
  )
}
