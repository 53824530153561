import React from 'react'

import {
  default as MuiDialogContent,
  DialogContentProps as MuiDialogContentProps,
} from '@material-ui/core/DialogContent'

export type DialogContentProps = MuiDialogContentProps

const DialogContent: React.FC<DialogContentProps> = ({ className, ...props }) => {
  return <MuiDialogContent className={className} {...props} />
}

export { DialogContent }
