import React from 'react'

import { Flex, Spinner, SpinnerProps } from '../../'

export type FullScreenSpinnerProps = SpinnerProps

export const FullScreenSpinner: React.FC<FullScreenSpinnerProps> = ({ color = 'primary', caption, className }) => {
  return (
    <Flex flexGrow='1' height='100%' justifyContent='center' alignItems='center'>
      <Spinner color={color} caption={caption} className={className} />
    </Flex>
  )
}
