import React, { isValidElement } from 'react'

import { isNumber, isString } from '@guiker/lodash'

export type StringLike = string | number
export type CellRenderable = StringLike | React.ReactElement

export const isCellRenderable = (arg: any): arg is CellRenderable => {
  return isString(arg) || isNumber(arg) || isValidElement(arg)
}

export const isCellRendableSingleOrArray = (arg: any): arg is string | string[] => {
  return isCellRenderable(arg) || (Array.isArray(arg) && arg.every((e) => e == null || isCellRenderable(e)))
}
