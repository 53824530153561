import React, { useCallback, useEffect, useRef } from 'react'

import { DeepOmit } from '@guiker/ts-utils'

import {
  FeedbackComponent,
  PageLayoutProps,
  Progress,
  ProgressProps,
  TakeOver,
  TakeOverProps,
  useLayoutContext,
  useLocation,
  useLocationQuery,
  useMediaQuery,
  useNavigate,
} from '../../..'
import { makeStyles, theme } from '../../../styles'
import { ScrollToTop } from '../ScrollTo'
import { StepProps } from '../Step'

type ProgressionBarPosition = {
  position?: 'relative' | 'fixed'
}

export type StepConfig = {
  name: string
  disabled?: boolean
  component: React.FC<StepProps>
  label?: string
  description?: string
  nextLabel?: string
  skip?: boolean
}

export type StepperProps = {
  backLabel: string
  finalLabel?: string
  hasProgressBar?: boolean
  hasDrawerNav?: boolean
  isLoading?: boolean
  nextLabel: string
  onClose?: () => void
  onLastStepClicked?: () => void
  onStepChange?: (currentStep: number, percentage: number) => void
  onStepLoad?: (props: OnStepLoadProps) => void
  pageLayoutProps?: DeepOmit<PageLayoutProps & { withSideMenu?: boolean }, { sideMenuProps?: { items: never } }>
  asTakeover?: boolean
  takeoverProps?: TakeOverProps
  progressBarProps?: Partial<Omit<ProgressProps, 'value'>> & ProgressionBarPosition
  startStepIndex?: number
  steps: StepConfig[]
  stepperButtonContainerProps?: {
    mx?: number
    mb?: number
  }
  stepQueryKey?: string
  title?: string | React.ReactNode
  subtitle?: string | React.ReactNode
}

export type OnStepLoadProps = {
  currentStep: number
  navigate: ReturnType<typeof useNavigate>
  direction: StepDirection
  goToStep: (stepIndex: number, direction?: StepDirection) => void
}

export type StepDirection = 'next' | 'prev'

const parseDirection = (direction: string) => {
  return (['next', 'prev'].includes(direction) ? direction : 'next') as StepDirection
}

const useStyles = makeStyles(
  {
    progressBarContainer: ({ isMobile, position = 'fixed' }: { isMobile: boolean } & ProgressionBarPosition) => ({
      width: '100%',
      position,
      zIndex: 801,
      top:
        position === 'fixed'
          ? isMobile
            ? theme.dimensions.appBar.height.mobile
            : theme.dimensions.appBar.height.desktop
          : 0,
      backgroundColor: theme.palette.common.white,
    }),
  },
  { name: 'Stepper' },
)

const Wrapper: React.FC<TakeOverProps & { asTakeover: boolean }> = ({ children, asTakeover, ...props }) => {
  if (asTakeover) {
    return <TakeOver {...props}>{children}</TakeOver>
  } else {
    return <div>{children}</div>
  }
}

export const Stepper: React.FC<StepperProps> = (props) => {
  const {
    finalLabel,
    hasProgressBar = true,
    hasDrawerNav = false,
    isLoading,
    nextLabel,
    onClose,
    onLastStepClicked,
    onStepChange,
    onStepLoad,
    pageLayoutProps: { withSideMenu, ...pageLayoutProps } = {},
    progressBarProps = {},
    startStepIndex = 0,
    stepperButtonContainerProps = { mx: 2, mb: 3 },
    stepQueryKey = 'step',
    steps,
    subtitle,
    takeoverProps,
    asTakeover = true,
    title,
    ...others
  } = props

  const location = useLocation()
  const navigate = useNavigate()
  const { isDrawerOpen } = useLayoutContext({
    shouldThrowIfUndefined: false,
  })
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const classes = useStyles({ isMobile, position: progressBarProps.position })
  const { step: stepName, direction } = useLocationQuery(stepQueryKey) || { step: steps[startStepIndex].name }
  const currentLocation = useLocation()

  const foundIndex = steps.findIndex((step: StepConfig) => step.name === stepName)
  const currentStep = foundIndex === -1 ? startStepIndex : foundIndex
  const isLastStep = currentStep >= steps.length - 1
  const stepDirection = useRef(parseDirection(direction))

  const computePercentage = (index: number): number => (steps.length === 0 ? 100 : ((index + 1) / steps.length) * 100)

  const currentPath = location.pathname

  const backDisabled = currentStep === 0
  const nextDisabled = currentStep >= steps.length
  const { component: Step, ...currentStepProps } = steps[currentStep]
  const progressBarPercentage = computePercentage(currentStep)

  const computeStepDelta = (stepDelta: number): number => {
    const { skip } = { ...steps[currentStep + stepDelta] }
    const delta = stepDelta > 0 ? 1 : -1
    return skip ? computeStepDelta(stepDelta + delta) : stepDelta
  }

  const onClickNext = useCallback(
    ({ asLastStep }: { asLastStep?: boolean } = {}) => {
      if (nextDisabled) {
        return
      }
      if (isLastStep || asLastStep) {
        onLastStepClicked && onLastStepClicked()
      } else {
        goToStep(currentStep + computeStepDelta(1), 'next')
      }
    },
    [nextDisabled, isLastStep, currentStep, steps, onLastStepClicked],
  )

  const onClickPrev = useCallback(() => {
    if (backDisabled) {
      return
    }

    goToStep(currentStep + computeStepDelta(-1), 'prev')
  }, [steps, currentStep, backDisabled])

  const goToStep = (i: number, direction: StepDirection = 'next') => {
    const gotoIndex = i < 0 ? startStepIndex : i > steps.length - 1 ? currentStep : i
    stepDirection.current = direction
    navigate(`${currentPath}?${stepQueryKey}=${steps[gotoIndex].name}&direction=${direction}`)
  }

  useEffect(() => {
    onStepChange && onStepChange(currentStep + 1, progressBarPercentage)
  }, [currentStep, progressBarPercentage])

  useEffect(() => {
    onStepLoad && onStepLoad({ navigate, currentStep, goToStep, direction: stepDirection.current })
  }, [currentStep])

  const sideMenuNavItems =
    withSideMenu &&
    steps.map((step) => ({
      disabled: step.disabled,
      text: step.label,
      href: `?${stepQueryKey}=${step.name}`,
    }))

  return (
    <Wrapper
      asTakeover={asTakeover}
      onBack={currentStep !== 0 ? onClickPrev : null}
      onClose={onClose ? onClose : () => navigate('/')}
      title={title}
      subtitle={subtitle}
      {...takeoverProps}
    >
      {asTakeover && hasProgressBar && progressBarPercentage != null && (
        <Progress
          value={progressBarPercentage}
          withBackgroundColor={false}
          className={classes.progressBarContainer}
          {...progressBarProps}
        />
      )}
      {isLoading ? (
        <FeedbackComponent loading={isLoading} />
      ) : (
        <>
          <ScrollToTop />
          <Step
            {...stepperButtonContainerProps}
            {...others}
            {...pageLayoutProps}
            {...currentStepProps}
            title={steps[currentStep].label}
            subtitle={steps[currentStep].description}
            onClickNext={onClickNext}
            onClickBack={onClickPrev}
            nextDisabled={nextDisabled}
            hasDrawerNav={hasDrawerNav}
            isDrawerMinimized={!isDrawerOpen}
            nextLabel={isLastStep ? finalLabel || nextLabel : nextLabel}
            sideMenuProps={
              withSideMenu
                ? {
                    ...pageLayoutProps.sideMenuProps,
                    currentLocation: `${currentLocation.pathname}${currentLocation.search}`,
                    items: sideMenuNavItems,
                  }
                : undefined
            }
          />
        </>
      )}
    </Wrapper>
  )
}
