import React from 'react'

import {
  InfiniteData,
  MutateOptions,
  MutationFunction,
  QueryClient,
  QueryClientProvider as BaseReactQueryConfigProvider,
  QueryClientProviderProps,
  QueryKey,
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useInfiniteQuery,
  UseMutateFunction,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query'

import { useQuery } from './use-query'

const QueryClientProvider: React.FC<React.PropsWithChildren & QueryClientProviderProps> = (props) => {
  return <BaseReactQueryConfigProvider {...props} />
}

const enum QueryStatus {
  Loading = 'loading',
  Error = 'error',
  Success = 'success',
}

export {
  InfiniteData,
  MutateOptions,
  MutationFunction,
  QueryClient,
  QueryClientProvider,
  QueryKey,
  QueryObserverResult,
  QueryStatus,
  RefetchOptions,
  RefetchQueryFilters,
  useInfiniteQuery,
  useQueryClient,
  useMutation,
  UseMutationOptions,
  UseMutateFunction,
  UseMutationResult,
  useQuery,
}

export * from './use-get-from-query-cache'
export * from './use-query-mutation'
