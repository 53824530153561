import * as React from 'react'

import { clsx } from '@guiker/clsx'
import { AnyColor, makeStyles, theme } from '@guiker/components-core'

import { PSmall } from '../../Data Display/Typography'

export type FormHelperTextProps = React.PropsWithChildren & {
  id?: string
  role?: string
  className?: string
  color?: AnyColor
  disabled?: boolean
  Component?: React.FC<{ className: string }>
}

const useStyles = makeStyles({
  root: {
    marginBottom: 0,
    color: ({ color }: { color: AnyColor }) => theme.palette.getColor(color),
  },
  disabled: {
    opacity: 0.5,
  },
})

const FormHelperText: React.FC<FormHelperTextProps> = ({
  className,
  Component = PSmall,
  color = 50,
  disabled,
  ...props
}) => {
  const classes = useStyles({ color })
  const composedClassName = clsx(className, classes.root, {
    [classes.disabled]: disabled,
  })

  return <Component className={composedClassName} {...props} />
}

export { FormHelperText }
