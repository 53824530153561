import React from 'react'

import { AnyColor, theme } from '@guiker/components-core'

type LandlordIconProps = {
  color?: AnyColor
}

const LandlordIcon: React.FC<LandlordIconProps> = ({ color: colorKey }) => {
  const color = theme.palette.getColor(colorKey)

  return (
    <svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M0.753906 14.2419V23.2419'
        stroke={color || 'currentColor'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M0.753906 21.7419H15.7539C15.7539 20.0851 14.4108 18.7419 12.7539 18.7419H8.99991C8.99991 17.0851 7.65676 15.7419 5.99991 15.7419H0.753906'
        stroke={color || 'currentColor'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.00391 18.7419H9.00391'
        stroke={color || 'currentColor'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.7271 13.756C12.2954 11.1639 15.3985 9.93411 18.3168 10.7481C21.2351 11.562 23.2537 14.2204 23.2541 17.25H16.5001'
        stroke={color || 'currentColor'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.5039 9C18.7821 9 20.6289 7.15317 20.6289 4.875C20.6289 2.59683 18.7821 0.75 16.5039 0.75C14.2257 0.75 12.3789 2.59683 12.3789 4.875C12.3789 7.15317 14.2257 9 16.5039 9Z'
        stroke={color || 'currentColor'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export { LandlordIcon }
