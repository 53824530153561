import React from 'react'

import { isObjectLike, objectKeys, SafeConcat, TokenizePath } from '@guiker/shared-framework'

import { deprecatedRouterBuilder } from './deprecated-router-builder'
import { AuthenticationMethod } from './route-config'

type RouteObject<Path extends string = string, NestedRoutes extends Routes<string, string> = never> = {
  path: Path
  routes?: NestedRoutes extends Routes<infer NestedKeys, infer NestedPath> ? Routes<NestedKeys, NestedPath> : never
}

type RouteString<Path extends string> = Path

type Route<Path extends string = string, NestedRoutes extends Routes<string, string> = never> =
  | RouteObject<Path, NestedRoutes>
  | RouteString<Path>

type Routes<Keys extends string = string, Path extends string = string> = {
  [key in Keys]: Route<Path, Routes<string, string>>
}

type FCWithParams<FullPath extends string = string> = React.FC<{ [key in TokenizePath<FullPath>]: string }>

type RouteComponentFunction<Path extends string = string, Prefix extends string = string> = FCWithParams<
  SafeConcat<Prefix, Path>
>

type RouteComponentObject<
  Path extends string = string,
  Prefix extends string = string,
  NestedRoutes extends RouteComponents = RouteComponents,
> = {
  path?: never
  authenticationMethod?: AuthenticationMethod
  requireAuthentication?: boolean
  requireAccountActivation?: boolean
  Component?: FCWithParams<SafeConcat<Prefix, Path>>
  routes?: NestedRoutes extends RouteComponents<infer NestedPath, string, infer NestedKeys>
    ? RouteComponents<NestedPath, Path, NestedKeys>
    : never
}

type RouteComponent<
  Path extends string = string,
  Prefix extends string = string,
  NestedRoutes extends RouteComponents = RouteComponents,
> = RouteComponentObject<Path, Prefix, NestedRoutes> | RouteComponentFunction<Prefix, Path>

type RouteComponents<Path extends string = string, Prefix extends string = string, Keys extends string = string> = {
  [key in Keys]: RouteComponent<Path, Prefix, RouteComponents<string, string, string>>
}

type MatchingReactRoute<R extends Route, Prefix extends string> = R extends Route<infer Path, infer NestedRoutes>
  ?
      | (Omit<RouteComponentObject<Path, Prefix>, 'routes'> & {
          routes?: NestedRoutes extends Routes ? MatchingReactRoutes<NestedRoutes, Path> : never
        })
      | RouteComponentFunction<Path, Prefix>
  : never

type MatchingReactRoutes<R extends Routes, Prefix extends string> = {
  [key in keyof R]: R[key] extends RouteObject<string, Routes<string, string>>
    ? MatchingReactRoute<Route<R[key]['path'], R[key]['routes']>, Prefix>
    : R[key] extends RouteString<string>
    ? MatchingReactRoute<Route<R[key]>, Prefix>
    : never
}

type ReactRoute<
  Path extends string,
  FullPath extends string,
  NestedRoutes extends Routes<string, string>,
> = RouteObject<Path, NestedRoutes> & Omit<RouteComponentObject<FullPath>, 'path'>

const isRouteObject = <P extends string, NestedRoutes extends Routes<string, string>>(
  arg: Route<P, NestedRoutes>,
): arg is RouteObject<P, NestedRoutes> => {
  return !!(arg as RouteObject<P, NestedRoutes>).path
}

const getPath = <P extends string, NestedRoutes extends Routes<string, string>>(route: Route<P, NestedRoutes>) => {
  return { path: (isRouteObject(route) ? route.path : route) as P }
}

const isRouteComponentObject = (arg: RouteComponent): arg is RouteComponentObject => {
  return isObjectLike(arg as RouteComponentObject)
}

const getComponent = <
  FullPath extends string,
  Prefix extends string,
  NestedRoutes extends Record<string, RouteComponentObject<string>>,
>(
  route: RouteComponent<FullPath, Prefix, NestedRoutes>,
) => {
  return isRouteComponentObject(route)
    ? {
        ...route,
        Component: route.Component as FCWithParams<FullPath>,
      }
    : {
        Component: route as FCWithParams<FullPath>,
      }
}

const mergeWebAppAndReactRoute = <
  Path extends string,
  FullPath extends string,
  NestedRoutes extends Routes<string, string>,
>(
  wepAppRoute: Route<Path, NestedRoutes>,
  reactRoute: MatchingReactRoute<Path, string>,
): ReactRoute<Path, FullPath, NestedRoutes> => {
  const nestedRoutes =
    isRouteObject(wepAppRoute) && isRouteComponentObject(reactRoute)
      ? mergeWebAppAndReactRoutes(wepAppRoute.routes, reactRoute.routes as any)
      : undefined

  return {
    ...getPath(wepAppRoute),
    ...getComponent(reactRoute),
    routes: nestedRoutes,
  } as ReactRoute<Path, FullPath, NestedRoutes>
}

const mergeWebAppAndReactRoutes = <
  R extends Routes<Keys, Path>,
  RC extends MatchingReactRoutes<R, ''>,
  Keys extends string,
  Path extends string,
>(
  routes: R,
  routeComponents: RC,
) => {
  return objectKeys(routes).reduce(
    (acc, key) => ({
      ...acc,
      [key]: mergeWebAppAndReactRoute(routes[key], routeComponents[key] as MatchingReactRoute<Path, ''>),
    }),
    {},
  )
}

export const webAppRouterBuilder = <
  R extends Routes<Keys, Path>,
  RC extends MatchingReactRoutes<R, ''>,
  Keys extends string,
  Path extends string,
>(
  routes: R,
  routeComponents: RC,
  options: { requireAuthentication: boolean; authenticationMethod?: AuthenticationMethod } = {
    requireAuthentication: true,
    authenticationMethod: 'user',
  },
) => {
  const { Router } = deprecatedRouterBuilder(mergeWebAppAndReactRoutes(routes, routeComponents), options)
  return Router
}
