import React from 'react'

import { AnyColor, theme } from '@guiker/components-core'

const LaundryRoomIcon: React.FC<{ color?: AnyColor }> = ({ color: colorKey }) => {
  const color = theme.palette.getColor(colorKey)
  return (
    <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M21.0522 20.2867C21.2841 20.2856 21.5122 20.2282 21.717 20.1195C21.9219 20.0108 22.0973 19.8541 22.2282 19.6627C22.3628 19.4675 22.4509 19.244 22.4856 19.0094C22.5203 18.7749 22.5006 18.5355 22.4282 18.3097C21.6453 16.1938 20.2174 14.3775 18.3462 13.1173C16.475 11.8571 14.2551 11.2167 12.0002 11.2867C9.74576 11.2173 7.52653 11.858 5.65592 13.1182C3.78531 14.3784 2.35786 16.1943 1.57517 18.3097C1.50227 18.5354 1.48239 18.7749 1.51707 19.0095C1.55176 19.2442 1.64008 19.4677 1.77517 19.6627C1.90586 19.8541 2.08107 20.0108 2.28575 20.1195C2.49042 20.2282 2.71843 20.2856 2.95017 20.2867H21.0522Z'
        stroke={color || 'currentColor'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.25 6.03662C14.25 5.59161 14.118 5.1566 13.8708 4.78659C13.6236 4.41658 13.2722 4.12819 12.861 3.95789C12.4499 3.7876 11.9975 3.74304 11.561 3.82986C11.1246 3.91667 10.7237 4.13096 10.409 4.44563C10.0943 4.7603 9.88005 5.16121 9.79323 5.59767C9.70642 6.03413 9.75098 6.48653 9.92127 6.89766C10.0916 7.30879 10.38 7.6602 10.75 7.90743C11.12 8.15466 11.555 8.28662 12 8.28662V11.2866'
        stroke={color || 'currentColor'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export { LaundryRoomIcon }
