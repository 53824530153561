import React from 'react'

const ScotiaBankIcon: React.FC = () => {
  return (
    <svg width='37' height='25' viewBox='0 0 37 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.395477 1.91357C0.395477 1.08515 1.06705 0.413574 1.89548 0.413574H34.8955C35.7239 0.413574 36.3955 1.08515 36.3955 1.91357V22.9136C36.3955 23.742 35.7239 24.4136 34.8955 24.4136H1.89548C1.06705 24.4136 0.395477 23.742 0.395477 22.9136V1.91357Z'
        fill='#EC121F'
      />
      <path
        d='M18.8938 4.77588H17.8426C14.621 4.77588 12.3413 7.90195 13.3242 10.9734L13.5835 11.7925L13.7473 11.1918C14.1978 9.55371 15.4537 8.25687 17.0782 7.76544C17.3921 7.66988 17.7198 7.61528 18.0474 7.61528H22.7433L25.2551 4.87144L18.8938 4.77588ZM17.9109 20.0513H18.962C22.1836 20.0513 24.4633 16.9252 23.4805 13.8538L23.2211 13.0347L23.0573 13.6353C22.6068 15.2735 21.3509 16.5703 19.7265 17.0617C19.4125 17.1573 19.0849 17.2119 18.7572 17.2119H14.0477L11.5359 19.9694L17.9109 20.0513Z'
        fill='white'
      />
      <path
        d='M18.3887 16.2701C20.5223 16.2701 22.2519 14.5405 22.2519 12.4069C22.2519 10.2733 20.5223 8.5437 18.3887 8.5437C16.2551 8.5437 14.5255 10.2733 14.5255 12.4069C14.5255 14.5405 16.2551 16.2701 18.3887 16.2701Z'
        fill='white'
      />
    </svg>
  )
}

export { ScotiaBankIcon }
