export type Shadows = {
  light: string
  medium: string
  heavy: string
}

export const shadows: Shadows = {
  light: '0px 4px 24px rgba(0, 0, 0, 0.08)',
  medium: '0px 4px 16px rgba(0, 0, 0, 0.12)',
  heavy: `0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12)`,
}
