import React from 'react'

import { SvgIconMediumViewBox, SvgIconProps } from '../../../SvgIcon'

export const ServicesIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIconMediumViewBox {...props}>
      <path
        d='M22.0626 10.046C21.6513 10.4175 21.4165 10.9458 21.4165 11.5C21.4165 12.0543 21.6513 12.5826 22.0626 12.954L23.1046 13.894C23.7203 14.4494 23.9196 15.3323 23.6023 16.0983C23.285 16.8644 22.5197 17.3477 21.6916 17.305L20.2916 17.233C19.7379 17.2049 19.1982 17.4125 18.8062 17.8046C18.4141 18.1966 18.2065 18.7363 18.2346 19.29L18.3066 20.69C18.3484 21.518 17.8645 22.2827 17.0983 22.5993C16.3321 22.916 15.4495 22.716 14.8946 22.1L13.9546 21.058C13.5832 20.6466 13.0549 20.4119 12.5006 20.4119C11.9464 20.4119 11.4181 20.6466 11.0466 21.058L10.1066 22.1C9.55091 22.7109 8.67178 22.9077 7.90867 22.592C7.14555 22.2763 6.66237 21.516 6.70062 20.691L6.77262 19.291C6.80183 18.7361 6.59384 18.1948 6.2005 17.8023C5.80716 17.4097 5.26553 17.2027 4.71062 17.233L3.31062 17.305C2.4831 17.3463 1.71909 16.8625 1.40257 16.0968C1.08605 15.3311 1.28548 14.4491 1.90062 13.894L2.94262 12.954C3.354 12.5826 3.58878 12.0543 3.58878 11.5C3.58878 10.9458 3.354 10.4175 2.94262 10.046'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.1762 12.25C15.8193 13.9959 14.2832 15.2498 12.5012 15.2498C10.7191 15.2498 9.18308 13.9959 8.82617 12.25'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.5 3.5V8.5C15.5 8.91421 15.1642 9.25 14.75 9.25H10.25C9.83579 9.25 9.5 8.91421 9.5 8.5V3.5'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M8 4.75L12.5 1L17 4.75' stroke='black' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    </SvgIconMediumViewBox>
  )
}
