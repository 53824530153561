import React from 'react'

import { AnyColor, theme } from '@guiker/components-core'

type BackgroundCheckSoftCheckProps = {
  color?: AnyColor
}

const BackgroundCheckSoftCheck: React.FC<BackgroundCheckSoftCheckProps> = ({ color: colorKey }) => {
  const color = theme.palette.getColor(colorKey)

  return (
    <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M22.8261 8.72424C23.1014 8.41892 23.2538 8.02238 23.2538 7.61124C23.2538 7.20011 23.1014 6.80357 22.8261 6.49824C20.2001 3.61324 16.0341 0.796244 12.0001 0.864244C7.9721 0.796244 3.8001 3.61324 1.1821 6.49824C0.906779 6.80357 0.754395 7.20011 0.754395 7.61124C0.754395 8.02238 0.906779 8.41892 1.1821 8.72424C3.7451 11.5482 7.9001 14.4302 12.0001 14.3612'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12 11.3633C11.2583 11.3633 10.5333 11.1433 9.91661 10.7313C9.29993 10.3192 8.81928 9.73357 8.53545 9.04835C8.25163 8.36312 8.17736 7.60912 8.32206 6.88169C8.46675 6.15427 8.8239 5.48608 9.34835 4.96163C9.8728 4.43719 10.541 4.08003 11.2684 3.93534C11.9958 3.79064 12.7498 3.86491 13.4351 4.14873C14.1203 4.43256 14.706 4.91321 15.118 5.52989C15.5301 6.14658 15.75 6.8716 15.75 7.61328'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.3782 13.2383C15.3782 13.9345 15.6547 14.6022 16.147 15.0944C16.6393 15.5867 17.307 15.8633 18.0032 15.8633C18.6994 15.8633 19.367 15.5867 19.8593 15.0944C20.3516 14.6022 20.6282 13.9345 20.6282 13.2383C20.6282 12.5421 20.3516 11.8744 19.8593 11.3821C19.367 10.8898 18.6994 10.6133 18.0032 10.6133C17.307 10.6133 16.6393 10.8898 16.147 11.3821C15.6547 11.8744 15.3782 12.5421 15.3782 13.2383V13.2383Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18 16.6133C16.6076 16.6133 15.2723 17.1664 14.2877 18.151C13.3031 19.1355 12.75 20.4709 12.75 21.8633V23.3633H23.25V21.8633C23.2497 20.471 22.6965 19.1358 21.712 18.1513C20.7275 17.1668 19.3923 16.6135 18 16.6133V16.6133Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export { BackgroundCheckSoftCheck }
