import React from 'react'

import { SvgIconMediumViewBox, SvgIconProps } from '../../SvgIcon'

const PayoutIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIconMediumViewBox {...props}>
      <path
        d='M4.25019 14.25C3.93546 14.25 3.62868 14.3489 3.3733 14.5329C3.11791 14.7168 2.92686 14.9765 2.82719 15.275L0.827195 21.275C0.751906 21.5005 0.731303 21.7407 0.767085 21.9757C0.802866 22.2108 0.894006 22.434 1.03299 22.6269C1.17198 22.8198 1.35482 22.9769 1.56645 23.0852C1.77809 23.1935 2.01244 23.25 2.25019 23.25H21.7502C21.9879 23.25 22.2223 23.1935 22.4339 23.0852C22.6456 22.9769 22.8284 22.8198 22.9674 22.6269C23.1064 22.434 23.1975 22.2108 23.2333 21.9757C23.2691 21.7407 23.2485 21.5005 23.1732 21.275L21.1732 15.275C21.0735 14.9765 20.8825 14.7168 20.6271 14.5329C20.3717 14.3489 20.0649 14.25 19.7502 14.25'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.75 7.5C18.75 10.75 13.618 18.027 12.304 19.836C12.2692 19.8839 12.2236 19.9229 12.1708 19.9498C12.1181 19.9767 12.0597 19.9907 12.0005 19.9907C11.9413 19.9907 11.8829 19.9767 11.8302 19.9498C11.7774 19.9229 11.7318 19.8839 11.697 19.836C10.383 18.027 5.25 10.75 5.25 7.5C5.25 5.70979 5.96116 3.9929 7.22703 2.72703C8.4929 1.46116 10.2098 0.75 12 0.75C13.7902 0.75 15.5071 1.46116 16.773 2.72703C18.0388 3.9929 18.75 5.70979 18.75 7.5V7.5Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.875 5.25001H11.25C10.9378 5.24901 10.635 5.357 10.3938 5.55535C10.1527 5.7537 9.98834 6.02997 9.9291 6.33653C9.86985 6.64309 9.91943 6.96071 10.0693 7.23463C10.2191 7.50854 10.4599 7.72158 10.75 7.83701L13.405 8.66201C13.6947 8.77789 13.935 8.9911 14.0845 9.26497C14.234 9.53883 14.2834 9.85623 14.2242 10.1626C14.165 10.4689 14.0009 10.7451 13.7602 10.9435C13.5194 11.142 13.217 11.2504 12.905 11.25H9.75'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M12 5.25V3.75' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M12 12.75V11.25' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    </SvgIconMediumViewBox>
  )
}

export { PayoutIcon }
