import React from 'react'

import { FormHelperText, FormHelperTextProps } from '../..'

export type HelperTextProps = FormHelperTextProps & {
  helperText?: string
}

const HelperText: React.FC<HelperTextProps> = ({ helperText, id, ...props }) => {
  const helperTextId = helperText ? `${id}-helper-text` : undefined

  return helperText ? (
    <FormHelperText aria-label='helper-message' id={helperTextId} {...props}>
      {helperText}
    </FormHelperText>
  ) : null
}

export { HelperText }
