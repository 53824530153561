import React, { useRef } from 'react'

import { Identifier } from 'dnd-core'
import { useDrag, useDrop } from 'react-dnd'

interface DragItem {
  index: number
  id: string
}

type GridItemProps = React.PropsWithChildren &
  DragItem & {
    canDrag: boolean
    onDrag: (dragIndex: number, hoverIndex: number) => unknown
    onDrop: () => unknown
  }

export const Draggable = 'draggable'

export const GridItem: React.FC<GridItemProps> = ({ children, id, index, canDrag, onDrag, onDrop, ...props }) => {
  const ref = useRef<HTMLDivElement>(null)

  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: Draggable,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleX = (hoverBoundingRect.left - hoverBoundingRect.right) / 2

      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      const hoverClientX = clientOffset.x - hoverBoundingRect.right
      if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
        return
      }
      if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
        return
      }

      item.index = hoverIndex
      onDrag(dragIndex, hoverIndex)
    },
    drop(item: DragItem) {
      item && onDrop()
    },
  })

  const [{ isDragging }, drag] = useDrag({
    canDrag,
    type: Draggable,
    item: () => {
      return { id, index }
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const opacity = isDragging ? 0.2 : 1
  drag(drop(ref))

  return (
    <div ref={ref} style={{ opacity }} data-handler-id={handlerId} className='GridItem-root' {...props}>
      {children}
    </div>
  )
}
