import React from 'react'

import { SvgIconMediumViewBox, SvgIconProps } from '../../../SvgIcon'

export const UserSwapIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIconMediumViewBox {...props}>
      <path
        d='M15 18.75H11.25V22.5'
        stroke='blcurrentColorack'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M22.667 19.483C21.8503 21.8696 19.5323 23.4098 17.0157 23.2381C14.4991 23.0663 12.4118 21.2254 11.927 18.75'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M19.5 15.75H23.25V12'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.832 15.0169C12.6487 12.6303 14.9667 11.0901 17.4833 11.2618C19.9999 11.4336 22.0872 13.2745 22.572 15.7499'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.781 10.5129C9.66842 9.53121 8.23377 8.99273 6.75 8.99994C3.70837 8.9761 1.13012 11.2321 0.75 14.2499'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.75 7.5C8.61396 7.5 10.125 5.98896 10.125 4.125C10.125 2.26104 8.61396 0.75 6.75 0.75C4.88604 0.75 3.375 2.26104 3.375 4.125C3.375 5.98896 4.88604 7.5 6.75 7.5Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.5 6C17.9497 6 19.125 4.82475 19.125 3.375C19.125 1.92525 17.9497 0.75 16.5 0.75C15.0503 0.75 13.875 1.92525 13.875 3.375C13.875 4.82475 15.0503 6 16.5 6Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20.4 8.99997C19.6736 7.74306 18.3854 6.91381 16.9405 6.77297C15.4956 6.63213 14.0715 7.197 13.116 8.28997'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIconMediumViewBox>
  )
}
