import React from 'react'

import { SvgIconLargerViewBox, SvgIconProps } from '../../../SvgIcon'

const OpenDialogIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIconLargerViewBox {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M29.9971 22H25.9971V28L17.9971 22H5.99707C3.78793 22 1.99707 20.2091 1.99707 18V6C1.99707 3.79086 3.78793 2 5.99707 2H29.9971C32.2062 2 33.9971 3.79086 33.9971 6V18C33.9971 20.2091 32.2062 22 29.9971 22Z'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M33.9971 56H37.9971V62L45.9971 56H57.9971C60.2062 56 61.9971 54.2091 61.9971 52V40C61.9971 37.7909 60.2062 36 57.9971 36H33.9971C31.7879 36 29.9971 37.7909 29.9971 40V52C29.9971 54.2091 31.7879 56 33.9971 56Z'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.2607 52C17.1267 52 20.2607 48.866 20.2607 45C20.2607 41.134 17.1267 38 13.2607 38C9.39475 38 6.26074 41.134 6.26074 45C6.26074 48.866 9.39475 52 13.2607 52Z'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M24.5326 61.9999C22.8616 57.2081 18.3421 53.9977 13.2673 53.9977C8.19244 53.9977 3.67298 57.2081 2.00195 61.9999'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M50.7305 18C54.5965 18 57.7305 14.866 57.7305 11C57.7305 7.13401 54.5965 4 50.7305 4C46.8645 4 43.7305 7.13401 43.7305 11C43.7305 14.866 46.8645 18 50.7305 18Z'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M61.9975 27.9999C60.3264 23.2081 55.807 19.9977 50.7321 19.9977C45.6573 19.9977 41.1378 23.2081 39.4668 27.9999'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M37.9971 46H53.9971'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M49.9971 42L53.9971 46L49.9971 50'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M25.9971 12H9.99707'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.9971 8L9.99707 12L13.9971 16'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIconLargerViewBox>
  )
}

export { OpenDialogIcon }
