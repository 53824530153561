import * as React from 'react'

import { clsx, makeStyles, MinHeight } from '@guiker/components-core'
import { default as MuiMenuItem, MenuItemProps as MuiMenuItemProps } from '@material-ui/core/MenuItem'

export type MenuItemProps = MuiMenuItemProps & {
  minHeight?: MinHeight
}

const useStyles = makeStyles(
  () => ({
    root: {
      minHeight: ({ minHeight }: MenuItemProps) => minHeight || 60,
    },
  }),
  {
    name: 'MenuItem',
  },
)

export const MenuItem: React.FC<MenuItemProps> = ({ className, minHeight, ...props }) => {
  const classes = useStyles({ minHeight })
  return <MuiMenuItem className={clsx(classes.root, className)} {...(props as any)} />
}
