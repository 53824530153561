import React from 'react'

import { SvgIconProps, SvgIconSmallViewBox } from '../../../SvgIcon'

const DoubleChevronRightIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIconSmallViewBox {...props}>
      <path
        d='M8.00033 1.33325L14.667 7.99992L8.00033 14.6666'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1.33333 1.33325L8 7.99992L1.33333 14.6666'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIconSmallViewBox>
  )
}

export { DoubleChevronRightIcon }
