import React from 'react'

import { clsx } from '@guiker/clsx'
import { isAtMostTablette, ThemeContextProvider, toPx } from '@guiker/components-core'
import { default as Fade } from '@material-ui/core/Fade'
import { default as Slide, SlideProps } from '@material-ui/core/Slide'
import { TransitionProps } from '@material-ui/core/transitions'

import { useMediaQuery } from '../../../hooks'
import { makeStyles, theme } from '../../../styles'
import { MUIModal, MUIModalProps } from '../MUIModal'

export type BaseModalProps = React.PropsWithChildren &
  Omit<MUIModalProps, 'children' | 'onClose'> & {
    onClose?: (event?: React.SyntheticEvent) => void
    fullScreen?: boolean
    minWidth?: string | number
    maxWidth?: string | number
    minHeight?: string | number
    TransitionComponent?: React.ComponentType<TransitionProps & { children?: React.ReactElement<any, any> }>
    classes?: {
      outerWrapper?: string
    }
  }

const useStyles = makeStyles(
  (theme) => ({
    outerWrapper: {
      width: '100%',
      maxWidth: ({ maxWidth }: Pick<BaseModalProps, 'maxWidth'>) => maxWidth,
      minWidth: ({ minWidth }: Pick<BaseModalProps, 'minWidth'>) => minWidth,
      overflow: 'hidden',
      borderRadius: theme.spacing(2),
      outline: 'none !important',
      [isAtMostTablette]: {
        width: '100%',
        alignSelf: 'flex-end',
        borderRadius: `${toPx(theme.spacing(2))} ${toPx(theme.spacing(2))} ${toPx(0)} ${toPx(0)}`,
        margin: 0,
        maxHeight: `calc(100% - ${toPx(theme.spacing(3))}) !important`,
        maxWidth: '100% !important',
      },
    },
    parent: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    root: {
      backgroundColor: theme.palette.common.white,
      outline: 'none !important',
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      minHeight: ({ minHeight }: Pick<BaseModalProps, 'minHeight'>) => minHeight || 'initial',
      padding: 0,
      maxHeight: `calc(100vh - ${toPx(theme.spacing(15))})`,
      overflow: 'auto',
      [isAtMostTablette]: {
        margin: 0,
        maxWidth: '100% !important',
      },
    },
    fullScreen: {
      width: '100% !important',
      height: '100% !important',
      maxHeight: '100% !important',
      maxWidth: '100% !important',
      borderRadius: 0,
    },
  }),
  { name: 'BaseModal' },
)

const SlideUp: React.FC<SlideProps> = (props) => {
  return <Slide {...props} direction='up' />
}

const BaseModalContent: React.FC<BaseModalProps> = ({
  children,
  className,
  classes: classesProps,
  fullScreen,
  maxWidth = 480,
  minWidth = 300,
  open,
  TransitionComponent: TransitionComponentProps,
  ...props
}) => {
  const classes = useStyles({ maxWidth: fullScreen ? '100%' : maxWidth, minWidth })
  const isAtMostTablette = useMediaQuery(theme.breakpoints.down('sm'))

  const TransitionComponent = TransitionComponentProps || (isAtMostTablette ? SlideUp : Fade)

  return (
    <MUIModal open={open} className={clsx(classes.parent, className)} {...props}>
      <TransitionComponent in={open}>
        <div className={clsx(classes.outerWrapper, classesProps?.outerWrapper, { [classes.fullScreen]: fullScreen })}>
          <div className={clsx(classes.root, { [classes.fullScreen]: fullScreen })}>{children}</div>
        </div>
      </TransitionComponent>
    </MUIModal>
  )
}

const BaseModal: React.FC<BaseModalProps> = (props) => {
  return (
    <ThemeContextProvider isDarkMode={false}>
      <BaseModalContent {...props} />
    </ThemeContextProvider>
  )
}

export { BaseModal }
