import React from 'react'

import { SvgIconProps, SvgIconSmallViewBox } from '../../../SvgIcon'

type FilledCheckmarkSmallIconProps = SvgIconProps

const FilledCheckmarkSmallIcon: React.FC<FilledCheckmarkSmallIconProps> = (props) => {
  return (
    <SvgIconSmallViewBox width='17' {...props}>
      <path
        d='M8.5 0C6.91775 0 5.37104 0.469192 4.05544 1.34824C2.73985 2.22729 1.71447 3.47672 1.10897 4.93853C0.503466 6.40034 0.34504 8.00887 0.653721 9.56072C0.962403 11.1126 1.72433 12.538 2.84315 13.6569C3.96197 14.7757 5.38743 15.5376 6.93928 15.8463C8.49113 16.155 10.0997 15.9965 11.5615 15.391C13.0233 14.7855 14.2727 13.7602 15.1518 12.4446C16.0308 11.129 16.5 9.58225 16.5 8C16.4976 5.87901 15.654 3.84559 14.1542 2.34582C12.6544 0.846052 10.621 0.00241984 8.5 0V0ZM12.6143 6.74286L8.07029 11.2846C7.96415 11.3908 7.83811 11.4751 7.69936 11.5326C7.56062 11.5902 7.41191 11.6198 7.26172 11.6198C7.11153 11.6198 6.96281 11.5902 6.82407 11.5326C6.68533 11.4751 6.55929 11.3908 6.45315 11.2846L4.38572 9.22057C4.17142 9.00567 4.05127 8.71444 4.0517 8.41096C4.05213 8.10747 4.1731 7.81658 4.388 7.60229C4.6029 7.38799 4.89413 7.26784 5.19762 7.26827C5.50111 7.2687 5.79199 7.38967 6.00629 7.60457L7.26343 8.86172L10.9937 5.128C11.2079 4.9131 11.4986 4.79207 11.802 4.79153C12.1054 4.791 12.3965 4.911 12.6114 5.12514C12.8263 5.33929 12.9474 5.63003 12.9479 5.93341C12.9484 6.23679 12.8284 6.52796 12.6143 6.74286Z'
        fill='#00B96F'
      />
    </SvgIconSmallViewBox>
  )
}

export { FilledCheckmarkSmallIcon }
