import React from 'react'

import { SvgIconProps, SvgIconSmallViewBox } from '../../../SvgIcon'

const ChevronRightIcon: React.FC<Omit<SvgIconProps, 'small'>> = (props) => {
  return (
    <SvgIconSmallViewBox {...props}>
      <path
        d='M4.5 1L11.5 8L4.5 15'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIconSmallViewBox>
  )
}

export { ChevronRightIcon }
