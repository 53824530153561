import { isMobile } from './media-query-helper'
import { toPx } from './utils'

export type Weight = 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900 | 'normal' | 'bold' | 'bolder' | 'lighter'

export interface FontFamilyVariants {
  bold?: string
  normal?: string
}

export interface FontFamilies {
  header: FontFamilyVariants
  body: FontFamilyVariants
}

export interface FontStyle {
  fontFamily?: React.CSSProperties['fontFamily']
  fontSize?: React.CSSProperties['fontSize']
  lineHeight?: React.CSSProperties['lineHeight']
  letterSpacing?: React.CSSProperties['letterSpacing']
  fontWeight?: Weight
  textTransform?: React.CSSProperties['textTransform']
  color?: React.CSSProperties['color']
}

export type TypographyVariants =
  | 'display1'
  | 'display2'
  | 'display3'
  | 'display4'
  | 'displayBodyBig'
  | 'displayBody'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'quote'
  | 'body'
  | 'bodyBig'
  | 'bodySmall'
  | 'bodySmaller'
  | 'bodySmallest'
  | 'bodyMedium'
  | 'bodyBold'
  | 'caption'
  | 'button'
  | 'buttonLarge'
  | 'tag'
  | 'inline'

export interface ThemeTypography {
  fontFamilies: FontFamilies
  variants: { [key in TypographyVariants]: FontStyle }
}

const fontFamilies = {
  header: {
    bold: 'TTFirsNeue, Helvetica Neue, Helvetica, sans-serif',
    normal: 'TTFirsNeue, Helvetica Neue, Helvetica, sans-serif',
  },
  quote: {
    normal: 'TTFirsNeue, Helvetica Neue, Helvetica, sans-serif',
  },
  body: {
    normal: 'TTFirsNeue, Helvetica Neue, Helvetica, sans-serif',
  },
} as const

const body = {
  fontFamily: fontFamilies.body.normal,
  fontWeight: 400 as Weight,
  fontSize: 16,
  lineHeight: toPx(24),
} as const

const header = {
  fontFamily: fontFamilies.header.bold,
  fontWeight: 500 as Weight,
} as const

export const typography: ThemeTypography = {
  fontFamilies,
  variants: {
    display1: {
      ...header,
      fontSize: 80,
      fontWeight: 'normal',
      lineHeight: toPx(88),
      letterSpacing: -1,
      [isMobile]: {
        fontSize: 64,
        lineHeight: toPx(78),
      },
    },
    display2: {
      ...header,
      fontSize: 64,
      fontWeight: 'normal',
      lineHeight: toPx(78),
      letterSpacing: -2,
      [isMobile]: {
        fontSize: 40,
        lineHeight: toPx(52),
      },
    },
    display3: {
      ...header,
      fontSize: 40,
      fontWeight: 'normal',
      lineHeight: toPx(52),
      letterSpacing: -2,
      [isMobile]: {
        fontSize: 32,
        lineHeight: toPx(48),
      },
    },
    display4: {
      ...header,
      fontSize: 32,
      lineHeight: toPx(48),
      fontWeight: 400,
      [isMobile]: {
        fontSize: 20,
        lineHeight: toPx(26),
      },
    },
    displayBodyBig: {
      ...body,
      fontSize: 20,
      lineHeight: toPx(34),
      [isMobile]: {
        fontSize: 18,
        lineHeight: toPx(32),
      },
    },
    displayBody: {
      ...body,
      lineHeight: toPx(28),
    },
    h1: {
      ...header,
      fontSize: 32,
      lineHeight: toPx(40),
      [isMobile]: {
        fontSize: 32,
        lineHeight: toPx(40),
      },
    },
    h2: {
      ...header,
      fontSize: 28,
      lineHeight: toPx(36),
      [isMobile]: {
        fontSize: 28,
        lineHeight: toPx(36),
      },
    },
    h3: {
      ...header,
      fontSize: 24,
      lineHeight: toPx(32),
      [isMobile]: {
        fontSize: 24,
        lineHeight: toPx(32),
      },
    },
    h4: {
      ...header,
      fontSize: 20,
      lineHeight: toPx(28),
      [isMobile]: {
        fontSize: 20,
        lineHeight: toPx(28),
      },
    },
    h5: {
      ...header,
      fontSize: 18,
      lineHeight: toPx(26),
      [isMobile]: {
        fontSize: 16,
        lineHeight: toPx(24),
      },
    },
    quote: {
      fontFamily: fontFamilies.quote.normal,
      fontSize: 22,
      lineHeight: toPx(36),
      fontWeight: 500,
    },
    bodyBig: {
      ...body,
      fontSize: 20,
      lineHeight: toPx(30),
    },
    body: {
      ...body,
    },
    bodySmall: {
      ...body,
      fontSize: 14,
      lineHeight: toPx(18),
    },
    bodySmaller: {
      ...body,
      fontWeight: 300,
      fontSize: 12,
      lineHeight: toPx(16),
    },
    bodySmallest: {
      ...body,
      fontWeight: 400,
      fontSize: 10,
      lineHeight: toPx(10),
    },
    bodyBold: {
      ...body,
      fontSize: 16,
      fontWeight: 500,
    },
    bodyMedium: {
      ...body,
      fontWeight: 500,
    },
    button: {
      ...body,
      fontWeight: 500,
      fontSize: 14,
      lineHeight: toPx(16),
    },
    buttonLarge: {
      ...body,
      fontWeight: 500,
      fontSize: 16,
      lineHeight: toPx(24),
    },
    caption: {
      ...body,
      fontSize: 14,
      lineHeight: toPx(24),
    },
    tag: {
      ...body,
      fontSize: 12,
      lineHeight: toPx(16),
      fontWeight: 600,
      letterSpacing: 0.5,
      textTransform: 'uppercase',
    },
    inline: {
      ...body,
      fontSize: 'inherit',
      lineHeight: 'inherit',
    },
  },
} as const
