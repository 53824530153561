import { get } from '@guiker/lodash'

import { Control, FieldError, FieldPath, useFormContext, UseFormReturn as RHFUseFormMethods, UseFormSetValue } from '.'

type UseFormMethods<T, C> = Partial<RHFUseFormMethods<T, C>>

type ErrorTypes = FieldError['type'] | 'matches'

export type ErrorMessages = {
  errorMessages?: {
    [key in ErrorTypes]?: string
  }
}

export type FormInput<T extends object, P extends FieldPath<T>, C = unknown> = {
  control: Control<T, C>
  name: P
  inputRef: any
  error: boolean
  errorMessage: string
  errorType: FieldError['type']
  setValue: UseFormSetValue<T>
  watch: RHFUseFormMethods<T>['watch']
}

export type UseFormInput<T extends object, P extends FieldPath<T>, C = unknown> = {
  name: P
  t: (key: string) => string
  namespace?: string
  methods?: UseFormMethods<T, C>
}

export const extractFieldError = (errors: object, name: string): FieldError => get(errors, name)

export const formInput = <T extends object, P extends FieldPath<T>, C = unknown>(
  name: P,
  methods: UseFormMethods<T, C>,
  t: (key: string) => string,
  namespace = 'errors',
): FormInput<T, P, C> => {
  const { control, register, formState, watch, setValue } = methods
  const error = extractFieldError(formState.errors, name)

  let errorType = error?.type

  switch (error?.type) {
    case 'typeError':
    case 'oneOf':
      errorType = 'required'
  }

  return {
    control,
    name,
    inputRef: register,
    errorType,
    error: !!error,
    errorMessage: error && t(`${namespace}:${errorType}`),
    setValue,
    watch,
  }
}

export const useFormInput = <T extends object, P extends FieldPath<T>, C = unknown>({
  name,
  t,
  namespace = 'errors',
  methods,
}: UseFormInput<T, P, C>) => {
  const allMethods = methods || useFormContext()

  return formInput(name, allMethods, t, namespace)
}
