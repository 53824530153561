import React from 'react'

const VisaIcon: React.FC = () => {
  return (
    <svg width='37' height='25' viewBox='0 0 37 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.360397 1.64124C0.360397 0.812808 1.03197 0.141235 1.8604 0.141235H34.8604C35.6888 0.141235 36.3604 0.812808 36.3604 1.64124V22.6412C36.3604 23.4697 35.6888 24.1412 34.8604 24.1412H1.8604C1.03197 24.1412 0.360397 23.4697 0.360397 22.6412V1.64124Z'
        fill='#0E4595'
      />
      <path
        d='M13.6006 16.9242L15.241 7.44046H17.8647L16.2232 16.9242H13.6006ZM25.702 7.6449C25.1822 7.45277 24.3676 7.24658 23.3505 7.24658C20.758 7.24658 18.9318 8.53282 18.9163 10.3763C18.9016 11.7391 20.2201 12.4993 21.2152 12.953C22.2365 13.4178 22.5797 13.7143 22.5749 14.1294C22.5685 14.765 21.7594 15.0555 21.0053 15.0555C19.9552 15.0555 19.3973 14.9117 18.5356 14.5576L18.1975 14.407L17.8293 16.53C18.4421 16.7947 19.5753 17.0241 20.7519 17.0359C23.5099 17.0359 25.3002 15.7644 25.3206 13.7958C25.3304 12.7169 24.6314 11.8959 23.1177 11.219C22.2007 10.7804 21.6391 10.4876 21.645 10.0434C21.645 9.64916 22.1204 9.22758 23.1475 9.22758C24.0055 9.2145 24.6271 9.39884 25.1113 9.59093L25.3464 9.70041L25.702 7.6449ZM32.4536 7.44032H30.4262C29.7981 7.44032 29.3281 7.60925 29.0523 8.22679L25.1558 16.918H27.9109C27.9109 16.918 28.3613 15.7493 28.4632 15.4928C28.7644 15.4928 31.4407 15.4969 31.8234 15.4969C31.9019 15.8289 32.1425 16.918 32.1425 16.918H34.5772L32.4536 7.44003V7.44032ZM29.2369 13.5644C29.4539 13.0179 30.2823 10.9133 30.2823 10.9133C30.2668 10.9385 30.4976 10.3642 30.6301 10.0081L30.8074 10.8258C30.8074 10.8258 31.3099 13.0896 31.4149 13.5644H29.2369ZM11.3733 7.44032L8.80463 13.9078L8.5309 12.5935C8.05271 11.0785 6.56285 9.43701 4.89729 8.61523L7.24597 16.9092L10.0219 16.9061L14.1525 7.44022H11.3733'
        fill='white'
      />
      <path
        d='M6.40783 7.44014H2.17715L2.14366 7.63746C5.43504 8.42247 7.6129 10.3196 8.51714 12.5988L7.59716 8.24061C7.43834 7.64012 6.97776 7.46092 6.40792 7.43994'
        fill='white'
      />
    </svg>
  )
}

export { VisaIcon }
