import React from 'react'

import { SvgIconMediumViewBox, SvgIconProps } from '../../../SvgIcon'

const UserIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIconMediumViewBox {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 11.25C14.8995 11.25 17.25 8.89949 17.25 6C17.25 3.10051 14.8995 0.75 12 0.75C9.10051 0.75 6.75 3.10051 6.75 6C6.75 8.89949 9.10051 11.25 12 11.25Z'
        stroke={'currentColor'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.25 23.25C3.14543 23.25 2.22863 22.3451 2.45252 21.2635C3.36996 16.8313 7.29594 13.5 12 13.5C16.7041 13.5 20.63 16.8313 21.5475 21.2635'
        stroke={'currentColor'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIconMediumViewBox>
  )
}

export { UserIcon }
