import React from 'react'

import { SvgIcon, SvgIconProps } from '../../../SvgIcon'

const FractionalOwnershipIcon: React.FC<Omit<SvgIconProps, 'viewBox'>> = (props) => {
  return (
    <SvgIcon size='medium' viewBox={`0 0 24 25`} {...props}>
      <path
        d='M20.189 10C20.189 10 20.939 15.25 17.189 15.25C13.5 15.25 14.25 19.75 14.25 19.75'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.146 24.25H7.854C6.71787 24.25 5.67923 23.6081 5.171 22.592L3.75 19.75H20.25L18.829 22.592C18.3208 23.6081 17.2821 24.25 16.146 24.25Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.6 16.75H12.75C11.0931 16.75 9.75 15.4069 9.75 13.75V10.75'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.75 13.75H8.25C7.00736 13.75 6 12.7426 6 11.5V10.75'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.75 10.75C13.939 10.7519 15.0169 10.0515 15.4981 8.96427C15.9793 7.87704 15.7731 6.60821 14.9722 5.72939C14.1714 4.85058 12.9271 4.52763 11.8 4.90603C11.1428 2.77555 9.02457 1.44755 6.82057 1.78416C4.61657 2.12077 2.99135 4.0205 3 6.25003C1.75736 6.25003 0.75 7.25739 0.75 8.50003C0.75 9.74267 1.75736 10.75 3 10.75H12.75Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M22.5 8.5C22.5 7.25736 21.4926 6.25 20.25 6.25C19.0074 6.25 18 7.25736 18 8.5C18 8.89782 18.158 9.27936 18.4393 9.56066C18.7206 9.84196 19.1022 10 19.5 10H21C21.8284 10 22.5 9.32843 22.5 8.5Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIcon>
  )
}

export { FractionalOwnershipIcon }
