import React from 'react'

import { SvgIconMediumViewBox, SvgIconProps } from '../../../SvgIcon'

type WasherDryerIconProps = SvgIconProps

const WasherDryerIcon: React.FC<WasherDryerIconProps> = (props) => {
  return (
    <SvgIconMediumViewBox {...props}>
      <path
        d='M0.75 0.75H23.25V23.25H0.75V0.75Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.25 13.5C5.25 15.2902 5.96116 17.0071 7.22703 18.273C8.4929 19.5388 10.2098 20.25 12 20.25C13.7902 20.25 15.5071 19.5388 16.773 18.273C18.0388 17.0071 18.75 15.2902 18.75 13.5C18.75 11.7098 18.0388 9.9929 16.773 8.72703C15.5071 7.46116 13.7902 6.75 12 6.75C10.2098 6.75 8.4929 7.46116 7.22703 8.72703C5.96116 9.9929 5.25 11.7098 5.25 13.5V13.5Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M23.25 6.74998H19.035C18.1251 5.80158 17.0329 5.04695 15.8239 4.53144C14.615 4.01592 13.3143 3.75018 12 3.75018C10.6857 3.75018 9.38502 4.01592 8.17606 4.53144C6.96711 5.04695 5.87487 5.80158 4.965 6.74998H0.75'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20.25 3.375C20.3242 3.375 20.3967 3.39699 20.4583 3.4382C20.52 3.4794 20.5681 3.53797 20.5965 3.60649C20.6248 3.67502 20.6323 3.75042 20.6178 3.82316C20.6033 3.8959 20.5676 3.96272 20.5152 4.01516C20.4627 4.06761 20.3959 4.10333 20.3232 4.11779C20.2504 4.13226 20.175 4.12484 20.1065 4.09645C20.038 4.06807 19.9794 4.02001 19.9382 3.95834C19.897 3.89667 19.875 3.82417 19.875 3.75C19.875 3.65054 19.9145 3.55516 19.9848 3.48483C20.0552 3.41451 20.1505 3.375 20.25 3.375Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.25 13.5C8.25 14.4946 8.64509 15.4484 9.34835 16.1517C10.0516 16.8549 11.0054 17.25 12 17.25C12.9946 17.25 13.9484 16.8549 14.6517 16.1517C15.3549 15.4484 15.75 14.4946 15.75 13.5C15.75 12.5054 15.3549 11.5516 14.6517 10.8483C13.9484 10.1451 12.9946 9.75 12 9.75C11.0054 9.75 10.0516 10.1451 9.34835 10.8483C8.64509 11.5516 8.25 12.5054 8.25 13.5Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIconMediumViewBox>
  )
}

export { WasherDryerIcon }
