import * as React from 'react'

import { clsx } from '@guiker/clsx'
import { makeStyles, Theme } from '@guiker/components-core'

import { Box, BoxProps } from '../..'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      boxShadow: ({ shadow }: CardProps) => (shadow ? theme.shadows[shadow] : 'initial'),
      overflow: 'hidden',
      backgroundColor: theme.palette.background.primary,
    },
  }),
  { name: 'Card' },
)

export type CardProps = BoxProps &
  React.PropsWithChildren & {
    className?: string
    shadow?: keyof Theme['shadows']
    radius?: number
  }

const Card: React.FC<CardProps> = ({ className, children, radius = 1, shadow, ...boxProps }) => {
  const classes = useStyles({ shadow, radius })

  return (
    <Box className={clsx(className, classes.root)} {...boxProps}>
      {children}
    </Box>
  )
}

export { Card }
