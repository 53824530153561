import React, { useDeferredValue, useEffect, useMemo, useState } from 'react'

import { clsx, padding } from '@guiker/components-core'

import { makeStyles } from '../../../styles'
import { Progress } from '../../Data Display/Progress'
import { Collapse, defaultRendered } from '..'
import { isCellRendableSingleOrArray } from './cell-renderable'
import { ColumnConfig } from './column-config'

type CollapsibleTableRowProps<T> = {
  collapsibleComponent?: React.FC<{ item: T }>
  columns: ColumnConfig<T>[]
  item: T
  open: boolean
  className?: string
  condensed?: boolean
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.light,
  },
  td: {
    padding: padding(2, 1),
    wordWrap: 'break-word',
    borderStyle: 'solid',
    borderWidth: 0,
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.grey[10],
  },
  collapse: {
    width: '100%',
  },
  row: {
    width: '100%',
    borderSpacing: 0,
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    visibility: 'hidden',
    height: 0,
    transition: theme.transitions.create(['visibility', 'height']),
  },
  rowOpen: {
    visibility: 'visible',
    height: 'auto',
    transition: theme.transitions.create(['visibility', 'height']),
  },
}))

const ColumnBasedCollapsibleTableRow = <T extends object>({
  condensed,
  columns,
  item,
  className,
}: Omit<CollapsibleTableRowProps<T>, 'open' | 'collapsibleComponent'>) => {
  const classes = useStyles()
  const collapsedItems = columns.map((column) => column.collapsibleValues?.(item) ?? [])
  const count = collapsedItems.reduce((acc, items) => (acc < items.length ? items.length : acc), 0)
  const collapsedRows = Array(count).fill(0)

  const collapsibleTableRows = useMemo(() => {
    return collapsedRows.map((_, rowIndex) => {
      const collapsibleRowKey = `TableRow--collapsible-column-${rowIndex}`
      return (
        <tr key={collapsibleRowKey} className={clsx(classes.root, className)}>
          {columns.map((column, columnIndex) => {
            const { name, renderCollapsibleValue, collapsibleOptions, headerOptions } = column
            const { verticalAlign, colSpan } = { ...collapsibleOptions }
            const key = `${collapsibleRowKey}--${name}`
            const collapsibleValues = collapsedItems[columnIndex]
            return (
              <td key={key} valign={verticalAlign} colSpan={colSpan ?? 1} className={clsx(classes.td, className)}>
                {collapsibleValues && renderCollapsibleValue && (
                  <TableDataRender
                    renderCollapsibleValue={renderCollapsibleValue}
                    collapsibleValues={collapsibleValues}
                    headerOptions={headerOptions}
                    item={item}
                    rowIndex={rowIndex}
                    condensed={condensed}
                  />
                )}
              </td>
            )
          })}
        </tr>
      )
    })
  }, [columns])

  return <>{collapsibleTableRows}</>
}

export const TableDataRender = <T extends object>(
  props: {
    item: T
    rowIndex: number
    condensed: boolean
    collapsibleValues: object[]
  } & Pick<ColumnConfig<T>, 'renderCollapsibleValue' | 'headerOptions'>,
) => {
  const { renderCollapsibleValue, collapsibleValues, item, rowIndex, headerOptions, condensed } = props
  const [mounted, setMounted] = useState(false)
  const hasMounted = useDeferredValue(mounted)
  const renderedValue = renderCollapsibleValue({
    ...collapsibleValues[rowIndex],
    index: rowIndex,
    parent: item,
  })

  const tdContent = useMemo(() => {
    if (hasMounted) {
      return isCellRendableSingleOrArray(renderedValue)
        ? defaultRendered(renderedValue, condensed ? 'smaller' : 'small', headerOptions?.textAlign)
        : renderedValue
    }
    return <Progress statusColor={10} value={100} />
  }, [hasMounted, renderedValue])

  useEffect(() => {
    setMounted(true)
  }, [])
  return <>{tdContent}</>
}

export const CollapsibleTableRow = <T extends object>({
  condensed,
  columns,
  item,
  open,
  className,
  collapsibleComponent: CollapsibleComponent,
}: CollapsibleTableRowProps<T>) => {
  const classes = useStyles()

  const collapsibleTableFooter = useMemo(() => {
    if (!columns.some((c) => c.renderCollapsibleFooter)) return

    return columns.map(({ name, renderCollapsibleFooter, collapsibleOptions }) => (
      <td key={`${name}-footer`} className={classes.td} colSpan={collapsibleOptions?.colSpan ?? 1}>
        {renderCollapsibleFooter && renderCollapsibleFooter(item)}
      </td>
    ))
  }, [columns])

  if (CollapsibleComponent) {
    return (
      <Collapse in={open} className={classes.collapse} unmountOnExit>
        <CollapsibleComponent item={item} />
      </Collapse>
    )
  }

  return (
    <tbody className={clsx(classes.row, className, { [classes.rowOpen]: open })}>
      {open && (
        <ColumnBasedCollapsibleTableRow condensed={condensed} className={className} columns={columns} item={item} />
      )}
      {open && !!collapsibleTableFooter && <tr>{collapsibleTableFooter}</tr>}
    </tbody>
  )
}
