import React from 'react'

import { makeStyles, theme, toPx } from '@guiker/components-core'
import { DateTime } from '@guiker/date'
import { useDateFormatter } from '@guiker/i18n'
import { ChevronRightIcon, DoubleChevronRightIcon, DropdownIcon } from '@guiker/icons'

import { Flex, FormHelperText, IconButton, Typography } from '../../../components'

export type Pager = {
  format: string
  onNext: (date?: DateTime) => void
  onJumpNext: () => void
  onPrev: (date?: DateTime) => void
  onJumpPrev: () => void
}

type ConsensedDatePickerTextProps = {
  name: string
  value: DateTime
  format?: string
  disabled: boolean
  errorMessage: string
  onClick: (e: any) => void
  pager?: Pager
}

const useStyle = makeStyles(
  {
    datepickerContainer: {
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.grey[60],
      },
    },
    datepickerTag: {
      width: 'max-content',
      color: 'inherit',
      fontSize: 14,
      fontWeight: 500,
      lineHeight: toPx(14),
      textTransform: 'uppercase',
    },
    datepickerIcon: {
      color: 'inherit',
    },
    clickable: {
      cursor: 'pointer',
    },
    leftIcon: {
      transform: 'rotate(180deg)',
    },
  },
  { name: 'CondensedDatePickerText' },
)

export const CondensedDatePickerText: React.FC<ConsensedDatePickerTextProps> = ({
  name,
  value,
  format,
  disabled,
  errorMessage,
  onClick,
  pager,
  ...props
}) => {
  const classes = useStyle()
  const { formatWithRelativeDate } = useDateFormatter()
  const errorHelperTextId = errorMessage ? `datepicker-error-text` : undefined

  return (
    <>
      <input
        type='hidden'
        data-testid={name}
        name={name}
        value={value && (value as DateTime)?.toFormat(format)}
        defaultValue={value && (value as DateTime)?.toFormat(format)}
        {...props}
      />
      <Flex gap={2} alignItems='center'>
        {pager && (
          <>
            <IconButton onClick={() => pager.onJumpPrev()}>
              {<DoubleChevronRightIcon size='smaller' className={classes.leftIcon} />}
            </IconButton>
            <IconButton onClick={() => pager.onPrev(value)}>
              {<ChevronRightIcon size='smaller' className={classes.leftIcon} />}
            </IconButton>
          </>
        )}
        <Flex
          flexDirection='row'
          flexShrink={1}
          gap={1}
          alignItems='center'
          className={classes.datepickerContainer}
          onClick={disabled ? null : onClick}
        >
          <Typography className={classes.datepickerTag} mb={0}>
            {pager ? value.toFormat(pager.format) : formatWithRelativeDate(value)}
          </Typography>
          <DropdownIcon className={classes.datepickerIcon} />
        </Flex>
        {pager && (
          <>
            <IconButton onClick={() => pager.onNext(value)}>{<ChevronRightIcon size='smaller' />}</IconButton>
            <IconButton onClick={() => pager.onJumpNext()}>{<DoubleChevronRightIcon size='smaller' />}</IconButton>
          </>
        )}
      </Flex>
      {errorMessage && (
        <FormHelperText color='alert' role='alert' aria-label={errorHelperTextId} id={errorHelperTextId}>
          {errorMessage}
        </FormHelperText>
      )}
    </>
  )
}
