import { useEffect, useState } from 'react'

type Options = {
  delay: number
  enabled?: boolean
}

const useTimeInterval = (fn: () => void, options: Options) => {
  const { delay, enabled = true } = options
  const [intervalState, setIntervalState] = useState({
    interval: undefined,
  })

  const handleFnUpdate = (updatedFn: () => void) => {
    if (intervalState.interval) {
      clearInterval(intervalState.interval)
    }
    setIntervalState({ interval: setInterval(updatedFn, delay) })
  }

  useEffect(() => {
    if (enabled) {
      setIntervalState({ interval: setInterval(fn, delay) })
    } else {
      clearInterval(intervalState.interval)
    }

    return () => clearInterval(intervalState.interval)
  }, [enabled])

  return { setTimeInterval: handleFnUpdate }
}

export { useTimeInterval }
