import { makeStyles, theme } from '@guiker/components-core'

export const marginKeyProps = ['m', 'mt', 'mr', 'mb', 'ml', 'mx', 'my'] as const
export const paddingKeyProps = ['p', 'pt', 'pr', 'pb', 'pl', 'px', 'py'] as const

type MarginKeyProps = (typeof marginKeyProps)[number]
type PaddingKeyProps = (typeof paddingKeyProps)[number]

export type SpacingProps = {
  [key in MarginKeyProps]?: number
} & {
  [key in PaddingKeyProps]?: number
}

export type UtilityStyleProps = SpacingProps & {
  maxWidth?: string | number
  minWidth?: string | number
}

const getSpacingIfExists = (value: number) => {
  if (value == null) {
    return undefined
  }

  return theme.spacing(value)
}

const combine = (a: number, b: number) => {
  if (a != null) return a
  return b
}

export const useUtilityStyle = makeStyles(
  {
    maxWidth: {
      width: '100%',
      maxWidth: ({ maxWidth }: UtilityStyleProps) => maxWidth || 'none',
      minWidth: ({ minWidth }: UtilityStyleProps) => minWidth || 'none',
    },
    spacing: {
      margin: ({ m }: UtilityStyleProps) => getSpacingIfExists(m),
      marginBottom: ({ mb, my }: UtilityStyleProps) => combine(getSpacingIfExists(mb), getSpacingIfExists(my)),
      marginTop: ({ mt, my }: UtilityStyleProps) => combine(getSpacingIfExists(mt), getSpacingIfExists(my)),
      marginRight: ({ mr, mx }: UtilityStyleProps) => combine(getSpacingIfExists(mr), getSpacingIfExists(mx)),
      marginLeft: ({ ml, mx }: UtilityStyleProps) => combine(getSpacingIfExists(ml), getSpacingIfExists(mx)),
      padding: ({ p }: UtilityStyleProps) => getSpacingIfExists(p),
      paddingBottom: ({ pb, py }: UtilityStyleProps) => combine(getSpacingIfExists(pb), getSpacingIfExists(py)),
      paddingTop: ({ pt, py }: UtilityStyleProps) => combine(getSpacingIfExists(pt), getSpacingIfExists(py)),
      paddingRight: ({ pr, px }: UtilityStyleProps) => combine(getSpacingIfExists(pr), getSpacingIfExists(px)),
      paddingLeft: ({ pl, px }: UtilityStyleProps) => combine(getSpacingIfExists(pl), getSpacingIfExists(px)),
    },
  },
  { name: 'Utility' },
)

const useEmpty = makeStyles({})
export const useEmptyStyles = <T extends () => any>(fn: T) => {
  const style = useEmpty()
  return style as ReturnType<typeof fn>
}
