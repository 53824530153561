import React from 'react'

import { AnyColor, makeStyles } from '../../../styles'
import { Progress } from '../Progress'
import { CARD_MEDIA_IMAGE_HEIGHT } from './CardMedia'

export type CardProgressProps = {
  value: number
  color: AnyColor
  offset?: number
}

const useStyles = makeStyles(
  () => ({
    root: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: ({ offset }: { offset: number }) => offset - 12,
    },
  }),
  { name: 'CardProgress' },
)

export const CardProgress: React.FC<CardProgressProps> = ({ value, color, offset = CARD_MEDIA_IMAGE_HEIGHT }) => {
  const classes = useStyles({ offset })

  return <Progress statusColor={color} value={value * 100} className={classes.root} />
}
