import React from 'react'

import { SvgIconMediumViewBox, SvgIconProps } from '../../../SvgIcon'

type UnitsIconProps = SvgIconProps

const UnitsIcon: React.FC<UnitsIconProps> = (props) => {
  return (
    <SvgIconMediumViewBox {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.005 17.15H6.755C6.34079 17.15 6.005 17.4858 6.005 17.9V22.4C6.005 22.8142 6.34079 23.15 6.755 23.15H11.255C11.6692 23.15 12.005 22.8142 12.005 22.4V17.15Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.255 17.15H12.005V22.4C12.005 22.8142 12.3408 23.15 12.755 23.15H17.255C17.6692 23.15 18.005 22.8142 18.005 22.4V17.9C18.005 17.4858 17.6692 17.15 17.255 17.15Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.255 11.15H9.755C9.34079 11.15 9.005 11.4858 9.005 11.9V17.15H15.005V11.9C15.005 11.4858 14.6692 11.15 14.255 11.15Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M23.255 23.15V7.55C23.2552 6.99589 22.9499 6.4868 22.461 6.226L12.711 1.026C12.27 0.790976 11.741 0.790976 11.3 1.026L1.55 6.226C1.06072 6.48653 0.755005 6.99567 0.755005 7.55V23.15'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIconMediumViewBox>
  )
}

export { UnitsIcon }
