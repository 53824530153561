import React from 'react'

import { SvgIconProps, SvgIconSmallViewBox } from '../../../SvgIcon'

export type ExpandIconProps = SvgIconProps

const ExpandIcon: React.FC<ExpandIconProps> = (props) => {
  return (
    <SvgIconSmallViewBox {...props}>
      <path
        d='M0.857143 0H5.42857C5.6559 0 5.87392 0.0903058 6.03466 0.251051C6.19541 0.411797 6.28571 0.629814 6.28571 0.857143C6.28571 1.08447 6.19541 1.30249 6.03466 1.46323C5.87392 1.62398 5.6559 1.71429 5.42857 1.71429H2.92571L6.03429 4.824C6.18569 4.98649 6.26812 5.2014 6.2642 5.42345C6.26028 5.64551 6.17033 5.85738 6.01328 6.01442C5.85624 6.17147 5.64437 6.26142 5.42231 6.26534C5.20025 6.26926 4.98534 6.18683 4.82286 6.03543L1.71429 2.92686V5.42857C1.71429 5.6559 1.62398 5.87392 1.46323 6.03466C1.30249 6.19541 1.08447 6.28571 0.857143 6.28571C0.629814 6.28571 0.411797 6.19541 0.251051 6.03466C0.0903058 5.87392 0 5.6559 0 5.42857L0 0.857143C0 0.629814 0.0903058 0.411797 0.251051 0.251051C0.411797 0.0903058 0.629814 0 0.857143 0V0Z'
        fill='currentColor'
      />
      <path
        d='M9.96627 9.96693C10.0458 9.88731 10.1402 9.82415 10.2441 9.78105C10.3481 9.73796 10.4595 9.71578 10.572 9.71578C10.6845 9.71578 10.7959 9.73796 10.8998 9.78105C11.0038 9.82415 11.0982 9.88731 11.1777 9.96693L14.2863 13.0744V10.5715C14.2863 10.3442 14.3766 10.1262 14.5373 9.96541C14.6981 9.80466 14.9161 9.71436 15.1434 9.71436C15.3707 9.71436 15.5888 9.80466 15.7495 9.96541C15.9103 10.1262 16.0006 10.3442 16.0006 10.5715V15.1429C16.0006 15.3703 15.9103 15.5883 15.7495 15.749C15.5888 15.9098 15.3707 16.0001 15.1434 16.0001H10.572C10.3447 16.0001 10.1266 15.9098 9.9659 15.749C9.80515 15.5883 9.71484 15.3703 9.71484 15.1429C9.71484 14.9156 9.80515 14.6976 9.9659 14.5368C10.1266 14.3761 10.3447 14.2858 10.572 14.2858H13.0737L9.96627 11.1784C9.88666 11.0989 9.82349 11.0044 9.7804 10.9005C9.73731 10.7966 9.71512 10.6852 9.71512 10.5726C9.71512 10.4601 9.73731 10.3487 9.7804 10.2448C9.82349 10.1408 9.88666 10.0464 9.96627 9.96693Z'
        fill='currentColor'
      />
      <path
        d='M10.537 4.25255C10.6155 4.16833 10.7101 4.10079 10.8153 4.05394C10.9204 4.00709 11.0339 3.9819 11.149 3.97987C11.2641 3.97784 11.3784 3.99901 11.4852 4.04212C11.5919 4.08523 11.6888 4.14939 11.7702 4.23079C11.8516 4.31218 11.9158 4.40913 11.9589 4.51586C12.002 4.62259 12.0232 4.73691 12.0211 4.852C12.0191 4.96709 11.9939 5.08059 11.9471 5.18573C11.9002 5.29087 11.8327 5.3855 11.7485 5.46397L5.46275 11.7497C5.38428 11.8339 5.28965 11.9014 5.18451 11.9483C5.07937 11.9951 4.96587 12.0203 4.85078 12.0224C4.73569 12.0244 4.62137 12.0032 4.51464 11.9601C4.40791 11.917 4.31096 11.8528 4.22957 11.7714C4.14817 11.6901 4.08401 11.5931 4.0409 11.4864C3.99779 11.3796 3.97662 11.2653 3.97865 11.1502C3.98068 11.0351 4.00587 10.9216 4.05272 10.8165C4.09957 10.7114 4.16711 10.6167 4.25133 10.5383L10.537 4.25255Z'
        fill='currentColor'
      />
    </SvgIconSmallViewBox>
  )
}

export { ExpandIcon }
