import React from 'react'

import { useLayoutContext } from '../../../hooks'
import { H2, TypographyProps } from '../../Data Display'
import { PageSection, PageSectionProps } from '../PageSection/PageSection'

type FormSection1Props = Omit<PageSectionProps, 'TitleComponent' | 'titleMb' | 'spacing'> & {
  titleMb?: TypographyProps['mb']
}

const FormSection1: React.FC<FormSection1Props> = (props) => {
  const { isMobile } = useLayoutContext()
  return (
    <PageSection
      TitleComponent={H2}
      hasDivider={true}
      titleMb={props.titleMb ?? 8}
      spacing={isMobile ? 5 : 10}
      {...props}
    />
  )
}

const FormSectionGroup = FormSection1

export { FormSectionGroup, FormSection1 }
