import React from 'react'

import { ColumnGridLayout, ColumnGridLayoutProps } from '../ColumnGridLayout'

type OneColumnGridLayoutProps = React.PropsWithChildren & Omit<ColumnGridLayoutProps, 'xs' | 'md'>

export const OneColumnGridLayout: React.FC<OneColumnGridLayoutProps> = ({ children, ...props }) => {
  return (
    <ColumnGridLayout
      xs={{ gridTemplateColumns: 1, gridColumn: { span: 1 } }}
      md={{ gridTemplateColumns: 2, gridColumn: { span: 1 } }}
      {...props}
    >
      {children}
    </ColumnGridLayout>
  )
}
