import React from 'react'

import { SvgIconMediumViewBox, SvgIconProps } from '../../../SvgIcon'

type BedroomIconProps = SvgIconProps

const BedroomIcon: React.FC<BedroomIconProps> = (props) => {
  return (
    <SvgIconMediumViewBox {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.5 2.25C1.5 1.42157 2.17157 0.75 3 0.75H21C21.8284 0.75 22.5 1.42157 22.5 2.25V21.75C22.5 22.5784 21.8284 23.25 21 23.25H3C2.17157 23.25 1.5 22.5784 1.5 21.75V2.25Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.5 5.25C4.5 4.42157 5.17157 3.75 6 3.75H9C9.82843 3.75 10.5 4.42157 10.5 5.25V6.75C10.5 7.57843 9.82843 8.25 9 8.25H6C5.17157 8.25 4.5 7.57843 4.5 6.75V5.25Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.5 5.25C13.5 4.42157 14.1716 3.75 15 3.75H18C18.8284 3.75 19.5 4.42157 19.5 5.25V6.75C19.5 7.57843 18.8284 8.25 18 8.25H15C14.1716 8.25 13.5 7.57843 13.5 6.75V5.25Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M1.5 8.25H22.5' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M1.5 12.75H22.5' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    </SvgIconMediumViewBox>
  )
}

export { BedroomIcon }
