import React from 'react'

import { Autocomplete as CLAutocomplete } from '@guiker/components-library'
import { FieldPath } from '@guiker/react-hook-form'

import { useApiFormContext } from '../Form'
import { RHFAutocomplete, RHFAutocompleteProps } from './RHFAutocomplete'

export type AutocompleteProps<T extends object, P extends FieldPath<T>> = RHFAutocompleteProps<T, P>

export const Autocomplete = <T extends object = any, P extends FieldPath<T> = any>(props: AutocompleteProps<T, P>) => {
  const { readOnly: contextReadOnly } = useApiFormContext({ shouldThrowIfUndefined: false })
  if (props.readOnly || !props.name) {
    return <CLAutocomplete {...props} />
  } else {
    return <RHFAutocomplete {...props} readOnly={contextReadOnly} />
  }
}
