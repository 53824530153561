import React from 'react'

import { clsx } from '@guiker/clsx'
import { makeStyles, toPx } from '@guiker/components-core'

import { Flex } from '../../Layout'
import { PSmall } from '../Typography'

type AdornmentPosition = 'start' | 'end'
type AlignItems = 'flex-start' | 'flex-end' | 'center' | 'baseline'

interface LabelProps {
  adornment?: {
    node: React.ReactNode
    position?: AdornmentPosition
    className?: string
    align?: AlignItems
  }
  className?: string
  condensed?: boolean
  disabled?: boolean
  description?: string
  gap?: number
  htmlFor?: string
  readOnly?: boolean
  required?: boolean
  text: string | React.ReactNode
  textClassName?: string
}

const useStyles = makeStyles(
  (theme) => ({
    description: {
      color: theme.palette.grey[50],
      marginBottom: 0,
    },
    disabled: {
      opacity: 0.5,
    },
    label: {
      color: theme.palette.common.black,
      fontFamily: theme.typography.variants.body.fontFamily,
      fontSize: theme.typography.variants.body.fontSize,
      lineHeight: toPx(20),
      fontWeight: 500,
    },
    condensedLabel: {
      ...theme.typography.variants.bodySmall,
      fontWeight: 400,
    },
    readOnly: {
      fontSize: theme.typography.variants.bodySmall.fontSize,
      fontWeight: 400,
    },
  }),
  { name: 'Label' },
)

const Label: React.FC<LabelProps> = ({
  adornment,
  className,
  condensed,
  description,
  disabled,
  gap = 1,
  readOnly,
  required,
  text,
  textClassName,
}) => {
  const { node: adornmentNode, className: adornmentClassName, position = 'start', align = 'center' } = adornment || {}
  const classes = useStyles({ position, disabled })
  const formattedLabel = text ? (required ? `${text} *` : text) : undefined

  const textElement =
    typeof text === 'string' ? (
      <span
        className={clsx(className, classes.label, textClassName, {
          [classes.disabled]: disabled,
          [classes.readOnly]: readOnly,
          [classes.condensedLabel]: condensed,
        })}
      >
        {formattedLabel}
      </span>
    ) : (
      text
    )

  const textContent = description ? (
    <Flex flexDirection='column'>
      {textElement}
      <PSmall className={classes.description}>{description}</PSmall>
    </Flex>
  ) : (
    textElement
  )

  if (!adornmentNode) return <>{textContent}</>

  return (
    <Flex
      alignItems={align}
      flexWrap='nowrap'
      justifyContent={position === 'start' ? 'flex-start' : 'flex-end'}
      flexDirection={position === 'start' ? 'row' : 'row-reverse'}
      gap={gap}
      className={className}
    >
      {adornmentNode && adornmentClassName ? (
        <div className={clsx(adornmentClassName)}>{adornmentNode}</div>
      ) : (
        adornmentNode
      )}
      {textContent}
    </Flex>
  )
}

export { Label, LabelProps }
