import { appendErrors, ResolverOptions } from 'react-hook-form'
import Yup from 'yup'

import { defaultsDeep, difference } from '@guiker/lodash'
import { toNestError } from '@hookform/resolvers'
import { yupResolver as baseYupResolver } from '@hookform/resolvers/yup'

type InnerError = {
  type: string
  path?: string
  message?: string
  types?: {
    [key: string]: string | boolean
  }
}

type AggregatedError = {
  [key: string]: InnerError
}

const parseErrorSchema = (
  error: Yup.ValidationError,
  validateAllFieldCriteria: boolean,
  prefix: string,
): AggregatedError => {
  return Array.isArray(error.inner)
    ? error.inner.reduce((previous: AggregatedError, { path: basePath, message, type }) => {
        const path = prefix ? `${prefix}${basePath}` : basePath
        return {
          ...previous,
          ...(path
            ? previous[path] && validateAllFieldCriteria
              ? ({
                  [path]: appendErrors(path, validateAllFieldCriteria, previous, type, message),
                } as AggregatedError)
              : ({
                  [path]: previous[path] || {
                    message,
                    type,
                    ...(validateAllFieldCriteria
                      ? {
                          types: { [type]: message || true },
                        }
                      : {}),
                  },
                } as AggregatedError)
            : {}),
        }
      }, {})
    : ({
        [prefix ? `${prefix}${error.path}` : error.path]: { message: error.message, type: error.type },
      } as AggregatedError)
}

export const yupResolver =
  <TFieldValues, Context extends object>(
    schema: Yup.Schema<TFieldValues>,
    options?: Omit<Yup.ValidateOptions, 'context'> & { defaultValues?: Record<string, unknown>; context?: Context },
    prefix?: string,
  ) =>
  async (values: TFieldValues, context: Context, resolverOptions: ResolverOptions<TFieldValues>) => {
    const { defaultValues, ...otherOptions } = {
      defaultValues: {},
      abortEarly: options?.abortEarly ?? false,
      ...(options || {}),
    }

    try {
      return await baseYupResolver(schema, otherOptions)(
        defaultsDeep(values, defaultValues),
        otherOptions.context,
        resolverOptions,
      )
    } catch (e) {
      return {
        values: {},
        errors: toNestError(parseErrorSchema(e, resolverOptions.criteriaMode === 'all', prefix), resolverOptions),
      }
    }
  }

export const pickSchema = <TFieldValues>(schema: Yup.Schema<TFieldValues>, picks: string[]) => {
  return (schema as any).pick(picks) as Yup.Schema<TFieldValues>
}

export const omitSchema = <TFieldValues>(schema: Yup.Schema<TFieldValues>, omits: string[]) => {
  const _schema = schema as any
  const attributes = difference(Object.keys(_schema.fields), omits)
  return _schema.pick(attributes) as Yup.Schema<TFieldValues>
}
