import React, { ReactNode, useCallback } from 'react'

import { clsx, makeStyles } from '@guiker/components-library'
import { useFormContext, UseFormProps } from '@guiker/react-hook-form'
import { MutationFunction, useMutation } from '@guiker/react-query'
import { renderChildren } from '@guiker/react-utils'

import { ContextConsumerNode, MutationOptions, OnSubmit } from '../../types'
import { useApiFormContext } from './ApiFormContext'

export type BaseFormProps<TFormValues, TResult, TContext> = {
  className?: string
  onSubmit?: OnSubmit<TResult, TFormValues>
  formOptions?: UseFormProps<TFormValues, TContext>
  apiOptions?: MutationOptions<TResult, TFormValues> & {
    skipMutation?: boolean
    onSubmitWithoutChange?: () => void
  }
  children?: ReactNode | ContextConsumerNode<TResult>
}

const useStyles = makeStyles(
  {
    root: {
      width: '100%',
      height: 'inherit',
    },
  },
  { name: 'BaseForm' },
)

export const BaseForm = <TResult, TContext, TFormValues extends Record<string, any> = Record<string, any>>({
  apiOptions,
  children,
  className,
  formOptions,
  onSubmit,
}: BaseFormProps<TFormValues, TResult, TContext>) => {
  const classes = useStyles()
  const { formState: formContextState } = useFormContext()
  const { isSubmitting, beforeValidation, generateFormSubmitHandler } = useApiFormContext()

  const { mutate, ...mutationProps } = useMutation(onSubmit as MutationFunction<TResult, TFormValues>, apiOptions)
  const handleFormSubmit = useCallback(generateFormSubmitHandler(mutate), [
    formContextState.isDirty,
    beforeValidation,
    isSubmitting,
  ])

  const formState = ['onTouched', 'onChange', 'onBlur'].includes(formOptions?.mode)
    ? formContextState
    : {
        isDirty: formContextState.isDirty,
        dirtyFields: formContextState.dirtyFields,
        isSubmitted: formContextState.isSubmitted,
        isSubmitSuccessful: formContextState.isSubmitSuccessful,
        submitCount: formContextState.submitCount,
        touchedFields: formContextState.touchedFields,
        isSubmitting: formContextState.isSubmitting,
        isValidating: formContextState.isValidating,
        errors: formContextState.errors,
        isValid: Object.keys(formContextState.errors).length > 0,
      }

  return (
    <form onSubmit={handleFormSubmit} noValidate className={clsx(classes.root, className)}>
      {renderChildren(children, {
        ...mutationProps,
        mutate,
        formState: {
          ...formState,
          isSubmitting: isSubmitting || formState.isSubmitting || mutationProps?.isLoading,
        },
      })}
    </form>
  )
}
