import React from 'react'

import { SvgIconLargeViewBox, SvgIconProps } from '../../../SvgIcon'

type LargeCalendarCheckProps = SvgIconProps

const LargeCalendarCheck: React.FC<LargeCalendarCheckProps> = (props) => {
  return (
    <SvgIconLargeViewBox size='large' {...props}>
      <path
        d='M36.25 6.25H3.75C2.36929 6.25 1.25 7.36929 1.25 8.75V36.25C1.25 37.6307 2.36929 38.75 3.75 38.75H36.25C37.6307 38.75 38.75 37.6307 38.75 36.25V8.75C38.75 7.36929 37.6307 6.25 36.25 6.25Z'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinejoin='round'
      />
      <path d='M1.25 16.25H38.75' stroke='currentColor' strokeWidth='2' strokeLinejoin='round' />
      <path d='M11.25 10V1.25' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M28.75 10V1.25' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M26.25 22.5L18.75 32.5L13.75 27.5'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIconLargeViewBox>
  )
}

export { LargeCalendarCheck }
