import React from 'react'

import { SvgIconLargeViewBox, SvgIconProps } from '../../../SvgIcon'

type NeutralFindIconProps = SvgIconProps

const NeutralFindIcon: React.FC<NeutralFindIconProps> = (props) => {
  return (
    <SvgIconLargeViewBox {...props}>
      <path
        d='M17.5 34.4367C26.4746 34.4367 33.75 27.1447 33.75 18.1496C33.75 9.15452 26.4746 1.86255 17.5 1.86255C8.52537 1.86255 1.25 9.15452 1.25 18.1496C1.25 27.1447 8.52537 34.4367 17.5 34.4367Z'
        stroke='currentColor'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M28.9902 29.6658L38.7502 39.448'
        stroke='currentColor'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.5 13.1382C19.5711 13.1382 21.25 11.4554 21.25 9.37965C21.25 7.30386 19.5711 5.62109 17.5 5.62109C15.4289 5.62109 13.75 7.30386 13.75 9.37965C13.75 11.4554 15.4289 13.1382 17.5 13.1382Z'
        stroke='currentColor'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20.2217 29.3417L21.25 23.1609H23.75V21.9081C23.75 20.2467 23.0915 18.6533 21.9194 17.4786C20.7473 16.3038 19.1576 15.6438 17.5 15.6438C15.8424 15.6438 14.2527 16.3038 13.0806 17.4786C11.9085 18.6533 11.25 20.2467 11.25 21.9081V23.1609H13.75L14.7783 29.3417'
        stroke='currentColor'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIconLargeViewBox>
  )
}

export { NeutralFindIcon }
