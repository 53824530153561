import { math } from '@guiker/lodash'
import { Currency, money } from '@guiker/money'

export type Formatter = {
  toDisplay: (stateValue: any) => number
  toActual: (displayValue: any) => number
}

export const numberFormatter = {
  currency: (currency: Currency): Formatter => ({
    toDisplay: (value: string) => money.fromAmount(Number(value ?? undefined), currency).monetized,
    toActual: (value: string | number) => money.toAmount(Number(value ?? undefined), currency).amount,
  }),
  percentage: (decimalPrecision: number): Formatter => ({
    toDisplay: (value: string) => math.decimal.round(Number(value ?? undefined) * 100, decimalPrecision),
    toActual: (value: string) => math.decimal.round(Number(value ?? undefined) / 100, decimalPrecision),
  }),
}
