import React from 'react'

import { useLayoutContext } from '../../../hooks'
import { H4 } from '../../Data Display'
import { PageSection, PageSectionProps } from './PageSection'

type PageSection4Props = Omit<PageSectionProps, 'TitleComponent' | 'titleMb' | 'spacing'>

export const PageSection4: React.FC<PageSection4Props> = (props) => {
  const { isMobile } = useLayoutContext()
  return (
    <PageSection
      TitleComponent={H4}
      hasDivider={false}
      titleMb={isMobile ? 2 : 3}
      spacing={isMobile ? 3 : 4}
      {...props}
    />
  )
}
