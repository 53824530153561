import React, { useEffect, useState } from 'react'

import { clsx } from '@guiker/clsx'
import { makeStyles, toPx } from '@guiker/components-core'
import { isNumber } from '@guiker/lodash'
import {
  ContentState,
  convertToRaw,
  draftToHtml,
  Editor,
  EditorProps,
  EditorState,
  htmlToDraft,
} from '@guiker/react-draftjs'
import { debounce } from '@guiker/react-throttle-debounce'

import { Input } from '../Input'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      ...theme.typography.variants.body,
    },
    wrapper: {},
    toolbar: {
      padding: 0,
      margin: 0,
      border: 'none',
    },
    editor: {
      maxHeight: ({ maxHeight }: { maxHeight: string | number }) => maxHeight,
      backgroundColor: theme.palette.grey[5],
      padding: theme.spacing(2),
      paddingTop: ({ label }: { label: string }) => (label ? theme.spacing(4) : theme.spacing(2)),
      minHeight: ({ toolbarHidden, minHeight }: { toolbarHidden: boolean; minHeight: number }) =>
        toolbarHidden ? 'auto' : minHeight || 300,
      '&:hover': {
        background: ({ readOnly }: { readOnly: string }) => (!readOnly ? theme.palette.grey[10] : 'inherit'),
      },
      '& > *': {
        maxHeight: ({ maxHeight }: { maxHeight: string | number }) =>
          `calc(${isNumber(maxHeight) ? toPx(maxHeight) : maxHeight} - ${theme.spacing(4)}px)`,
        overflow: `scroll`,
      },
    },
    editorReadOnly: {
      paddingBottom: 0,
      paddingTop: '0px !important',
      paddingLeft: 0,
      paddingRight: 0,
      backgroundColor: 'transparent',
      color: `${theme.palette.text.primary.main} !important`,
      '&:hover': {
        backgroundColor: theme.palette.common.white,
      },
    },
    editorDisabled: {
      color: 'rgba(0, 0, 0, 0.38)',
      backgroundColor: theme.palette.grey[5],
      boxShadow: 'none',
    },
  }),
  {
    name: 'RichTextEditor',
  },
)

type RichTextEditorProps = Omit<EditorProps, 'toolbar' | 'onChange'> & {
  name?: string
  label?: string
  type?: React.InputHTMLAttributes<unknown>['type']
  className?: string
  disabled?: boolean
  defaultValue?: string
  error?: boolean
  errorMessage?: string
  hideLongText?: boolean
  maxHeight?: number | string
  minHeight?: number
  maxLengthBeforeHiding?: number
  onChange?: (htmlContent: string) => void
}

const EditorComponent = Editor as unknown as React.FC<EditorProps>

export const getContentBlocks = (defaultValue: string, hideLongText: boolean, maxLength = 400) => {
  const contentBlock = htmlToDraft(defaultValue || '')
  let isHidable = false

  let contentBlocks = contentBlock.contentBlocks

  if (contentBlocks && hideLongText) {
    let contentLength = 0
    let sliceItemCount = contentBlock.contentBlocks.length

    for (let i = 0; i < contentBlock.contentBlocks.length; i++) {
      contentLength += contentBlock.contentBlocks[i].getLength()

      if (contentLength >= maxLength) {
        isHidable = true

        if (i === 0) {
          contentBlocks = htmlToDraft(`${defaultValue.slice(0, maxLength)}...`).contentBlocks
        }

        sliceItemCount = i + 1
        break
      }
    }

    contentBlocks = contentBlocks.splice(0, sliceItemCount)
  }

  return { isHidable, contentBlocks }
}

// https://jpuri.github.io/react-draft-wysiwyg/#/docs
const RichTextEditor: React.FC<RichTextEditorProps> = ({
  type,
  label,
  className,
  readOnly = false,
  disabled = false,
  hideLongText = false,
  defaultValue,
  wrapperClassName,
  editorClassName,
  toolbarClassName,
  onChange,
  error,
  errorMessage,
  maxHeight = 300,
  maxLengthBeforeHiding = 400,
  ...otherProps
}) => {
  const toolbarHidden = readOnly || disabled
  const classes = useStyles({ label, maxHeight, readOnly, toolbarHidden: true })
  const [editorState, setEditorState] = useState<EditorState>()

  useEffect(() => {
    const { contentBlocks } = getContentBlocks(defaultValue || '', hideLongText, maxLengthBeforeHiding)

    if (!contentBlocks) return

    const contentState = ContentState.createFromBlockArray(contentBlocks)
    setEditorState(EditorState.createWithContent(contentState))
  }, [defaultValue, hideLongText])

  const toolbarOptions: EditorProps['toolbar'] = {
    options: ['inline', 'blockType', 'list', 'textAlign', 'history'],
    inline: { options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace'] },
    list: { inDropdown: true },
    textAlign: { inDropdown: true },
    link: { inDropdown: true },
  }

  const debouncedOnChange = debounce(300, (htmlContent?: string) => {
    onChange && onChange(htmlContent)
  })

  const handleEditorStateChange = (state: EditorState) => {
    const currentState = convertToRaw(state.getCurrentContent())
    debouncedOnChange(draftToHtml(currentState))
    setEditorState(state)
  }

  return (
    <Input
      hidden={type === 'hidden'}
      error={error}
      className={clsx(classes.root, className)}
      errorMessage={errorMessage}
      disabled={disabled}
      readOnly={readOnly}
    >
      <EditorComponent
        editorState={editorState}
        wrapperClassName={clsx(classes.wrapper, wrapperClassName)}
        toolbarClassName={clsx(classes.toolbar, toolbarClassName)}
        editorClassName={clsx(classes.editor, editorClassName, {
          [classes.editorReadOnly]: readOnly,
          [classes.editorDisabled]: disabled,
        })}
        toolbar={toolbarOptions}
        toolbarHidden={true} // until we find css include fix
        readOnly={toolbarHidden}
        onEditorStateChange={handleEditorStateChange}
        {...otherProps}
      />
    </Input>
  )
}

export { RichTextEditor, RichTextEditorProps }
