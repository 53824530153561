import React from 'react'

import { AnyColor, theme } from '@guiker/components-core'

const HouseSignalIcon: React.FC<{ color?: AnyColor }> = ({ color: colorKey }) => {
  const color = theme.palette.getColor(colorKey)
  return (
    <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8.86682 15C11.8566 12.2516 15.7672 10.7224 19.8283 10.7134C23.8893 10.7045 27.8066 12.2165 30.8085 14.9516'
        stroke={color || 'currentColor'}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5 6.47503C9.26031 3.0574 14.5682 1.21233 20.0298 1.25058C25.4914 1.28883 30.773 3.20806 34.985 6.68503'
        stroke={color || 'currentColor'}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.365 26.5801V36.2467C12.365 36.9098 12.6284 37.5457 13.0972 38.0145C13.5661 38.4834 14.2019 38.7467 14.865 38.7467H24.865C25.528 38.7467 26.1639 38.4834 26.6328 38.0145C27.1016 37.5457 27.365 36.9098 27.365 36.2467V26.5801'
        stroke={color || 'currentColor'}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.86499 28.7667L17.395 22.1784C18.0786 21.5804 18.9559 21.2507 19.8642 21.2507C20.7724 21.2507 21.6498 21.5804 22.3333 22.1784L29.865 28.7667'
        stroke={color || 'currentColor'}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M22.365 38.75H17.365V33.75C17.365 33.087 17.6284 32.4511 18.0972 31.9822C18.5661 31.5134 19.2019 31.25 19.865 31.25C20.528 31.25 21.1639 31.5134 21.6328 31.9822C22.1016 32.4511 22.365 33.087 22.365 33.75V38.75Z'
        stroke={color || 'currentColor'}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export { HouseSignalIcon }
