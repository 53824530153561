import React from 'react'

export type AuthenticationMethod = 'user' | 'token'

type RouteConfigBuildOption<Path extends string = string, Component extends React.FC = React.FC<any>> = {
  exact?: boolean
  isOutlet?: boolean
  path: Path
  Component?: Component
  routes?: { [key: string]: RouteConfig }
}

export type RouteConfig<
  Path extends string = string,
  Component extends React.FC = React.FC<any>,
> = RouteConfigBuildOption<Path, Component> & {
  authenticationMethod?: AuthenticationMethod
  requireAuthentication?: boolean
  requireAccountActivation?: boolean
}

const build = <T extends RouteConfigBuildOption>(
  options: RouteConfigBuildOption & { requireAuthentication?: boolean; requireAccountActivation?: boolean },
): T =>
  ({
    ...options,
    requireAccountActivation: options.requireAccountActivation ?? false,
  } as unknown as T)

export const routeConfigBuilder = {
  build,
  authGuarded: <AM extends AuthenticationMethod>(authenticationMethod: AM) => ({
    build: <RC extends RouteConfigBuildOption>(
      options: RC & { requireAccountActivation?: boolean },
    ): RC & {
      requireAuthentication: true
      requireAccountActivation: boolean
      authenticationMethod: AM
    } => ({
      ...options,
      authenticationMethod,
      requireAuthentication: true,
      requireAccountActivation: options.requireAccountActivation ?? false,
    }),
  }),

  public: () => ({
    build: <RC extends RouteConfigBuildOption>(
      options: RC & { requireAccountActivation?: boolean },
    ): RC & {
      requireAuthentication: false
      requireAccountActivation: false
    } => ({
      ...options,
      requireAuthentication: false,
      requireAccountActivation: false,
    }),
  }),
}
