import React from 'react'

import { isMobile, makeStyles, padding, theme, toPx } from '@guiker/components-core'

import { usePageLayoutContext } from '../../../hooks'
import { Box } from '../..'

export type ButtonContainerProps = React.PropsWithChildren & {
  hasDrawerNav?: boolean
  isDrawerMinimized?: boolean
}

const useStyles = makeStyles({
  root: ({ hasDrawerNav, isDrawerMinimized }: { hasDrawerNav: boolean; isDrawerMinimized: boolean }) => ({
    display: 'flex',
    justifyContent: 'center',
    position: 'fixed',
    bottom: 0,
    height: theme.dimensions.buttonContainer.height,
    right: 0,
    width:
      hasDrawerNav && isDrawerMinimized !== undefined
        ? isDrawerMinimized
          ? `calc(100% - ${theme.dimensions.drawerNavigation.width.minimized}px)`
          : `calc(100% - ${theme.dimensions.drawerNavigation.width.maximized}px)`
        : '100%',
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows.medium,
    zIndex: theme.zIndexs.takeOver,
    padding: padding(2, 10),
    [isMobile]: {
      padding: padding(2, 2),
    },
  }),
  container: {
    width: '100%',
    display: 'flex',
    zIndex: theme.zIndexs.takeOver,
    justifyContent: 'flex-end',
    columnGap: toPx(theme.spacing(3)),
    [isMobile]: {
      columnGap: toPx(theme.spacing(1)),
    },
  },
})

const ButtonContainer: React.FC<ButtonContainerProps> = ({ children, hasDrawerNav = false, isDrawerMinimized }) => {
  const classes = useStyles({ hasDrawerNav, isDrawerMinimized })
  const { maxWidth } = usePageLayoutContext()

  return (
    <Box className={classes.root}>
      <Box maxWidth={maxWidth} className={classes.container}>
        {children}
      </Box>
    </Box>
  )
}

export { ButtonContainer }
