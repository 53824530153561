import React, { ReactNode, useEffect } from 'react'

import { Box } from '@guiker/components-library'
import { DataLayerEvent, useDataTrackingContext } from '@guiker/data-tracking-context'
import { FormProvider, SubmitHandler, useForm, UseFormProps } from '@guiker/react-hook-form'

export type FormProps<TFormValues> = {
  onSubmit?: SubmitHandler<TFormValues>
  options?: UseFormProps<TFormValues>
  children?: ReactNode
  width?: number
  formName: string
}

const Form = <TFormValues extends Record<string, any> = Record<string, any>>({
  onSubmit,
  children,
  options = {},
  width,
  formName,
  ...props
}: FormProps<TFormValues>) => {
  const { reValidateMode = 'onBlur', ...formOptions } = options
  const methods = useForm<TFormValues>({ ...formOptions, reValidateMode })
  const {
    formState: { submitCount, errors },
  } = methods
  const { sendEvent } = useDataTrackingContext()

  const sendFormEvent = (event: DataLayerEvent) => {
    if (formName) {
      sendEvent(event)
    }
  }

  useEffect(() => {
    sendFormEvent({ event: 'formStart', formName })
  }, [formName])

  const handleFormSubmit = () =>
    methods.handleSubmit((data, e) => {
      sendFormEvent({ event: 'formSubmitAttempt', formName })
      return onSubmit(data, e)
    })

  useEffect(() => {
    if (submitCount >= 1 && errors && Object.keys(errors).length > 0) {
      const formErrorDetails = JSON.stringify(methods.formState.errors)
      sendFormEvent({ event: 'formError', formName, formErrorDetails })
    }
  }, [submitCount, errors, formName])

  return (
    <Box width={width}>
      <FormProvider {...methods} {...props}>
        <form onSubmit={handleFormSubmit()} noValidate>
          {children}
        </form>
      </FormProvider>
    </Box>
  )
}

export { Form }
