import React from 'react'

import { clsx } from '@guiker/clsx'

import { useLayoutContext } from '../../../hooks'
import { isMobile, makeStyles } from '../../../styles'
import { BoxProps } from '../Box'
import { Flex } from '../Flex'

export type BaseAppBarProps = BoxProps &
  React.PropsWithChildren & {
    position?: string
    darkMode?: boolean
    hasBorder?: boolean
    asTakeover?: boolean
  }

type StyleProps = BaseAppBarProps & {
  isMobile?: boolean
  isModalOpen?: boolean
}

const XPADDING = {
  mobile: 2,
  desktop: 8,
}

const useStyles = makeStyles(
  (theme) => ({
    root: ({ hasBorder, position, asTakeover, isModalOpen }: StyleProps) => {
      const commonStyle = {
        position,
        top: 0,
        zIndex: isModalOpen ? theme.zIndexs.appBar - 1 : theme.zIndexs.appBar,
        width: '100%',
        borderBottomWidth: 1,
        borderBottomStyle: hasBorder ? ('solid' as const) : ('none' as const),
        borderBottomColor: theme.palette.grey[30],
        backgroundColor: theme.palette.secondary.main,
      }

      return {
        ...commonStyle,
        left: asTakeover ? 0 : undefined,
        paddingLeft: theme.spacing(XPADDING.desktop),
        paddingRight: theme.spacing(XPADDING.desktop),
        height: theme.dimensions.appBar.height.desktop,
        maxHeight: theme.dimensions.appBar.height.desktop,
        [isMobile]: {
          height: theme.dimensions.appBar.height.mobile,
          maxHeight: theme.dimensions.appBar.height.mobile,
          paddingLeft: theme.spacing(XPADDING.mobile),
          paddingRight: theme.spacing(XPADDING.mobile),
        },
      }
    },
  }),
  { name: 'BaseAppBar' },
)

const BaseAppBar: React.FC<BaseAppBarProps> = ({
  hasBorder = false,
  position = 'fixed',
  asTakeover = false,
  children,
  className,
  ...props
}) => {
  const { isModalOpen } = useLayoutContext()
  const classes = useStyles({ hasBorder, position, asTakeover, isModalOpen })

  return (
    <Flex fullWidth alignItems='center' justifyContent='center' className={clsx(className, classes.root)} {...props}>
      {children}
    </Flex>
  )
}

export { BaseAppBar }
