import React from 'react'

import { Link as BaseLink, LinkProps as BaseLinkProps, useLocation } from 'react-router-dom'

import { clsx } from '@guiker/clsx'
import { makeStyles } from '@guiker/components-core'
import { useTranslation } from '@guiker/i18n'

import { useRouter } from './use-router'

export type LinkProps = React.PropsWithChildren &
  Omit<BaseLinkProps, 'to'> & {
    disabled?: boolean
    to?: string
    onClick?: (event?: React.SyntheticEvent) => void
    prefixWithLocale?: boolean
    target?: '_top' | '_blank' | '_self' | '_parent'
    isExternalLink?: boolean
    isRelative?: boolean
    fullWidth?: boolean
  }

const useStyles = makeStyles(
  {
    root: {
      width: ({ fullWidth }: { fullWidth: boolean }) => (fullWidth ? '100%' : 'revert'),
      color: 'inherit',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'none',
      },
    },
    pointer: {
      cursor: 'pointer',
    },
  },
  { name: 'Link' },
)

const Link: React.FC<LinkProps> = ({
  children,
  className,
  disabled,
  fullWidth,
  isExternalLink = false,
  isRelative,
  prefixWithLocale,
  to,
  target,
  ...props
}) => {
  const classes = useStyles({ fullWidth })
  const { i18n } = useTranslation()
  const { prefixWithLocale: routerPrefixWithLocale } = useRouter() || {}
  const location = useLocation()
  const allClassNames = clsx(className, classes.root)

  if (disabled || !to) {
    return (
      <span className={clsx(allClassNames, { [classes.pointer]: !disabled })} {...props}>
        {children}
      </span>
    )
  }

  if (isExternalLink) {
    return (
      <a className={allClassNames} href={to} target={target} {...props}>
        {children}
      </a>
    )
  } else {
    const currentLanguageTag = i18n.language?.split('-')[0]
    const shouldPrefix = (prefixWithLocale ?? routerPrefixWithLocale) && currentLanguageTag && to.startsWith('/')
    const url = shouldPrefix && !to.startsWith(`/${currentLanguageTag}/`) ? `/${currentLanguageTag}${to}` : to
    let path = url !== '/' ? url.replace(/\/$/, '') : url
    path = isRelative ? `./${path}` : path

    return (
      <BaseLink className={allClassNames} to={path} state={{ ...props.state, from: location?.pathname }} {...props}>
        {children}
      </BaseLink>
    )
  }
}

export { Link }
