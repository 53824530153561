import React from 'react'

const NewIcon: React.FC = () => {
  return (
    <svg width='37' height='27' viewBox='0 0 37 27' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.30159 1.97436C0.30159 1.14594 0.973163 0.474365 1.80159 0.474365H34.8016C35.63 0.474365 36.3016 1.14594 36.3016 1.97437V25.3744C36.3016 26.2028 35.63 26.8744 34.8016 26.8744H1.80159C0.973164 26.8744 0.30159 26.2028 0.30159 25.3744V1.97436Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.05159 6.92432C7.05159 6.09589 7.72316 5.42432 8.55159 5.42432H28.0516C28.88 5.42432 29.5516 6.09589 29.5516 6.92432V20.4243C29.5516 21.2527 28.88 21.9243 28.0516 21.9243H8.55159C7.72316 21.9243 7.05159 21.2527 7.05159 20.4243V6.92432Z'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.05159 9.92432H29.5516'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.5516 14.4243H19.8016'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.5516 17.4243H16.8016'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export { NewIcon }
