import React, { createContext, useCallback, useContext, useState } from 'react'

import { isEqual, isUndefined, merge, omitBy } from '@guiker/lodash'

import { LocalStorageKey } from './entity'
import { persistancy } from './persistance'

type Context<T extends object = object> = {
  data: T
  setData: (data: T) => void
  unsetData: () => void
  spoof: {
    isOpen: boolean
    setIsOpen: (value: boolean) => void
  }
}

const PersistancyContext = createContext(undefined as Context)

export const PersistancyContextProvider = <T extends object>({
  children,
  sessionKey,
}: React.PropsWithChildren & { sessionKey: LocalStorageKey }) => {
  const { getItem, persistItem, clear } = persistancy(sessionKey)
  const persisted = getItem<string>()
  const parsed = persisted ? JSON.parse(persisted) : undefined
  const [data, _setData] = useState<T>(parsed)
  const [isOpen, setIsOpen] = useState(false)

  const setData = useCallback(
    (value: T) => {
      if (!value) {
        unsetData()
      } else {
        const mergedData = merge({}, data, value)
        const hasUpdated = !isEqual(data, omitBy(mergedData, isUndefined))
        hasUpdated && persistItem(mergedData as any)
        _setData(hasUpdated ? mergedData : data)
      }
    },
    [data],
  )

  const unsetData = () => {
    _setData(undefined)
    clear()
  }

  const value = { data, setData, unsetData, spoof: { isOpen, setIsOpen } }

  return <PersistancyContext.Provider value={value}>{children}</PersistancyContext.Provider>
}

export const usePersistancyContext = <T extends object>() => {
  const context = useContext<Context<T>>(PersistancyContext as React.Context<Context<T>>)

  if (context === undefined) {
    throw new Error('usePersistancyContext can only be used inside PersistancyContextProvider')
  }

  return context
}
