import React from 'react'

import { SvgIconProps, SvgIconSmallViewBox } from '../../../SvgIcon'

export const CheckOutlineIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIconSmallViewBox {...props}>
      <circle cx='8' cy='8' r='7.25' stroke='currentColor' stroke-width='1.5' />
      <path d='M4.5 8L7 10.5L11.5 6.5' stroke='currentColor' stroke-width='2' stroke-linecap='round' />
    </SvgIconSmallViewBox>
  )
}
