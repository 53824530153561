import React from 'react'

import { useFormContext } from '@guiker/react-hook-form'

type HiddenInputProps = {
  defaultValue: string | object | number | readonly string[]
  name: string
}

export const HiddenInput: React.FC<HiddenInputProps> = ({ defaultValue, name }) => {
  const methods = useFormContext()

  return <input type='hidden' {...methods.register(name, { value: defaultValue })} />
}
