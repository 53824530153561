import React from 'react'

const ShareIcon: React.FC = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M15 3.75L12 0.75L9 3.75'
        stroke='#00B96F'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M23.25 23.25L23.25 17.25C23.25 16.4216 22.5784 15.75 21.75 15.75L2.25 15.75C1.42157 15.75 0.750001 16.4216 0.750001 17.25L0.75 23.25'
        stroke='#00B96F'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M12 15.75L12 0.75' stroke='#00B96F' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}

export { ShareIcon }
