import React from 'react'

const UniversityIcon: React.FC = () => {
  return (
    <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0)'>
        <path
          d='M5.00011 19.2876V20.2876C4.99194 20.4787 5.04454 20.6675 5.15036 20.8269C5.25619 20.9862 5.40979 21.108 5.58911 21.1746L12.5001 23.2876V14.4546L6.02711 12.0816C5.89893 12.0467 5.76418 12.0436 5.63454 12.0726C5.5049 12.1015 5.38427 12.1616 5.28311 12.2477C5.18195 12.3338 5.1033 12.4433 5.05399 12.5666C5.00467 12.69 4.98618 12.8235 5.00011 12.9556V14.7996'
          stroke='#252E54'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M20.013 19.1366V20.2936C20.022 20.4847 19.9699 20.6738 19.8641 20.8333C19.7584 20.9928 19.6046 21.1144 19.425 21.1806L12.5 23.2876V14.4546L18.986 12.0816C19.1142 12.0467 19.2489 12.0436 19.3786 12.0726C19.5082 12.1015 19.6288 12.1616 19.73 12.2477C19.8312 12.3338 19.9098 12.4433 19.9591 12.5666C20.0084 12.69 20.0269 12.8235 20.013 12.9556V14.9436'
          stroke='#252E54'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M6.48702 18.3946C6.48702 18.8946 5.92402 19.2946 5.23602 19.2876C3.85802 19.2816 2.74502 18.4716 2.75002 17.4776V16.5776C2.75002 15.5846 3.87502 14.7846 5.25002 14.7906C5.93902 14.7906 6.49502 15.1986 6.49302 15.6906L6.48702 18.3946Z'
          stroke='#252E54'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M17.757 18.3946C17.757 18.8946 18.32 19.2946 19.01 19.2876C20.39 19.2816 21.505 18.4716 21.5 17.4776V16.5776C21.5 15.5846 20.373 14.7846 18.993 14.7906C18.304 14.7906 17.746 15.1986 17.749 15.6906L17.757 18.3946Z'
          stroke='#252E54'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M8.38721 4.9126C8.38721 5.4543 8.4939 5.9907 8.7012 6.49117C8.9085 6.99164 9.21235 7.44637 9.59539 7.82941C9.97843 8.21246 10.4332 8.5163 10.9336 8.7236C11.4341 8.9309 11.9705 9.0376 12.5122 9.0376C13.0539 9.0376 13.5903 8.9309 14.0908 8.7236C14.5912 8.5163 15.046 8.21246 15.429 7.82941C15.8121 7.44637 16.1159 6.99164 16.3232 6.49117C16.5305 5.9907 16.6372 5.4543 16.6372 4.9126C16.6372 4.37089 16.5305 3.8345 16.3232 3.33403C16.1159 2.83356 15.8121 2.37882 15.429 1.99578C15.046 1.61274 14.5912 1.3089 14.0908 1.10159C13.5903 0.894294 13.0539 0.787598 12.5122 0.787598C11.9705 0.787598 11.4341 0.894294 10.9336 1.10159C10.4332 1.3089 9.97843 1.61274 9.59539 1.99578C9.21235 2.37882 8.9085 2.83356 8.7012 3.33403C8.4939 3.8345 8.38721 4.37089 8.38721 4.9126V4.9126Z'
          stroke='#252E54'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0'>
          <rect width='24' height='24' fill='white' transform='translate(0 0.0366211)' />
        </clipPath>
      </defs>
    </svg>
  )
}

export { UniversityIcon }
