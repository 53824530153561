import * as React from 'react'

import { clsx } from '@guiker/clsx'
import { isNumber, isString } from '@guiker/lodash'

import { makeStyles } from '../../../styles'
import { Typography } from '../Typography'

export type BadgeProps = React.PropsWithChildren & { className?: string }

const BADGE_SIZE = 22

const sizePerStringLength = (value: number) => {
  const length = `${value}`.length
  return length <= 1 ? 10 : length <= 2 ? 8 : 6
}

const useStyles = makeStyles(
  (theme) => ({
    root: {
      backgroundColor: theme.palette.status.alert.main,
      borderColor: theme.palette.secondary.main,
      borderWidth: 4,
      borderStyle: 'solid',
      borderRadius: BADGE_SIZE / 2,
      height: BADGE_SIZE,
      width: BADGE_SIZE,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    text: {
      color: theme.palette.common.white,
      fontSize: ({ number }: { number: number }) => sizePerStringLength(number),
      lineHeight: ({ number }: { number: number }) => `${sizePerStringLength(number)}px`,
      textAlign: 'center',
      margin: 0,
    },
  }),
  { name: 'Badge' },
)

const Badge: React.FC<BadgeProps> = ({ className, children }) => {
  const classes = useStyles({ number: children })

  return (
    <div className={clsx(className, classes.root)}>
      {isString(children) || isNumber(children) ? (
        <Typography className={classes.text} variant='bodySmallest'>
          {isNumber(children) && children > 99 ? '99+' : children}
        </Typography>
      ) : (
        children
      )}
    </div>
  )
}

export { Badge }
