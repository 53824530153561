import React from 'react'

import { clsx } from '@guiker/clsx'
import { AnyColor, lighten, makeStyles } from '@guiker/components-core'
import {
  default as MuiLinearProgress,
  LinearProgressProps as MuiLinearProgressProps,
} from '@material-ui/core/LinearProgress'

import { TagTypography } from '../../Data Display'

export type ProgressProps = Omit<MuiLinearProgressProps, 'color'> & {
  statusColor?: AnyColor
  description?: string
  withBackgroundColor?: boolean
}

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    progressRoot: {
      height: theme.dimensions.progressBar.height,
      backgroundColor: ({ statusColor, withBackgroundColor }: ProgressProps) =>
        withBackgroundColor ? lighten(theme.palette.getColor(statusColor, 'main'), 0.8) : theme.palette.grey[5],
    },
    bar: {
      backgroundColor: ({ statusColor }: { statusColor: AnyColor }) => theme.palette.getColor(statusColor, 'main'),
    },
    descriptionContainer: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(1),
    },
    descriptionText: {
      color: ({ statusColor }: { statusColor: AnyColor }) => theme.palette.getColor(statusColor, 'main'),
    },
  }),
  { name: 'Progress' },
)

const Progress: React.FC<ProgressProps> = ({
  className,
  statusColor = 'success',
  value = 0,
  description,
  withBackgroundColor = true,
  ...other
}) => {
  const classes = useStyles({ withBackgroundColor, statusColor })

  return (
    <div className={clsx(classes.root, className)}>
      <MuiLinearProgress
        variant='determinate'
        value={value}
        classes={{
          root: classes.progressRoot,
          bar: classes.bar,
        }}
        {...other}
      />
      {description && (
        <div className={clsx(classes.descriptionContainer)}>
          <TagTypography className={classes.descriptionText}>{description}</TagTypography>
        </div>
      )}
    </div>
  )
}

export { Progress }
