import React from 'react'

import { SvgIconLargerViewBox, SvgIconProps } from '../../../SvgIcon'

const HealthBenefitsIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIconLargerViewBox {...props}>
      <path
        d='M53.9971 10.0107C39.453 7.32912 24.5412 7.32912 9.99707 10.0107'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M62 14C62 15.0609 61.5786 16.0783 60.8284 16.8284C60.0783 17.5786 59.0609 18 58 18C56.9391 18 55.9217 17.5786 55.1716 16.8284C54.4214 16.0783 54 15.0609 54 14V6C54 4.93913 54.4214 3.92172 55.1716 3.17157C55.9217 2.42143 56.9391 2 58 2C59.0609 2 60.0783 2.42143 60.8284 3.17157C61.5786 3.92172 62 4.93913 62 6V14Z'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10 14C10 15.0609 9.57857 16.0783 8.82843 16.8284C8.07828 17.5786 7.06087 18 6 18C4.93913 18 3.92172 17.5786 3.17157 16.8284C2.42143 16.0783 2 15.0609 2 14V6C2 4.93913 2.42143 3.92172 3.17157 3.17157C3.92172 2.42143 4.93913 2 6 2C7.06087 2 8.07828 2.42143 8.82843 3.17157C9.57857 3.92172 10 4.93913 10 6V14Z'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M47.1704 17.1733C46.76 17.5846 46.4441 18.0803 46.2447 18.626C46.0453 19.1717 45.9672 19.7543 46.0158 20.3333C46.0158 20.4293 46.6638 30.0507 41.4824 35.6907C40.6057 36.6142 39.5888 37.3935 38.4691 38H25.5331C24.4292 37.4015 23.4237 36.637 22.5518 35.7333C17.3331 30.0747 17.9731 20.504 17.9891 20.32C18.0354 19.7424 17.9557 19.1616 17.7554 18.6178C17.5551 18.074 17.239 17.5802 16.8291 17.1707'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.2324 25.9706C10.7581 31.555 12.9929 36.8414 16.6324 41.1093C18.6784 43.3308 21.196 45.0657 24.0004 46.1866V58C24.0004 59.0608 24.4218 60.0783 25.172 60.8284C25.9221 61.5785 26.9396 62 28.0004 62C29.0613 62 30.0787 61.5785 30.8289 60.8284C31.579 60.0783 32.0004 59.0608 32.0004 58C32.0004 59.0608 32.4218 60.0783 33.172 60.8284C33.9221 61.5785 34.9396 62 36.0004 62C37.0613 62 38.0787 61.5785 38.8289 60.8284C39.579 60.0783 40.0004 59.0608 40.0004 58V46.1866C42.8009 45.0678 45.3157 43.3366 47.3604 41.12C50.9999 36.8521 53.2348 31.5656 53.7604 25.9813'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M25 25C25 26.8565 25.7375 28.637 27.0503 29.9497C28.363 31.2625 30.1435 32 32 32C33.8565 32 35.637 31.2625 36.9497 29.9497C38.2625 28.637 39 26.8565 39 25C39 23.1435 38.2625 21.363 36.9497 20.0503C35.637 18.7375 33.8565 18 32 18C30.1435 18 28.363 18.7375 27.0503 20.0503C25.7375 21.363 25 23.1435 25 25V25Z'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M24 50H40' stroke='currentColor' strokeWidth='3' strokeLinecap='round' strokeLinejoin='round' />
    </SvgIconLargerViewBox>
  )
}

export { HealthBenefitsIcon }
