export const dimensions = {
  pageLayout: {
    maxWidth: {
      mobile: '100%',
      desktop: 1440,
    },
    landingPagesMaxWidth: {
      mobile: '100%',
      desktop: 1440,
    },
    padding: {
      mobile: {
        top: 4,
        bottom: 6,
        right: 2,
        left: 2,
      },
      desktop: {
        top: 8,
        bottom: 8,
        right: 8,
        left: 8,
      },
    },
  },
  narrowPageLayout: {
    maxWidth: {
      mobile: '100%',
      desktop: 900,
    },
  },
  drawerNavigation: {
    width: {
      maximized: 280,
      minimized: 56,
    },
  },
  topTabNavigation: {
    height: {
      desktop: 67,
      mobile: 52,
    },
  },
  contentNavigation: {
    width: {
      desktop: 385,
      mobile: '100%',
    },
  },
  progressBar: {
    height: 12,
  },
  appBar: {
    height: {
      mobile: 56,
      desktop: 64,
    },
  },
  buttonContainer: {
    height: 74,
  },
}
