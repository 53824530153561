import React, { useState } from 'react'

import { clsx } from '@guiker/clsx'
import { makeStyles, toPx } from '@guiker/components-core'
import { DropdownIcon } from '@guiker/icons'

import { Collapse, Typography } from '../../Data Display'
import { TextButton } from '../../Inputs'
import { Flex } from '../../Layout'
import { CheckedIcon, FailedIcon, LinkWrapper } from './accessories'

type ProgressMarkerProps = {
  order: number
  isCompleted?: boolean
  isFailed?: boolean
  isFirst?: boolean
  isLast?: boolean
  isNext?: boolean
}

export type ProgressTrackerItemProps = {
  collapsed?: boolean
  collapsible?: React.ReactNode
  content: React.ReactNode
  disabled?: boolean
  link?: string
  isExternalLink?: boolean
  marker: ProgressMarkerProps
}

const INDEX_INDICATOR_SIZE = 20

const useStyles = makeStyles(
  (theme) => ({
    indexContainer: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      width: theme.spacing(4),
      minWidth: theme.spacing(4),
      minHeight: theme.spacing(8),
      marginRight: theme.spacing(2),
    },
    hidden: {
      visibility: 'hidden',
    },
    bottomLine: {
      marginTop: toPx(3),
    },
    line: {
      width: 1,
      height: '100%',
      backgroundColor: theme.palette.grey[60],
    },
    topLine: {
      minHeight: 18,
      maxHeight: 18,
      marginBottom: toPx(3),
    },
    index: {
      alignItems: 'center',
      display: 'flex',
      borderColor: theme.palette.primary.main,
      borderStyle: 'solid',
      borderWidth: 1,
      marginBottom: 0,
      justifyContent: 'center',
      minWidth: INDEX_INDICATOR_SIZE,
      minHeight: INDEX_INDICATOR_SIZE,
      maxWidth: INDEX_INDICATOR_SIZE,
      maxHeight: INDEX_INDICATOR_SIZE,
      overflow: 'hidden',
      fontSize: 11,
    },
    indexTypography: {
      marginBottom: 0,
      fontVariantNumeric: 'tabular-nums',
      color: 'inherit',
      fontSize: 'inherit',
      fontWeight: theme.typography.variants.bodyBold.fontWeight,
    },
    nextActiveIndex: {
      boxShadow: '2px 2px 4px 2px rgba(0, 0, 0, 0.18)',
    },
    button: {
      marginTop: theme.spacing(1),
      justifyContent: 'flex-start',
      textAlign: 'left',
    },
    stepIcon: {
      minWidth: 13,
      zIndex: 20,
    },
    icon: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      height: '100%',
      width: '100%',
      padding: 2,
    },
    isCompleted: {
      color: 'white',
      backgroundColor: theme.palette.primary.main,
    },
    toggleIcon: {
      width: 12,
      transition: theme.transitions.create(['transform'], {
        duration: theme.transitions.duration.short,
      }),
    },
    iconCollapsed: {
      transform: 'rotate(0)',
    },
    iconExpanded: {
      transform: 'rotate(180deg)',
    },
    checkedIcon: {},
    failedIcon: {
      color: theme.palette.status.alert.main,
      borderStyle: 'none',
    },
  }),
  { name: 'TrackerItem' },
)

export const ProgressMarker: React.FC<{ marker: ProgressMarkerProps }> = ({ marker }) => {
  const classes = useStyles()

  return (
    <div
      className={clsx(
        classes.index,
        marker.isNext && classes.nextActiveIndex,
        marker.isCompleted && classes.isCompleted,
        marker.isFailed && classes.failedIcon,
      )}
    >
      {marker.isCompleted ? (
        <CheckedIcon />
      ) : marker.isFailed ? (
        <FailedIcon />
      ) : (
        <Typography className={classes.indexTypography}>{marker.order}</Typography>
      )}
    </div>
  )
}

export const ProgressTrackerItem: React.FC<ProgressTrackerItemProps> = ({
  content,
  collapsed: collaspedProps = true,
  collapsible,
  disabled: isDisabled = false,
  link,
  isExternalLink,
  marker,
}) => {
  const classes = useStyles()
  const [collapsed, setCollapsed] = useState(collaspedProps)

  const toggleCollapse = () => {
    setCollapsed((curr) => !curr)
  }

  return (
    <Flex>
      <div className={classes.indexContainer}>
        <div className={clsx(classes.line, classes.topLine, marker.isFirst && classes.hidden)} />
        <LinkWrapper link={link} isExternalLink={isExternalLink} isDisabled={isDisabled}>
          <ProgressMarker marker={marker} />
        </LinkWrapper>
        <div className={clsx(classes.line, classes.bottomLine, marker.isLast && classes.hidden)} />
      </div>
      <Flex fullWidth flexDirection='column' mt={2}>
        <Flex fullWidth justifyContent='space-between' alignItems='center'>
          <LinkWrapper link={link} isExternalLink={isExternalLink} isDisabled={isDisabled}>
            <TextButton disabled={isDisabled} className={classes.button}>
              {content}
            </TextButton>
          </LinkWrapper>
          {collapsible && (
            <DropdownIcon
              onClick={toggleCollapse}
              className={clsx(classes.toggleIcon, collapsed ? classes.iconCollapsed : classes.iconExpanded)}
            />
          )}
        </Flex>
        {collapsible && (
          <Collapse in={!collapsed}>
            <Flex fullWidth mt={2}>
              {collapsible}
            </Flex>
          </Collapse>
        )}
      </Flex>
    </Flex>
  )
}
