import React from 'react'

import { SvgIconLargerViewBox, SvgIconProps } from '../../../SvgIcon'

const StockOptionsIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIconLargerViewBox width={64} height={64} {...props}>
      <path
        d='M54.1067 38.24L40.3467 40.8693'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M38.4424 22.608L29.1944 25.664C28.4788 25.8996 27.7015 25.8637 27.0106 25.5633C26.3197 25.2628 25.7634 24.7187 25.4477 24.0347C25.1075 23.3045 25.0644 22.4706 25.3276 21.7093C25.5909 20.9479 26.1398 20.3187 26.8584 19.9547L35.8157 15.4507C36.5792 15.0656 37.4136 14.8414 38.2672 14.7919C39.1208 14.7423 39.9755 14.8685 40.7784 15.1627L54.0397 20.0133'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.82715 38.2614H16.3471L25.7125 48.7574C26.1894 49.31 26.824 49.7036 27.531 49.8854C28.2381 50.0671 28.9838 50.0282 29.6681 49.774C30.3524 49.5198 30.9426 49.0623 31.3595 48.4631C31.7764 47.8638 32.0001 47.1514 32.0005 46.4214V44.7814L32.4671 44.968C33.1371 45.236 33.8625 45.3357 34.5798 45.2585C35.2972 45.1812 35.9847 44.9294 36.5823 44.525C37.1798 44.1206 37.6692 43.5759 38.0075 42.9386C38.3459 42.3014 38.5229 41.5909 38.5231 40.8694H39.8271C40.5537 40.8689 41.2658 40.6662 41.8837 40.284C42.5016 39.9018 43.0009 39.3552 43.3259 38.7054C43.6508 38.0555 43.7885 37.3281 43.7235 36.6045C43.6585 35.8808 43.3933 35.1896 42.9578 34.608L34.5871 23.8827'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M28.2213 19.2667L27.2667 18.48C26.3047 17.7716 25.1413 17.3895 23.9467 17.3893C23.2348 17.3891 22.5295 17.5258 21.8693 17.792L10 22.5387'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M2 18H7.33333C8.01049 17.9717 8.67125 18.2129 9.17101 18.6707C9.67078 19.1285 9.96885 19.7656 10 20.4426V37.5546C9.96884 38.2319 9.67086 38.8693 9.17118 39.3275C8.67149 39.7857 8.01074 40.0275 7.33333 40H2'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M62 40H56.6667C55.9893 40.0275 55.3285 39.7857 54.8288 39.3275C54.3291 38.8693 54.0312 38.2319 54 37.5546V20.4426C54.0311 19.7656 54.3292 19.1285 54.829 18.6707C55.3288 18.2129 55.9895 17.9717 56.6667 18H62'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M31.9996 44.7813L29.3916 42.1733'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M38.5231 40.8693L34.6084 36.9547'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIconLargerViewBox>
  )
}

export { StockOptionsIcon }
