import React from 'react'

import { SvgIconMediumViewBox, SvgIconProps } from '../../../SvgIcon'

type TikTokIconPros = SvgIconProps

const TikTokIcon: React.FC<TikTokIconPros> = (props) => {
  return (
    <SvgIconMediumViewBox {...props}>
      <path
        d='M21.0746 6.00927C19.7028 6.00927 18.4371 5.55482 17.4207 4.78817C16.255 3.90931 15.4174 2.62014 15.1216 1.13425C15.0484 0.767121 15.009 0.388255 15.0052 0H11.0865V10.7078L11.0818 16.5729C11.0818 18.141 10.0607 19.4705 8.64525 19.9381C8.23445 20.0738 7.7908 20.1381 7.32884 20.1128C6.73918 20.0804 6.18661 19.9024 5.70633 19.6151C4.68429 19.0039 3.99134 17.895 3.97257 16.6264C3.94299 14.6438 5.54577 13.0274 7.52696 13.0274C7.91803 13.0274 8.29361 13.0913 8.64525 13.2073V10.2805V9.22846C8.27436 9.17353 7.8969 9.14489 7.51522 9.14489C5.34672 9.14489 3.31859 10.0463 1.86885 11.6702C0.773097 12.8974 0.115833 14.4631 0.0144267 16.1048C-0.118435 18.2616 0.670752 20.3118 2.20124 21.8245C2.42612 22.0465 2.66226 22.2526 2.90921 22.4428C4.22139 23.4526 5.82558 24 7.51522 24C7.8969 24 8.27436 23.9718 8.64525 23.9169C10.2236 23.6831 11.6799 22.9606 12.8292 21.8245C14.2414 20.4287 15.0216 18.5757 15.0301 16.6034L15.0099 7.84492C15.6836 8.36463 16.4202 8.79466 17.2108 9.12846C18.4404 9.64723 19.7441 9.91013 21.0858 9.90967V7.06418V6.00833C21.0868 6.00927 21.0755 6.00927 21.0746 6.00927V6.00927Z'
        fill='currentColor'
      />
    </SvgIconMediumViewBox>
  )
}

export { TikTokIcon }
