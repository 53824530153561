import React from 'react'

import { clsx } from '@guiker/clsx'
import { AnyColor } from '@guiker/components-core'

import { makeStyles, theme } from '../../../styles'
import { Flex, Typography } from '../../'
import { CircularProgress } from '../CircularProgress'

export interface SpinnerProps {
  color?: AnyColor
  caption?: string
  className?: string
}

const useStyle = makeStyles({
  root: {
    color: ({ color }: { color: AnyColor }) => theme.palette.getColor(color),
  },
})

export const Spinner: React.FC<SpinnerProps> = ({ color = 'primary', caption, className }) => {
  const classes = useStyle({ color })
  return (
    <Flex
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      width='100%'
      height='100%'
      gap={2}
      className={className}
    >
      <CircularProgress variant='indeterminate' color='inherit' className={clsx(classes.root, className)} />
      {caption && <Typography variant='caption'>{caption}</Typography>}
    </Flex>
  )
}
