import React from 'react'

import { clsx } from '@guiker/clsx'
import { isAtMostTablette, makeStyles, theme } from '@guiker/components-core'
import { DoubleChevronRightIcon } from '@guiker/icons'

import { useLayoutContext } from '../../../hooks'
import { Drawer } from './Drawer'
import { DrawerItem } from './DrawerItem'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      zIndex: theme.zIndexs.takeOver + 1,
      backgroundColor: theme.palette.background.primary,
      flexShrink: 0,
      overflow: 'hidden',
    },
    drawerPaper: {
      zIndex: theme.zIndexs.takeOver + 1,
      overflow: 'hidden',
      justifyContent: 'space-between',
      maxHeight: '100vh',
      paddingTop: theme.dimensions.appBar.height.desktop,
      [isAtMostTablette]: {
        maxHeight: '100vh',
        paddingTop: theme.dimensions.appBar.height.mobile,
      },
    },
    minimized: {
      width: theme.dimensions.drawerNavigation.width.minimized,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    maximized: ({ width }: { width: number }) => ({
      width,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    }),
    expandIcon: ({ anchor }: { anchor: 'left' | 'right' }) => ({
      transform: `rotate(${anchor === 'left' ? 0 : 180}deg)`,
      transition: theme.transitions.create('transform', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
    expandIconExpanded: ({ anchor }: { anchor: 'left' | 'right' }) => ({
      transform: `rotate(${anchor === 'left' ? 180 : 0}deg)`,
    }),
  }),
  { name: 'MinimizableDrawer' },
)

type MinimizableDrawerProps = React.PropsWithChildren & {
  anchor: 'left' | 'right'
  width?: number
  collapseLabel: string
}

export const MinimizableDrawer: React.FC<MinimizableDrawerProps> = ({
  children,
  anchor,
  collapseLabel,
  width = theme.dimensions.drawerNavigation.width.maximized,
}) => {
  const classes = useStyles({ anchor, width })
  const {
    isRightDrawerOpen,
    setIsRightDrawerOpen,
    isDrawerOpen: isLeftDrawerOpen,
    setIsDrawerOpen: setIsLeftDrawerOpen,
    isTakeover,
  } = useLayoutContext()

  const { isDrawerOpen, setIsDrawerOpen } =
    anchor === 'left'
      ? { isDrawerOpen: isLeftDrawerOpen, setIsDrawerOpen: setIsLeftDrawerOpen }
      : { isDrawerOpen: isRightDrawerOpen, setIsDrawerOpen: setIsRightDrawerOpen }

  if (isTakeover) return

  return (
    <Drawer
      open={true}
      className={classes.root}
      variant='permanent'
      anchor={anchor}
      classes={{
        paper: clsx(classes.drawerPaper, {
          [classes.minimized]: !isDrawerOpen,
          [classes.maximized]: isDrawerOpen,
        }),
      }}
    >
      {children}
      <div>
        <DrawerItem
          item={{
            onClick: () => setIsDrawerOpen(!isDrawerOpen),
            icon: (
              <DoubleChevronRightIcon
                className={clsx(classes.expandIcon, { [classes.expandIconExpanded]: isDrawerOpen })}
              />
            ),
            text: collapseLabel,
          }}
        />
      </div>
    </Drawer>
  )
}
