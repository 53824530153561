import React from 'react'

import { clsx } from '@guiker/clsx'
import { makeStyles, theme } from '@guiker/components-core'
import { default as FormControlLabel } from '@material-ui/core/FormControlLabel'
import { default as MuiSwitch, SwitchProps as MuiSwitchProps } from '@material-ui/core/Switch'

import { ErrorMessageAndHelperText, Grid, useUtilityStyle } from '../../..'
import { Label, PBold } from '../../Data Display'

export type SwitchProps = Omit<MuiSwitchProps, 'color'> & {
  maxWidth?: string | number
  label?: string
  adornment?: React.ReactNode
  description?: string
  helperText?: string
  error?: boolean
  errorMessage?: string
  options: [{ label: string; value: string }, { label: string; value: string }]
}

const useStyles = makeStyles(
  {
    root: {
      width: 40,
      height: 24,
      padding: 0,
      margin: theme.spacing(1),
      '& > .MuiIconButton-root': {
        backgroundColor: 'inherit',
      },
    },
    toggle: {
      display: 'flex',
      boxSizing: 'border-box',
      padding: theme.spacing(2),
      marginLeft: 0,
      justifyContent: 'space-between',
      minHeight: 50,
    },
    switchBase: {
      padding: 2,
      '&$checked': {
        transform: 'translateX(16px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: theme.palette.primary.main,
          opacity: 1,
          border: 'none',
          borderRadius: 2,
        },
      },
    },
    thumb: {
      width: 20,
      height: 20,
      borderRadius: 1,
      backgroundColor: theme.palette.common.white,
      boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24)',
    },
    track: {
      borderRadius: 2,
      background: theme.palette.grey[5],
      opacity: 1,
      transition: theme.transitions.create(['background-color']),
    },
    grey: {
      color: theme.palette.grey[30],
    },
    checked: {},
  },
  {
    name: 'switch',
  },
)

const Switch: React.FC<SwitchProps> = (props) => {
  const {
    className,
    adornment,
    description,
    value,
    disabled,
    checked,
    label,
    maxWidth = 360,
    error,
    errorMessage,
    helperText,
    readOnly,
    name,
    id,
    options,
    ...other
  } = props
  const classes = useStyles()
  const utilityClasses = useUtilityStyle({ maxWidth })

  return (
    <>
      <FormControlLabel
        className={clsx(className, classes.toggle, utilityClasses.maxWidth)}
        label={<Label text={label} adornment={{ node: adornment }} description={description} disabled={disabled} />}
        value={value}
        name={name}
        control={
          <Grid container alignItems='center' justify='center'>
            <Grid item>
              <PBold
                mb={0}
                className={clsx({
                  [classes.grey]: value === options[1].value || checked,
                })}
              >
                {options[0].label}
              </PBold>
            </Grid>
            <Grid item>
              <MuiSwitch
                className={clsx(classes.root, className)}
                disabled={disabled || readOnly}
                disableRipple={true}
                disableFocusRipple={true}
                disableTouchRipple={true}
                readOnly={readOnly}
                classes={{
                  root: classes.root,
                  switchBase: classes.switchBase,
                  thumb: classes.thumb,
                  track: classes.track,
                  checked: classes.checked,
                }}
                value={value}
                checked={checked || value === options[1].value}
                {...other}
              />
            </Grid>
            <Grid item>
              <PBold
                mb={0}
                className={clsx({
                  [classes.grey]: value === options[0].value || !checked,
                })}
              >
                {options[1].label}
              </PBold>
            </Grid>
          </Grid>
        }
      />
      <ErrorMessageAndHelperText errorMessage={errorMessage} helperText={helperText} id={id || name} />
    </>
  )
}

export { Switch }
