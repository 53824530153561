import React from 'react'

export const CSVIcon: React.FC = () => {
  return (
    <svg width='15' height='15' viewBox='0 0 13 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M2.49951 12.627H2.12549C1.71127 12.627 1.37549 12.2912 1.37549 11.877V2.12695C1.37549 1.71274 1.71127 1.37695 2.12549 1.37695H7.43999C7.63876 1.377 7.82939 1.45594 7.96999 1.59645L10.906 4.53195C11.0466 4.67267 11.1256 4.86351 11.1255 5.06245V6.5'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M11.1255 5.12695H8.12549C7.71127 5.12695 7.37549 4.79117 7.37549 4.37695V1.37695'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M6.62549 8.87695C5.79706 8.87695 5.12549 9.54853 5.12549 10.377V11.127C5.12549 11.9554 5.79706 12.627 6.62549 12.627'
        stroke='black'
        stroke-width='0.8'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M9.62549 8.87695H8.87549C8.46127 8.87695 8.12549 9.21274 8.12549 9.62695C8.12549 10.752 9.62549 10.752 9.62549 11.877C9.62549 12.2912 9.2897 12.627 8.87549 12.627H8.12549'
        stroke='black'
        stroke-width='0.8'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M11.1255 8.87695V10.15C11.1255 11.0317 11.3865 11.8937 11.8755 12.6275C12.3645 11.8937 12.6255 11.0317 12.6255 10.15V8.87695'
        stroke='black'
        stroke-width='0.8'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}
