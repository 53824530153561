import React, { createContext, useEffect, useRef, useState } from 'react'

import { useFormContext } from '@guiker/react-hook-form'
import { UseMutateFunction } from '@guiker/react-query'
import { generateUseContext } from '@guiker/react-utils'
import { sleep } from '@guiker/shared-framework'

import { BeforeValidation, FormSubmitHandlerGenerator, MutationOptions, OnSubmit } from '../../types'
import { isExplicitRunBeforeValidation, useFormEvent } from '../../utils'

type Context<T extends Record<string, any> = Record<string, any>> = {
  formName: string
  readOnly: boolean
  isSubmitting: boolean
  beforeValidation?: BeforeValidation<T>
  generateFormSubmitHandler: FormSubmitHandlerGenerator
}

const ApiFormContext = createContext<Context>(undefined)

type Props<TFormValues, TResult> = {
  formName: string
  beforeValidation?: BeforeValidation<TFormValues>
  onSubmit?: OnSubmit<TResult, TFormValues>
  skipIfIsNotDirty?: boolean
  apiOptions?: MutationOptions<TResult, TFormValues> & {
    skipMutation?: boolean
    onSubmitWithoutChange?: () => void
  }
  readOnly: boolean
} & React.PropsWithChildren

const ApiFormContextProvider = <TResult, TFormValues extends Record<string, any> = Record<string, any>>({
  formName,
  beforeValidation,
  onSubmit,
  skipIfIsNotDirty,
  apiOptions,
  readOnly: readOnlyProp,
  children,
}: Props<TFormValues, TResult>) => {
  const { handleSubmit, formState, getValues } = useFormContext()
  const { sendFormEvent, handleFormError } = useFormEvent(formName)
  const { isDirty } = formState

  const reSubmitFunction = useRef<() => Promise<void>>()
  const [processedBeforeValidation, setProcessedBeforeValidation] = useState(!beforeValidation)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [readOnly, setReadOnly] = useState(readOnlyProp)

  const onFormSubmit = async (payload: TFormValues, mutate: UseMutateFunction<TResult, unknown, TFormValues>) => {
    if (!isDirty && !!apiOptions.onSubmitWithoutChange) {
      apiOptions.onSubmitWithoutChange()
      sendFormEvent({ event: 'formSubmitSuccess', formName })
    } else if (apiOptions.skipMutation) {
      if (!!onSubmit) {
        try {
          await onSubmit(payload)
          sendFormEvent({ event: 'formSubmitSuccess', formName })
        } catch (error) {
          handleFormError(error, { payload })
        }
      } else {
        handleFormError(new Error('Unable to call undefined onSubmit method'))
      }
    } else {
      mutate(payload, {
        onSuccess: () => sendFormEvent({ event: 'formSubmitSuccess', formName }),
        onError: (error: Error) => {
          handleFormError(error, { payload })
        },
      })
    }
  }

  const clearStates = () => {
    setIsSubmitting(false)
    setProcessedBeforeValidation(false)
    reSubmitFunction.current = undefined
  }

  const generateFormSubmitHandler = (mutate: UseMutateFunction<TResult, unknown, TFormValues>) => {
    const actualSubmit = handleSubmit((data: TFormValues) => {
      sendFormEvent({ event: 'formSubmitAttempt', formName })
      setIsSubmitting(true)
      return onFormSubmit(data, mutate)
    })

    return async (e) => {
      if (e && e.preventDefault) {
        e.preventDefault()
        e.persist()
        e.stopPropagation()
      }

      if (isSubmitting) {
        return
      }
      if (beforeValidation && !processedBeforeValidation && (!skipIfIsNotDirty || isDirty)) {
        reSubmitFunction.current = () => actualSubmit()
        const formData = getValues() as TFormValues
        const resolve = () => setProcessedBeforeValidation(true)
        const reject = () => clearStates()

        const runBeforeValidation = isExplicitRunBeforeValidation(beforeValidation)
          ? beforeValidation.run
          : (_: unknown) => beforeValidation().then(resolve).catch(reject)

        const isProcessed = await runBeforeValidation({ resolve, reject, formData })
          .then(() => true)
          .catch(() => false)

        setIsSubmitting(isProcessed)
        return
      } else {
        return actualSubmit(e).finally(() => clearStates())
      }
    }
  }

  const performResubmit = async () => {
    await sleep(500)
    return reSubmitFunction.current ? reSubmitFunction.current().finally(() => clearStates()) : clearStates()
  }

  useEffect(() => {
    processedBeforeValidation && performResubmit()
  }, [processedBeforeValidation])

  useEffect(() => {
    setReadOnly(readOnlyProp)
  }, [readOnlyProp])

  return (
    <ApiFormContext.Provider
      value={{
        formName,
        readOnly,
        isSubmitting,
        beforeValidation,
        generateFormSubmitHandler,
      }}
    >
      {children}
    </ApiFormContext.Provider>
  )
}

const useApiFormContext = generateUseContext(ApiFormContext)

export { ApiFormContextProvider, useApiFormContext }
