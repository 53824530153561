import React from 'react'

import { default as MuiDialogTitle, DialogTitleProps as MuiDialogTitleProps } from '@material-ui/core/DialogTitle'

export type DialogTitleProps = MuiDialogTitleProps

const DialogTitle: React.FC<DialogTitleProps> = ({ className, ...props }) => {
  return <MuiDialogTitle className={className} {...props} />
}

export { DialogTitle }
