import { ImmutableArray, ImmutableObject } from '@guiker/common'

import { LocalStorageKey } from './entity'

export type Value<T> = T extends string ? T : T extends Array<infer U> ? ImmutableArray<U> : ImmutableObject<T>

export const persistancy = (key: LocalStorageKey) => {
  const hasLocalStorageSupport = !!localStorage

  const getItem = <T extends string>(): Value<T> =>
    hasLocalStorageSupport ? (localStorage.getItem(key) as Value<T>) : null
  const persistItem = <T>(val: Value<T>) =>
    hasLocalStorageSupport &&
    localStorage.setItem(key, typeof val === 'object' ? (JSON.stringify(val) as string) : (val as string))
  const clear = () => hasLocalStorageSupport && localStorage.removeItem(key)

  return {
    getItem,
    persistItem,
    clear,
  }
}
