import React from 'react'

import { RichTextEditor as CLRichTextEditor } from '@guiker/components-library'
import { FieldPath } from '@guiker/react-hook-form'

import { useApiFormContext } from '../Form'
import { RHFRichTextEditor, RichTextEditorProps as RHFRichTextEditorProps } from './RHFRichTextEditor'

export type RichTextEditorProps<T extends object, P extends FieldPath<T>> = RHFRichTextEditorProps<T, P>

const RichTextEditor = <T extends object = any, P extends FieldPath<T> = any>({
  defaultValue,
  type,
  ...props
}: RichTextEditorProps<T, P>) => {
  const { readOnly: contextReadOnly } = useApiFormContext({ shouldThrowIfUndefined: false })
  if (props.readOnly) {
    return <CLRichTextEditor defaultValue={defaultValue} {...props} />
  } else {
    return <RHFRichTextEditor defaultValue={defaultValue} type={type} readOnly={contextReadOnly} {...props} />
  }
}

export { RichTextEditor }
