import React from 'react'

import { SvgIconMediumViewBox } from '../../../SvgIcon'

const HouseIcon: React.FC = (props) => {
  return (
    <SvgIconMediumViewBox {...props}>
      <path
        d='M3.754 13.9233V22.1733H9.754V16.1733C9.754 15.3449 10.4256 14.6733 11.254 14.6733H12.754C13.5824 14.6733 14.254 15.3449 14.254 16.1733V22.1733H20.254V13.9233'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M0.753998 12.4231L10.944 2.2341C11.5297 1.64853 12.4792 1.64853 13.065 2.2341L23.254 12.4231'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.504 5.67334V4.17334H20.254V9.42334'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIconMediumViewBox>
  )
}

export { HouseIcon }
