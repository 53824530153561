import * as React from 'react'

import { clsx } from '@guiker/clsx'
import { AnyColor, ColorVariant, makeStyles } from '@guiker/components-core'

import { BaseButton, BaseButtonProps } from '../BaseButton'

export type ButtonProps = React.PropsWithChildren &
  Omit<BaseButtonProps, 'color' | 'size' | 'onClick'> & {
    color?: AnyColor
    colorVariant?: ColorVariant<AnyColor>
    size?: 'small' | 'medium' | 'large'
    maxWidth?: number | string
    target?: '_blank' | '_top'
    onClick?: (event?: React.MouseEvent) => void
  }

type StyleColorProps = {
  color: AnyColor
  colorVariant: ColorVariant<AnyColor>
}

const useStyles = makeStyles(
  (theme) => ({
    root: {
      backgroundColor: ({ color, colorVariant }: StyleColorProps) => theme.palette.getColor(color, colorVariant),
      color: ({ color, colorVariant }: StyleColorProps) =>
        colorVariant === 'main' ? theme.palette.text.secondary.main : theme.palette.getColor(color),
      '&:hover': {
        backgroundColor: ({ color }: StyleColorProps) => theme.palette.getColor(color, 'hover'),
        '&.Mui-disabled': {
          backgroundColor: theme.palette.grey[10],
        },
      },
      '&:active': {
        backgroundColor: ({ color }: StyleColorProps) => theme.palette.getColor(color, 'active'),
      },
      '&:disabled': {
        backgroundColor: theme.palette.grey[10],
        color: theme.palette.common.white,
      },
    },
  }),
  { name: 'Button' },
)

const Button: React.FC<ButtonProps> = ({ className, color = 'primary', colorVariant = 'main', children, ...other }) => {
  const classes = useStyles({ color, colorVariant, children })

  return (
    <BaseButton className={clsx(classes.root, className)} {...other}>
      {children}
    </BaseButton>
  )
}

export { Button }
