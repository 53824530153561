import React from 'react'

const BuildingIcon: React.FC = () => {
  return (
    <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M20.25 4.60001V23.32' stroke='black' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M3.75 4.60001H20.25' stroke='black' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M3.75 23.32V4.60001' stroke='black' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M20.25 23.32H3.75' stroke='black' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.25 4.6H3.75L5.25 1.72H18.75L20.25 4.6V4.6Z'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M0.75 23.32H23.25' stroke='black' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.25 21.16C14.25 19.9671 13.2426 19 12 19C10.7574 19 9.75 19.9671 9.75 21.16V23.32H14.25V21.16Z'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.5 7.12C7.70711 7.12 7.875 7.28117 7.875 7.48'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.125 7.48C7.125 7.28117 7.29289 7.12 7.5 7.12'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.5 7.84C7.29289 7.84 7.125 7.67882 7.125 7.48'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.875 7.48C7.875 7.67882 7.70711 7.84 7.5 7.84'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.5 11.44C7.70711 11.44 7.875 11.6012 7.875 11.8'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.125 11.8C7.125 11.6012 7.29289 11.44 7.5 11.44'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.5 12.16C7.29289 12.16 7.125 11.9988 7.125 11.8'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.875 11.8C7.875 11.9988 7.70711 12.16 7.5 12.16'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.5 15.76C7.70711 15.76 7.875 15.9212 7.875 16.12'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.125 16.12C7.125 15.9212 7.29289 15.76 7.5 15.76'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.5 16.48C7.29289 16.48 7.125 16.3188 7.125 16.12'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.875 16.12C7.875 16.3188 7.70711 16.48 7.5 16.48'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12 7.12C12.2071 7.12 12.375 7.28117 12.375 7.48'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.625 7.48C11.625 7.28117 11.7929 7.12 12 7.12'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12 7.84C11.7929 7.84 11.625 7.67882 11.625 7.48'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.375 7.48C12.375 7.67882 12.2071 7.84 12 7.84'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12 11.44C12.2071 11.44 12.375 11.6012 12.375 11.8'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.625 11.8C11.625 11.6012 11.7929 11.44 12 11.44'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12 12.16C11.7929 12.16 11.625 11.9988 11.625 11.8'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.375 11.8C12.375 11.9988 12.2071 12.16 12 12.16'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12 15.76C12.2071 15.76 12.375 15.9212 12.375 16.12'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.625 16.12C11.625 15.9212 11.7929 15.76 12 15.76'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12 16.48C11.7929 16.48 11.625 16.3188 11.625 16.12'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.375 16.12C12.375 16.3188 12.2071 16.48 12 16.48'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.5 7.12C16.7071 7.12 16.875 7.28117 16.875 7.48'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.125 7.48C16.125 7.28117 16.2929 7.12 16.5 7.12'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.5 7.84C16.2929 7.84 16.125 7.67882 16.125 7.48'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.875 7.48C16.875 7.67882 16.7071 7.84 16.5 7.84'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.5 11.44C16.7071 11.44 16.875 11.6012 16.875 11.8'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.125 11.8C16.125 11.6012 16.2929 11.44 16.5 11.44'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.5 12.16C16.2929 12.16 16.125 11.9988 16.125 11.8'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.875 11.8C16.875 11.9988 16.7071 12.16 16.5 12.16'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.5 15.76C16.7071 15.76 16.875 15.9212 16.875 16.12'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.125 16.12C16.125 15.9212 16.2929 15.76 16.5 15.76'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.5 16.48C16.2929 16.48 16.125 16.3188 16.125 16.12'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.875 16.12C16.875 16.3188 16.7071 16.48 16.5 16.48'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export { BuildingIcon }
