import { theme } from './theme'
import { toPx } from './utils'

const isNumber = (val: unknown): val is number => typeof val === 'number'

export const spacingShortand =
  (keepPixelValue: boolean) =>
  (...values: (string | number)[]) => {
    return values
      .map((value) => {
        return isNumber(value) ? toPx(keepPixelValue ? value : theme.spacing(value)) : value
      })
      .join(' ')
  }

const padding = spacingShortand(false)
const margin = spacingShortand(false)
const paddingPx = spacingShortand(true)

const flex = (grow: number, shrink: number, base: string | number = '') => {
  return `${grow} ${shrink} ${isNumber(base) ? `${base}px` : base}`.trim()
}

export { margin, padding, paddingPx, flex }
