import React from 'react'

import { NumberFormat, NumberFormatProps as CLNumberFormatProps } from '@guiker/components-library'
import { useTranslation } from '@guiker/i18n'
import { Controller, ErrorMessages, FieldPath, PathValue, useFormInput } from '@guiker/react-hook-form'

export type NumberFormatProps<T extends object, P extends FieldPath<T>> = Omit<
  CLNumberFormatProps,
  'name' | 'defaultValue'
> &
  ErrorMessages & {
    name: P
    defaultValue?: PathValue<T, P>
  }

const RHFNumberFormat = <T extends object, P extends FieldPath<T>>({
  defaultValue,
  name,
  onChange,
  errorMessages = {},
  formatter,
  ...props
}: NumberFormatProps<T, P>) => {
  const { t } = useTranslation()
  const { control, errorType, errorMessage } = useFormInput<T, P>({ name, t })

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { onChange: onChangeRenderProps, ref, ...renderProps }, fieldState }) => (
        <NumberFormat
          onChange={(event) => {
            onChange && onChange(event)
            onChangeRenderProps(event)
          }}
          name={name}
          error={!!fieldState.error}
          errorMessage={errorMessages[errorType] || (fieldState.error && errorMessage)}
          inputRef={ref}
          defaultValue={defaultValue}
          formatter={formatter}
          {...renderProps}
          {...props}
        />
      )}
    />
  )
}

export { RHFNumberFormat }
