import { useDeviceContext } from '@guiker/react-use-device'

import { theme } from '..'
import { useMediaQuery } from '.'

export const useScreenSize = () => {
  const { isBrowser } = useDeviceContext()

  return {
    isXs: useMediaQuery(theme.breakpoints.up('xs'), { noSsr: isBrowser }),
    isSm: useMediaQuery(theme.breakpoints.up('sm'), { noSsr: isBrowser }),
    isMd: useMediaQuery(theme.breakpoints.up('md'), { noSsr: isBrowser }),
    isLg: useMediaQuery(theme.breakpoints.up('lg'), { noSsr: isBrowser }),
    isXl: useMediaQuery(theme.breakpoints.up('xl'), { noSsr: isBrowser }),
  }
}
