import React from 'react'

export const LinkIcon: React.FC = () => {
  return (
    <svg width='15' height='15' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M6.9716 12.2495L5.14693 14.0735C4.79305 14.4117 4.32241 14.6004 3.83293 14.6004C3.34346 14.6004 2.87282 14.4117 2.51893 14.0735L1.92427 13.4795C1.57591 13.1311 1.38021 12.6586 1.38021 12.1659C1.38021 11.6732 1.57591 11.2006 1.92427 10.8522L5.79094 6.98554C6.13957 6.6373 6.61217 6.4417 7.10493 6.4417C7.59769 6.4417 8.0703 6.6373 8.41893 6.98554L9.01427 7.5802C9.13963 7.70556 9.30965 7.77599 9.48693 7.77599C9.66422 7.77599 9.83424 7.70556 9.9596 7.5802C10.085 7.45484 10.1554 7.28482 10.1554 7.10754C10.1554 6.93025 10.085 6.76023 9.9596 6.63487L9.36427 6.0402C8.7654 5.44213 7.95364 5.1062 7.10727 5.1062C6.2609 5.1062 5.44914 5.44213 4.85027 6.0402L0.983601 9.90687C0.687243 10.2032 0.452157 10.555 0.291768 10.9422C0.131379 11.3294 0.0488281 11.7444 0.0488281 12.1635C0.0488281 12.5826 0.131379 12.9976 0.291768 13.3848C0.452157 13.772 0.687243 14.1239 0.983601 14.4202L1.57827 15.0142C1.87454 15.3108 2.22637 15.5461 2.61364 15.7067C3.00091 15.8672 3.41603 15.9499 3.83527 15.9499C4.2545 15.9499 4.66962 15.8672 5.0569 15.7067C5.44417 15.5461 5.796 15.3108 6.09227 15.0142L7.91627 13.1909C7.97821 13.129 8.02735 13.0555 8.06089 12.9746C8.09443 12.8937 8.11171 12.807 8.11174 12.7194C8.11177 12.6319 8.09455 12.5452 8.06107 12.4642C8.02759 12.3833 7.9785 12.3098 7.9166 12.2479C7.8547 12.1859 7.78121 12.1368 7.70033 12.1032C7.61944 12.0697 7.53274 12.0524 7.44517 12.0524C7.35761 12.0524 7.27089 12.0696 7.18998 12.1031C7.10907 12.1366 7.03554 12.1856 6.9736 12.2475L6.9716 12.2495Z'
        fill='#000000'
      />
      <path
        d='M15.0164 1.57543L14.4218 0.98076C13.8222 0.384351 13.0108 0.0495605 12.1651 0.0495605C11.3194 0.0495605 10.5081 0.384351 9.90844 0.98076L7.97178 2.91409C7.85034 3.03983 7.78314 3.20823 7.78466 3.38303C7.78618 3.55783 7.85629 3.72503 7.9799 3.84864C8.1035 3.97224 8.27071 4.04236 8.44551 4.04388C8.62031 4.04539 8.78871 3.9782 8.91444 3.85676L10.8478 1.92343C11.1965 1.57556 11.6689 1.3802 12.1614 1.3802C12.654 1.3802 13.1264 1.57556 13.4751 1.92343L14.0704 2.51876C14.2431 2.69125 14.3801 2.89609 14.4735 3.12155C14.567 3.34702 14.6151 3.58869 14.6151 3.83276C14.6151 4.07683 14.567 4.3185 14.4735 4.54397C14.3801 4.76943 14.2431 4.97427 14.0704 5.14676L10.2038 9.01343C10.0317 9.1866 9.82689 9.32391 9.60134 9.4174C9.37579 9.51089 9.13394 9.55871 8.88978 9.55809C8.64587 9.55874 8.40426 9.51099 8.17894 9.41761C7.95362 9.32423 7.74906 9.18708 7.57711 9.01409C7.51605 8.94889 7.44251 8.89662 7.36086 8.86039C7.2792 8.82417 7.1911 8.80472 7.10179 8.80321C7.01247 8.80169 6.92376 8.81815 6.84093 8.85159C6.75809 8.88503 6.68282 8.93478 6.61959 8.99787C6.55636 9.06097 6.50645 9.13614 6.47284 9.2189C6.43922 9.30166 6.42258 9.39034 6.4239 9.47965C6.42522 9.56897 6.44449 9.65712 6.48054 9.73885C6.51659 9.82058 6.5687 9.89423 6.63378 9.95543C6.92937 10.2525 7.28098 10.488 7.66824 10.6482C8.05549 10.8084 8.47069 10.8902 8.88978 10.8888C9.30897 10.8902 9.72429 10.8085 10.1117 10.6483C10.499 10.4881 10.8508 10.2526 11.1464 9.95543L15.0131 6.08876C15.3096 5.79245 15.5449 5.4406 15.7054 5.05334C15.8659 4.66607 15.9485 4.25097 15.9485 3.83176C15.9485 3.41255 15.8659 2.99745 15.7054 2.61018C15.5449 2.22292 15.3096 1.87107 15.0131 1.57476L15.0164 1.57543Z'
        fill='#000000'
      />
    </svg>
  )
}
