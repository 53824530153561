import React from 'react'

import { SvgIconProps, SvgIconSmallViewBox } from '../../../SvgIcon'

type EyeMiniIcon = SvgIconProps

const EyeMiniIcon: React.FC<EyeMiniIcon> = (props) => {
  return (
    <SvgIconSmallViewBox {...props}>
      <path
        d='M14.9061 7.2321C15.0949 7.43125 15.2002 7.69525 15.2002 7.9697C15.2002 8.24415 15.0949 8.50814 14.9061 8.7073C13.7381 9.9681 11.0853 12.4001 8.00048 12.4001C4.91568 12.4001 2.26288 9.9681 1.09488 8.7073C0.906043 8.50814 0.800781 8.24415 0.800781 7.9697C0.800781 7.69525 0.906043 7.43125 1.09488 7.2321C2.83655 5.16911 5.31375 3.86622 8.00048 3.6001C10.6872 3.86622 13.1644 5.16911 14.9061 7.2321Z'
        stroke='currentColor'
        strokeWidth='1.6'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6 8C6 8.53043 6.21071 9.03914 6.58579 9.41421C6.96086 9.78929 7.46957 10 8 10C8.53043 10 9.03914 9.78929 9.41421 9.41421C9.78929 9.03914 10 8.53043 10 8C10 7.46957 9.78929 6.96086 9.41421 6.58579C9.03914 6.21071 8.53043 6 8 6C7.46957 6 6.96086 6.21071 6.58579 6.58579C6.21071 6.96086 6 7.46957 6 8V8Z'
        stroke='currentColor'
        strokeWidth='1.6'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIconSmallViewBox>
  )
}

export { EyeMiniIcon }
