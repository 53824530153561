import React from 'react'

import { SvgIconMediumViewBox, SvgIconProps } from '../../../SvgIcon'

const DeleteIcon: React.FC<Omit<SvgIconProps, 'small'>> = (props) => {
  return (
    <SvgIconMediumViewBox {...props}>
      <path
        d='M18.1777 23.25H7.67773C7.27991 23.25 6.89838 23.092 6.61707 22.8107C6.33577 22.5294 6.17773 22.1478 6.17773 21.75V8.25H19.6777V21.75C19.6777 22.1478 19.5197 22.5294 19.2384 22.8107C18.9571 23.092 18.5756 23.25 18.1777 23.25Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.6777 18.75V12.75'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.1777 18.75V12.75'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M2.42773 6.21196L21.5017 2.15796'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.5415 0.783026L9.14147 1.71803C8.94835 1.75878 8.76516 1.83725 8.60242 1.94893C8.43967 2.06062 8.30057 2.20332 8.19309 2.36887C8.0856 2.53442 8.01185 2.71955 7.97605 2.91366C7.94025 3.10776 7.94311 3.30703 7.98447 3.50003L8.30047 4.96503L15.6365 3.40503L15.3205 1.93803C15.2377 1.54898 15.0038 1.20874 14.6701 0.992148C14.3365 0.775552 13.9305 0.70033 13.5415 0.783026V0.783026Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIconMediumViewBox>
  )
}

export { DeleteIcon }
