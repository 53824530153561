import React, { useEffect } from 'react'

import { safelyGetWindow } from '@guiker/react-utils'

import { Device } from './Device'
import { DeviceContext } from './DeviceContext'

const canUseNative: boolean = typeof navigator !== 'undefined' && navigator.product === 'ReactNative'
const canUseDOM = !!(!!safelyGetWindow() && window.document && window.document.createElement)

const DeviceContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [hasMounted, setHasMounted] = React.useState(false)

  useEffect(() => {
    setHasMounted(true)
  }, [])

  const device = canUseNative ? Device.Native : canUseDOM ? Device.Browser : Device.Server

  const contextValue = {
    isBrowser: device === Device.Browser,
    isServer: device === Device.Server,
    isNative: device === Device.Native,
    hasMounted,
    device,
    canUseWorkers: typeof Worker !== 'undefined',
    canUseEventListeners: device === Device.Browser && !!safelyGetWindow().addEventListener,
    canUseViewport: device === Device.Browser && !!safelyGetWindow().screen,
  }

  return <DeviceContext.Provider value={contextValue}>{children}</DeviceContext.Provider>
}

export { DeviceContextProvider }
