import React from 'react'

import { clsx, isAtMostTablette, makeStyles, toPx } from '@guiker/components-core'

import { TwoColumnsGridLayout } from '../'

const useStyle = makeStyles(
  (theme) => ({
    container: {
      paddingTop: theme.spacing(12),
      paddingBottom: theme.spacing(12),
      minHeight: `calc(100vh - ${toPx(theme.dimensions.appBar.height.desktop)})`,
      maxHeight: 880,
      [isAtMostTablette]: {
        maxHeight: 'initial',
        paddingTop: theme.spacing(6),
        minHeight: `calc(100vh - ${toPx(theme.dimensions.appBar.height.mobile)})`,
      },
    },
    left: {
      width: '100%',
      flex: 1,
    },
    right: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignSelf: 'center',
      flex: 1,
      position: 'relative',
      [isAtMostTablette]: {
        justifyContent: 'center',
      },
    },
  }),
  {
    name: 'HeroLayout',
  },
)

export type HeroLayoutProps = {
  left: React.ReactNode
  right?: React.ReactNode
  className?: string
}

export const HeroLayout: React.FC<HeroLayoutProps> = ({ className, left, right }) => {
  const classes = useStyle()

  return (
    <div className={clsx(classes.container, className)}>
      {right ? (
        <TwoColumnsGridLayout gap={10}>
          <div className={classes.left}>{left}</div>
          <div className={classes.right}>{right}</div>
        </TwoColumnsGridLayout>
      ) : (
        <div className={classes.left}>{left}</div>
      )}
    </div>
  )
}
