import React from 'react'

import { AnyColor, ColorVariant, makeStyles } from '@guiker/components-core'
import { AlertIcon } from '@guiker/icons'

import { Button, ButtonProps, SecondaryButton } from '../../'
import { CircularProgress } from '../../Feedback'

export interface ButtonWithLoaderProps extends ButtonProps {
  disabled?: boolean
  isLoading?: boolean
  buttonComponent?: typeof Button | typeof SecondaryButton
  errors?: { [x in string]: any }
}

const useStyles = makeStyles({
  startIcon: {
    marginRight: 8,
  },
})

const ButtonWithLoader: React.FC<ButtonWithLoaderProps> = ({
  isLoading,
  disabled,
  type = 'submit',
  buttonComponent: ButtonComponent = Button,
  errors,
  color,
  colorVariant,
  ...props
}) => {
  const classes = useStyles()
  const hasErrors = Object.keys(errors ?? {}).length > 0
  const buttonColor: AnyColor = hasErrors ? 'alert' : color
  const buttonColorVariant: ColorVariant<AnyColor> = hasErrors ? 'background' : colorVariant
  const StartIcon = isLoading ? (
    <CircularProgress size={16} color='inherit' variant='indeterminate' className={classes.startIcon} />
  ) : hasErrors ? (
    <AlertIcon color='alert' />
  ) : undefined

  return (
    <ButtonComponent
      type={type}
      disabled={disabled || isLoading}
      startIcon={StartIcon}
      color={buttonColor}
      colorVariant={buttonColorVariant}
      {...props}
    />
  )
}

export { ButtonWithLoader }
