import React, { useCallback, useState } from 'react'

import { FieldPath } from '@guiker/react-hook-form'
import { useMutation } from '@guiker/react-query'
import { debounce } from '@guiker/shared-framework'

import { Autocomplete, AutocompleteProps } from '../'

type AsyncAutocompleteProps<T extends object = any, P extends FieldPath<T> = any> = Omit<
  AutocompleteProps<T, P>,
  'type' | 'options'
> & {
  searchOptions: (searchString: string) => Promise<T[]>
  handleSelectOption: (option: T) => void
}

export const AsyncAutocomplete = <T extends object = any, P extends FieldPath<T> = any>({
  handleSelectOption,
  searchOptions,
  ...props
}: AsyncAutocompleteProps<T, P>) => {
  const [options, setOptions] = useState<T[]>([])

  const { mutate: handleSearch, isLoading } = useMutation(searchOptions, {
    onSuccess: (searchResults) => setOptions(searchResults),
    onError: () => setOptions([]),
  })

  const handleInputChange = useCallback(
    debounce((searchString: string) => {
      if (!/^\s*$/.test(searchString)) {
        handleSearch(searchString)
      }
    }, 400),
    [],
  )

  return (
    <Autocomplete
      type='text'
      options={options}
      onInputChange={(_, newValue, reason) => {
        reason === 'input' && !!newValue ? handleInputChange(newValue) : setOptions([])
      }}
      onChange={(_, option: T) => handleSelectOption(option)}
      isLoading={isLoading}
      {...props}
    />
  )
}
