import React from 'react'

import { SvgIconMediumViewBox, SvgIconProps } from '../../../SvgIcon'

type GymIconProps = SvgIconProps

const GymIcon: React.FC<GymIconProps> = (props) => {
  return (
    <SvgIconMediumViewBox {...props}>
      <path
        d='M20.249 3.75395C14.795 2.74836 9.20306 2.74836 3.74902 3.75395'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M23.25 5.25C23.25 5.64782 23.092 6.02936 22.8107 6.31066C22.5294 6.59196 22.1478 6.75 21.75 6.75C21.3522 6.75 20.9706 6.59196 20.6893 6.31066C20.408 6.02936 20.25 5.64782 20.25 5.25V2.25C20.25 1.85218 20.408 1.47064 20.6893 1.18934C20.9706 0.908035 21.3522 0.75 21.75 0.75C22.1478 0.75 22.5294 0.908035 22.8107 1.18934C23.092 1.47064 23.25 1.85218 23.25 2.25V5.25Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.75 5.25C3.75 5.64782 3.59196 6.02936 3.31066 6.31066C3.02936 6.59196 2.64782 6.75 2.25 6.75C1.85218 6.75 1.47064 6.59196 1.18934 6.31066C0.908035 6.02936 0.75 5.64782 0.75 5.25V2.25C0.75 1.85218 0.908035 1.47064 1.18934 1.18934C1.47064 0.908035 1.85218 0.75 2.25 0.75C2.64782 0.75 3.02936 0.908035 3.31066 1.18934C3.59196 1.47064 3.75 1.85218 3.75 2.25V5.25Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.689 6.43996C17.5351 6.59419 17.4166 6.78008 17.3418 6.98473C17.2671 7.18937 17.2378 7.40785 17.256 7.62496C17.256 7.66096 17.499 11.269 15.556 13.384C15.2272 13.7303 14.8459 14.0225 14.426 14.25H9.57497C9.161 14.0255 8.78394 13.7389 8.45697 13.4C6.49997 11.278 6.73997 7.68896 6.74597 7.61996C6.76334 7.40335 6.73344 7.18555 6.65833 6.98164C6.58322 6.77772 6.4647 6.59256 6.31097 6.43896'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.83698 9.73901C4.03409 11.8331 4.87216 13.8155 6.23698 15.416C7.00421 16.2491 7.94833 16.8997 8.99998 17.32V21.75C8.99998 22.1478 9.15801 22.5294 9.43931 22.8107C9.72062 23.092 10.1022 23.25 10.5 23.25C10.8978 23.25 11.2793 23.092 11.5606 22.8107C11.8419 22.5294 12 22.1478 12 21.75C12 22.1478 12.158 22.5294 12.4393 22.8107C12.7206 23.092 13.1022 23.25 13.5 23.25C13.8978 23.25 14.2793 23.092 14.5606 22.8107C14.8419 22.5294 15 22.1478 15 21.75V17.32C16.0502 16.9004 16.9932 16.2513 17.76 15.42C19.1248 13.8195 19.9629 11.8371 20.16 9.74301'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.375 9.375C9.375 10.0712 9.65156 10.7389 10.1438 11.2312C10.6361 11.7234 11.3038 12 12 12C12.6962 12 13.3639 11.7234 13.8562 11.2312C14.3484 10.7389 14.625 10.0712 14.625 9.375C14.625 8.67881 14.3484 8.01113 13.8562 7.51884C13.3639 7.02656 12.6962 6.75 12 6.75C11.3038 6.75 10.6361 7.02656 10.1438 7.51884C9.65156 8.01113 9.375 8.67881 9.375 9.375V9.375Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M9 18.75H15' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    </SvgIconMediumViewBox>
  )
}

export { GymIcon }
