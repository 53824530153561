import React from 'react'

import { clsx } from '@guiker/clsx'

import { fade, makeStyles } from '../../../styles'
import { Flex } from '../../Layout'
import { Card } from './Card'
import { CardMedia } from './CardMedia'
import { CardProgress } from './CardProgress'

export type CardWithMediaProps = React.PropsWithChildren & {
  Chip?: React.FC<{ className?: string }>
  className?: string
  condensed?: boolean
  media?: {
    images: {
      url: string
      alt?: string
    }[]
    width?: number
    height?: number
  }
  progress?: number
  shadow?: boolean
}

const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: '100%',
      height: '100%',
      maxWidth: ({ maxWidth }: { maxWidth: number | string }) => maxWidth,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    chip: {
      position: 'absolute',
      zIndex: 1,
      top: theme.spacing(2),
      right: theme.spacing(2),
      color: `${theme.palette.text.primary.main} !important`,
      backgroundColor: `${fade(theme.palette.background.primary, 0.95)} !important`,
    },
  }),
  { name: 'CardWithMedia' },
)

export const CardWithMedia: React.FC<CardWithMediaProps> = ({
  className,
  condensed = false,
  children,
  media,
  progress,
  shadow = true,
  Chip,
}) => {
  const classes = useStyles({ maxWidth: media?.width })

  return (
    <Card
      className={clsx(className, classes.root)}
      display='flex'
      flexDirection='column'
      shadow={shadow ? 'medium' : undefined}
      radius={1}
    >
      {Chip && <Chip className={classes.chip} />}
      <CardMedia condensed={condensed} {...media} />
      {!!progress && <CardProgress color='success' value={progress} />}
      <Flex px={!shadow ? 0 : condensed ? 2 : 3} py={condensed ? 2 : 3} flexDirection='column'>
        {children}
      </Flex>
    </Card>
  )
}
