import React from 'react'

import { clsx } from '@guiker/clsx'
import { default as MuiTooltip, TooltipProps as MuiTooltipProps } from '@material-ui/core/Tooltip'

import { makeStyles, theme, toPx } from '../../../styles'

export type BaseTooltipProps = MuiTooltipProps & {
  color?: string
  backgroundColor?: string
}

const useStyles = makeStyles({
  arrow: {
    color: ({ backgroundColor }: { backgroundColor: string }) =>
      backgroundColor ? backgroundColor : theme.palette.primary.main,
  },
  tooltip: {
    ...theme.typography.variants.bodySmall,
    padding: theme.spacing(2),
    lineHeight: toPx(24),
    backgroundColor: ({ backgroundColor }: { backgroundColor: string }) =>
      backgroundColor ? backgroundColor : theme.palette.primary.main,
    color: ({ color }: { color: string }) => (color ? color : theme.palette.common.white),
  },
})

const BaseTooltip: React.FC<BaseTooltipProps> = ({
  className,
  title,
  color,
  backgroundColor,
  children,
  arrow = true,
  ...props
}) => {
  const classes = useStyles({ color, backgroundColor })

  return (
    <MuiTooltip
      title={title}
      arrow={arrow}
      classes={{
        tooltip: clsx(classes.tooltip, className),
        arrow: classes.arrow,
      }}
      {...props}
    >
      {children}
    </MuiTooltip>
  )
}

export { BaseTooltip }
