import React from 'react'

import { makeStyles, theme } from '@guiker/components-core'
import { useTranslation } from '@guiker/i18n'
import { SuccessCheckmarkIcon } from '@guiker/icons'

import { Flex, PBig, PSmall } from '../../'
import { ButtonWithLoader, ButtonWithLoaderProps } from '../../Inputs'

type SuccessPageProps = React.PropsWithChildren & {
  illustration?: React.ReactNode
  title?: string
  description?: string
  buttonProps?: ButtonWithLoaderProps
}

const useStyle = makeStyles({
  root: {
    gap: theme.spacing(2),
    minHeight: 732,
    height: '100%',
  },
})

export const SuccessPage: React.FC<SuccessPageProps> = ({
  title,
  description,
  illustration,
  buttonProps,
  children,
}) => {
  const { t } = useTranslation(['common-components'])
  const classes = useStyle()
  return (
    <Flex fullWidth flexDirection='column' justifyContent='center' alignItems='center' className={classes.root}>
      {illustration ? illustration : <SuccessCheckmarkIcon />}
      <PBig mb={1}>{title || t('successPage.title')}</PBig>
      {description && <PSmall>{description}</PSmall>}
      {children || (buttonProps && <ButtonWithLoader {...buttonProps} />)}
    </Flex>
  )
}
