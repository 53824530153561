import React from 'react'

import { SvgIconMediumViewBox, SvgIconProps } from '../../../SvgIcon'

type HandShakingIconProps = SvgIconProps

const HandShakingIcon: React.FC<HandShakingIconProps> = (props) => {
  return (
    <SvgIconMediumViewBox {...props}>
      <path
        d='M20.2899 14.3766L15.1299 15.3626'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.416 8.51463L10.948 9.66063C10.6797 9.74898 10.3882 9.73554 10.1291 9.62286C9.87 9.51019 9.6614 9.30616 9.54302 9.04963C9.41542 8.77582 9.39927 8.46312 9.49798 8.17761C9.5967 7.89211 9.80254 7.65616 10.072 7.51963L13.431 5.83063C13.7173 5.68625 14.0302 5.60216 14.3503 5.58359C14.6704 5.56501 14.9909 5.61234 15.292 5.72263L20.265 7.54163'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.68506 14.3846H6.13006L9.64206 18.3206C9.82091 18.5279 10.0589 18.6755 10.324 18.7436C10.5891 18.8118 10.8688 18.7972 11.1254 18.7018C11.382 18.6065 11.6034 18.435 11.7597 18.2103C11.916 17.9855 11.9999 17.7184 12.0001 17.4446V16.8296L12.1751 16.8996C12.4263 17.0001 12.6983 17.0375 12.9673 17.0085C13.2363 16.9796 13.4942 16.8851 13.7182 16.7335C13.9423 16.5818 14.1258 16.3776 14.2527 16.1386C14.3796 15.8996 14.446 15.6332 14.4461 15.3626H14.9351C15.2075 15.3624 15.4745 15.2864 15.7063 15.1431C15.938 14.9998 16.1252 14.7948 16.2471 14.5511C16.3689 14.3074 16.4205 14.0346 16.3962 13.7633C16.3718 13.4919 16.2724 13.2327 16.1091 13.0146L12.9701 8.99261'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.583 7.26162L10.225 6.96662C9.86425 6.70097 9.428 6.55766 8.98 6.55762C8.71305 6.55752 8.44858 6.60879 8.201 6.70862L3.75 8.48862'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M0.75 6.78666H2.75C3.00393 6.77608 3.25172 6.86652 3.43913 7.03819C3.62654 7.20986 3.73832 7.44878 3.75 7.70266V14.1197C3.73832 14.3736 3.62657 14.6126 3.43919 14.7845C3.25181 14.9563 3.00403 15.047 2.75 15.0367H0.75'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M23.25 15.0367H21.25C20.996 15.047 20.7482 14.9563 20.5608 14.7845C20.3734 14.6126 20.2617 14.3736 20.25 14.1197V7.70266C20.2617 7.44878 20.3735 7.20986 20.5609 7.03819C20.7483 6.86652 20.9961 6.77608 21.25 6.78666H23.25'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12 16.8296L11.022 15.8516'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.446 15.3627L12.978 13.8947'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIconMediumViewBox>
  )
}

export { HandShakingIcon }
