import React from 'react'

import { SvgIconProps, SvgIconSmallViewBox } from '../../../SvgIcon'

type DropdownIconProps = SvgIconProps

const DropdownIcon: React.FC<DropdownIconProps> = (props) => {
  return (
    <SvgIconSmallViewBox {...props}>
      <path fillRule='evenodd' clipRule='evenodd' d='M8 11L13 5H3L8 11Z' fill='currentColor' />
    </SvgIconSmallViewBox>
  )
}

export { DropdownIcon }
