import React from 'react'

import { useLayoutContext } from '../../../hooks'
import { H1 } from '../../Data Display'
import { PageSection, PageSectionProps } from './PageSection'

type PageSection1Props = Omit<PageSectionProps, 'TitleComponent' | 'titleMb' | 'spacing'>

export const PageSection1: React.FC<PageSection1Props> = (props) => {
  const { isMobile } = useLayoutContext()

  return (
    <PageSection
      TitleComponent={H1}
      hasDivider={false}
      titleMb={isMobile ? 6 : 8}
      spacing={isMobile ? 10 : 16}
      {...props}
    />
  )
}
