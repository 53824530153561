import React from 'react'

const DesjardinsIcon: React.FC = () => {
  return (
    <svg width='37' height='25' viewBox='0 0 37 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.0239868 2.24194C0.0239868 1.41352 0.69556 0.741943 1.52399 0.741943H34.524C35.3524 0.741943 36.024 1.41352 36.024 2.24194V23.2419C36.024 24.0704 35.3524 24.7419 34.524 24.7419H1.52399C0.69556 24.7419 0.0239868 24.0704 0.0239868 23.2419V2.24194Z'
        fill='#00874E'
      />
      <path
        d='M10.9059 8.64231V16.8415L18.024 20.9411L25.142 16.8415V8.64231L18.024 4.54272L10.9059 8.64231ZM23.1961 9.76969V15.7653L18.024 18.7375L12.8519 15.7141V9.76969L18.024 6.7975L23.1961 9.76969Z'
        fill='white'
      />
    </svg>
  )
}

export { DesjardinsIcon }
