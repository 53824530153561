import React from 'react'

import { SvgIconProps, SvgIconSmallViewBox } from '../../../SvgIcon'

type CalendarIconProps = SvgIconProps

const CalendarIcon: React.FC<CalendarIconProps> = (props) => {
  return (
    <SvgIconSmallViewBox {...props}>
      <path
        d='M13.5998 2.3999H2.3998C1.51615 2.3999 0.799805 3.11625 0.799805 3.9999V13.5999C0.799805 14.4836 1.51615 15.1999 2.3998 15.1999H13.5998C14.4835 15.1999 15.1998 14.4836 15.1998 13.5999V3.9999C15.1998 3.11625 14.4835 2.3999 13.5998 2.3999Z'
        stroke='black'
        strokeWidth='1.6'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M4 0.799805V3.9998' stroke='black' strokeWidth='1.6' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M12 0.799805V3.9998' stroke='black' strokeWidth='1.6' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M0.799805 7.19971H15.1998'
        stroke='black'
        strokeWidth='1.6'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIconSmallViewBox>
  )
}

export { CalendarIcon }
