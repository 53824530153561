import React from 'react'

import { SvgIconMediumViewBox, SvgIconProps } from '../../../SvgIcon'

type AirconditionerIconProps = SvgIconProps

const AirconditionerIcon: React.FC<AirconditionerIconProps> = (props) => {
  return (
    <SvgIconMediumViewBox {...props}>
      <path
        d='M2.25 12.75C1.85218 12.75 1.47064 12.592 1.18934 12.3107C0.908035 12.0294 0.75 11.6478 0.75 11.25V3.75C0.75 2.95435 1.06607 2.19129 1.62868 1.62868C2.19129 1.06607 2.95435 0.75 3.75 0.75H20.25C21.0456 0.75 21.8087 1.06607 22.3713 1.62868C22.9339 2.19129 23.25 2.95435 23.25 3.75V11.25C23.25 11.6478 23.092 12.0294 22.8107 12.3107C22.5294 12.592 22.1478 12.75 21.75 12.75'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M0.75 9.75H23.25' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M19.5 4.125C19.5742 4.125 19.6467 4.14699 19.7083 4.1882C19.77 4.2294 19.8181 4.28797 19.8465 4.35649C19.8748 4.42502 19.8823 4.50042 19.8678 4.57316C19.8533 4.6459 19.8176 4.71272 19.7652 4.76516C19.7127 4.81761 19.6459 4.85333 19.5732 4.86779C19.5004 4.88226 19.425 4.87484 19.3565 4.84645C19.288 4.81807 19.2294 4.77001 19.1882 4.70834C19.147 4.64667 19.125 4.57417 19.125 4.5C19.125 4.40054 19.1645 4.30516 19.2348 4.23484C19.3052 4.16451 19.4005 4.125 19.5 4.125Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.25 14.25V17.25C5.25 18.0456 4.93393 18.8087 4.37132 19.3713C3.80871 19.9339 3.04565 20.25 2.25 20.25'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.75 14.25V21.75C9.75 22.1478 9.59196 22.5294 9.31066 22.8107C9.02936 23.092 8.64782 23.25 8.25 23.25'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.75 14.25V17.25C18.75 18.0456 19.0661 18.8087 19.6287 19.3713C20.1913 19.9339 20.9544 20.25 21.75 20.25'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.25 14.25V21.75C14.25 22.1478 14.408 22.5294 14.6893 22.8107C14.9706 23.092 15.3522 23.25 15.75 23.25'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIconMediumViewBox>
  )
}

export { AirconditionerIcon }
