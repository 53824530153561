import React from 'react'

import { Amount, currency, money } from '@guiker/money'

import { Flex } from '../../Layout'
import { P, PSmall } from '../Typography'

type MonetaryAmountProps = React.PropsWithChildren & { amount: Amount }

export const MonetaryAmount: React.FC<MonetaryAmountProps> = ({ amount, children }) => {
  return (
    <Flex gap={0.5} alignItems='baseline'>
      <PSmall mb={0}>{currency[amount.currency].symbol}</PSmall>
      <P mb={0}>{money.fromAmount(amount.value, amount.currency).toString(false, true)}</P>
      {children}
    </Flex>
  )
}
