import React from 'react'

type ContextConsumerNode<Props> = (props: Props) => React.ReactNode

export const renderChildren = function <Props>(
  children: React.ReactNode | ContextConsumerNode<Props>,
  props?: Props,
): React.ReactNode {
  if ((children as ContextConsumerNode<Props>) instanceof Function) {
    return (children as ContextConsumerNode<Props>)(props)
  } else {
    return children as React.ReactNode
  }
}
