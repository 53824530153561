import React from 'react'

import { Toggle, ToggleProps as CLToggleProps } from '@guiker/components-library'
import { useTranslation } from '@guiker/i18n'
import { Controller, ErrorMessages, FieldPath, PathValue, useFormInput } from '@guiker/react-hook-form'

export type ToggleProps<T extends object, P extends FieldPath<T>> = Omit<CLToggleProps, 'name' | 'defaultValue'> &
  ErrorMessages & {
    name: P
    defaultValue?: PathValue<T, P>
  }

export const RHFToggle = <T extends object, P extends FieldPath<T>>({
  defaultValue,
  onChange,
  name,
  ...props
}: ToggleProps<T, P>) => {
  const { t } = useTranslation()
  const { control } = useFormInput<T, P>({ name, t })

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue ?? null}
      render={({ field: { onChange: onChangeRenderProps, value, ref } }) => {
        return (
          <Toggle
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onChange && onChange(e, e.target.checked)
              onChangeRenderProps(e.target.checked)
            }}
            inputRef={ref}
            checked={JSON.parse(value) || false}
            value={value}
            {...props}
          />
        )
      }}
    />
  )
}
