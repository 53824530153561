import React from 'react'

import { SvgIconLargerViewBox, SvgIconProps } from '../../../SvgIcon'

const CompetitiveCompensationIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIconLargerViewBox {...props}>
      <path
        d='M50.0004 38H44.5791C43.7483 38.0006 42.9435 38.2902 42.3029 38.8193C41.6622 39.3483 41.2256 40.0838 41.068 40.8996C40.9103 41.7153 41.0414 42.5605 41.4387 43.2902C41.8361 44.0199 42.475 44.5885 43.2458 44.8987L48.7498 47.1013C49.5206 47.4115 50.1594 47.9801 50.5568 48.7098C50.9542 49.4395 51.0852 50.2847 50.9276 51.1004C50.7699 51.9162 50.3333 52.6517 49.6927 53.1807C49.052 53.7098 48.2473 53.9994 47.4164 54H42.0004'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M46 38V36' stroke='currentColor' strokeWidth='3' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M46 56V54' stroke='currentColor' strokeWidth='3' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M30 46C30 50.2435 31.6857 54.3131 34.6863 57.3137C37.6869 60.3143 41.7565 62 46 62C50.2435 62 54.3131 60.3143 57.3137 57.3137C60.3143 54.3131 62 50.2435 62 46C62 41.7565 60.3143 37.6869 57.3137 34.6863C54.3131 31.6857 50.2435 30 46 30C41.7565 30 37.6869 31.6857 34.6863 34.6863C31.6857 37.6869 30 41.7565 30 46Z'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M54 22V6C54 4.93913 53.5786 3.92172 52.8284 3.17157C52.0783 2.42143 51.0609 2 50 2H6C4.93913 2 3.92172 2.42143 3.17157 3.17157C2.42143 3.92172 2 4.93913 2 6V38C2 39.0609 2.42143 40.0783 3.17157 40.8284C3.92172 41.5786 4.93913 42 6 42H22'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M2 14H54' stroke='currentColor' strokeWidth='3' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M10 24H26' stroke='currentColor' strokeWidth='3' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M10 32H18' stroke='currentColor' strokeWidth='3' strokeLinecap='round' strokeLinejoin='round' />
    </SvgIconLargerViewBox>
  )
}

export { CompetitiveCompensationIcon }
