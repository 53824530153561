import * as React from 'react'

import { clsx } from '@guiker/clsx'
import { AnyColor, makeStyles, theme } from '@guiker/components-core'

import { Box, BoxProps } from '../..'

export type DividerProps = Omit<BoxProps, 'color'> & {
  thickness?: number
  color?: AnyColor
  orientation?: 'horizontal' | 'vertical'
}

const useStyles = makeStyles({
  root: {
    border: 'none',
    backgroundColor: ({ color }: { color: AnyColor }) => theme.palette.getColor(color),
    minHeight: ({ orientation, thickness }: DividerProps) => (orientation === 'horizontal' ? thickness : '100%'),
    width: ({ orientation, thickness, width }: DividerProps) =>
      width ? width : orientation === 'vertical' ? thickness : '100%',
  },
})

const Divider: React.FC<DividerProps> = ({
  className,
  color = 10,
  orientation = 'horizontal',
  thickness = 1,
  width,
  ...other
}) => {
  const classes = useStyles({ orientation, thickness, width, color })

  return (
    <Box component={orientation === 'horizontal' ? 'hr' : 'div'} className={clsx(classes.root, className)} {...other} />
  )
}

export { Divider }
