import React from 'react'

import { SvgIconProps, SvgIconSmallViewBox } from '../../../SvgIcon'

const OutlinedAlertIcon: React.FC<Omit<SvgIconProps, 'small'>> = (props) => {
  return (
    <SvgIconSmallViewBox {...props}>
      <path d='M8 4.7998V7.9998' stroke='currentColor' strokeWidth='1.6' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M8.00039 11.7718C7.66903 11.7718 7.40039 11.5032 7.40039 11.1718C7.40039 10.8404 7.66903 10.5718 8.00039 10.5718'
        stroke='currentColor'
        strokeWidth='1.6'
      />
      <path
        d='M8 11.7718C8.33138 11.7718 8.6 11.5032 8.6 11.1718C8.6 10.8404 8.33138 10.5718 8 10.5718'
        stroke='currentColor'
        strokeWidth='1.6'
      />
      <path
        d='M3.21621 15.2H12.781C13.2009 15.2 13.6136 15.0906 13.9783 14.8825C14.343 14.6745 14.6472 14.3749 14.8609 14.0135C15.0746 13.652 15.1904 13.2411 15.1969 12.8212C15.2033 12.4014 15.1003 11.9871 14.8978 11.6192L9.29461 1.56639C9.1676 1.33406 8.9804 1.1402 8.75264 1.00515C8.5249 0.870094 8.265 0.798828 8.00021 0.798828C7.73543 0.798828 7.47552 0.870094 7.24778 1.00515C7.02002 1.1402 6.83282 1.33406 6.70581 1.56639L1.10261 11.624C0.900465 11.9914 0.797545 12.4051 0.803997 12.8243C0.810446 13.2436 0.926046 13.6539 1.1394 14.0149C1.35275 14.3758 1.65648 14.675 2.02067 14.8828C2.38485 15.0906 2.7969 15.1999 3.21621 15.2Z'
        stroke='currentColor'
        strokeWidth='1.6'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIconSmallViewBox>
  )
}

export { OutlinedAlertIcon }
