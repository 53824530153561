import { Breakpoints, breakpoints } from './breakpoints'
import { dimensions } from './dimensions'
import { darkPalette, Palette, palette } from './palette'
import { Shadows, shadows } from './shadows'
import { Spacing, spacing } from './spacing'
import { transitions } from './transitions'
import { ThemeTypography, typography } from './typography'
import { zIndexs } from './zIndex'

export interface Theme {
  breakpoints: Breakpoints
  dimensions: typeof dimensions
  zIndexs: typeof zIndexs
  palette: Palette
  shadows: Shadows
  typography: ThemeTypography
  spacing: Spacing
  transitions: typeof transitions
}

export const theme: Theme = {
  breakpoints,
  palette,
  shadows,
  typography,
  spacing,
  transitions,
  dimensions,
  zIndexs,
} as const

export const darkTheme: Theme = {
  ...theme,
  palette: darkPalette,
} as const
