import { useEffect, useRef } from 'react'

import { useConfig } from '@guiker/config-context'

export const useLifecycleMonitoring = (name: string) => {
  const { logger } = useConfig()
  const renderingCounter = useRef(0)
  const mountingCounter = useRef(0)

  useEffect(() => {
    mountingCounter.current = mountingCounter.current + 1
    logger.log(`${name}.Mounting ${mountingCounter.current}`)

    return () => {
      logger.log(`${name}.Unounting`)
    }
  }, [])

  useEffect(() => {
    renderingCounter.current = renderingCounter.current + 1
    logger.log(`${name}.Rendering: ${renderingCounter.current}`)
  })
}
