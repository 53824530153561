import React from 'react'

import { AnyColor, theme } from '@guiker/components-core'

type EllipseIconProps = {
  color?: AnyColor
  size?: number
}

const EllipseIcon: React.FC<EllipseIconProps> = ({ color: colorKey, size }) => {
  const color = theme.palette.getColor(colorKey)

  return (
    <svg width={size || '8'} height={size || '8'} viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='4' cy='4' r='4' fill={color || 'currentColor'} />
    </svg>
  )
}

export { EllipseIcon }
