import { BreakpointKey, breakpoints } from '.'

const buildQuery = ({ value, type }: { value: BreakpointKey | number; type: 'min-width' | 'max-width' }) => {
  return value ? `(${type}: ${typeof value === 'number' ? value : breakpoints.values[value]}px)` : null
}

export const buildMediaQuery = ({
  minWidth,
  maxWidth,
}: {
  minWidth?: BreakpointKey | number
  maxWidth?: BreakpointKey | number
}) => {
  return `@media ${[
    buildQuery({ value: minWidth, type: 'min-width' }),
    buildQuery({ value: maxWidth, type: 'max-width' }),
  ]
    .filter((value) => !!value)
    .join(' and ')}`
}

export const isMobile = buildMediaQuery({ maxWidth: 'sm' })
export const isNotMobile = buildMediaQuery({ minWidth: 'sm' })
export const isTablette = buildMediaQuery({ minWidth: 'sm', maxWidth: 'md' })
export const isAtMostTablette = buildMediaQuery({ maxWidth: 'md' })
export const isAtLeastTablette = isNotMobile
export const isMidSizeDesktop = buildMediaQuery({ minWidth: 'md', maxWidth: 'lg' })
export const isAtMostMidSizeDesktop = buildMediaQuery({ maxWidth: 'lg' })
export const isAtLeastMidSizeDesktop = buildMediaQuery({ minWidth: 'md' })
export const isLargeScreenDesktop = buildMediaQuery({ minWidth: 'lg' })
export const isExtraLargeScreenDesktop = buildMediaQuery({ minWidth: 'xl' })
export const isNotLargeScreenDesktop = isAtMostMidSizeDesktop
