import React from 'react'

const ChaseIcon: React.FC = () => {
  return (
    <svg width='37' height='25' viewBox='0 0 37 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.873291 1.56323C0.873291 0.734805 1.54486 0.0632324 2.37329 0.0632324H35.3733C36.2017 0.0632324 36.8733 0.734805 36.8733 1.56323V22.5632C36.8733 23.3917 36.2017 24.0632 35.3733 24.0632H2.37329C1.54486 24.0632 0.873291 23.3917 0.873291 22.5632V1.56323Z'
        fill='#3169BF'
      />
      <path
        d='M16.6784 4.35278C16.3775 4.35278 16.1338 4.59651 16.1338 4.89704V8.7142H26.2169L21.6251 4.35314L16.6784 4.35278Z'
        fill='white'
      />
      <path
        d='M26.5858 9.86898C26.5858 9.56775 26.3438 9.32507 26.0412 9.32507H22.2251V19.4085L26.584 14.8146L26.5858 9.86898Z'
        fill='white'
      />
      <path
        d='M21.0689 19.7735C21.3691 19.7735 21.6128 19.5295 21.6128 19.2282V15.4125H11.5293L16.1218 19.7728L21.0689 19.7735Z'
        fill='white'
      />
      <path
        d='M11.1608 14.2581C11.1608 14.559 11.4045 14.8041 11.7061 14.8041H15.5215V4.71997L11.1615 9.31248L11.1608 14.2581Z'
        fill='white'
      />
    </svg>
  )
}

export { ChaseIcon }
