import React from 'react'

const LaurentianIcon: React.FC = () => {
  return (
    <svg width='37' height='25' viewBox='0 0 37 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.378815 2.02136C0.378815 1.19294 1.05039 0.521362 1.87881 0.521362H34.8788C35.7072 0.521362 36.3788 1.19294 36.3788 2.02136V23.0214C36.3788 23.8498 35.7072 24.5214 34.8788 24.5214H1.87882C1.05039 24.5214 0.378815 23.8498 0.378815 23.0214V2.02136Z'
        fill='#0B3B6A'
      />
      <path
        d='M11.208 9.3647L13.7167 11.8801H16.4154V9.17545L13.9092 6.66095L11.208 9.3647ZM10.0833 11.9078V15.7392H13.6366L15.5423 13.8235L13.6366 11.9078H10.0833ZM16.4623 13.1553V19.4518H20.2949V13.1553L18.3788 11.2492L16.4623 13.1553ZM23.1202 11.9078L21.215 13.8235L23.1202 15.7392H26.6743V11.9078H23.1202ZM25.5493 9.3647L22.848 6.66095L20.3414 9.17545V11.8801H23.0406L25.5493 9.3647ZM16.4623 9.16051L18.3788 11.0666L20.2949 9.16051V5.59106H16.4623V9.16051Z'
        fill='#F9AE26'
      />
    </svg>
  )
}

export { LaurentianIcon }
