import React from 'react'

import { SvgIconProps, SvgIconSmallViewBox } from '../../../SvgIcon'

const DollarIcon: React.FC<Omit<SvgIconProps, 'small'>> = (props) => {
  return (
    <SvgIconSmallViewBox {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10 4.99998H7.19267C6.86912 4.97415 6.55052 5.09148 6.32101 5.32099C6.0915 5.5505 5.97417 5.8691 6 6.19265C6 7.49998 10 8.49998 10 9.80731C10.0058 10.1254 9.88207 10.4322 9.65712 10.6571C9.43218 10.8821 9.12541 11.0058 8.80733 11H6'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M8 5V3.5' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M8 12.5V11' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    </SvgIconSmallViewBox>
  )
}

export { DollarIcon }
