import React from 'react'

import { SvgIconMediumViewBox, SvgIconProps } from '../../../SvgIcon'

const ArrowLeftIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIconMediumViewBox {...props}>
      <path d='M24.25 12H1.75' stroke={'currentColor'} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M12.25 1.5L1.75 12L12.25 22.5'
        stroke={'currentColor'}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIconMediumViewBox>
  )
}

export { ArrowLeftIcon }
