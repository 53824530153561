import React, { useEffect } from 'react'

import { clsx } from '@guiker/clsx'
import { makeStyles } from '@guiker/components-core'
import { isLocalhost, safelyGetWindow } from '@guiker/react-utils'

const useStyles = makeStyles({
  root: {
    width: ({ width }: { width?: number | string }) => width ?? '100%',
    height: ({ height }: { height?: number | string }) => height ?? '100vh',
    overflow: 'visible',
    border: 'none',
    boxShadow: 'none',
  },
})

export type IFrameProps<T> = {
  className?: string
  src: string
  width?: number | string
  height?: number | string
  messageHandler?: (payload: T) => void
  originRegex?: RegExp
}

const isValidOrigin = (origin: string, originRegex?: RegExp) => isLocalhost() || originRegex?.test(origin)

export const useIFrameMessage = (handler: (payload: unknown) => void, originRegex?: RegExp) => {
  const _windows = safelyGetWindow()

  return useEffect(() => {
    const parseAndHandle = (event: MessageEvent) => {
      if (!event || !event.data || !isValidOrigin(event.origin, originRegex)) return
      handler(event.data)
    }

    _windows?.parent.removeEventListener('message', parseAndHandle)
    _windows?.parent.addEventListener('message', parseAndHandle)
    return () => _windows?.parent.removeEventListener('message', parseAndHandle)
  }, [handler])
}

export const IFrame = <T extends object = object>(props: IFrameProps<T>) => {
  const { className, src, width, height, messageHandler, originRegex } = props
  const classes = useStyles({ width, height })
  messageHandler && useIFrameMessage(messageHandler, originRegex)

  return <iframe className={clsx(className, classes.root)} src={src} />
}
