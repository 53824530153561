import React, { CSSProperties, ReactNode } from 'react'

import { isString } from '@guiker/lodash'

import { Flex, FlexProps } from '../../Layout'
import { H5, P } from '../Typography'

type GridTextProps = {
  title: string
  mainText: string | ReactNode
  secondaryText?: string | ReactNode
  link?: ReactNode
  textAlign?: CSSProperties['textAlign']
  alignItems?: FlexProps['alignItems']
}

export const GridText: React.FC<GridTextProps> = ({
  title,
  mainText,
  secondaryText,
  link,
  textAlign = 'left',
  alignItems = 'flex-start',
}) => {
  return (
    <Flex flexDirection='column' alignItems={alignItems}>
      <H5 textAlign={textAlign} mb={1}>
        {title}
      </H5>
      {isString(mainText) ? (
        <P textAlign={textAlign} mb={0}>
          {mainText}
        </P>
      ) : (
        mainText
      )}
      {!!secondaryText ? (
        isString(secondaryText) ? (
          <P textAlign={textAlign} color={60} mb={0}>
            {secondaryText}
          </P>
        ) : (
          secondaryText
        )
      ) : null}
      {!!link && link}
    </Flex>
  )
}
