import React from 'react'

import { CurrencyTextField as CLCurrencyTextField } from '@guiker/components-library'
import { FieldPath } from '@guiker/react-hook-form'

import { useApiFormContext } from '../Form'
import { CurrencyTextFieldProps as RHFCurrencyTextFieldProps, RHFCurrencyTextField } from './RHFCurrencyTextField'

export type CurrencyTextFieldProps<T extends object = any, P extends FieldPath<T> = any> = RHFCurrencyTextFieldProps<
  T,
  P
>

const CurrencyTextField = <T extends object = any, P extends FieldPath<T> = any>({
  defaultValue,
  type,
  ...props
}: CurrencyTextFieldProps<T, P>) => {
  const { readOnly: contextReadOnly } = useApiFormContext({ shouldThrowIfUndefined: false })
  if (props.readOnly) {
    return <CLCurrencyTextField value={defaultValue || '-'} {...props} />
  } else {
    return <RHFCurrencyTextField defaultValue={defaultValue} type={type} readOnly={contextReadOnly} {...props} />
  }
}

export { CurrencyTextField }
