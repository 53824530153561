import React from 'react'

import { SvgIconMediumViewBox, SvgIconProps } from '../../SvgIcon'

type FileTextCheckIconProps = SvgIconProps

const FileTextCheckIcon: React.FC<FileTextCheckIconProps> = (props) => {
  return (
    <SvgIconMediumViewBox {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.25 23.25C20.5637 23.25 23.25 20.5637 23.25 17.25C23.25 13.9363 20.5637 11.25 17.25 11.25C13.9363 11.25 11.25 13.9363 11.25 17.25C11.25 20.5637 13.9363 23.25 17.25 23.25Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M19.9237 15.5059L17.0187 19.3789C16.888 19.5525 16.6885 19.6611 16.4716 19.6767C16.2548 19.6922 16.0419 19.6131 15.8877 19.4599L14.3877 17.9599'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M3.75 6.75H14.25' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M3.75 11.25H9.75' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M3.75 15.75H7.5' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M7.5 20.25H2.25C1.42157 20.25 0.75 19.5784 0.75 18.75V2.25C0.75 1.42157 1.42157 0.75 2.25 0.75H12.879C13.2765 0.750085 13.6578 0.907982 13.939 1.189L16.811 4.061C17.092 4.3422 17.2499 4.72345 17.25 5.121V7.5'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIconMediumViewBox>
  )
}

export { FileTextCheckIcon }
