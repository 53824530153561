import React from 'react'

import { RichTextEditor, RichTextEditorProps as CLRichTextEditorProps } from '@guiker/components-library'
import { useTranslation } from '@guiker/i18n'
import { Controller, ErrorMessages, FieldPath, PathValue, useFormInput } from '@guiker/react-hook-form'

export type RichTextEditorProps<T extends object, P extends FieldPath<T>> = Omit<
  CLRichTextEditorProps,
  'name' | 'defaultValue'
> &
  ErrorMessages & {
    name: P
    defaultValue?: PathValue<T, P>
  }

const RHFRichTextEditor = <T extends object, P extends FieldPath<T>>({
  defaultValue,
  name,
  onChange,
  errorMessages = {},
  ...props
}: RichTextEditorProps<T, P>) => {
  const { t } = useTranslation()
  const { control, errorType, errorMessage } = useFormInput<T, P>({ name, t })

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange: onChangeRenderProps, ref, ...renderProps }, fieldState }) => (
        <RichTextEditor
          defaultValue={defaultValue}
          onChange={(htmlContent: string) => {
            onChange && onChange(htmlContent)
            onChangeRenderProps(htmlContent)
          }}
          error={!!fieldState.error}
          errorMessage={errorMessages[errorType] || (fieldState.error && errorMessage)}
          editorRef={ref}
          {...renderProps}
          {...props}
        />
      )}
    />
  )
}

export { RHFRichTextEditor }
