import React from 'react'

import { SvgIconMediumViewBox, SvgIconProps } from '../../../SvgIcon'

type DishwasherIconProps = SvgIconProps

const DishwasherIcon: React.FC<DishwasherIconProps> = (props) => {
  return (
    <SvgIconMediumViewBox {...props}>
      <path
        d='M0.75 9C0.75 11.188 1.61919 13.2865 3.16637 14.8336C4.71354 16.3808 6.81196 17.25 9 17.25C11.188 17.25 13.2865 16.3808 14.8336 14.8336C16.3808 13.2865 17.25 11.188 17.25 9C17.25 6.81196 16.3808 4.71354 14.8336 3.16637C13.2865 1.61919 11.188 0.75 9 0.75C6.81196 0.75 4.71354 1.61919 3.16637 3.16637C1.61919 4.71354 0.75 6.81196 0.75 9V9Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.25 9C5.25 9.99456 5.64509 10.9484 6.34835 11.6517C7.05161 12.3549 8.00544 12.75 9 12.75C9.99456 12.75 10.9484 12.3549 11.6517 11.6517C12.3549 10.9484 12.75 9.99456 12.75 9C12.75 8.00544 12.3549 7.05161 11.6517 6.34835C10.9484 5.64509 9.99456 5.25 9 5.25C8.00544 5.25 7.05161 5.64509 6.34835 6.34835C5.64509 7.05161 5.25 8.00544 5.25 9V9Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.63 20.243C9.36133 21.1311 10.2703 21.8563 11.2985 22.3723C12.3268 22.8882 13.4516 23.1834 14.6006 23.2389C15.7497 23.2944 16.8977 23.1089 17.9709 22.6944C19.044 22.2799 20.0186 21.6456 20.8321 20.8321C21.6456 20.0186 22.2799 19.044 22.6944 17.9709C23.1089 16.8977 23.2944 15.7497 23.2389 14.6006C23.1834 13.4516 22.8882 12.3268 22.3723 11.2985C21.8563 10.2703 21.1311 9.36133 20.243 8.63'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.645 18.733C14.762 18.744 14.88 18.75 15 18.75C15.9946 18.75 16.9484 18.3549 17.6517 17.6517C18.3549 16.9484 18.75 15.9946 18.75 15C18.75 14.88 18.744 14.762 18.733 14.645'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIconMediumViewBox>
  )
}

export { DishwasherIcon }
