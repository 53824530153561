import React, { useEffect, useState } from 'react'

import { clsx, lighten, makeStyles } from '@guiker/components-core'
import { CollapseProps, default as BaseCollapse } from '@material-ui/core/Collapse'

import { H4, RotatingIcon } from '../..'
import { Flex } from '../../Layout'

type Props = CollapseProps & {
  header: React.ReactNode
  hasBorder?: boolean
  onClick?: () => void
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
    userSelect: 'none',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderBottom: ({ hasBorder }: { hasBorder: boolean }) => {
      return hasBorder ? `1px solid ${lighten(theme.palette.background.secondary, 0.8)}` : 'none'
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },
  icon: {
    marginLeft: theme.spacing(1),
    width: 12,
    display: 'flex',
    alignItems: 'center',
    transition: theme.transitions.create(['transform'], {
      duration: theme.transitions.duration.short,
    }),
  },
}))

export const CollapseWithHeader: React.FC<Props> = ({
  children,
  onClick,
  in: collapsedProp = false,
  hasBorder = true,
  header,
  className,
  ...props
}) => {
  const classes = useStyles({ hasBorder })
  const [collapsed, setCollapsed] = useState(collapsedProp)

  useEffect(() => {
    collapsed !== collapsedProp && setCollapsed(collapsedProp)
  }, [collapsedProp])

  return (
    <Flex flexDirection='column' gap={2}>
      <div
        onClick={() => {
          setCollapsed(!collapsed)
          onClick?.()
        }}
        className={clsx(className, classes.container)}
      >
        <H4 mb={0}> {header}</H4>
        <RotatingIcon isRotated={collapsed} size='smaller' />
      </div>
      <BaseCollapse in={collapsed} {...props}>
        <Flex mb={2} flexDirection='column'>
          {children}
        </Flex>
      </BaseCollapse>
    </Flex>
  )
}
