import { useEffect, useState } from 'react'

import { safelyGetWindow } from '@guiker/react-utils'

export const useDOMElementInserted = (elementId: string, parent: Element = document.body) => {
  const [element, setElement] = useState<Element>()

  useEffect(() => {
    if (element) return
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (!mutation.addedNodes) return

        setElement(() => safelyGetWindow().document.getElementById(elementId))
        observer.disconnect()
      })
    })

    observer.observe(parent, {
      childList: true,
      subtree: true,
      attributes: false,
      characterData: false,
    })

    return () => observer.disconnect()
  }, [elementId, parent])

  return element
}
