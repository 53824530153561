import React from 'react'

import { Checkbox as CLCheckbox } from '@guiker/components-library'
import { FieldPath } from '@guiker/react-hook-form'

import { useApiFormContext } from '../Form'
import { RHFCheckbox, RHFCheckboxProps } from './RHFCheckbox'

export type CheckboxProps<T extends object, P extends FieldPath<T>> = RHFCheckboxProps<T, P>

export const Checkbox = <T extends object = any, P extends FieldPath<T> = any>(props: CheckboxProps<T, P>) => {
  const { readOnly: contextReadOnly } = useApiFormContext({ shouldThrowIfUndefined: false })
  if (props.readOnly) {
    const defaultChecked =
      props.defaultValue === null || props.defaultValue === undefined
        ? false
        : Boolean(JSON.parse(props.defaultValue as unknown as string))

    return <CLCheckbox defaultChecked={defaultChecked} {...props} />
  } else {
    return <RHFCheckbox {...props} readOnly={contextReadOnly} />
  }
}
