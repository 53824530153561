import React from 'react'

import { SvgIconMediumViewBox, SvgIconProps } from '../../../SvgIcon'

const PropertySaleIcon: React.FC<SvgIconProps> = ({ strokeWidth = 1.5, ...props }) => {
  return (
    <SvgIconMediumViewBox {...props}>
      <path
        d='M13.5 17.25V21.75C13.5 22.5784 14.1716 23.25 15 23.25H16.5'
        stroke='currentColor'
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.5 17.25H3C2.25716 17.1765 1.59138 17.7091 1.5 18.45C1.5 20.25 4.5 20.25 4.5 22.05C4.40862 22.7908 3.74284 23.3235 3 23.25H1.5'
        stroke='currentColor'
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.5 23.25V18.75C7.5 17.9216 8.17157 17.25 9 17.25C9.82843 17.25 10.5 17.9216 10.5 18.75V23.25'
        stroke='currentColor'
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M7.5 20.25H10.5' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M22.5 23.25H21C20.1716 23.25 19.5 22.5784 19.5 21.75V18.75C19.5 17.9216 20.1716 17.25 21 17.25H22.5'
        stroke='currentColor'
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M19.5 20.25H22.5' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M3.75101 14.25V1.5C3.75178 1.2545 3.87266 1.02492 4.07463 0.885353C4.27661 0.745787 4.5341 0.713905 4.76401 0.799996L13.014 5.07C13.3061 5.17937 13.5 5.4581 13.501 5.77V14.25'
        stroke='currentColor'
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.00101 5.25101H3.75101'
        stroke='currentColor'
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.50101 8.25101H3.75101'
        stroke='currentColor'
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.251 9.75H13.501'
        stroke='currentColor'
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.251 12.75H13.501'
        stroke='currentColor'
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.75101 3.37901V0.751007'
        stroke='currentColor'
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.5 6.75H19.5C20.3284 6.75 21 7.42157 21 8.25V14.25'
        stroke='currentColor'
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIconMediumViewBox>
  )
}

export { PropertySaleIcon }
