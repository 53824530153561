import React from 'react'

const CitiIcon: React.FC = () => {
  return (
    <svg width='37' height='25' viewBox='0 0 37 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.0239868 1.8479C0.0239868 1.01947 0.69556 0.3479 1.52399 0.3479H34.524C35.3524 0.3479 36.024 1.01947 36.024 1.8479V22.8479C36.024 23.6763 35.3524 24.3479 34.524 24.3479H1.52399C0.69556 24.3479 0.0239868 23.6763 0.0239868 22.8479V1.8479Z'
        fill='#004685'
      />
      <path
        d='M13.9785 16.0643L13.9403 16.1014C13.3417 16.7129 12.6489 17.0359 11.9353 17.0359C10.4449 17.0359 9.36371 15.915 9.36371 14.3689C9.36371 12.8252 10.4449 11.7025 11.9353 11.7025C12.6489 11.7025 13.3417 12.0274 13.9403 12.6413L13.9785 12.6777L14.9369 11.5156L14.9113 11.4845C14.1143 10.5404 13.1576 10.0812 11.9817 10.0812C10.8029 10.0812 9.7235 10.4795 8.94673 11.1967C8.10086 11.9742 7.65588 13.0707 7.65588 14.3689C7.65588 15.6672 8.10086 16.766 8.94673 17.5435C9.7235 18.2649 10.8029 18.6603 11.9817 18.6603C13.1576 18.6603 14.1143 18.2004 14.9113 17.2557L14.9369 17.227L13.9785 16.0643Z'
        fill='white'
      />
      <path d='M15.905 18.5018H17.5979V10.2153H15.905V18.5018Z' fill='white' />
      <path
        d='M24.2305 16.7005C23.7784 16.9764 23.3584 17.1156 22.9808 17.1156C22.4333 17.1156 22.1855 16.8253 22.1855 16.1804V11.7833H23.9112V10.2235H22.1855V7.64484L20.5266 8.53584V10.2235H19.0945V11.7833H20.5266V16.4605C20.5266 17.7342 21.2795 18.6061 22.4042 18.6288C23.1672 18.6437 23.6271 18.4156 23.9059 18.2484L23.9237 18.2371L24.3306 16.639L24.2305 16.7005Z'
        fill='white'
      />
      <path d='M25.6319 18.5018H27.3272V10.2153H25.6319V18.5018Z' fill='white' />
      <path
        d='M28.3146 8.76286C26.7676 6.56643 24.1991 5.25621 21.5858 5.25621C18.9731 5.25621 16.404 6.56643 14.8599 8.76286L14.7801 8.87573H16.728L16.7494 8.85244C18.0748 7.48012 19.8059 6.75514 21.5858 6.75514C23.3657 6.75514 25.0956 7.48012 26.4239 8.85244L26.4454 8.87573H28.3921L28.3146 8.76286Z'
        fill='#EE1C25'
      />
    </svg>
  )
}

export { CitiIcon }
