import { useEffect, useMemo, useRef, useState } from 'react'

import { safelyGetWindow } from '@guiker/react-utils'

import { InPageNavigationItem } from './InPageNavigationItem'
import { useDOMElementInserted } from './use-dom-element-added'

const updateElementsBasedOnIntersection = (elements: Element[], entry: IntersectionObserverEntry) => {
  if (entry.isIntersecting) {
    elements.push(entry.target)
  } else {
    elements = elements.filter((e) => e !== entry.target)
  }

  return elements
}

export const useGetOnScreenElements = (elements: Element[]) => {
  const [intersectingElements, setIntersectingElements] = useState<Element[]>([])
  const observerRef = useRef(null)

  useEffect(() => {
    observerRef.current = new IntersectionObserver((entries) => {
      setIntersectingElements((elements) => {
        let newElements = [...elements]

        entries.forEach((entry) => {
          newElements = updateElementsBasedOnIntersection(newElements, entry)
        })

        return newElements
      })
    })

    elements.map((el) => observerRef.current.observe(el))

    return () => {
      observerRef.current.disconnect()
    }
  }, [elements])

  return intersectingElements
}

const getElements = (items: InPageNavigationItem[]) => {
  return items
    .map(({ href }) => href?.startsWith('#') && safelyGetWindow()?.document.getElementById(href.replace('#', '')))
    .filter((v) => !!v)
}

export const useSideMenuActiveItem = ({
  items,
}: {
  items: InPageNavigationItem[]
  offset: number
  threshold: number
}) => {
  if (!items || items.length === 0) return null

  const foundElement = useDOMElementInserted(items[0]?.href)
  const elements = useMemo(() => getElements(items), [items, foundElement])
  const onScreenElements = useGetOnScreenElements(elements)
  const elementId = onScreenElements[0]?.id

  return elementId ? `#${elementId}` : null
}
