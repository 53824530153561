import React from 'react'

import { SvgIcon, SvgIconProps } from '../../SvgIcon'

type SuccessCheckmarkIconProps = SvgIconProps

const SuccessCheckmarkIcon: React.FC<SuccessCheckmarkIconProps> = ({ width = 64, height = 64, ...props }) => {
  return (
    <SvgIcon width={width} height={height} viewBox='1 1 63 63' {...props}>
      <path
        d='M32.0001 62.2222C48.6913 62.2222 62.2223 48.6913 62.2223 32C62.2223 15.3087 48.6913 1.77777 32.0001 1.77777C15.3088 1.77777 1.77783 15.3087 1.77783 32C1.77783 48.6913 15.3088 62.2222 32.0001 62.2222Z'
        fill='currentColor'
      />
      <path
        d='M32.0002 58.1037C46.4169 58.1037 58.1039 46.4167 58.1039 32C58.1039 17.5833 46.4169 5.89624 32.0002 5.89624C17.5835 5.89624 5.89648 17.5833 5.89648 32C5.89648 46.4167 17.5835 58.1037 32.0002 58.1037Z'
        fill='#fafafa'
      />
      <path
        d='M28.6822 41.5551L20.5845 33.4574L23.4993 30.5426L28.6822 35.7256L40.5055 23.9023L43.4203 26.8171L28.6822 41.5551Z'
        fill='currentColor'
      />
    </SvgIcon>
  )
}

export { SuccessCheckmarkIcon }
