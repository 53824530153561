import React from 'react'

import { AnyColor, theme } from '@guiker/components-core'

type DeleteMiniIconProps = {
  color?: AnyColor
}

const DeleteMiniIcon: React.FC<DeleteMiniIconProps> = ({ color }) => {
  const colorKey = theme.palette.getColor(color)
  const fill = colorKey || 'currentColor'

  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12.9981 7.0469C12.9445 6.98721 12.879 6.93947 12.8057 6.90678C12.7325 6.8741 12.6532 6.8572 12.573 6.85719H3.43012C3.3497 6.85673 3.27008 6.87326 3.19649 6.90569C3.12289 6.93812 3.05697 6.98571 3.00304 7.04537C2.9491 7.10502 2.90836 7.17539 2.88349 7.25187C2.85861 7.32835 2.85016 7.40922 2.85869 7.48919L3.71583 15.4892C3.73084 15.63 3.79761 15.7602 3.90319 15.8546C4.00877 15.949 4.14564 16.0008 4.28726 16H11.7158C11.8575 16.0008 11.9943 15.949 12.0999 15.8546C12.2055 15.7602 12.2723 15.63 12.2873 15.4892L13.1444 7.48919C13.1525 7.40933 13.1436 7.32867 13.1184 7.25246C13.0932 7.17625 13.0522 7.10621 12.9981 7.0469Z'
        fill={fill}
      />
      <path
        d='M14.2866 3.19086H11.4294C11.3536 3.19086 11.281 3.16075 11.2274 3.10717C11.1738 3.05359 11.1437 2.98092 11.1437 2.90514V2C11.1431 1.46975 10.9322 0.961396 10.5572 0.586455C10.1823 0.211513 9.67394 0.00060496 9.14369 0L6.85798 0C6.33356 0.0022826 5.83083 0.209517 5.45713 0.577444C5.08344 0.945371 4.86842 1.44483 4.85798 1.96914L4.84427 2.90857C4.84307 2.98366 4.81248 3.05528 4.75906 3.10806C4.70563 3.16084 4.63365 3.19057 4.55855 3.19086H1.71512C1.41202 3.19086 1.12133 3.31126 0.907001 3.52559C0.692673 3.73992 0.572266 4.03061 0.572266 4.33371C0.572266 4.63682 0.692673 4.92751 0.907001 5.14184C1.12133 5.35616 1.41202 5.47657 1.71512 5.47657H14.2866C14.5897 5.47657 14.8803 5.35616 15.0947 5.14184C15.309 4.92751 15.4294 4.63682 15.4294 4.33371C15.4294 4.03061 15.309 3.73992 15.0947 3.52559C14.8803 3.31126 14.5897 3.19086 14.2866 3.19086ZM6.57227 1.99543C6.57376 1.92054 6.60448 1.84921 6.65787 1.79667C6.71126 1.74414 6.78308 1.71457 6.85798 1.71429H9.14369C9.21947 1.71429 9.29214 1.74439 9.34572 1.79797C9.39931 1.85155 9.42941 1.92422 9.42941 2V2.90514C9.42941 2.98092 9.39931 3.05359 9.34572 3.10717C9.29214 3.16075 9.21947 3.19086 9.14369 3.19086H6.84426C6.8064 3.19071 6.76894 3.18307 6.73404 3.16837C6.69915 3.15367 6.66751 3.13221 6.64095 3.10522C6.61438 3.07824 6.59343 3.04626 6.57929 3.01114C6.56515 2.97601 6.5581 2.93843 6.55855 2.90057L6.57227 1.99543Z'
        fill={fill}
      />
    </svg>
  )
}

export { DeleteMiniIcon }
