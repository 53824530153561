import { Children, cloneElement, isValidElement, ReactElement, ReactNode } from 'react'

import { isFragment } from 'react-is'

type ReactChild = ReactElement | string | number

export const flattenChildren = (children: ReactNode, depth = 0, keys: (string | number)[] = []): ReactChild[] => {
  return Children.toArray(children)
    .filter((child) => !!child)
    .reduce((acc: ReactChild[], node, nodeIndex) => {
      if (isFragment(node)) {
        return [
          ...acc,
          ...flattenChildren(
            (node as ReactElement).props.children,
            depth + 1,
            keys.concat((node as ReactElement).key || nodeIndex),
          ),
        ]
      }

      if (isValidElement(node)) {
        return [
          ...acc,
          cloneElement(node, {
            key: keys.concat(String(node.key)).join('.'),
          }),
        ]
      }

      if (typeof node === 'string' || typeof node === 'number') {
        return [...acc, node]
      }

      return acc
    }, []) as ReactChild[]
}
