import { useEffect, useState } from 'react'

export const useWasComponentRendered = () => {
  const [wasComponentRendered, setWasComponentRendered] = useState(false)

  useEffect(() => {
    setWasComponentRendered(true)

    // return () => setWasComponentRendered(false)
  }, [])

  return wasComponentRendered
}
