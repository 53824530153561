import React from 'react'

import { SvgIconMediumViewBox, SvgIconProps } from '../../../SvgIcon'

type LinkedInIconProps = SvgIconProps

const LinkedInIcon: React.FC<LinkedInIconProps> = (props) => {
  return (
    <SvgIconMediumViewBox {...props}>
      <path
        d='M23.994 24.7683V24.7673H24V15.9653C24 11.6593 23.073 8.34229 18.039 8.34229C15.619 8.34229 13.995 9.67029 13.332 10.9293H13.262V8.74429H8.48901V24.7673H13.459V16.8333C13.459 14.7443 13.855 12.7243 16.442 12.7243C18.991 12.7243 19.029 15.1083 19.029 16.9673V24.7683H23.994Z'
        fill='currentColor'
      />
      <path d='M0.395996 8.74536H5.372V24.7684H0.395996V8.74536Z' fill='currentColor' />
      <path
        d='M2.882 0.768311C1.291 0.768311 0 2.05931 0 3.65031C0 5.24131 1.291 6.55931 2.882 6.55931C4.473 6.55931 5.764 5.24131 5.764 3.65031C5.763 2.05931 4.472 0.768311 2.882 0.768311V0.768311Z'
        fill='currentColor'
      />
    </SvgIconMediumViewBox>
  )
}

export { LinkedInIcon }
