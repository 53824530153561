import React from 'react'

import { SvgIconMediumViewBox, SvgIconProps } from '../../../SvgIcon'

type ElectricityIconProps = SvgIconProps

const ElectricityIcon: React.FC<ElectricityIconProps> = (props) => {
  return (
    <SvgIconMediumViewBox {...props}>
      <path
        d='M17.8478 11.3061C17.9432 11.1511 17.9955 10.9735 17.9993 10.7915C18.0031 10.6096 17.9583 10.43 17.8696 10.2711C17.7808 10.1123 17.6513 9.97996 17.4944 9.88784C17.3375 9.79572 17.1588 9.74713 16.9768 9.74707H13.4998V0.74707L6.15183 12.6891C6.05642 12.844 6.00408 13.0216 6.00023 13.2034C5.99638 13.3853 6.04116 13.565 6.12994 13.7238C6.21872 13.8826 6.34828 14.0148 6.50525 14.1068C6.66222 14.1988 6.8409 14.2472 7.02283 14.2471H10.4998V23.2471L17.8478 11.3061Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIconMediumViewBox>
  )
}

export { ElectricityIcon }
