import React from 'react'

import { SvgIconLargerViewBox, SvgIconProps } from '../../../SvgIcon'

const CentralLocationIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIconLargerViewBox {...props}>
      <path d='M54 10V62' stroke='currentColor' strokeWidth='3' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M10 10H54' stroke='currentColor' strokeWidth='3' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M10 62V10' stroke='currentColor' strokeWidth='3' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M54 62H10' stroke='currentColor' strokeWidth='3' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M54 10H10L14 2H50L54 10Z'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M2 62H62' stroke='currentColor' strokeWidth='3' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M38 56C38 54.4087 37.3679 52.8826 36.2426 51.7574C35.1174 50.6321 33.5913 50 32 50C30.4087 50 28.8826 50.6321 27.7574 51.7574C26.6321 52.8826 26 54.4087 26 56V62H38V56Z'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20 17C20.2652 17 20.5196 17.1054 20.7071 17.2929C20.8946 17.4804 21 17.7348 21 18'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M19 18C19 17.7348 19.1054 17.4804 19.2929 17.2929C19.4804 17.1054 19.7348 17 20 17'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20 19C19.7348 19 19.4804 18.8946 19.2929 18.7071C19.1054 18.5196 19 18.2652 19 18'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M21 18C21 18.2652 20.8946 18.5196 20.7071 18.7071C20.5196 18.8946 20.2652 19 20 19'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20 29C20.2652 29 20.5196 29.1054 20.7071 29.2929C20.8946 29.4804 21 29.7348 21 30'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M19 30C19 29.7348 19.1054 29.4804 19.2929 29.2929C19.4804 29.1054 19.7348 29 20 29'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20 31C19.7348 31 19.4804 30.8946 19.2929 30.7071C19.1054 30.5196 19 30.2652 19 30'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M21 30C21 30.2652 20.8946 30.5196 20.7071 30.7071C20.5196 30.8946 20.2652 31 20 31'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20 41C20.2652 41 20.5196 41.1054 20.7071 41.2929C20.8946 41.4804 21 41.7348 21 42'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M19 42C19 41.7348 19.1054 41.4804 19.2929 41.2929C19.4804 41.1054 19.7348 41 20 41'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20 43C19.7348 43 19.4804 42.8946 19.2929 42.7071C19.1054 42.5196 19 42.2652 19 42'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M21 42C21 42.2652 20.8946 42.5196 20.7071 42.7071C20.5196 42.8946 20.2652 43 20 43'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M32 17C32.2652 17 32.5196 17.1054 32.7071 17.2929C32.8946 17.4804 33 17.7348 33 18'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M31 18C31 17.7348 31.1054 17.4804 31.2929 17.2929C31.4804 17.1054 31.7348 17 32 17'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M32 19C31.7348 19 31.4804 18.8946 31.2929 18.7071C31.1054 18.5196 31 18.2652 31 18'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M33 18C33 18.2652 32.8946 18.5196 32.7071 18.7071C32.5196 18.8946 32.2652 19 32 19'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M32 29C32.2652 29 32.5196 29.1054 32.7071 29.2929C32.8946 29.4804 33 29.7348 33 30'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M31 30C31 29.7348 31.1054 29.4804 31.2929 29.2929C31.4804 29.1054 31.7348 29 32 29'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M32 31C31.7348 31 31.4804 30.8946 31.2929 30.7071C31.1054 30.5196 31 30.2652 31 30'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M33 30C33 30.2652 32.8946 30.5196 32.7071 30.7071C32.5196 30.8946 32.2652 31 32 31'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M32 41C32.2652 41 32.5196 41.1054 32.7071 41.2929C32.8946 41.4804 33 41.7348 33 42'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M31 42C31 41.7348 31.1054 41.4804 31.2929 41.2929C31.4804 41.1054 31.7348 41 32 41'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M32 43C31.7348 43 31.4804 42.8946 31.2929 42.7071C31.1054 42.5196 31 42.2652 31 42'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M33 42C33 42.2652 32.8946 42.5196 32.7071 42.7071C32.5196 42.8946 32.2652 43 32 43'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M44 17C44.2652 17 44.5196 17.1054 44.7071 17.2929C44.8946 17.4804 45 17.7348 45 18'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M43 18C43 17.7348 43.1054 17.4804 43.2929 17.2929C43.4804 17.1054 43.7348 17 44 17'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M44 19C43.7348 19 43.4804 18.8946 43.2929 18.7071C43.1054 18.5196 43 18.2652 43 18'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M45 18C45 18.2652 44.8946 18.5196 44.7071 18.7071C44.5196 18.8946 44.2652 19 44 19'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M44 29C44.2652 29 44.5196 29.1054 44.7071 29.2929C44.8946 29.4804 45 29.7348 45 30'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M43 30C43 29.7348 43.1054 29.4804 43.2929 29.2929C43.4804 29.1054 43.7348 29 44 29'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M44 31C43.7348 31 43.4804 30.8946 43.2929 30.7071C43.1054 30.5196 43 30.2652 43 30'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M45 30C45 30.2652 44.8946 30.5196 44.7071 30.7071C44.5196 30.8946 44.2652 31 44 31'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M44 41C44.2652 41 44.5196 41.1054 44.7071 41.2929C44.8946 41.4804 45 41.7348 45 42'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M43 42C43 41.7348 43.1054 41.4804 43.2929 41.2929C43.4804 41.1054 43.7348 41 44 41'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M44 43C43.7348 43 43.4804 42.8946 43.2929 42.7071C43.1054 42.5196 43 42.2652 43 42'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M45 42C45 42.2652 44.8946 42.5196 44.7071 42.7071C44.5196 42.8946 44.2652 43 44 43'
        stroke='currentColor'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIconLargerViewBox>
  )
}

export { CentralLocationIcon }
