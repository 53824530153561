import { useLocationQuery } from '.'

export const usePaginationQueryParams = ({
  key = 'page',
  defaultPage = 1,
}: { key?: string; defaultPage?: number } = {}) => {
  const queryParams = useLocationQuery(key)
  const page = parseInt(queryParams.page || `${defaultPage}`)

  return { page }
}
