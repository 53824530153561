import React from 'react'

import { ErrorMessage, HelperText } from '../'

export type ErrorMessageAndHelperTextProps = {
  errorMessage?: string
  helperText?: string
  id?: string
}

const ErrorMessageAndHelperText: React.FC<ErrorMessageAndHelperTextProps> = ({ helperText, errorMessage, id }) => {
  return (
    <>
      <ErrorMessage errorMessage={errorMessage} id={id} />
      <HelperText helperText={helperText} id={id} />
    </>
  )
}

export { ErrorMessageAndHelperText }
