import { CSSProperties, useContext } from 'react'

import { makeStyles as muiMakeStyles, Styles, WithStylesOptions } from '@material-ui/styles'

import { Theme } from './theme'
import { ThemeContext } from './ThemeContext'

const useTheme = () => {
  const context = useContext(ThemeContext)

  if (context === undefined) {
    throw new Error('useTheme can only be used inside ThemeContextProvider')
  }

  return context?.theme
}

export const makeStyles = <ClassKey extends string = string>(
  style: Styles<Theme, {}, ClassKey>,
  options?: WithStylesOptions<Theme>,
) => {
  return muiMakeStyles(style, { ...options, useTheme })
}

export const buildStyleProps = <T extends keyof CSSProperties>(args: { css: T; props: CSSProperties[T][] }) => {
  const { css, props } = args
  return props.reduce((acc, prop) => {
    return { ...acc, [prop]: { [css]: prop } }
  }, {} as { [K in (typeof props)[number]]: { [C in typeof css]: K } })
}
