import React from 'react'

import { DatePicker as CLDatePicker } from '@guiker/components-library'
import { FieldPath } from '@guiker/react-hook-form'

import { useApiFormContext } from '../Form'
import { RHFDatePicker, RHFDatePickerProps } from './RHFDatePicker'

export type DatePickerProps<T extends object, P extends FieldPath<T>> = RHFDatePickerProps<T, P>

const DatePicker = <T extends object = any, P extends FieldPath<T> = any>(props: DatePickerProps<T, P>) => {
  const { readOnly: contextReadOnly } = useApiFormContext({ shouldThrowIfUndefined: false })
  if (props.readOnly) {
    return <CLDatePicker value={props.defaultValue} {...props} />
  } else {
    return <RHFDatePicker readOnly={contextReadOnly} {...props} />
  }
}

export { DatePicker }
