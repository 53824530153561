import React from 'react'

import { clsx } from '@guiker/clsx'
import { isMobile, makeStyles } from '@guiker/components-core'

import { useLayoutContext } from '../../../hooks'
import { ScrollToTop } from '../../Navigation'

const useStyles = makeStyles(
  (theme) => ({
    '@global': {
      body: {
        transition: theme.transitions.create(['background-color'], {
          duration: theme.transitions.duration.short,
        }),

        backgroundColor: theme.palette.background.primary,
      },
    },
    container: {
      minHeight: '100dvh',
    },
    page: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    pageContent: {
      width: '100%',
      height: '100%',
      overflowX: 'hidden',
      zIndex: theme.zIndexs.appBar - 1,
      marginTop: ({ appBarIsDisplayed }: { appBarIsDisplayed: boolean }) =>
        appBarIsDisplayed ? theme.dimensions.appBar.height.desktop : 0,
      minHeight: ({ appBarIsDisplayed }: { appBarIsDisplayed: boolean }) =>
        appBarIsDisplayed ? `calc(100dvh - ${theme.dimensions.appBar.height.desktop}px)` : '100dvh',
      [isMobile]: {
        minHeight: ({ appBarIsDisplayed }: { appBarIsDisplayed: boolean }) =>
          appBarIsDisplayed ? `calc(100dvh - ${theme.dimensions.appBar.height.mobile}px)` : '100dvh',
        marginTop: ({ appBarIsDisplayed }: { appBarIsDisplayed: boolean }) =>
          appBarIsDisplayed ? theme.dimensions.appBar.height.mobile : 0,
      },
    },
  }),
  {
    name: 'AppLayout',
  },
)

type AppLayoutProps = React.PropsWithChildren & {
  className?: string
  appBar: React.ReactNode
  footer?: React.ReactNode
}

export const AppLayout: React.FC<AppLayoutProps> = ({ children, className, appBar, footer }) => {
  const { appBarIsDisplayed, footerIsDisplayed, isTakeover } = useLayoutContext()
  const classes = useStyles({ appBarIsDisplayed })

  return (
    <div className={clsx(className)}>
      <div className={clsx([classes.container, classes.page])}>
        <ScrollToTop />
        {appBarIsDisplayed && !isTakeover && appBar}
        <div className={clsx([classes.page, classes.pageContent])}>{children}</div>
      </div>
      {footerIsDisplayed && !isTakeover && footer}
    </div>
  )
}
