import * as React from 'react'

import { isString } from '@guiker/lodash'

import { Flex, H1, P } from '../..'

export type PageLayoutHeaderProps = {
  subtitle?: string | React.ReactNode
  title?: string | React.ReactNode
  topActions?: React.ReactNode
}

const PageLayoutHeader: React.FC<PageLayoutHeaderProps> = ({ subtitle, title, topActions }) => {
  return (
    <Flex flexDirection='column' gap={1}>
      {(title || topActions) && (
        <Flex flexDirection='row' gap={4} justifyContent='space-between' flexWrap='wrap'>
          {title ? (
            isString(title) ? (
              <H1 mb={0} breakline>
                {title}
              </H1>
            ) : (
              title
            )
          ) : (
            <div />
          )}
          {topActions && (
            <Flex gap={2} alignSelf='center' alignItems='center'>
              {topActions}
            </Flex>
          )}
        </Flex>
      )}
      {subtitle &&
        (isString(subtitle) ? (
          <P color={60} breakline>
            {subtitle}
          </P>
        ) : (
          subtitle
        ))}
    </Flex>
  )
}

export { PageLayoutHeader }
