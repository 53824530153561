import React, { Key } from 'react'

import { clsx } from '@guiker/clsx'
import { buildStyleProps, makeStyles, theme } from '@guiker/components-core'

import { useEmptyStyles, useUtilityStyle } from '../../../styles'
import { BoxProps } from '../Box'

const spacingProps = <T extends string>(key: T) => {
  const values: Record<number, any> = {}

  Array.from(Array(40).keys()).map((index) => {
    values[index + 0.5] = {
      [key]: theme.spacing(index + 0.5),
    }
    values[index] = {
      [key]: theme.spacing(index),
    }
  })

  return values
}

const flexAlignmentProps = <T extends 'justifyContent' | 'alignItems' | 'alignSelf'>(key: T) => {
  const alignmentProps = ['baseline', 'space-evenly', 'space-between', 'flex-start', 'flex-end', 'center']
  return buildStyleProps<T>({ css: key, props: alignmentProps })
}
const flexWrapProps = buildStyleProps({ css: 'flexWrap', props: ['wrap', 'nowrap'] })
const flexDirectionProps = buildStyleProps({
  css: 'flexDirection',
  props: ['row', 'row-reverse', 'column', 'column-reverse'],
})

const useStylGap = makeStyles(spacingProps('gap'), { name: 'FlexBoxGap' })
const useStyleJustifyContent = makeStyles(flexAlignmentProps('justifyContent'), { name: 'FlexBoxJustifyContent' })
const useStyleAlignItems = makeStyles(flexAlignmentProps('alignItems'), { name: 'FlexBoxAlignItems' })
const useStyleAlignSelf = makeStyles(flexAlignmentProps('alignSelf'), { name: 'FlexBoxAlignSelf' })
const useStyleWrap = makeStyles(flexWrapProps, { name: 'FlexBoxWrap' })
const useStyleDirection = makeStyles(flexDirectionProps, { name: 'FlexBoxDirection' })

const useFlexStyles = () => ({
  alignItems: useStyleAlignItems(),
  alignSelf: useStyleAlignSelf(),
  direction: useStyleDirection(),
  justifyContent: useStyleJustifyContent(),
  wrap: useStyleWrap(),
  gap: useStylGap(),
})

const useVariableFlexStyles = makeStyles(
  {
    flex: {
      flex: ({ flex }: { flex: BoxProps['flex'] }) => flex,
    },
    flexGrow: {
      flexGrow: ({ flexGrow }: { flexGrow: BoxProps['flexGrow'] }) => flexGrow,
    },
    flexShrink: {
      flexShrink: ({ flexShrink }: { flexShrink: BoxProps['flexShrink'] }) => flexShrink,
    },
    width: {
      width: ({ width }: { width: BoxProps['width'] }) => width,
    },
    height: {
      height: ({ height }: { height: BoxProps['height'] }) => height,
    },
    maxHeight: {
      maxHeight: ({ maxHeight }: { maxHeight: BoxProps['maxHeight'] }) => maxHeight,
    },
  },
  { name: 'FlexBoxVariable' },
)

const useStyles = makeStyles(
  {
    base: {
      display: 'flex',
    },
    fullWidth: {
      width: '100%',
    },
    clickable: {
      cursor: 'pointer',
    },
  },
  {
    name: 'Flex',
  },
)

type FlexRelatedBoxProps = 'display' | 'justifyContent' | 'alignItems' | 'alignSelf' | 'flexWrap' | 'flexDirection'

/**
 * @todo improve props to take mediaquery
 * flexDirection='column' or flexDirection={{ xs: 'column-reserve', md: 'row }} and handle with clsx
 */
export type FlexProps = React.PropsWithChildren & {
  alignItems?: keyof ReturnType<typeof flexAlignmentProps<'alignItems'>>
  alignSelf?: keyof ReturnType<typeof flexAlignmentProps<'alignSelf'>>
  justifyContent?: keyof ReturnType<typeof flexAlignmentProps<'justifyContent'>>
  flexWrap?: keyof typeof flexWrapProps
  flexDirection?: keyof typeof flexDirectionProps
  gap?: keyof ReturnType<typeof spacingProps>
  fullWidth?: boolean
  onClick?: React.MouseEventHandler<HTMLDivElement>
  xs?: number | string
  sm?: number | string
  md?: number | string
  key?: Key
  ref?: React.Ref<HTMLDivElement>
} & Omit<BoxProps, FlexRelatedBoxProps>

export const Flex: React.FC<FlexProps> = React.forwardRef(
  (
    {
      alignItems,
      alignSelf,
      children,
      justifyContent,
      flexWrap,
      flexDirection,
      flexGrow,
      flexShrink,
      flex,
      gap,
      width,
      height,
      maxHeight,
      fullWidth = false,
      className,
      id,
      onClick,
      xs,
      sm,
      md,
      key,
      ...boxProps
    },
    ref: React.Ref<HTMLDivElement>,
  ) => {
    const utilityClasses =
      Object.keys(boxProps).length > 0 ? useUtilityStyle(boxProps) : useEmptyStyles(useUtilityStyle)
    const hasVariableFlex = [flex, flexGrow, flexShrink, width, height, maxHeight].some((v) => v != null)
    const variableFlexClasses = hasVariableFlex
      ? useVariableFlexStyles({ flex, flexGrow, flexShrink, width, height, maxHeight })
      : useEmptyStyles(useVariableFlexStyles)
    const classes = useStyles()
    const flexClasses = useFlexStyles()

    const classNames = clsx([
      className,
      classes.base,
      className,
      justifyContent ? flexClasses.justifyContent[justifyContent] : undefined,
      alignItems ? flexClasses.alignItems[alignItems] : undefined,
      alignSelf ? flexClasses.alignSelf[alignSelf] : undefined,
      fullWidth ? [classes.fullWidth] : undefined,
      flexWrap ? flexClasses.wrap[flexWrap] : undefined,
      flexDirection ? flexClasses.direction[flexDirection] : undefined,
      flex !== undefined ? variableFlexClasses.flex : undefined,
      flexGrow !== undefined ? variableFlexClasses.flexGrow : undefined,
      flexShrink !== undefined ? variableFlexClasses.flexShrink : undefined,
      height !== undefined ? variableFlexClasses.height : undefined,
      maxHeight !== undefined ? variableFlexClasses.maxHeight : undefined,
      width !== undefined && !fullWidth ? variableFlexClasses.width : undefined,
      onClick !== undefined ? [classes.clickable] : undefined,
      gap ? flexClasses.gap[gap] : undefined,
      utilityClasses.spacing,
      ...(Object.keys(boxProps).length > 0 ? [utilityClasses.maxWidth, utilityClasses.spacing] : []),
    ])

    return (
      <div id={id} className={classNames} onClick={onClick} key={key} ref={ref}>
        {children}
      </div>
    )
  },
)
