import { useAuthenticationContext } from '@guiker/authentication-context'
import { useConfig } from '@guiker/config-context'
import { FeatureFlag } from '@guiker/feature-flag'

export const useFeatureFlag = () => {
  const { stage } = useConfig()
  const { user } = useAuthenticationContext()

  return FeatureFlag({ stage, user })
}
