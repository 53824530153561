import React from 'react'

import { SvgIconProps, SvgIconSmallViewBox } from '../../../SvgIcon'

type SynchronizeArrowsMiniIconProps = SvgIconProps

const SynchronizeArrowsMiniIcon: React.FC<SynchronizeArrowsMiniIconProps> = (props) => {
  return (
    <SvgIconSmallViewBox {...props}>
      <path
        d='M12.4307 10.2158L14.6467 9.66064L15.2003 11.8766'
        stroke='currentColor'
        strokeWidth='1.6'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.419 9.71826C14.0368 11.1296 13.2016 12.3762 12.0418 13.2666C10.882 14.157 9.46189 14.6417 7.99977 14.6463C6.63727 14.6413 5.30897 14.2192 4.19344 13.4369C3.07791 12.6546 2.22863 11.5496 1.75977 10.2703'
        stroke='currentColor'
        strokeWidth='1.6'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.5694 5.78385L1.3534 6.33905L0.799805 4.12305'
        stroke='currentColor'
        strokeWidth='1.6'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1.58105 6.28152C1.96324 4.87022 2.79843 3.62353 3.95821 2.73318C5.11799 1.84282 6.53813 1.35808 8.00025 1.35352V1.35352C9.36275 1.35852 10.691 1.78054 11.8066 2.56285C12.9221 3.34515 13.7714 4.45023 14.2403 5.72952'
        stroke='currentColor'
        strokeWidth='1.6'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIconSmallViewBox>
  )
}

export { SynchronizeArrowsMiniIcon }
