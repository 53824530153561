import React from 'react'

import { theme } from '@guiker/components-core'

import { SvgIconMediumViewBox, SvgIconProps } from '../../SvgIcon'

export interface NavigationMenuIconProps extends SvgIconProps {}

const NavigationMenuIcon: React.FC<NavigationMenuIconProps> = ({ color, ...props }) => {
  const svgColor = theme.palette.getColor(color) || 'currentColor'

  return (
    <SvgIconMediumViewBox color={color} {...props}>
      <path d='M2.25 18.0029H21.75' stroke={svgColor} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M2.25 12.0029H21.75' stroke={svgColor} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M2.25 6.00293H21.75' stroke={svgColor} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    </SvgIconMediumViewBox>
  )
}

export { NavigationMenuIcon }
