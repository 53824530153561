import { camelCase } from '@guiker/lodash'

import { useLocation } from '.'

export const useLocationQuery = (params: string | string[]): { [key: string]: string | null } => {
  const location = useLocation()

  if (typeof params === 'string') {
    params = [params]
  }

  const result: { [key: string]: string | null } = {}
  const searchResult = new URLSearchParams(location.search)

  params.forEach((param) => (result[camelCase(param)] = searchResult.get(param)))

  return result
}
