import { useEffect, useMemo } from 'react'

import { useLocation } from '@guiker/router'

export const useScrollToAnchor = () => {
  const location = useLocation()
  const hash = useMemo(() => location.hash.replace('#', ''), [location])

  useEffect(() => {
    document.getElementById(hash)?.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }, [hash])
}
