import React, { useState } from 'react'

import { clsx } from '@guiker/clsx'
import { ChevronLeft, ChevronRight } from '@material-ui/icons'

import { makeStyles, theme } from '../../../styles'
import { Flex } from '../../Layout'

export type CardMediaProps = {
  condensed?: boolean
  className?: string
  images: {
    url: string
    alt?: string
  }[]
  width?: number
  height?: number
}

export const CARD_MEDIA_IMAGE_WIDTH = 380
export const CARD_MEDIA_IMAGE_HEIGHT = 240
export const SMALL_CARD_MEDIA_IMAGE_HEIGHT = 160

const useStyles = makeStyles(
  () => ({
    container: {
      position: 'relative',
      '&:hover $chevron': {
        visibility: 'visible',
      },
    },
    root: ({ condensed }: { condensed?: boolean }) => ({
      width: '100%',
      minWidth: '100%',
      objectFit: 'cover',
      height: condensed ? SMALL_CARD_MEDIA_IMAGE_HEIGHT : CARD_MEDIA_IMAGE_HEIGHT,
      minHeight: condensed ? SMALL_CARD_MEDIA_IMAGE_HEIGHT : CARD_MEDIA_IMAGE_HEIGHT,
    }),
    offset: {
      position: 'relative',
      left: ({ index }: { index: number }) => -index * 100 + '%',
      transition: theme.transitions.create(['left'], {
        duration: theme.transitions.duration.standard,
        easing: theme.transitions.easing.easeInOut,
      }),
    },
    chevron: {
      cursor: 'pointer',
      backgroundColor: theme.palette.background.primary,
      top: 'calc(50% - 12px)',
    },
    leftChevron: {
      left: 0,
    },
    rightChevron: {
      right: 0,
    },
    navContainer: {
      position: 'absolute',
      visibility: 'hidden',
      width: 40,
      height: '100%',
      top: 0,
    },
  }),
  { name: 'CardMedia' },
)

export const CardMedia: React.FC<CardMediaProps> = ({
  className: propsClassName,
  condensed,
  width = CARD_MEDIA_IMAGE_WIDTH,
  height = CARD_MEDIA_IMAGE_HEIGHT,
  images,
}) => {
  const [index, setIndex] = useState(0)
  const classes = useStyles({ width, height, condensed, index })
  const className = clsx(propsClassName, classes.root)

  const nextPicture = (event: React.MouseEvent) => {
    event.preventDefault()
    setIndex((curr) => (curr + 1) % (images.length - 1))
  }

  const previousPicture = (event: React.MouseEvent) => {
    event.preventDefault()
    setIndex((curr) => (curr === 0 ? images.length - 1 : curr - 1))
  }

  return (
    <div className={classes.container}>
      <Flex className={classes.offset}>
        {images.map((image) => (
          <img src={image.url} alt={image.alt} className={clsx(className)} width={width} height={height} />
        ))}
      </Flex>
      {images.length > 1 && (
        <>
          <div onClick={previousPicture} className={clsx(classes.navContainer, classes.leftChevron)}>
            <Flex height='100%' alignItems='center' justifyContent='center'>
              <ChevronLeft className={classes.chevron} />
            </Flex>
          </div>
          <div onClick={nextPicture} className={clsx(classes.navContainer, classes.rightChevron)}>
            <Flex height='100%' alignItems='center' justifyContent='center'>
              <ChevronRight className={classes.chevron} />
            </Flex>
          </div>
        </>
      )}
    </div>
  )
}
