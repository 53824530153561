import React from 'react'

import { SvgIconProps, SvgIconSmallViewBox } from '../../../SvgIcon'

const TransferSmallIcon: React.FC<Omit<SvgIconProps, 'small'>> = (props) => {
  return (
    <SvgIconSmallViewBox {...props}>
      <path d='M5.33398 5.66675V14.1667' stroke='black' stroke-linecap='round' stroke-linejoin='round' />
      <path
        d='M7.33398 12.1667L5.33398 14.1667L3.33398 12.1667'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path d='M10.666 10.3333V1.83325' stroke='black' stroke-linecap='round' stroke-linejoin='round' />
      <path
        d='M8.66602 3.83325L10.666 1.83325L12.666 3.83325'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </SvgIconSmallViewBox>
  )
}

export { TransferSmallIcon }
