import React from 'react'

import { ThemeContextProvider } from '@guiker/components-core'
import { Marker as BaseMarker, MarkerProps as BaseMarkerProps } from '@react-google-maps/api'

import { getIconUrl } from '../../util'

export type MarkerProps = Omit<BaseMarkerProps, 'icon'> & {
  icon: React.ReactNode
}

export const Marker: React.FC<MarkerProps> = ({ icon, ...props }) => {
  return <BaseMarker icon={getIconUrl(<ThemeContextProvider>{icon}</ThemeContextProvider>)} {...props} />
}
