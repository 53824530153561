import * as React from 'react'

import { clsx } from '@guiker/clsx'
import { makeStyles } from '@guiker/components-core'
import { ButtonProps as MuiButtonProps, default as MuiButton } from '@material-ui/core/Button'

export type BaseButtonProps = React.PropsWithChildren &
  Omit<MuiButtonProps, 'color' | 'size' | 'onClick'> & {
    size?: 'small' | 'medium' | 'large'
    maxWidth?: number | string
    target?: '_blank' | '_top'
    onClick?: (event?: React.MouseEvent) => void
  }

const useStyles = makeStyles(
  (theme) => ({
    root: {
      ...theme.typography.variants.button,
      padding: ({ isIcon }: { isIcon: boolean }) =>
        isIcon ? theme.spacing(2.5) : `${theme.spacing(1.5)}px ${theme.spacing(2.5)}px`,
      textTransform: 'none',
      textDecoration: 'none !important',
      whiteSpace: 'nowrap',
      borderRadius: 0,
      minWidth: 40,
      '&:disabled': {
        backgroundColor: theme.palette.grey[10],
        color: theme.palette.common.white,
        boxShadow: 'none',
      },
      maxWidth: ({ maxWidth }: BaseButtonProps) => (maxWidth ? maxWidth : 'default'),
      '&:hover': {
        textDecoration: 'none !important',
      },
    },
    sizeSmall: {
      padding: ({ isIcon }: { isIcon: boolean }) =>
        isIcon ? theme.spacing(1.5) : `${theme.spacing(1)}px ${theme.spacing(1.5)}px`,
      fontSize: 14,
      lineHeight: '16px',
    },
    sizeLarge: {
      padding: ({ isIcon }: { isIcon: boolean }) =>
        isIcon ? theme.spacing(4) : `${theme.spacing(2.5)}px ${theme.spacing(4)}px`,
      fontSize: 16,
      lineHeight: '16px',
    },
    iconSizeSmall: {
      marginLeft: '0 !important',
      '& > *:first-child': {
        height: 12,
        width: 12,
      },
    },
    iconSizeMedium: {
      '& > *:first-child': {
        height: 16,
        width: 16,
      },
    },
    iconSizeLarge: {
      '& > *:first-child': {
        height: 16,
        width: 16,
      },
    },
    endIcon: {
      marginLeft: ({ children }: BaseButtonProps) => (children ? theme.spacing(1) : 0),
    },
    startIcon: {
      marginRight: ({ children }: BaseButtonProps) => (children ? theme.spacing(1.5) : 0),
      marginLeft: ({ children }: BaseButtonProps) => (children ? -4 : 0),
    },
  }),
  { name: 'BaseButton' },
)

const BaseButton: React.FC<BaseButtonProps> = ({ className, classes: classesProps, children, maxWidth, ...other }) => {
  const isIcon = !children && (other.startIcon || other.endIcon)
  const classes = useStyles({ children, maxWidth, isIcon })

  return (
    <MuiButton
      disableRipple
      className={clsx(classes.root, className)}
      classes={{
        ...classes,
        ...classesProps,
        sizeSmall: clsx(classes.sizeSmall, classesProps?.sizeSmall),
        iconSizeSmall: clsx(classes.iconSizeSmall, classesProps?.iconSizeSmall),
        sizeLarge: clsx(classes.sizeLarge, classesProps?.sizeLarge),
        iconSizeLarge: clsx(classes.iconSizeLarge, classesProps?.iconSizeLarge),
        iconSizeMedium: clsx(classes.iconSizeMedium, classesProps?.iconSizeMedium),
      }}
      {...other}
    >
      {children}
    </MuiButton>
  )
}

export { BaseButton }
