import React from 'react'

import { Bar, BarChart as BaseBarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'

import { theme } from '@guiker/react-framework'

export type BarChartKpi<K extends string> = {
  [key in K]: number
} & {
  label: string
}

export type BarChartProps<K extends string = any> = {
  dataset: BarChartKpi<K>[]
  dataKey: K
  width?: number
  height?: number
}

export const BarChart = <K extends string>({ dataset, dataKey, width = 200, height = 200 }: BarChartProps<K>) => {
  const color = theme.palette.graphs.graph0.main

  return (
    <ResponsiveContainer width='100%' height='100%'>
      <BaseBarChart width={width} height={height} data={dataset} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='label' />
        <YAxis />
        <Tooltip />
        <Bar dataKey={dataKey} fill={color} />
      </BaseBarChart>
    </ResponsiveContainer>
  )
}
