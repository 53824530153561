import React from 'react'

import { clsx } from '@guiker/clsx'
import { ChevronLeftIcon, ChevronRightIcon } from '@guiker/icons'
import { Link } from '@guiker/router'
import { Pagination } from '@material-ui/lab'

import { makeStyles, theme } from '../../../styles'
import { BaseButton, BaseButtonProps } from '../..'

export type PaginationProps = {
  page?: number
  totalPages?: number
  setPage?: (page: number) => void
  /** Set `withLink` to true if component is used where webcrawlers are expected (for SEO purposes) */
  withLink?: boolean
  currentUrl?: string
  PreviousPageComponent?: React.FC<BaseButtonProps>
  NextPageComponent?: React.FC<BaseButtonProps>
  ChangePageComponent?: React.FC<PaginationButtonProps>
}

export type PaginationButtonProps = BaseButtonProps & { isCurrentPage?: boolean }

const BUTTON_WIDTH = 40

const useStyles = makeStyles({
  buttonBase: {
    borderRadius: 0,
    width: BUTTON_WIDTH,
    minWidth: BUTTON_WIDTH,
    maxWidth: BUTTON_WIDTH,
    height: BUTTON_WIDTH,
    minHeight: BUTTON_WIDTH,
    maxHeight: BUTTON_WIDTH,
    backgroundColor: 'transparent',
    fontWeight: 500,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.grey[5],
    },
    '&:disabled': {
      color: theme.palette.grey[50],
      backgroundColor: 'transparent',
    },
  },
  nonClickable: {
    cursor: 'default',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  changePageButton: {
    backgroundColor: 'transparent',
    '&:hover': {
      color: theme.palette.grey[60],
    },
  },
  currentPageButton: {
    backgroundColor: theme.palette.grey[5],
  },
})

export const PaginationNumberButton: React.FC<PaginationButtonProps> = ({ className, isCurrentPage, ...props }) => {
  const classes = useStyles()

  return (
    <PaginationButton
      className={clsx(className, {
        [classes.currentPageButton]: isCurrentPage,
      })}
      {...props}
    />
  )
}

export const PaginationArrowButton: React.FC<BaseButtonProps> = ({ className, ...props }) => {
  const classes = useStyles()

  return <PaginationButton className={clsx(className, classes.changePageButton)} {...props} />
}

export const PaginationButton: React.FC<BaseButtonProps> = ({ className, ...props }) => {
  const classes = useStyles()

  return <BaseButton className={clsx(className, classes.buttonBase)} {...props} />
}

export const DefaultPreviousPageComponent: React.FC<BaseButtonProps> = (props) => {
  return (
    <PaginationArrowButton {...props}>
      <ChevronLeftIcon />
    </PaginationArrowButton>
  )
}

export const DefaultNextPageComponent: React.FC<BaseButtonProps> = (props) => {
  return (
    <PaginationArrowButton {...props}>
      <ChevronRightIcon />
    </PaginationArrowButton>
  )
}

export const DefaultChangePageComponent: React.FC<PaginationButtonProps> = (props) => {
  return <PaginationNumberButton {...props} />
}

const TablePagination: React.FC<PaginationProps> = ({
  page = 1,
  totalPages,
  setPage,
  withLink = false,
  currentUrl = '',
  PreviousPageComponent = DefaultPreviousPageComponent,
  NextPageComponent = DefaultNextPageComponent,
  ChangePageComponent = DefaultChangePageComponent,
}) => {
  const classes = useStyles()

  return (
    <Pagination
      count={totalPages}
      boundaryCount={1}
      siblingCount={1}
      page={page}
      renderItem={(item) => {
        switch (item.type) {
          case 'previous':
            const previousIsDisabled = page <= 1

            if (withLink && !previousIsDisabled) {
              return (
                <Link to={`${currentUrl}?page=${page - 1}`}>
                  <PreviousPageComponent disabled={previousIsDisabled} />
                </Link>
              )
            } else {
              const goToPreviousPage = () => !previousIsDisabled && setPage && setPage(page - 1)
              return <PreviousPageComponent disabled={previousIsDisabled} onClick={goToPreviousPage} />
            }

          case 'next':
            const nextIsDisabled = page > totalPages - 1

            if (withLink && !nextIsDisabled) {
              return (
                <Link to={`${currentUrl}?page=${page + 1}`}>
                  <NextPageComponent disabled={nextIsDisabled} />
                </Link>
              )
            } else {
              const goToNextPage = () => !nextIsDisabled && setPage && setPage(page + 1)
              return <NextPageComponent disabled={nextIsDisabled} onClick={goToNextPage} />
            }

          case 'end-ellipsis':
          case 'start-ellipsis':
            return (
              <PaginationButton className={clsx(classes.nonClickable, classes.buttonBase)}>{'...'}</PaginationButton>
            )
          default:
            const isDisabled = page === item.page

            if (withLink && !isDisabled) {
              return (
                <Link to={`${currentUrl}?page=${item.page}`}>
                  <ChangePageComponent isCurrentPage={item.selected}>{item.page}</ChangePageComponent>
                </Link>
              )
            } else {
              return (
                <ChangePageComponent isCurrentPage={item.selected} onClick={() => setPage && setPage(item.page)}>
                  {item.page}
                </ChangePageComponent>
              )
            }
        }
      }}
    />
  )
}

export { TablePagination }
