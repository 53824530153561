import * as React from 'react'

import { clsx } from '@guiker/clsx'
import { makeStyles, padding } from '@guiker/components-core'
import { ArrowRightIcon } from '@guiker/icons'
import { Divider, lighten, ListItemAvatar } from '@material-ui/core'

import { theme } from '../../../styles'
import { Avatar, Box, Flex, H4, Link, P } from '../..'
import { List, ListItem, ListItemIcon } from '..'

const isString = (value: unknown) => typeof value === 'string'

const useStyles = makeStyles({
  root: {
    padding: padding(3, -3),
  },
  item: {
    width: '100% !important',
    padding: padding(2, 3),
    textDecoration: 'none !important',
    cursor: 'pointer',
  },
  avatar: {
    backgroundColor: lighten(theme.palette.primary.main, 0.7),
    width: 48,
    height: 48,
    fontSize: 22,
  },
  iconContainer: {
    justifyContent: 'flex-end',
  },
  listItemAvatar: {
    marginRight: theme.spacing(2),
  },
})

export type NavigationListProps = {
  className?: string
  itemClassName?: string
  disableRipple?: boolean
  hasAvatar?: boolean
  hasDivider?: boolean
  items: {
    actionButton?: React.ReactElement
    avatar?: string | React.ReactElement
    avatarFallback?: string
    disabled?: boolean
    primaryText?: string | React.ReactNode
    secondaryText?: string | React.ReactNode
    prefixWithLocale?: boolean
    url?: string
    onClick?: () => void
    statusText?: string | React.ReactNode
  }[]
}

const getInitial = (name: string) => (!name ? '' : name.split('')[0].toUpperCase())

const getFullInitial = (names: string) => {
  let fullString = ''

  names.split(' ').forEach((name) => (fullString = `${fullString}${getInitial(name)}`))

  return fullString
}

const NavigationList: React.FC<NavigationListProps> = ({
  className,
  itemClassName,
  items,
  hasAvatar = true,
  hasDivider = true,
}) => {
  const classes = useStyles()

  const getComponent = ({
    url,
    onClick,
    prefixWithLocale,
  }: {
    url?: string
    onClick?: () => void
    prefixWithLocale?: boolean
  }) =>
    url
      ? {
          component: Link,
          prefixWithLocale,
          to: url,
          target: '_top',
        }
      : !!onClick
      ? {
          component: Box,
          onClick,
        }
      : { component: 'li' }

  const getFallbackInitial = (text: unknown) => {
    return isString(text) ? getFullInitial(text as string) : ''
  }

  return (
    <List className={clsx(classes.root, className)}>
      {items.map((item, index) => (
        <React.Fragment key={index}>
          <ListItem disabled={item.disabled} {...getComponent(item)} className={clsx(classes.item, itemClassName)}>
            {hasAvatar && (
              <ListItemAvatar className={classes.listItemAvatar}>
                {item.avatar ? (
                  !isString(item.avatar) ? (
                    (item.avatar as React.ReactElement)
                  ) : (
                    <Avatar className={classes.avatar} src={item.avatar as string} />
                  )
                ) : (
                  <Avatar className={classes.avatar}>
                    {getFallbackInitial(item.avatarFallback || item.primaryText)}
                  </Avatar>
                )}
              </ListItemAvatar>
            )}
            <Flex fullWidth flexDirection='column'>
              {item.primaryText && (isString(item.primaryText) ? <H4 mb={0}>{item.primaryText}</H4> : item.primaryText)}
              {item.statusText && (isString(item.statusText) ? <P mb={0}>{item.statusText}</P> : item.statusText)}
              {item.secondaryText &&
                (isString(item.secondaryText) ? <P mb={0}>{item.secondaryText}</P> : item.secondaryText)}
            </Flex>
            {(item.url || item.onClick) && (
              <ListItemIcon className={classes.iconContainer}>
                {item.disabled ? <></> : <ArrowRightIcon color='textPrimary' />}
              </ListItemIcon>
            )}
          </ListItem>
          {!!item.actionButton && item.actionButton}
          {hasDivider && index !== items.length - 1 && <Divider variant='fullWidth' />}
        </React.Fragment>
      ))}
    </List>
  )
}

export { NavigationList }
