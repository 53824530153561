import React, { useEffect, useMemo, useState } from 'react'

import { ColumnGridLayout, Flex, Spinner, TablePagination, useScreenSize } from '@guiker/components-library'
import { useQuery } from '@guiker/react-query'
import { useLocation, usePaginationQueryParams } from '@guiker/router'

export type Fetcher<T> = (args: {
  page: number
  perPage: number
}) => Promise<{ data: T[]; meta: { totalPages: number } }>

export type PaginatedGridProps<T extends { id: string }> = {
  cacheKey: string | string[] | object
  enabled?: boolean
  rows?: number
  maxColumns?: number
  minColumns?: number
  withLink?: boolean
  withPagination?: boolean
  emptyState?: React.ReactNode
  CellComponent: React.FC<{ data: T }>
  refetchTrigger?: string
  fetcher?: Fetcher<T>
}

type GetPerPage = {
  isLg: boolean
  isXl: boolean
  rows: number
  maxColumns?: number
  minColumns?: number
}

const ColumnMap = {
  xs: 1,
  sm: 2,
  md: 2,
  lg: 3,
  xl: 3,
  default: 3,
}

const boundedValue = (value: number, min: number, max: number) => {
  let bounded = value
  if (max) {
    bounded = Math.min(max, bounded)
  }
  if (min) {
    bounded = Math.max(min, bounded)
  }

  return bounded
}

const useGetPerPage = ({ rows, maxColumns, minColumns }: GetPerPage) => {
  const { isXs, isSm, isMd, isLg, isXl } = useScreenSize()

  return useMemo(() => {
    let columns =
      (isXl && ColumnMap.xl) ||
      (isLg && ColumnMap.lg) ||
      (isMd && ColumnMap.md) ||
      (isSm && ColumnMap.sm) ||
      (isXs && ColumnMap.xs)

    columns = boundedValue(columns, minColumns, maxColumns)

    return { columns, perPage: columns * rows }
  }, [rows, maxColumns, minColumns, isXs, isSm, isMd, isLg, isXl])
}

export const PaginatedGrid = <T extends { id: string }>({
  cacheKey,
  CellComponent,
  emptyState,
  enabled = true,
  fetcher,
  maxColumns,
  minColumns,
  rows = 2,
  withPagination = true,
  withLink = true,
  refetchTrigger = undefined,
}: PaginatedGridProps<T>) => {
  if (!enabled) {
    return null
  }

  const location = useLocation()
  const { page: queryPage } = usePaginationQueryParams()
  const { isLg, isXl } = useScreenSize()
  const [page, setPage] = useState(() => queryPage || 1)
  const { perPage } = useGetPerPage({ isLg, isXl, rows, maxColumns, minColumns })

  const {
    data: response,
    isLoading,
    refetch,
  } = useQuery(
    [...(Array.isArray(cacheKey) ? cacheKey : [cacheKey]), { page }, { perPage }],
    () => fetcher({ page, perPage }),
    {
      enabled,
      keepPreviousData: true,
    },
  )

  useEffect(() => {
    if (withLink && page !== queryPage) {
      setPage(queryPage)
    }
  }, [queryPage])

  useEffect(() => {
    refetchTrigger && refetch()
  }, [refetchTrigger])

  if (isLoading) {
    return <Spinner />
  } else if (emptyState && !response?.data?.length) {
    return <>{emptyState}</>
  }

  return (
    <Flex flexDirection='column' gap={6}>
      <ColumnGridLayout
        gap={4}
        gridTemplateColumns={3}
        xs={{ gridTemplateColumns: boundedValue(ColumnMap.xs, minColumns, maxColumns) }}
        sm={{ gridTemplateColumns: boundedValue(ColumnMap.sm, minColumns, maxColumns) }}
        md={{ gridTemplateColumns: boundedValue(ColumnMap.md, minColumns, maxColumns) }}
        lg={{ gridTemplateColumns: boundedValue(ColumnMap.lg, minColumns, maxColumns) }}
        xl={{ gridTemplateColumns: boundedValue(ColumnMap.xl, minColumns, maxColumns) }}
      >
        {response?.data.map((data) => (
          <CellComponent key={data.id} data={data} />
        ))}
      </ColumnGridLayout>
      {withPagination && (
        <Flex justifyContent='center'>
          <TablePagination
            withLink={withLink}
            currentUrl={location.pathname.replace(/\/$/, '')}
            totalPages={response?.meta?.totalPages}
            page={page}
            setPage={setPage}
          />
        </Flex>
      )}
    </Flex>
  )
}
