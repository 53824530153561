import React from 'react'

export const ProcessCostIcon: React.FC = () => {
  return (
    <svg width='24' height='26' viewBox='0 0 24 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M6.21401 10.2334C6.6532 10.8351 7.34692 11.1754 8.07201 11.1448C9.21101 11.1448 10.135 10.4229 10.135 9.53336C10.135 8.64378 9.21101 7.92294 8.07201 7.92294C6.93301 7.92294 6.01001 7.20107 6.01001 6.31044C6.01001 5.41982 6.93401 4.69899 8.07201 4.69899C8.7971 4.66837 9.49082 5.00868 9.93001 5.61044'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.07202 11.1448V12.2187'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M8.07202 3.625V4.69896' stroke='black' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M23.25 22.3604L21.76 20.0312V14.5625C21.76 12.4792 17.96 10.251 16.51 9.09375'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.44 15.9104L14.792 12.8646C14.2831 12.3344 13.4579 12.3344 12.949 12.8646C12.4401 13.3947 12.4401 14.2542 12.949 14.7844L16.482 18.9375V21.5417C16.482 22.7719 18.17 24.7135 18.17 24.7135'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.51 24.7187H2.13401C1.37501 24.7182 0.76001 24.0771 0.76001 23.2865V2.71354C0.76001 1.92292 1.37501 1.28183 2.13401 1.28125H15.134C15.8936 1.28125 16.5095 1.92234 16.51 2.71354V14.299'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.51 15.3438H4.51001C4.0958 15.3438 3.76001 15.6935 3.76001 16.125V20.8125C3.76001 21.244 4.0958 21.5937 4.51001 21.5937H13.51'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M3.76001 18.4688H12.01' stroke='black' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M8.26001 15.3438V21.5937'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
