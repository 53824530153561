import React from 'react'

import { SvgIconMediumViewBox, SvgIconProps } from '../../../SvgIcon'

type WheelChairAccessibleIconProps = SvgIconProps

const WheelChairAccessibleIcon: React.FC<WheelChairAccessibleIconProps> = (props) => {
  return (
    <SvgIconMediumViewBox {...props}>
      <path
        d='M8.625 3.56006C8.625 4.25625 8.90156 4.92393 9.39384 5.41621C9.88613 5.9085 10.5538 6.18506 11.25 6.18506C11.9462 6.18506 12.6139 5.9085 13.1062 5.41621C13.5984 4.92393 13.875 4.25625 13.875 3.56006C13.875 2.86387 13.5984 2.19619 13.1062 1.7039C12.6139 1.21162 11.9462 0.935059 11.25 0.935059C10.5538 0.935059 9.88613 1.21162 9.39384 1.7039C8.90156 2.19619 8.625 2.86387 8.625 3.56006V3.56006Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.408 22.2311L15.572 18.5601H11.25C10.8522 18.5601 10.4706 18.402 10.1893 18.1207C9.90804 17.8394 9.75 17.4579 9.75 17.0601V9.56006C9.75 9.16223 9.90804 8.7807 10.1893 8.4994C10.4706 8.21809 10.8522 8.06006 11.25 8.06006C11.6478 8.06006 12.0294 8.21809 12.3107 8.4994C12.592 8.7807 12.75 9.16223 12.75 9.56006V15.5601H16.5C16.7786 15.56 17.0517 15.6375 17.2888 15.7839C17.5258 15.9303 17.7174 16.1399 17.842 16.3891L20.092 20.8891C20.27 21.245 20.2992 21.657 20.1734 22.0345C20.0476 22.412 19.7769 22.7241 19.421 22.9021C19.0651 23.08 18.653 23.1093 18.2755 22.9835C17.898 22.8576 17.586 22.587 17.408 22.2311V22.2311Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.75 22.257C11.3717 23.0527 9.73374 23.2682 8.1965 22.8562C6.65927 22.4442 5.34867 21.4384 4.55302 20.06C3.75737 18.6817 3.54185 17.0438 3.95386 15.5065C4.36588 13.9693 5.37169 12.6587 6.75002 11.863'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIconMediumViewBox>
  )
}

export { WheelChairAccessibleIcon }
