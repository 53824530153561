import React from 'react'

import { BlockEditor, BlockEditorProps as CLBlockEditorProps } from '@guiker/components-library'
import { useTranslation } from '@guiker/i18n'
import { Controller, ErrorMessages, FieldPath, PathValue, useFormInput } from '@guiker/react-hook-form'

export type RHFBlockEditorProps<T extends object, P extends FieldPath<T>> = Omit<
  CLBlockEditorProps,
  'name' | 'defaultValue'
> &
  ErrorMessages & {
    name: P
    defaultValue?: PathValue<T, P>
  }

export const RHFBlockEditor = <T extends object, P extends FieldPath<T>>({
  defaultValue,
  name,
  onChange,
}: RHFBlockEditorProps<T, P>) => {
  const { t } = useTranslation()
  const { control } = useFormInput<T, P>({ name, t })

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange: onChangeRenderProps } }) => (
        <BlockEditor
          defaultValue={defaultValue}
          onChange={(content) => {
            onChange && onChange(content)
            onChangeRenderProps(content)
          }}
        />
      )}
    />
  )
}
