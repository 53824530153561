import React, { useState } from 'react'

import { Cell, Pie, PieChart as BasePieChart, ResponsiveContainer, Sector } from 'recharts'

import { AnyColor, clsx, isMobile, makeStyles, padding, theme, toPx } from '@guiker/components-core'
import { optionalConcat } from '@guiker/lodash'
import { Flex, PBold, useLayoutContext, WithTooltip } from '@guiker/react-framework'
import { Kpi } from '@guiker/statistic-shared'

export type PieChartKpi<K extends string> = Kpi<K> & {
  startDegree?: number
  endDegree?: number
  label: string
  color: AnyColor
  onHover?: React.ReactNode
}

export type PieChartProps<K extends string = any> = {
  dataset: PieChartKpi<K>[]
  showLegend?: boolean
  showTotal?: boolean
  highlight?: {
    label?: string
    unit?: string
    value: number
    labelFormatter?: (value: number) => string
  }
  total?: number
  labelFormatter?: (value: number) => string
}

type LegendProps = {
  activeIndex?: Number
  dataset: {
    name: string
    color: string
    legendValue: any
    onClick?: () => void
    onHover?: React.ReactNode
  }[]
}

const useStyles = makeStyles(
  (theme) => ({
    '@global': {
      g: {
        '&:focus': {
          outline: 'none',
        },
      },
    },
    pieChart: {
      width: '100%',
      fontSize: toPx(14),
      display: 'flex',
      flexDirection: 'column',
      gap: 2,
    },
    legendLabel: {
      ...theme.typography.variants.bodySmaller,
      fill: theme.palette.grey[60],
    },
    legendValue: {
      ...theme.typography.variants.body,
      color: theme.palette.text.primary.main,
    },
    highlightLabel: {
      ...theme.typography.variants.bodySmaller,
      fill: theme.palette.grey[60],
    },
    highlightValue: {
      ...theme.typography.variants.body,
      color: theme.palette.text.primary.main,
    },
    displayLegendContainer: {
      display: 'grid',
      gap: theme.spacing(1),
      columnGap: theme.spacing(1),
      gridTemplateColumns: '1fr 1fr',
      padding: padding(0, 2),
      margin: 'auto',
      width: '100%',
      [isMobile]: {
        padding: 0,
      },
    },
    displayLegendLabel: {
      width: '100%',
      alignItems: 'center',
    },
    legendHighlight: {
      borderRadius: 2,
      backgroundColor: theme.palette.others.alabaster.background,
    },
    legendItem: {
      padding: theme.spacing(1),
    },
    displayLegendColor: {
      display: 'block',
      width: 16,
      height: 16,
      borderRadius: 2,
    },
  }),
  { name: 'PieChart' },
)

const getDefaultFormatter = (unit: string) => (value: number) => optionalConcat([unit, value.toLocaleString()], ' ')

const ActiveShape = ({ total, length, labelFormatter, highlight, ...props }) => {
  const classes = useStyles()
  const { isAtMostSmallSizeDesktop } = useLayoutContext()
  const defaultFormatter = getDefaultFormatter(highlight?.unit)
  const RADIAN = Math.PI / 180
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props

  const isMajority = percent > 0.75
  const _midAngle = isMajority ? 220 : midAngle
  const sin = Math.sin(-RADIAN * _midAngle)
  const cos = Math.cos(-RADIAN * _midAngle)

  const sx = cx + (outerRadius + 12) * cos
  const sy = cy + (outerRadius + 12) * sin
  const mx = cx + (outerRadius + 26) * cos
  const my = cy + (outerRadius + 26) * sin
  const ex = mx + (cos >= 0 ? 1 : -1) * 14
  const ey = my
  const dy = highlight?.label ? 16 : 4
  const textValue = labelFormatter ? labelFormatter(value) : defaultFormatter(value)
  const textLength = Math.max(payload.label.length, textValue.length)
  const labelOffset = (cos >= 0 ? 1 : -1) * (textLength * (payload.label.length > textValue.length ? 4.5 : 3.5) + 10)
  const labelX = ex + labelOffset

  return (
    <g>
      {highlight?.label && (
        <text x={cx} y={cy} dy={-8} textAnchor='middle' className={classes.highlightLabel}>
          {highlight.label}
        </text>
      )}
      {highlight && (
        <text x={cx} y={cy} dy={dy} textAnchor='middle' className={classes.highlightValue}>
          {highlight.labelFormatter ? highlight.labelFormatter(highlight.value) : defaultFormatter(highlight.value)}
        </text>
      )}
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      {!isAtMostSmallSizeDesktop && (
        <>
          <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill='none' />
          <circle cx={ex} cy={ey} r={2} fill={fill} stroke='none' />
          <text x={labelX} y={ey - 6} textAnchor='middle' className={classes.legendLabel}>
            {payload.label}
          </text>
          <text x={labelX} y={ey} dy={16} textAnchor='middle' className={classes.legendValue}>
            {textValue}
          </text>
        </>
      )}
    </g>
  )
}

const Legend: React.FC<LegendProps> = ({ dataset, activeIndex }) => {
  const classes = useStyles()
  const { isAtMostSmallSizeDesktop } = useLayoutContext()

  return (
    <div className={classes.displayLegendContainer}>
      {dataset.map((d, index) => (
        <WithTooltip title={d.onHover} color={theme.palette.primary.main} backgroundColor={theme.palette.primary.main}>
          <Flex
            onClick={d.onClick}
            flexDirection='column'
            className={clsx(
              classes.legendItem,
              index === activeIndex && isAtMostSmallSizeDesktop ? classes.legendHighlight : undefined,
            )}
          >
            <Flex className={classes.displayLegendLabel} gap={1}>
              <div className={classes.displayLegendColor} style={{ backgroundColor: d.color }}>
                &nbsp;
              </div>
              <div>{d.name}</div>
            </Flex>
            <PBold pl={3}>{d.legendValue}</PBold>
          </Flex>
        </WithTooltip>
      ))}
    </div>
  )
}

export const PieChart: React.FC<PieChartProps> = ({ dataset, labelFormatter, highlight, total, showLegend = true }) => {
  const classes = useStyles()
  const data = dataset
    .filter((d) => d.value > 0)
    .map((d) => ({
      ...d,
      name: d.label,
      color: theme.palette.getColor(d.color, 'main'),
      legendValue: labelFormatter ? labelFormatter(d.value) : getDefaultFormatter(d.unit)(d.value),
    }))

  const [activeIndex, setActiveIndex] = useState(data.findIndex((d) => d.value > 0))

  return (
    <div className={classes.pieChart}>
      <ResponsiveContainer width='100%' height='100%' minHeight={260}>
        <BasePieChart>
          <Pie
            activeIndex={activeIndex}
            activeShape={(props) => (
              <ActiveShape
                total={total}
                labelFormatter={labelFormatter}
                highlight={highlight}
                length={dataset.length}
                {...props}
              />
            )}
            data={data}
            cx='50%'
            cy='50%'
            innerRadius={60}
            outerRadius={80}
            startAngle={450}
            endAngle={90}
            paddingAngle={dataset.length > 1 ? 3 : 0}
            dataKey='value'
            onMouseEnter={(_: any, index: number) => setActiveIndex(index)}
          >
            {data?.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color?.toString()} />
            ))}
          </Pie>
        </BasePieChart>
      </ResponsiveContainer>
      {showLegend && <Legend dataset={data} activeIndex={activeIndex} />}
      <div style={{ clear: 'both' }}>&nbsp;</div>
    </div>
  )
}
