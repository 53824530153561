import React from 'react'

import { SvgIconProps, SvgIconSmallViewBox } from '../../../SvgIcon'

export type PlusSmallIconProps = SvgIconProps

const PlusSmallIcon: React.FC<PlusSmallIconProps> = (props) => {
  return (
    <SvgIconSmallViewBox {...props}>
      <path
        d='M14.8571 6.85714H9.42857C9.3528 6.85714 9.28012 6.82704 9.22654 6.77346C9.17296 6.71988 9.14286 6.6472 9.14286 6.57143V1.14286C9.14286 0.839753 9.02245 0.549062 8.80812 0.334735C8.59379 0.120408 8.30311 0 8 0C7.6969 0 7.40621 0.120408 7.19188 0.334735C6.97755 0.549062 6.85714 0.839753 6.85714 1.14286V6.57143C6.85714 6.6472 6.82704 6.71988 6.77346 6.77346C6.71988 6.82704 6.6472 6.85714 6.57143 6.85714H1.14286C0.839753 6.85714 0.549062 6.97755 0.334735 7.19188C0.120408 7.40621 0 7.6969 0 8C0 8.30311 0.120408 8.59379 0.334735 8.80812C0.549062 9.02245 0.839753 9.14286 1.14286 9.14286H6.57143C6.6472 9.14286 6.71988 9.17296 6.77346 9.22654C6.82704 9.28012 6.85714 9.3528 6.85714 9.42857V14.8571C6.85714 15.1602 6.97755 15.4509 7.19188 15.6653C7.40621 15.8796 7.6969 16 8 16C8.30311 16 8.59379 15.8796 8.80812 15.6653C9.02245 15.4509 9.14286 15.1602 9.14286 14.8571V9.42857C9.14286 9.3528 9.17296 9.28012 9.22654 9.22654C9.28012 9.17296 9.3528 9.14286 9.42857 9.14286H14.8571C15.1602 9.14286 15.4509 9.02245 15.6653 8.80812C15.8796 8.59379 16 8.30311 16 8C16 7.6969 15.8796 7.40621 15.6653 7.19188C15.4509 6.97755 15.1602 6.85714 14.8571 6.85714Z'
        fill='currentColor'
      />
    </SvgIconSmallViewBox>
  )
}

export { PlusSmallIcon }
