import React from 'react'

import { useLayoutContext } from '../../../hooks'
import { H3 } from '../../Data Display'
import { PageSection, PageSectionProps } from '../PageSection/PageSection'

type FormSection2Props = Omit<PageSectionProps, 'TitleComponent' | 'titleMb' | 'spacing'>

const FormSection2: React.FC<FormSection2Props> = (props) => {
  const { isMobile } = useLayoutContext()

  return <PageSection TitleComponent={H3} titleMb={6} spacing={isMobile ? 4 : 8} {...props} />
}

const FormSection = FormSection2

export { FormSection, FormSection2 }
