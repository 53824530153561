import React from 'react'

import { useLayoutContext } from '../../../hooks'
import { H3 } from '../../Data Display'
import { PageSection, PageSectionProps } from './PageSection'

type PageSection3Props = Omit<PageSectionProps, 'TitleComponent' | 'titleMb' | 'spacing'>

export const PageSection3: React.FC<PageSection3Props> = (props) => {
  const { isMobile } = useLayoutContext()

  return (
    <PageSection
      TitleComponent={H3}
      hasDivider={false}
      titleMb={isMobile ? 3 : 4}
      spacing={isMobile ? 4 : 5}
      {...props}
    />
  )
}
