import { createContext } from 'react'

import { Device } from './Device'

type Context = {
  device: Device
  canUseEventListeners: boolean
  canUseViewport: boolean
  canUseWorkers: boolean
  hasMounted: boolean
  isBrowser: boolean
  isNative: boolean
  isServer: boolean
}

export const DeviceContext = createContext<Context>(null)
