import React, { ReactNode } from 'react'

import { isMobile, makeStyles, MinWidth } from '@guiker/components-core'
import { compact, isArray, isNumber, isString } from '@guiker/shared-framework'

import { ColumnGridLayout, Flex } from '../../Layout'
import { H2, H5, PSmall, TypographyProps } from '../Typography'

type Item = {
  valueAdornment?: ReactNode
  label: string | number | ReactNode
  value: string | number | ReactNode
  unit?: string | number | ReactNode
}

type KpiCardProps = {
  color?: 'primary' | 'secondary'
  top?: Item
  bottom?: Item | Item[]
  condensed?: boolean
  onClick?: () => void
  minWidth?: MinWidth
}

type ItemProps = {
  item: Item
  color?: KpiCardProps['color']
  LabelComponent: React.FC<TypographyProps>
  ValueComponent: React.FC<TypographyProps>
}

const useStyles = makeStyles(
  (theme) => ({
    root: {
      borderRadius: 8,
      border: ({ onClick }: KpiCardProps) => (!!onClick ? 'solid 1px transparent' : 'none'),
      backgroundColor: ({ color }: KpiCardProps) =>
        color === 'secondary' ? theme.palette.grey[10] : theme.palette.primary.main,
      minHeight: ({ condensed }: KpiCardProps) => (condensed ? 80 : 193),
      '&:hover': {
        borderColor: ({ onClick }: KpiCardProps) => (!!onClick ? theme.palette.primary.main : 'none'),
        cursor: ({ onClick }: KpiCardProps) => (!!onClick ? 'pointer' : 'default'),
      },
    },
    text: {
      marginBottom: 0,
      color: ({ color }: KpiCardProps) =>
        color === 'secondary' ? theme.palette.text.primary.main : theme.palette.text.secondary.main,
    },
    row: {
      overflowX: 'hidden',
      [isMobile]: {
        overflowX: 'auto',
      },
    },
  }),
  { name: 'KpiCard' },
)

const isNumberOrString = (value: unknown): value is number | string => {
  return isNumber(value) || isString(value)
}

const Item: React.FC<ItemProps> = ({ color, item, LabelComponent, ValueComponent }) => {
  const classes = useStyles({ color })

  return (
    <Flex flexDirection='column' gap={1}>
      {isNumberOrString(item.label) ? (
        <LabelComponent className={classes.text}>{item.label}</LabelComponent>
      ) : (
        item.label
      )}
      <Flex gap={1} alignItems='center'>
        {item.valueAdornment}
        {isNumberOrString(item.value) ? (
          <ValueComponent className={classes.text}>{item.value}</ValueComponent>
        ) : (
          item.value
        )}
        {item.value ? (
          isNumberOrString(item.unit) ? (
            <LabelComponent className={classes.text}>{item.unit}</LabelComponent>
          ) : (
            item.unit
          )
        ) : (
          <></>
        )}
      </Flex>
    </Flex>
  )
}

type KpiRowProps = {
  condensed?: boolean
  minWidth?: MinWidth
  items: Omit<KpiCardProps, 'minWidth' | 'condensed'>[]
}

export const KpiRow: React.FC<KpiRowProps> = ({ items, condensed, minWidth }) => {
  const classes = useStyles()
  return (
    <ColumnGridLayout
      gap={4}
      gridColumn={{ start: 'auto', span: 1 }}
      gridTemplateColumns={3}
      minWidth={minWidth}
      className={classes.row}
    >
      {items.map((item) => {
        return <KpiCard color={item.color} top={item.top} bottom={item.bottom} condensed={condensed} />
      })}
    </ColumnGridLayout>
  )
}

export const KpiCard: React.FC<KpiCardProps> = ({
  color = 'primary',
  condensed = false,
  bottom,
  top,
  onClick,
  minWidth,
}) => {
  const classes = useStyles({ color, condensed, onClick })
  const handleClick = () => onClick?.()
  const bottomItems = compact(isArray(bottom) ? bottom : [bottom])

  return (
    <Flex
      flexDirection='column'
      justifyContent='space-between'
      onClick={() => handleClick()}
      className={classes.root}
      minWidth={minWidth}
      px={4}
      py={3}
    >
      {top && <Item item={top} LabelComponent={H5} ValueComponent={H2} color={color} />}
      <Flex flexDirection='row' justifyContent='space-between'>
        {bottomItems?.length ? (
          bottomItems.map((item) => {
            return <Item item={item} LabelComponent={PSmall} ValueComponent={H5} color={color} />
          })
        ) : (
          <></>
        )}
      </Flex>
    </Flex>
  )
}
