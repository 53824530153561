import { QueryFunction, useQuery as baseUseQuery, UseQueryOptions } from '@tanstack/react-query'

export const useQuery = <
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends string[] = string[],
>(
  queryKey: unknown | unknown[],
  func: QueryFunction<TQueryFnData, TQueryKey>,
  options?: Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'queryKey' | 'queryFn' | 'initialData'> & {
    initialData?: () => undefined
  },
) => {
  const { isInitialLoading, ...result } = baseUseQuery(
    (Array.isArray(queryKey) ? queryKey : [queryKey]).map((k) => JSON.stringify(k)),
    func,
    options,
  )

  return {
    ...result,
    isLoading: isInitialLoading,
  }
}
