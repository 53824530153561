import React from 'react'

import { NumberFormat as CLNumberFormat } from '@guiker/components-library'
import { FieldPath } from '@guiker/react-hook-form'

import { useApiFormContext } from '../Form'
import { NumberFormatProps as RHFNumberFormatProps, RHFNumberFormat } from './RHFNumberFormat'

export type NumberFormatProps<T extends object, P extends FieldPath<T>> = RHFNumberFormatProps<T, P>

const NumberFormat = <T extends object = any, P extends FieldPath<T> = any>({
  defaultValue,
  type,
  ...props
}: NumberFormatProps<T, P>) => {
  const { readOnly: contextReadOnly } = useApiFormContext({ shouldThrowIfUndefined: false })
  if (props.readOnly) {
    return <CLNumberFormat value={defaultValue ?? '-'} {...props} />
  } else {
    return <RHFNumberFormat defaultValue={defaultValue} type={type} readOnly={contextReadOnly} {...props} />
  }
}

export { NumberFormat }
