import React from 'react'

import { useTranslation } from '@guiker/i18n'

import { makeStyles, theme } from '../../../styles'
import { ButtonProps, P, SecondaryButton } from '../..'
import { ButtonWithLoader, ButtonWithLoaderProps } from '../../Inputs'
import { Modal, ModalProps } from '../Modal'

export type ConfirmDialogProps = ModalProps & {
  description?: string | React.ReactNode
  cancelButtonProps?: ButtonProps
  confirmButtonProps?: ButtonWithLoaderProps
  helperText?: string | React.ReactNode
}

const useStyles = makeStyles({
  helperText: {
    color: theme.palette.grey[60],
    fontSize: 14,
    lineHeight: '24px',
  },
})

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  description,
  cancelButtonProps,
  confirmButtonProps,
  helperText,
  children,
  ...props
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Modal
      {...props}
      actions={
        <>
          {cancelButtonProps && (
            <SecondaryButton {...cancelButtonProps}>
              {cancelButtonProps.children || t('common:actions.cancel')}
            </SecondaryButton>
          )}
          {children || (
            <ButtonWithLoader {...confirmButtonProps}>
              {confirmButtonProps?.children || t('common:actions.confirm')}
            </ButtonWithLoader>
          )}
        </>
      }
    >
      {typeof description === 'string' ? <P mb={2}>{description}</P> : description}
      {helperText && (typeof helperText === 'string' ? <P className={classes.helperText}>{helperText}</P> : helperText)}
    </Modal>
  )
}

export { ConfirmDialog }
