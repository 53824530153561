import React from 'react'

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

export const DragAndDropProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <DndProvider backend={HTML5Backend} context={window}>
      {children}
    </DndProvider>
  )
}
