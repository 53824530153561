import React, { createContext, useEffect, useRef, useState } from 'react'

import { usePersistancyContext } from '@guiker/react-persistance'
import { MutationFunction, useMutation } from '@guiker/react-query'
import { generateUseContext } from '@guiker/react-utils'
import { sleep, yup } from '@guiker/shared-framework'

import { MutationOptions, OnSubmit } from '../../types'

type AssembedFormState<T> = {
  isDirty: boolean
  isSubmitting: boolean
  values: T
}

type OnNextOptions = {
  withoutChange?: boolean
}

type Context<T> = {
  currentFragmentIndex: number
  totalFragmentsSize: number
  schema: yup.Schema<T>
  defaultValue: T
  formState: AssembedFormState<T>
  dataStore: {
    get: () => T
    set: (data?: Partial<T>) => Promise<void>
  }
  onPrev: () => void
  onNext: (options?: OnNextOptions) => void
}

const ApiFormFragmentsContext = createContext<Context<Record<string, any>>>(undefined)

type Props<TFormValues, TResult> = {
  defaultValue?: TFormValues
  onSubmit: OnSubmit<TResult, TFormValues>
  apiOptions: MutationOptions<TResult, TFormValues> & { onSubmitWithoutChange?: () => void }
  size: number
  schema: yup.Schema<TFormValues>
  onClickBackFirstFragment?: () => void
} & React.PropsWithChildren

export const ApiFormFragmentsContextProvider = <
  TResult,
  TFormValues extends Record<string, any> = Record<string, any>,
>({
  apiOptions = {},
  defaultValue,
  onSubmit,
  onClickBackFirstFragment,
  size,
  schema,
  children,
}: Props<TFormValues, TResult>) => {
  const { data: persisted, setData: setPersisted } = usePersistancyContext<{ formFragments: TFormValues }>()

  const lastIndex = size - 1
  const fragmentIndex = useRef(0)
  const [currentFragmentIndex, setCurrentFragmentIndex] = useState(0)
  const [assembledFormState, setAssembledFormState] = useState<AssembedFormState<TFormValues>>({
    isDirty: false,
    isSubmitting: false,
    values: defaultValue ?? persisted.formFragments,
  })
  const { mutate } = useMutation(onSubmit as MutationFunction<TResult, TFormValues>, apiOptions)

  useEffect(() => {
    if (assembledFormState.isSubmitting) {
      !assembledFormState.isDirty && apiOptions.onSubmitWithoutChange
        ? apiOptions.onSubmitWithoutChange()
        : mutate(assembledFormState.values, {
            onSuccess: () => setPersisted({ formFragments: null }),
            onSettled: () => setAssembledFormState({ ...assembledFormState, isSubmitting: false }),
          })
    }
  }, [assembledFormState.isSubmitting])

  const setData = async (data?: Partial<TFormValues>) => {
    const values = { ...assembledFormState.values, ...data }
    const isLastFragment = fragmentIndex.current === lastIndex
    const isDirty = assembledFormState.isDirty || !!data
    setPersisted({ formFragments: values })
    setAssembledFormState({ isDirty, values, isSubmitting: isLastFragment })
    await sleep(200)
  }

  const gotoPrevFragment = () => {
    const isFirstFragment = fragmentIndex.current === 0
    isFirstFragment ? onClickBackFirstFragment?.() : setCurrentFragmentIndex(--fragmentIndex.current)
  }

  const gotoNextFragment = (options: OnNextOptions = {}) => {
    const isLastFragment = fragmentIndex.current === lastIndex
    options.withoutChange && setData()
    !isLastFragment && setCurrentFragmentIndex(++fragmentIndex.current)
  }

  const value = {
    dataStore: {
      get: () => assembledFormState.values,
      set: setData,
    },
    defaultValue: defaultValue ?? persisted.formFragments,
    schema,
    formState: assembledFormState,
    currentFragmentIndex,
    totalFragmentsSize: size,
    onPrev: gotoPrevFragment,
    onNext: gotoNextFragment,
  }

  return <ApiFormFragmentsContext.Provider value={value}>{children}</ApiFormFragmentsContext.Provider>
}

export const useApiFormFragmentsContext = generateUseContext(ApiFormFragmentsContext)
