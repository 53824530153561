import React from 'react'

import { RedirectNoIndex } from './Redirect'
import { Route } from './Route'
import { AuthenticationMethod, RouteConfig } from './route-config'
import { Routes } from './Routes'

type RouterBuilderConfig = RouteConfig & {
  redirect?: string
}

const nestedRoutesBuilder = <K extends string>(
  routes: { [key in K]: RouterBuilderConfig },
  defaultOptions: { requireAuthentication: boolean; authenticationMethod: AuthenticationMethod },
  prefix = '',
): JSX.Element[] => {
  return Object.entries(routes).map(
    ([
      _key,
      {
        path,
        requireAuthentication,
        authenticationMethod,
        Component,
        routes: nestedRoutes,
        redirect,
        requireAccountActivation,
      },
    ]: [K, RouterBuilderConfig]) => {
      const fullPath = prefix ? `${prefix}${path}`.replace('//', '/') : path

      const combinedAuthenticationMethod = authenticationMethod || defaultOptions.authenticationMethod || 'user'
      const combinedRequireAuthentication = requireAuthentication ?? defaultOptions?.requireAuthentication ?? true

      const authenticationAttributes = {
        authenticationMethod: combinedAuthenticationMethod,
        requireAuthentication: combinedRequireAuthentication,
        requireAccountActivation: requireAccountActivation ?? false,
      }

      return (
        <Route Component={Component} path={path} {...authenticationAttributes}>
          {redirect && <Route path='/' Component={() => <RedirectNoIndex path={`./${redirect}`} />} />}
          {nestedRoutes && nestedRoutesBuilder(nestedRoutes, defaultOptions, fullPath)}
        </Route>
      )
    },
  )
}

export const deprecatedRouterBuilder = <K extends string, R extends { [key in K]: RouterBuilderConfig }>(
  routes: R,
  {
    requireAuthentication = true,
    authenticationMethod = 'user',
  }: { requireAuthentication: boolean; authenticationMethod?: AuthenticationMethod } = {
    requireAuthentication: true,
    authenticationMethod: 'user',
  },
) => {
  const nestedRoutes = nestedRoutesBuilder(routes, { requireAuthentication, authenticationMethod }, '')

  const Router: React.FC = () => {
    return <Routes>{nestedRoutes}</Routes>
  }

  return {
    routes,
    Router,
  }
}
