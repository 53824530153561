import React from 'react'

import { AnyColor, theme } from '@guiker/components-core'

const DwelingIcon: React.FC<{ color?: AnyColor }> = ({ color: colorKey }) => {
  const color = theme.palette.getColor(colorKey)
  return (
    <svg width='42' height='41' viewBox='0 0 42 41' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M19.6667 24.8824V36.7429C19.6667 38.153 20.8607 39.2961 22.3334 39.2961H33.0001C34.4728 39.2961 35.6667 38.153 35.6667 36.7429V24.8824'
        stroke={color || 'currentColor'}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M41.0001 29.4783L29.4445 19.526C28.4306 18.653 26.8922 18.653 25.8783 19.526L14.3228 29.4783'
        stroke={color || 'currentColor'}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.3334 39.2979H3.66675V2.27661C3.66729 1.85843 3.882 1.46709 4.24132 1.22936C4.60065 0.991622 5.0589 0.937725 5.46764 1.08512L24.1343 8.35321C24.6542 8.53856 24.9993 9.0135 25.0001 9.5447V13.766'
        stroke={color || 'currentColor'}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.66667 39.2979H1'
        stroke={color || 'currentColor'}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.66675 9.93628H3.66675'
        stroke={color || 'currentColor'}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.3334 15.0426H3.66675'
        stroke={color || 'currentColor'}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.3334 20.149H3.66675'
        stroke={color || 'currentColor'}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.3333 6.08936V1'
        stroke={color || 'currentColor'}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export { DwelingIcon }
