import React, { CSSProperties } from 'react'

import { clsx } from '@guiker/clsx'
import { padding } from '@guiker/components-core'

import { makeStyles, theme } from '../../../styles'
import { RotatingIcon } from '../../Feedback'
import { PSmaller } from '..'
import { ColumnConfig } from './column-config'
import { Sort } from './sort'

const useStyles = makeStyles({
  header: {
    padding: padding(2, 1),
  },
  label: {
    textTransform: 'uppercase',
    color: theme.palette.grey[50],
    textAlign: ({ textAlign }: { textAlign: React.CSSProperties['textAlign'] }) => textAlign || 'left',
    margin: 0,
    paddingRight: 0,
  },
  endAdornment: {
    marginLeft: theme.spacing(1),
  },
  clickable: {
    cursor: 'pointer',
  },
  fixedWidth: ({ width }: THProps<unknown>) => ({
    width,
    maxWidth: width,
  }),
})

type THProps<T> = {
  className?: string
  colSpan?: number
  column: ColumnConfig<T>
  textAlign?: CSSProperties['textAlign']
  showLabel?: boolean
  sort?: Sort
  width: number | string
}

type BaseTHProps = React.PropsWithChildren & {
  className?: string
  colSpan?: number
  showLabel?: boolean
  textAlign?: CSSProperties['textAlign']
  onClick?: () => unknown
  width?: number | string
  endAdornment?: React.ReactNode
}

export const BaseTH: React.FC<BaseTHProps> = ({
  children,
  className,
  colSpan,
  endAdornment,
  onClick,
  showLabel = true,
  textAlign,
  width,
}) => {
  const classes = useStyles({ width, textAlign })

  return (
    <th
      className={clsx(classes.fixedWidth, showLabel && classes.header, className, {
        [classes.clickable]: !!onClick,
      })}
      onClick={onClick}
      colSpan={colSpan || 1}
    >
      {showLabel && (
        <PSmaller className={classes.label} whiteSpace='nowrap'>
          {children}
          {endAdornment && <span className={classes.endAdornment}>{endAdornment}</span>}
        </PSmaller>
      )}
    </th>
  )
}

const TH = <T extends object>({ className, column, width, sort, textAlign, showLabel = true }: THProps<T>) => {
  const isSortingColumn = sort?.by === column.name
  const isRotated = isSortingColumn ? sort.order !== 1 : false
  const sortable = (column.sortable ?? true) && sort?.setSortBy

  return (
    <BaseTH
      showLabel={showLabel}
      className={className}
      colSpan={column.colSpan}
      textAlign={textAlign}
      width={width}
      onClick={sortable ? () => sort?.setSortBy(column.name) : undefined}
      endAdornment={sortable && (isSortingColumn ? <RotatingIcon size='smallest' isRotated={isRotated} /> : '-')}
    >
      {column.label}
    </BaseTH>
  )
}

export { TH }
