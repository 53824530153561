import React from 'react'

import { Flex } from '../../Layout/Flex/Flex'
import { Input, InputProps } from '../Input'
import { InputBase, InputBaseProps } from '../InputBase'

export type TextFieldProps = InputBaseProps &
  InputProps & {
    inputBaseProps?: InputBaseProps
    customAdornment?: React.ReactNode
    multiline?: boolean
  }

const TextField: React.FC<TextFieldProps> = ({ className, inputBaseProps, children, customAdornment, ...props }) => {
  const input = (
    <InputBase type='text' {...inputBaseProps} {...props}>
      {children}
    </InputBase>
  )

  return (
    <Input className={className} {...props}>
      {customAdornment ? (
        <Flex>
          {customAdornment}
          {input}
        </Flex>
      ) : (
        input
      )}
    </Input>
  )
}

export { TextField }
