import { useState } from 'react'

export const useSort = ({
  sort: sortProps = 'createdAt',
  sortOrder: sortOrderProps = -1,
}: {
  sortOrder: 1 | -1
  sort: string
}) => {
  const [sortOrder, setSortOrder] = useState<1 | -1>(sortOrderProps)
  const [sort, setSort] = useState<string>(sortProps)

  const setSortBy = (headerName: string) => {
    if (headerName === sort) {
      setSortOrder((sortOrder) => (sortOrder === 1 ? -1 : 1))
    } else {
      setSort(headerName)
      setSortOrder(sortOrderProps)
    }
  }

  return {
    setSortBy,
    sortOrder,
    sort,
  }
}
