import React from 'react'

import { SvgIconLargeViewBox, SvgIconProps } from '../../../SvgIcon'

type LargeLikeChatProps = SvgIconProps

const LargeLikeChat: React.FC<LargeLikeChatProps> = (props) => {
  return (
    <SvgIconLargeViewBox {...props}>
      <path
        d='M11.25 22.5833V14.4167H12.4167C13.9638 14.4167 15.4475 13.8021 16.5415 12.7081C17.6354 11.6142 18.25 10.1304 18.25 8.58333C18.25 7.96449 18.4958 7.371 18.9334 6.93342C19.371 6.49583 19.9645 6.25 20.5833 6.25C20.9992 6.25 21.411 6.33191 21.7952 6.49105C22.1794 6.65019 22.5285 6.88344 22.8225 7.1775C23.1166 7.47155 23.3498 7.82064 23.509 8.20484C23.6681 8.58903 23.75 9.00081 23.75 9.41667V10.9167C23.75 11.2261 23.8729 11.5228 24.0917 11.7416C24.3105 11.9604 24.6072 12.0833 24.9167 12.0833H25.25C25.7389 12.0835 26.2224 12.186 26.6693 12.3844C27.1161 12.5828 27.5165 12.8726 27.8445 13.2352C28.1725 13.5978 28.4209 14.025 28.5737 14.4894C28.7265 14.9539 28.7803 15.4452 28.7317 15.9317L28.37 19.5483C28.2547 20.6997 27.7157 21.7672 26.8577 22.5436C25.9997 23.32 24.8838 23.75 23.7267 23.75H18.9033C18.4698 23.7503 18.0384 23.6897 17.6217 23.57L14.7883 22.7633C14.3716 22.6436 13.9402 22.5831 13.5067 22.5833H11.25'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M36.25 31.25H18.75L8.75 38.75V31.25H3.75C3.08696 31.25 2.45107 30.9866 1.98223 30.5178C1.51339 30.0489 1.25 29.413 1.25 28.75V3.75C1.25 3.08696 1.51339 2.45107 1.98223 1.98223C2.45107 1.51339 3.08696 1.25 3.75 1.25H36.25C36.913 1.25 37.5489 1.51339 38.0178 1.98223C38.4866 2.45107 38.75 3.08696 38.75 3.75V28.75C38.75 29.413 38.4866 30.0489 38.0178 30.5178C37.5489 30.9866 36.913 31.25 36.25 31.25Z'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIconLargeViewBox>
  )
}

export { LargeLikeChat }
