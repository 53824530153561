import React from 'react'

import { makeStyles, theme } from '@guiker/components-core'

import { useModal } from '../../..'
import { Alert, Slide, Snackbar } from '../../'

export interface ErrorToasterProps {
  type: 'error' | 'info' | 'success' | 'warning'
  onClose?: (event: React.SyntheticEvent<Element, Event>) => void
  message: string | undefined | React.ReactNode
  showClose?: boolean
  autoHideDuration?: number
}

const useStyles = makeStyles({
  root: {
    boxShadow: theme.shadows.light,
    marginTop: theme.spacing(8),
    whiteSpace: 'pre-line',
    '& .MuiAlert-action': {
      alignItems: 'flex-start',
    },
  },
})

const ErrorToaster: React.FC<ErrorToasterProps> = ({
  type,
  onClose,
  message,
  showClose,
  autoHideDuration,
  ...otherProps
}) => {
  const classes = useStyles()
  const { isOpen, closeModal } = useModal(true)

  const handleClose = (event: React.SyntheticEvent<Element, Event>) => {
    !!onClose && onClose(event)
    closeModal()
  }

  return (
    <Snackbar
      {...otherProps}
      open={isOpen}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      TransitionComponent={(props) => <Slide {...props} direction='down' />}
    >
      <Alert severity={type} onClose={!!showClose ? handleClose : undefined} classes={{ root: classes.root }}>
        {message}
      </Alert>
    </Snackbar>
  )
}

export { ErrorToaster }
