import React from 'react'

import { Label } from '../../Data Display'
import { BaseRadio, BaseRadioProps } from './BaseRadio'

type AdornmentPosition = 'start' | 'end'

export type RadioProps = Omit<BaseRadioProps, 'label'> & {
  label?: string | React.ReactNode
  adornment?: React.ReactNode
  adornmentClassName?: string
  description?: string
  adornmentPosition?: AdornmentPosition
}

const Radio: React.FC<RadioProps> = (props) => {
  const { adornment, adornmentClassName, description, adornmentPosition, disabled, label, ...other } = props

  return (
    <BaseRadio
      disabled={disabled}
      label={
        <Label
          text={label}
          adornment={{
            node: adornment,
            position: adornmentPosition,
            className: adornmentClassName,
            align: 'flex-start',
          }}
          description={description}
          disabled={disabled}
        />
      }
      {...other}
    />
  )
}

export { Radio }
