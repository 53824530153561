import React, { useState } from 'react'

import { DropdownIcon } from '@guiker/icons'

import { Link, LinkProps, Menu, MenuItem, MenuProps } from '../../..'
import { makeStyles, theme } from '../../../styles'

export type DropdownMenuProps = {
  darkMode?: boolean
  text: string
  items: (LinkProps & {
    text?: string
  })[]
  ClickableComponent?: React.FC<
    React.PropsWithChildren & {
      darkMode?: boolean
      onClick?: (event?: React.SyntheticEvent) => void
    }
  >
  anchorOrigin?: MenuProps['anchorOrigin']
  transformOrigin?: MenuProps['transformOrigin']
}

const useStyles = makeStyles(
  {
    paper: {
      marginTop: theme.spacing(3),
      width: 180,
    },
    item: {
      height: 44,
    },
    link: {
      width: '100% !important',
    },
  },
  {
    name: 'DropdownMenu',
  },
)

type DefaultDropdownButtonProps = LinkProps & {
  darkMode?: boolean
}

const DefaultDropdownButton: React.FC<DefaultDropdownButtonProps> = ({ darkMode, ...props }) => {
  return (
    <Link underline={false} color={darkMode ? 'white' : 'textPrimary'} endAdornment={<DropdownIcon />} {...props} />
  )
}

const DropdownMenu: React.FC<DropdownMenuProps> = ({
  darkMode,
  ClickableComponent = DefaultDropdownButton,
  text,
  items,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin = {
    vertical: 'top',
    horizontal: 'right',
  },
}) => {
  const classes = useStyles()
  const [anchor, setAnchor] = useState<Element>(null)

  const onClick = (event?: React.SyntheticEvent) => {
    anchor ? onClose() : setAnchor(event?.currentTarget)
  }

  const onClose = () => {
    setAnchor(null)
  }

  return (
    <>
      <ClickableComponent darkMode={darkMode} onClick={onClick}>
        {text}
      </ClickableComponent>
      <Menu
        keepMounted={true}
        anchorEl={anchor}
        open={!!anchor}
        classes={{
          paper: classes.paper,
        }}
        onClose={onClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        {items.map(({ text, onClick, ...props }) => (
          <MenuItem className={classes.item} key={text}>
            <Link
              className={classes.link}
              onClick={(e: React.SyntheticEvent) => {
                onClick?.(e)
                onClose()
              }}
              color='textPrimary'
              underline={false}
              hoverUnderline={false}
              {...props}
            >
              {text}
            </Link>
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export { DropdownMenu }
