import React from 'react'

import { SvgIconMediumViewBox, SvgIconProps } from '../../../SvgIcon'

type StoveIconProps = SvgIconProps

const StoveIcon: React.FC<StoveIconProps> = (props) => {
  return (
    <SvgIconMediumViewBox {...props}>
      <path
        d='M6.75 11.25H23.25V21.75H6.75V11.25Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.75 21.75V23.25'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20.25 21.75V23.25'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.75 14.25H17.25'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M0.75 0.75H6.75C7.14782 0.75 7.52936 0.908035 7.81066 1.18934C8.09196 1.47064 8.25 1.85218 8.25 2.25V6.75C8.25 7.14782 8.40804 7.52936 8.68934 7.81066C8.97064 8.09196 9.35218 8.25 9.75 8.25H20.25C20.6478 8.25 21.0294 8.09196 21.3107 7.81066C21.592 7.52936 21.75 7.14782 21.75 6.75V3.75C21.75 3.35218 21.592 2.97064 21.3107 2.68934C21.0294 2.40804 20.6478 2.25 20.25 2.25H8.25'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M12.75 2.25V8.25' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M17.25 2.25V8.25' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    </SvgIconMediumViewBox>
  )
}

export { StoveIcon }
