import React from 'react'

import { isAtLeastTablette, isAtMostTablette, makeStyles } from '@guiker/components-core'
import { isNumber } from '@guiker/lodash'

type GridColumnEnd = {
  start?: number | 'auto'
  end: number | 'auto'
}

type GridColumnSpan = {
  start?: number | 'auto'
  span: number
}

export type GridColumnCount = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
export type GridColumn = GridColumnCount | GridColumnEnd | GridColumnSpan

const addImportant = (value: string, important: boolean) => {
  return important ? `${value} !important` : value
}

export const parseGridColumn = (value: GridColumn, important = true) => {
  if (!value) return

  if (isNumber(value)) {
    return addImportant(`1 / span ${value}`, important)
  }

  const start = value.start != null ? value.start : 1

  if ((value as GridColumnSpan).span) {
    return addImportant(`${start} / span ${(value as GridColumnSpan).span}`, important)
  } else {
    return addImportant(`${start} / span ${(value as GridColumnEnd).end}`, important)
  }
}

export type ColumnGridItemProps = React.PropsWithChildren & {
  Component?: 'div' | 'span' // add more if needed
  xs?: GridColumn
  md?: GridColumn
  lg?: GridColumn
}

const useStyles = makeStyles(
  {
    root: ({ xs, md }: ColumnGridItemProps) => ({
      [isAtLeastTablette]: {
        gridColumn: parseGridColumn(md),
      },
      [isAtMostTablette]: {
        gridColumn: parseGridColumn(xs),
      },
    }),
  },
  { name: 'ColumnGridItem' },
)

const ColumnGridItem: React.FC<ColumnGridItemProps> = ({ children, xs, md, Component = 'div' }) => {
  const classes = useStyles({ xs, md })

  return (
    <>
      {React.Children.map(children, (child) => (
        <Component className={classes.root}>{child}</Component>
      ))}
    </>
  )
}

export { ColumnGridItem }
