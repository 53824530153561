import * as React from 'react'

import { clsx } from '@guiker/clsx'
import { isMobile, makeStyles, padding, theme } from '@guiker/components-core'

import { useLayoutContext, useLocation } from '../../../hooks'
import { Flex, FullScreenSpinner } from '../..'
import {
  Breadcrumb,
  BreadcrumbItem,
  InPageNavigation,
  InPageNavigationProps,
  TakeOver,
  TakeOverProps,
} from '../../Navigation'
import { PageLayoutContext, PageLayoutContextProvider } from './PageLayoutContext'
import { PageLayoutHeader } from './PageLayoutHeader'

export type PageLayoutProps = React.PropsWithChildren & {
  breadcrumbItems?: BreadcrumbItem[]
  className?: string
  forceTakeover?: boolean
  hasButtonContainer?: boolean
  header?: React.ReactNode
  isLoading?: boolean
  noPadding?: boolean
  noPaddingBottom?: boolean
  noBreadcrumpSpacer?: boolean
  noPaddingTop?: boolean
  maxWidth?: PageLayoutContext['maxWidth']
  sideMenuProps?: InPageNavigationProps
  subtitle?: string | React.ReactNode
  takeoverProps?: TakeOverProps & { hideSubtitle?: boolean }
  title?: string | React.ReactNode
  topActions?: React.ReactNode
}

const useStyles = makeStyles(
  (theme) => ({
    root: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      width: '100%',
      height: '100%',
      padding: padding(
        theme.dimensions.pageLayout.padding.desktop.top,
        theme.dimensions.pageLayout.padding.desktop.right,
        theme.dimensions.pageLayout.padding.desktop.bottom,
        theme.dimensions.pageLayout.padding.desktop.left,
      ),
      [isMobile]: {
        /** important to pass in value in top -> right -> bottom -> left order */
        padding: padding(
          theme.dimensions.pageLayout.padding.mobile.top,
          theme.dimensions.pageLayout.padding.mobile.right,
          theme.dimensions.pageLayout.padding.mobile.bottom,
          theme.dimensions.pageLayout.padding.mobile.left,
        ),
      },
    },
    isTakeover: {
      paddingTop:
        theme.dimensions.appBar.height.desktop + theme.spacing(theme.dimensions.pageLayout.padding.desktop.top),
      [isMobile]: {
        paddingTop:
          theme.dimensions.appBar.height.mobile + theme.spacing(theme.dimensions.pageLayout.padding.mobile.top),
      },
    },
    container: {
      width: '100%',
      [isMobile]: {
        maxWidth: ' 100%',
      },
      maxWidth: ({ maxWidth }: { maxWidth: string | number }) => maxWidth,
      height: '100%',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      width: '100%',
      height: '100%',
    },
    noPaddingTop: {
      paddingTop: 0,
    },
    noPaddingBottom: {
      paddingBottom: 0,
    },
    noPadding: {
      padding: 0,
      [isMobile]: {
        padding: 0,
      },
    },
    hasButtonContainer: {
      paddingBottom: theme.spacing(18),
      [isMobile]: {
        paddingBottom: theme.spacing(12),
      },
    },
    breadcrumbSpacer: {
      minHeight: 24,
    },
  }),
  { name: 'PageLayout' },
)

const BreadcrumbsContent: React.FC<PageLayoutProps> = ({
  breadcrumbItems,
  children,
  className,
  forceTakeover = false,
  hasButtonContainer = false,
  header,
  isLoading,
  maxWidth = theme.dimensions.pageLayout.maxWidth.desktop,
  noPadding = false,
  noPaddingBottom = false,
  noBreadcrumpSpacer = false,
  noPaddingTop = false,
  sideMenuProps,
  subtitle,
  title,
  topActions,
}) => {
  const classes = useStyles({ maxWidth, hasBreadcrumb: breadcrumbItems?.length > 1 })
  const { pathname, search } = useLocation()
  const { isAtLeastMidSizeDesktop, isTakeover, appBarIsDisplayed } = useLayoutContext()
  const currentLocation = `${pathname}${search}`
  const hasVisibleBreadcrumbs = breadcrumbItems?.length > 1
  const showBreadcrumpSpacer = !noBreadcrumpSpacer && isAtLeastMidSizeDesktop

  // The SideMenu needs to be re-rendered when isLoading changed, as during initial rendering, the anchor element would be hidden.
  const MemoizedStickySideMenu = React.useMemo(() => {
    if (!sideMenuProps) return

    return <InPageNavigation currentLocation={currentLocation} {...sideMenuProps} />
  }, [currentLocation, isLoading, sideMenuProps, isAtLeastMidSizeDesktop])

  return (
    <>
      {header}
      <PageLayoutContextProvider maxWidth={maxWidth}>
        <Flex flexDirection='column' gap={4} height='100%' className={className}>
          {!isAtLeastMidSizeDesktop && MemoizedStickySideMenu}
          <div
            className={clsx(
              classes.root,
              noPadding && classes.noPadding,
              noPaddingTop && classes.noPaddingTop,
              noPaddingBottom && classes.noPaddingBottom,
              hasButtonContainer && classes.hasButtonContainer,
              (isTakeover || forceTakeover) && !appBarIsDisplayed && classes.isTakeover,
            )}
          >
            <Flex flexDirection='column' className={classes.container} gap={4}>
              {subtitle || topActions || title || hasVisibleBreadcrumbs ? (
                <Flex gap={2} flexDirection='column' width={'100%'}>
                  {hasVisibleBreadcrumbs ? (
                    <Breadcrumb items={breadcrumbItems} />
                  ) : showBreadcrumpSpacer ? (
                    <div className={classes.breadcrumbSpacer} />
                  ) : (
                    <></>
                  )}
                  {(subtitle || topActions || title) && (
                    <PageLayoutHeader
                      title={title ?? (forceTakeover ? undefined : title)}
                      subtitle={subtitle ?? (forceTakeover ? undefined : subtitle)}
                      topActions={topActions}
                    />
                  )}
                </Flex>
              ) : showBreadcrumpSpacer ? (
                <div className={classes.breadcrumbSpacer} />
              ) : (
                <></>
              )}
              <Flex gap={6} height='100%'>
                {isAtLeastMidSizeDesktop && MemoizedStickySideMenu}
                <Flex gap={5} flexDirection='column' width={'100%'}>
                  <div className={classes.content}>{children}</div>
                </Flex>
              </Flex>
            </Flex>
          </div>
        </Flex>
      </PageLayoutContextProvider>
    </>
  )
}

const PageLayout: React.FC<PageLayoutProps> = ({ forceTakeover, takeoverProps, ...props }) => {
  const { onClose, onBack } = takeoverProps || {}

  if (props.isLoading) {
    return <FullScreenSpinner />
  }

  if (forceTakeover) {
    return (
      <TakeOver
        {...takeoverProps}
        onBack={onBack}
        onClose={onClose}
        title={props.title}
        subtitle={!takeoverProps?.hideSubtitle ? props.subtitle : undefined}
      >
        <BreadcrumbsContent {...props} forceTakeover />
      </TakeOver>
    )
  } else {
    return <BreadcrumbsContent {...props} />
  }
}

export { PageLayout }
