import React, { useState } from 'react'

import { clsx } from '@guiker/clsx'
import { DropdownIcon, NavigationMenuIcon } from '@guiker/icons'
import { Link } from '@guiker/router'

import {
  Avatar,
  Box,
  DrawerProps,
  IconButton,
  List as BaseList,
  ListItem as BaseListItem,
  ResponsiveMenu,
} from '../../../'
import { useMediaQuery } from '../../../hooks'
import { makeStyles, theme } from '../../../styles'
import { Label } from '../../Data Display'
import { Flex } from '../../Layout'
import { NavigationItemProps } from './NavigationItemProps'

export type AppBarMenuProps = {
  bottomMenuItems?: NavigationItemProps[]
  taskMenu: React.ReactNode
  darkMode?: boolean
  drawer?: boolean
  drawerProps?: DrawerProps
  notificationMenu: React.ReactNode
  topMenuItems?: NavigationItemProps[]
  user?: {
    firstName: string
    lastName: string
    avatar: string
  } | null
}

const useStyles = makeStyles({
  avatar: {
    marginRight: 0,
    width: 32,
    height: 32,
  },
  menuPaper: {
    minWidth: 266,
  },
  listItem: {
    height: 60,
  },
  bold: {
    fontWeight: 600,
  },

  userMenuHeader: {
    padding: 0,
    display: 'flex',
    alignItems: 'center',
  },
})

const ListItem: React.FC<NavigationItemProps & { onClose: () => void }> = ({
  text,
  icon,
  onClick,
  onClose,
  ...props
}) => {
  const classes = useStyles()

  return (
    <Link
      onClick={() => {
        onClick && onClick()
        onClose && onClose()
      }}
      {...props}
    >
      <BaseListItem button disableRipple disableTouchRipple className={clsx(classes.listItem)}>
        <Label text={text} gap={2} adornment={{ node: icon }} />
      </BaseListItem>
    </Link>
  )
}

const List: React.FC<{ items: NavigationItemProps[]; onClose: () => void }> = ({ items, onClose }) => {
  return (
    <BaseList>
      {items?.map(({ Component, ...props }, index) => (
        <ListItem key={index} onClose={onClose} {...props} />
      ))}
    </BaseList>
  )
}

const UserMenuHeader: React.FC<{ user: AppBarMenuProps['user'] }> = ({ user }) => {
  const classes = useStyles()

  return (
    <Box className={classes.userMenuHeader}>
      <Avatar src={user?.avatar} className={classes.avatar} />
    </Box>
  )
}

const AppBarMenu: React.FC<AppBarMenuProps> = ({
  drawer,
  drawerProps,
  topMenuItems,
  bottomMenuItems,
  taskMenu,
  notificationMenu,
  user,
}) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const classes = useStyles({ isMobile })

  const [anchor, setAnchor] = useState<Element>(null)

  const onClick = (event: React.SyntheticEvent) => {
    anchor ? onClose() : setAnchor(event.currentTarget)
  }

  const onClose = () => {
    setAnchor(null)
  }

  const empty = (topMenuItems || []).length + (bottomMenuItems || []).length === 0

  if (empty) return <div></div>
  return (
    <>
      <Flex alignItems='center' gap={4}>
        <Flex alignItems='center' gap={3}>
          {taskMenu}
          {notificationMenu}
          {isMobile && (
            <IconButton onClick={onClick}>
              <NavigationMenuIcon />
            </IconButton>
          )}
        </Flex>
        {!isMobile && user && (
          <IconButton>
            <Flex alignItems='center' gap={1} onClick={onClick}>
              <UserMenuHeader user={user} />
              <DropdownIcon color='white' />
            </Flex>
          </IconButton>
        )}
      </Flex>
      <ResponsiveMenu
        drawerProps={{
          anchor: 'right',
          ...drawerProps,
        }}
        forceDrawer={drawer}
        keepMounted={true}
        anchorEl={anchor}
        open={!!anchor}
        onClose={onClose}
        classes={{ paper: classes.menuPaper }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Flex flexDirection='column' flexGrow={isMobile ? 1 : 'default'}>
          <Flex flexDirection='column' justifyContent='space-between' flexGrow={isMobile ? 1 : 'default'}>
            {topMenuItems && <List items={topMenuItems} onClose={onClose} />}
            {bottomMenuItems && <List items={bottomMenuItems} onClose={onClose} />}
          </Flex>
        </Flex>
      </ResponsiveMenu>
    </>
  )
}

export { AppBarMenu }
