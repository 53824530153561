import React from 'react'

import { SvgIconProps, SvgIconSmallViewBox } from '../../../SvgIcon'

export type NetworkIconProps = SvgIconProps

const NetworkIcon: React.FC<NetworkIconProps> = (props) => {
  return (
    <SvgIconSmallViewBox {...props}>
      <path
        d='M12.5014 10.2973C12.6394 10.2973 12.7514 10.4092 12.7514 10.5473C12.7514 10.6854 12.6394 10.7973 12.5014 10.7973C12.3633 10.7973 12.2514 10.6854 12.2514 10.5473C12.2514 10.4092 12.3633 10.2973 12.5014 10.2973'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.5013 7.54842C14.1574 7.54842 15.5 8.89101 15.5 10.5472C15.5 11.8273 13.7088 14.2636 12.8951 15.3039C12.8004 15.4251 12.6551 15.496 12.5013 15.496C12.3474 15.496 12.2021 15.4251 12.1074 15.3039C11.2938 14.263 9.50251 11.8273 9.50251 10.5472C9.50251 8.89101 10.8451 7.54842 12.5013 7.54842V7.54842Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.50115 15.3505C5.44617 16.1779 1.48735 13.5644 0.65481 9.51052C-0.17773 5.4566 2.43068 1.49445 6.48354 0.656747C10.5364 -0.180954 14.5019 2.42241 15.3447 6.4742'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.19654 15.2765C5.1823 13.7892 4.50458 11.0863 4.50458 7.9989C4.50458 4.91151 5.1823 2.2093 6.19654 0.721253'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M0.522848 7.4991H8.50287' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M2.00494 3.50076H14.0006' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M1.37118 11.4974H7.5033' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M9.80973 0.721253C10.6731 2.18019 11.1989 3.81394 11.3484 5.5026'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIconSmallViewBox>
  )
}

export { NetworkIcon }
