import React from 'react'

import { useLayoutContext } from '../../../hooks'
import { H2 } from '../../Data Display'
import { PageSection, PageSectionProps } from './PageSection'

type Props = Omit<PageSectionProps, 'TitleComponent' | 'titleMb' | 'spacing'>
type PageSection2Props = Props & { titleMb?: number }

export const PageSection2: React.FC<PageSection2Props> = ({ titleMb, ...props }) => {
  const { isMobile } = useLayoutContext()

  return (
    <PageSection
      TitleComponent={H2}
      hasDivider={false}
      titleMb={titleMb ?? (isMobile ? 4 : 6)}
      spacing={isMobile ? 5 : 10}
      {...props}
    />
  )
}
