import React from 'react'

import { AnyColor, clsx, makeStyles } from '@guiker/components-core'
import { ChevronDownIcon, SvgIconProps } from '@guiker/icons'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      cursor: 'pointer',
      transition: theme.transitions.create(['transform'], {
        duration: theme.transitions.duration.short,
      }),
      transform: 'rotate(0)',
    },
    iconRotated: {
      transform: 'rotate(180deg)',
    },
  }),
  {
    name: 'RotatingIcon',
  },
)

export type RotatingIconProps = {
  Icon?: React.FC<{ className?: string; color?: AnyColor; size?: SvgIconProps['size']; onClick?: () => void }>
  isRotated?: boolean
  onClick?: () => void
  color?: AnyColor
  size?: SvgIconProps['size']
}

export const RotatingIcon: React.FC<RotatingIconProps> = ({
  Icon = ChevronDownIcon,
  isRotated,
  color,
  onClick,
  size = 'small',
}) => {
  const classes = useStyles()

  return (
    <Icon
      className={clsx(classes.root, isRotated && classes.iconRotated)}
      color={color}
      size={size}
      onClick={onClick}
    />
  )
}
