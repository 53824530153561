import React, { useMemo } from 'react'

import { Divider, Flex, Modal, P, Spinner } from '@guiker/components-library'
import { useT } from '@guiker/i18n'
import { CSVDownload, CSVLink } from '@guiker/react-export-csv'
import { useQuery } from '@guiker/react-query'
import { ExtractKeyOf } from '@guiker/ts-utils'

type CSVModalProps<T extends object, Status extends string> = {
  open: boolean
  queryKey: string
  onClose: () => void
  fetcher: (statuses: Status[]) => Promise<T[]>
  statuses: Status[]
}

type CSVData<T extends object> = {
  headers?: ExtractKeyOf<T>[]
  body: T[]
}

const ErrorCSVContent: React.FC = () => {
  const { tBase } = useT()

  return (
    <Flex flexDirection='column'>
      <P>{tBase('csvExport.error')}</P>
    </Flex>
  )
}

const DownloadCSVContent = <T extends {}>({ body, headers }: CSVData<T>) => {
  const { Trans } = useT()

  return (
    <Flex flexDirection='column'>
      <CSVDownload data={body} headers={headers} />
      <Divider color={'white'} />
      <P>
        <Trans i18nKey={'csvExport.download'}>
          <CSVLink data={body} headers={headers} />
        </Trans>
      </P>
    </Flex>
  )
}

const LoadingCSVDataContent: React.FC = () => {
  const { tBase } = useT()

  return (
    <Flex flexDirection='column'>
      <Spinner />
      <Divider color={'white'} />
      <P>{tBase('csvExport.fileGeneration')}</P>
    </Flex>
  )
}

export const ExportCSVModal = <T extends object, Status extends string>({
  open,
  onClose,
  fetcher,
  queryKey,
  statuses,
}: CSVModalProps<T, Status>) => {
  const { data, isLoading } = useQuery([queryKey], () => fetcher(statuses))
  const headers = useMemo(() => {
    return data?.length ? (Object.keys(data[0]) as CSVData<T>['headers']) : []
  }, [data])

  return (
    <Modal open={open} onClose={onClose}>
      <Flex flexDirection='column' alignItems='center'>
        {isLoading ? (
          <LoadingCSVDataContent />
        ) : !!data?.length ? (
          <DownloadCSVContent headers={headers} body={data} />
        ) : (
          <ErrorCSVContent />
        )}
      </Flex>
    </Modal>
  )
}
