import React from 'react'

import { AnyColor, theme } from '@guiker/components-core'

const BikeParkingIcon: React.FC<{ color?: AnyColor }> = ({ color: colorKey }) => {
  const color = theme.palette.getColor(colorKey)
  return (
    <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.5 21.7866C6.57107 21.7866 8.25 20.1077 8.25 18.0366C8.25 15.9656 6.57107 14.2866 4.5 14.2866C2.42893 14.2866 0.75 15.9656 0.75 18.0366C0.75 20.1077 2.42893 21.7866 4.5 21.7866Z'
        stroke={color || 'currentColor'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.5 21.7866C21.5711 21.7866 23.25 20.1077 23.25 18.0366C23.25 15.9656 21.5711 14.2866 19.5 14.2866C17.4289 14.2866 15.75 15.9656 15.75 18.0366C15.75 20.1077 17.4289 21.7866 19.5 21.7866Z'
        stroke={color || 'currentColor'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.5 18.0367C6.073 11.6707 10.924 8.86565 17.85 9.78665'
        stroke={color || 'currentColor'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.25 6.78662H9.75'
        stroke={color || 'currentColor'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.5 6.78662L8.999 11.2836'
        stroke={color || 'currentColor'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.75 2.28662H19.5C20.3284 2.28662 21 2.95819 21 3.78662C21 4.61505 20.3284 5.28662 19.5 5.28662H17.865C17.6397 5.28664 17.4263 5.3878 17.2837 5.5622C17.1411 5.7366 17.0843 5.96581 17.129 6.18662L19.5 18.0366'
        stroke={color || 'currentColor'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export { BikeParkingIcon }
