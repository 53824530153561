import React from 'react'

import { Slider as CLSlider } from '@guiker/components-library'
import { FieldPath } from '@guiker/react-hook-form'

import { useApiFormContext } from '../Form'
import { RHFSlider, SliderProps as RHFSliderProps } from './RHFSlider'

export type SliderProps<T extends object, P extends FieldPath<T>> = RHFSliderProps<T, P>

export const Slider = <T extends object = any, P extends FieldPath<T> = any>(props: SliderProps<T, P>) => {
  const { readOnly: contextReadOnly } = useApiFormContext({ shouldThrowIfUndefined: false })
  if (props.disabled ?? contextReadOnly) {
    return <CLSlider {...props} />
  } else {
    return <RHFSlider {...props} />
  }
}
