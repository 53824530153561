import * as React from 'react'

import { Image, ImageProps } from '../Image'

export type ThumbnailProps = Omit<ImageProps, 'width' | 'height'> & {
  width?: number
  height?: number
}

const Thumbnail: React.FC<ThumbnailProps> = ({ height = 136, width = 202, ...props }) => {
  return <Image height={height} width={width} {...props} />
}

export { Thumbnail }
