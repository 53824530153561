import React from 'react'

import { Collapse, FormSection1, ScrollToTop, Step, StepProps } from '@guiker/components-library'
import { TFunction, useTranslation } from '@guiker/i18n'
import { DefaultValues, yupResolver } from '@guiker/react-hook-form'
import { DefaultValue, GetInputProps, useGetInputProps } from '@guiker/react-utils'
import { yup } from '@guiker/shared-framework'

import { MutationOptions, OnSubmit } from '../../types'
import { ApiForm } from './ApiForm'
import { ApiFormFragmentsContextProvider, useApiFormFragmentsContext } from './ApiFormFragmentsContext'

export type FragmentComponentProps<T> = {
  defaultValue: T
  inputProps: GetInputProps<DefaultValue<T>>
  t: TFunction
}

export type Fragment<T extends object> = {
  fields: (keyof T)[]
  component: React.FC<FragmentComponentProps<T>>
}

type FragmentStepProps = Omit<StepProps, 'onSubmit' | 'defaultValue'>

export type ApiFormFragmentsProps<TResult, T extends object> = FragmentStepProps & {
  schema: yup.Schema<T>
  isFragmented: boolean
  fragments: Fragment<T>[]
  formName?: string
  defaultValue: T
  tNamespaces: string[]
  tPrefix?: string
  readOnly?: boolean
  onSubmit: OnSubmit<TResult, T>
  apiOptions?: MutationOptions<TResult, T> & { onSubmitWithoutChange?: () => void }
  onClickBackFirstFragment?: () => void
}

const FragmentContent = <T extends object>(
  props: FragmentStepProps & {
    index: number
    formName?: string
    fragment: Fragment<T>
    componentProps: Omit<FragmentComponentProps<T>, 'defaultValue'>
  },
) => {
  const { dataStore, formState, currentFragmentIndex, totalFragmentsSize, schema, onNext, onPrev } =
    useApiFormFragmentsContext()
  const { index, formName, fragment, componentProps, ...fragmentStepProps } = props
  const { fields, component: FragmentComponent } = fragment

  const open = currentFragmentIndex === index
  const fragmentSchema = (schema as any).pick(fields)
  const handleFragmentSubmit = (payload: typeof fragmentSchema) => dataStore.set(payload)

  return (
    <Collapse in={open} mountOnEnter>
      {open && <ScrollToTop />}
      <ApiForm
        onSubmit={handleFragmentSubmit}
        formName={formName}
        formOptions={{
          resolver: yupResolver(fragmentSchema),
          defaultValues: dataStore.get(),
        }}
        apiOptions={{
          onSuccess: () => onNext(),
          onSubmitWithoutChange: () => onNext({ withoutChange: true }),
        }}
      >
        {({ isLoading, formState: { errors } }) => (
          <Step
            {...fragmentStepProps}
            isNested
            hasButtonContainer
            isSubmitting={isLoading || formState.isSubmitting}
            errors={errors}
            onClickBack={onPrev}
            progression={{
              format: '#/#',
              current: index + 1,
              total: totalFragmentsSize,
            }}
          >
            <FragmentComponent {...componentProps} defaultValue={dataStore.get() as T} />
          </Step>
        )}
      </ApiForm>
    </Collapse>
  )
}

export const ApiFormFragments = <TResult, T extends Record<string, any>>({
  isFragmented,
  fragments,
  formName,
  defaultValue,
  schema,
  readOnly,
  tPrefix,
  tNamespaces,
  onSubmit,
  onClickBackFirstFragment,
  apiOptions,
  ...fragmentStepProps
}: ApiFormFragmentsProps<TResult, T>) => {
  const { t } = useTranslation(tNamespaces)
  const inputProps = useGetInputProps({ defaultValue, readOnly, tPrefix, schema: schema as yup.ObjectSchema<T> })

  if (!isFragmented) {
    return (
      <ApiForm
        onSubmit={onSubmit}
        formName={formName}
        formOptions={{
          resolver: yupResolver(schema),
          defaultValues: defaultValue as DefaultValues<T>,
        }}
        apiOptions={apiOptions}
      >
        {({ isLoading, formState }) => (
          <Step
            {...fragmentStepProps}
            isNested
            hasButtonContainer
            onClickBack={() => onClickBackFirstFragment()}
            isSubmitting={isLoading || formState.isSubmitting}
            errors={formState.errors}
          >
            <FormSection1 hasDivider={false}>
              {fragments.map((fragment, index) => {
                const { component: FragmentComponent } = fragment
                const componentProps = { defaultValue, inputProps, t }
                return <FragmentComponent key={`fragment-${index + 1}`} {...componentProps} />
              })}
            </FormSection1>
          </Step>
        )}
      </ApiForm>
    )
  }

  return (
    <ApiFormFragmentsContextProvider
      defaultValue={defaultValue}
      size={fragments.length}
      schema={schema}
      onSubmit={onSubmit}
      onClickBackFirstFragment={onClickBackFirstFragment}
      apiOptions={apiOptions}
    >
      {fragments.map((fragment, index) => (
        <FragmentContent<T>
          {...fragmentStepProps}
          key={`fragment-${index + 1}`}
          index={index}
          fragment={fragment}
          formName={formName ? `${formName}-fragment${index + 1}` : undefined}
          componentProps={{ t, inputProps }}
        />
      ))}
    </ApiFormFragmentsContextProvider>
  )
}
