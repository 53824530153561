import React from 'react'

import { SvgIconMediumViewBox, SvgIconProps } from '../../../SvgIcon'

type WeChatIconProps = SvgIconProps

const WeChatIcon: React.FC<WeChatIconProps> = (props) => {
  return (
    <SvgIconMediumViewBox {...props}>
      <path
        d='M9 0.268311C4.029 0.268311 0 3.62681 0 7.76831C0 10.1563 1.347 12.2743 3.4365 13.6453L2.25 16.0183L5.4825 14.6338C6.1755 14.8798 6.8955 15.0823 7.665 15.1768C7.56 14.7193 7.5 14.2498 7.5 13.7683C7.5 9.63281 11.5365 6.26831 16.5 6.26831C16.9545 6.26831 17.3985 6.30581 17.8365 6.35981C17.0445 2.89181 13.392 0.268311 9 0.268311ZM6 5.89331C5.379 5.89331 4.875 5.38931 4.875 4.76831C4.875 4.14731 5.379 3.64331 6 3.64331C6.621 3.64331 7.125 4.14731 7.125 4.76831C7.125 5.38931 6.621 5.89331 6 5.89331ZM12 5.89331C11.379 5.89331 10.875 5.38931 10.875 4.76831C10.875 4.14731 11.379 3.64331 12 3.64331C12.621 3.64331 13.125 4.14731 13.125 4.76831C13.125 5.38931 12.621 5.89331 12 5.89331Z'
        fill='currentColor'
      />
      <path
        d='M24 13.7683C24 10.4548 20.6415 7.76831 16.5 7.76831C12.3585 7.76831 9 10.4548 9 13.7683C9 17.0818 12.3585 19.7683 16.5 19.7683C17.181 19.7683 17.829 19.6723 18.4575 19.5358L22.5 21.2683L21.102 18.4738C22.854 17.3758 24 15.6868 24 13.7683ZM14.25 13.3933C13.629 13.3933 13.125 12.8893 13.125 12.2683C13.125 11.6473 13.629 11.1433 14.25 11.1433C14.871 11.1433 15.375 11.6473 15.375 12.2683C15.375 12.8893 14.871 13.3933 14.25 13.3933ZM18.75 13.3933C18.129 13.3933 17.625 12.8893 17.625 12.2683C17.625 11.6473 18.129 11.1433 18.75 11.1433C19.371 11.1433 19.875 11.6473 19.875 12.2683C19.875 12.8893 19.371 13.3933 18.75 13.3933Z'
        fill='currentColor'
      />
    </SvgIconMediumViewBox>
  )
}

export { WeChatIcon }
