import React from 'react'

import { GoogleReCaptchaProvider as BaseGoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

import { useConfig } from '@guiker/config-context'

const GoogleReCaptchaProvider = BaseGoogleReCaptchaProvider as React.FC<
  React.PropsWithChildren & Parameters<typeof BaseGoogleReCaptchaProvider>[0]
>

const RecaptchaProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { recaptchaSiteKey } = useConfig()

  return <GoogleReCaptchaProvider reCaptchaKey={recaptchaSiteKey}>{children}</GoogleReCaptchaProvider>
}

export { RecaptchaProvider }
