import React from 'react'

import { AnyColor, theme } from '@guiker/components-core'

const PetIcon: React.FC<{ color?: AnyColor }> = ({ color: colorKey }) => {
  const color = theme.palette.getColor(colorKey)
  return (
    <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3 15.7866C4.24264 15.7866 5.25 14.1077 5.25 12.0366C5.25 9.96555 4.24264 8.28662 3 8.28662C1.75736 8.28662 0.75 9.96555 0.75 12.0366C0.75 14.1077 1.75736 15.7866 3 15.7866Z'
        stroke={color || 'currentColor'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.25 8.28662C9.49264 8.28662 10.5 6.60769 10.5 4.53662C10.5 2.46555 9.49264 0.786621 8.25 0.786621C7.00736 0.786621 6 2.46555 6 4.53662C6 6.60769 7.00736 8.28662 8.25 8.28662Z'
        stroke={color || 'currentColor'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M21 15.0366C22.2426 15.0366 23.25 13.3577 23.25 11.2866C23.25 9.21555 22.2426 7.53662 21 7.53662C19.7574 7.53662 18.75 9.21555 18.75 11.2866C18.75 13.3577 19.7574 15.0366 21 15.0366Z'
        stroke={color || 'currentColor'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.75 8.28662C16.9926 8.28662 18 6.60769 18 4.53662C18 2.46555 16.9926 0.786621 15.75 0.786621C14.5074 0.786621 13.5 2.46555 13.5 4.53662C13.5 6.60769 14.5074 8.28662 15.75 8.28662Z'
        stroke={color || 'currentColor'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.324 15.6896C15.9389 15.3892 15.7234 14.9206 15.746 14.4326C15.746 14.3846 15.746 14.3326 15.746 14.2866C15.746 12.2156 14.0671 10.5366 11.996 10.5366C9.92493 10.5366 8.246 12.2156 8.246 14.2866C8.246 14.3356 8.246 14.3866 8.246 14.4326C8.26961 14.9208 8.05393 15.3898 7.668 15.6896C6.6592 16.4051 6.04228 17.5506 6 18.7866C6 21.2716 8.686 23.2866 12 23.2866C15.314 23.2866 18 21.2716 18 18.7866C17.9559 17.5491 17.3358 16.4034 16.324 15.6896Z'
        stroke={color || 'currentColor'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export { PetIcon }
