import React, { useState } from 'react'

import { Checkbox } from '../../Inputs'
import { Flex } from '../../Layout'
import { Filter } from './Filter'

type Options<OptionValues> =
  | { value: OptionValues; label: string }[]
  | ReadonlyArray<{ value: OptionValues; label: string }>

export type FilterMultiSelectProps<OptionValues extends string = string> = {
  inputLabel?: string
  label: string
  defaultValue?: OptionValues[]
  onChange?: (value: OptionValues[]) => unknown
  options: Options<OptionValues>
}

type FilterMultiSelectInputProps<OptionValues extends string> = {
  onChange?: (value: string[]) => unknown
  defaultValue?: string[]
  options: Options<OptionValues>
}

const FilterMultiSelectInput = <OptionValues extends string>({
  options,
  defaultValue,
  onChange,
}: FilterMultiSelectInputProps<OptionValues>) => {
  const [value, setValue] = useState(defaultValue || [])

  const changeValues = (newValue: string[]) => {
    if (newValue !== value) {
      setValue(newValue)
      onChange(newValue)
    }
  }

  const select = (selectedValue: string) => {
    changeValues([...value, selectedValue])
  }

  const unselect = (selectedValue: string) => {
    changeValues(value.filter((v) => v !== selectedValue))
  }

  return (
    <Flex flexDirection='column' gap={1}>
      {options.map((option) => {
        const checked = value?.includes(option.value)

        return (
          <Checkbox
            checked={checked}
            label={option.label}
            condensed={true}
            onClick={() => {
              checked ? unselect(option.value) : select(option.value)
            }}
          />
        )
      })}
    </Flex>
  )
}

export const FilterMultiSelect: React.FC<FilterMultiSelectProps> = ({
  defaultValue,
  inputLabel,
  label,
  onChange,
  options,
}) => {
  const formatter = (value: string[]) => {
    const main = value
      .slice(0, 2)
      .map((v) => options.find((o) => o.value === v)?.label)
      .join(', ')

    if (value.length <= 2) {
      return main
    }

    return `${main} (+${value.length - 2})`
  }

  return (
    <Filter
      label={label}
      inputLabel={inputLabel}
      defaultValue={defaultValue}
      valueFormatter={formatter}
      onChange={onChange}
      InputComponent={({ onChange }) => (
        <FilterMultiSelectInput options={options} defaultValue={defaultValue} onChange={onChange} />
      )}
    />
  )
}
