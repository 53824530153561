import React from 'react'

import { SvgIconMediumViewBox, SvgIconProps } from '../../SvgIcon'

export const TaskIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIconMediumViewBox {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.5 1.49902C8.5 1.08481 8.83579 0.749023 9.25 0.749023H15.25C15.6642 0.749023 16 1.08481 16 1.49902V2.99902C16 3.41324 15.6642 3.74902 15.25 3.74902H9.25C8.83579 3.74902 8.5 3.41324 8.5 2.99902V1.49902Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16 2.24902H19C19.8284 2.24902 20.5 2.9206 20.5 3.74902'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20.5 3.74902V21.749C20.5 22.5775 19.8284 23.249 19 23.249H5.5C4.67157 23.249 4 22.5775 4 21.749V3.74902C4 2.9206 4.67157 2.24902 5.5 2.24902H8.5'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M11.75 9H17.75' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M11.75 13.5H17.75' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M11.75 18.5H17.75' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.75 17.5C6.75 17.2239 6.97386 17 7.25 17H8.75C9.02614 17 9.25 17.2239 9.25 17.5V19C9.25 19.2761 9.02614 19.5 8.75 19.5H7.25C6.97386 19.5 6.75 19.2761 6.75 19V17.5Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M9 12.499L7.8 14.099L7 13.299' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M9 8L7.8 9.6L7 8.8' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </SvgIconMediumViewBox>
  )
}
