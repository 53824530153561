import React, { useEffect, useRef, useState } from 'react'

import { clsx } from '@guiker/clsx'
import { MaxHeight } from '@guiker/components-core'
import { useT } from '@guiker/i18n'
import { isNumber } from '@guiker/lodash'

import { makeStyles, useIsOverflowing } from '../../../hooks'
import { PSmall } from '../..'
import { TextButton } from '../../Inputs'
import { Flex } from '../../Layout'

const useStyles = makeStyles(
  (theme) => ({
    container: ({ maxHeight }: { maxHeight: MaxHeight }) => ({
      transition: theme.transitions.create(['max-height'], {
        duration: theme.transitions.duration.standard,
        easing: theme.transitions.easing.easeInOut,
      }),
      maxHeight: isNumber(maxHeight) ? maxHeight + theme.spacing(9) : `calc(${maxHeight} + ${theme.spacing(9)}px)`,
      overflow: 'hidden',
      paddingBottom: theme.spacing(6),
    }),
    seeAll: () => ({
      maxHeight: '100vh',
    }),
    buttonContainer: {
      height: theme.spacing(9),
      marginTop: -theme.spacing(9),
      position: 'relative',
      paddingBottom: theme.spacing(2),
      background: 'linear-gradient(#ffffff00, #ffffffaa 2%, #ffffffff 20%)',
    },
  }),
  { name: 'ExpandableFadingBlock' },
)

type ExpandableFadingBlockProps = React.PropsWithChildren & {
  maxHeight: MaxHeight
}

export const ExpandableFadingBlock: React.FC<ExpandableFadingBlockProps> = ({ children, maxHeight }) => {
  const [seeAll, setSeeAll] = useState<boolean>(undefined)
  const classes = useStyles({ maxHeight })
  const ref = useRef()
  const isOverflowing = useIsOverflowing(ref)
  const { tBase } = useT({})

  useEffect(() => {
    if (isOverflowing && seeAll === undefined) {
      setSeeAll(false)
    }
  }, [isOverflowing])

  return (
    <Flex flexDirection='column' gap={2}>
      <Flex ref={ref} gap={3} flexDirection='column' className={clsx(classes.container, seeAll && classes.seeAll)}>
        {children}
      </Flex>
      {seeAll !== undefined && (
        <Flex justifyContent='center' alignItems='flex-end' className={classes.buttonContainer}>
          {seeAll ? (
            <TextButton onClick={() => setSeeAll(false)}>
              <PSmall mb={0}>{tBase('actions.seeLess')}</PSmall>
            </TextButton>
          ) : (
            <TextButton onClick={() => setSeeAll(true)}>
              <PSmall mb={0}>{tBase('actions.seeMore')}</PSmall>
            </TextButton>
          )}
        </Flex>
      )}
    </Flex>
  )
}
