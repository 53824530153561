import React from 'react'

import { SvgIconMediumViewBox, SvgIconProps } from '../../../SvgIcon'

type WifiIconProps = SvgIconProps

const WifiIcon: React.FC<WifiIconProps> = (props) => {
  return (
    <SvgIconMediumViewBox {...props}>
      <path
        d='M0.75 17.2501C0.75076 16.1342 1.02804 15.0359 1.55706 14.0533C2.08607 13.0708 2.85033 12.2347 3.78149 11.6197C4.71265 11.0047 5.7817 10.6301 6.89305 10.5293C8.00439 10.4285 9.12339 10.6047 10.15 11.0421'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.37658 4.98608V5.98608C3.41506 7.13714 3.1165 8.27437 2.51758 9.25808'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.6225 4.98608V5.98608C11.5836 7.13732 11.8826 8.27475 12.4825 9.25808'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.38672 5.19993C4.16768 5.19509 4.9386 5.02352 5.64792 4.69672C6.35723 4.36991 6.98857 3.8954 7.49972 3.30493C8.01012 3.89639 8.64107 4.3719 9.35026 4.69959C10.0594 5.02728 10.8305 5.19958 11.6117 5.20493'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.375 4.875C3.375 5.96902 3.8096 7.01823 4.58318 7.79182C5.35677 8.5654 6.40598 9 7.5 9C8.59402 9 9.64323 8.5654 10.4168 7.79182C11.1904 7.01823 11.625 5.96902 11.625 4.875C11.625 3.78098 11.1904 2.73177 10.4168 1.95818C9.64323 1.1846 8.59402 0.75 7.5 0.75C6.40598 0.75 5.35677 1.1846 4.58318 1.95818C3.8096 2.73177 3.375 3.78098 3.375 4.875V4.875Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.25 22.4241C17.3242 22.4241 17.3967 22.4461 17.4583 22.4873C17.52 22.5285 17.5681 22.587 17.5965 22.6556C17.6248 22.7241 17.6323 22.7995 17.6178 22.8722C17.6033 22.945 17.5676 23.0118 17.5152 23.0642C17.4627 23.1167 17.3959 23.1524 17.3232 23.1669C17.2504 23.1813 17.175 23.1739 17.1065 23.1455C17.038 23.1171 16.9794 23.0691 16.9382 23.0074C16.897 22.9457 16.875 22.8732 16.875 22.7991C16.875 22.6996 16.9145 22.6042 16.9848 22.5339C17.0552 22.4636 17.1505 22.4241 17.25 22.4241Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M23.25 15.622C21.5518 14.2487 19.434 13.4995 17.25 13.4995C15.066 13.4995 12.9482 14.2487 11.25 15.622'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.748 19.2381C14.7387 18.4372 15.9741 18.0002 17.248 18.0002C18.522 18.0002 19.7574 18.4372 20.748 19.2381'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIconMediumViewBox>
  )
}

export { WifiIcon }
