import { createContext } from 'react'

import { RouteConfig } from './route-config'

export type RouterContextProps = {
  routes: { [key: string]: RouteConfig }
  unauthenticatedRedirectPath: string
  accountActivationRedirectPath?: string
  prefixWithLocale: boolean
}

export const RouterContext = createContext<RouterContextProps>(null)
