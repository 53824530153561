import React from 'react'

import { SvgIconMediumViewBox, SvgIconProps } from '../../../SvgIcon'

type PdfFileIconProps = SvgIconProps

const PdfFileIcon: React.FC<PdfFileIconProps> = (props) => {
  return (
    <SvgIconMediumViewBox {...props}>
      <path d='M4.49899 20.498V12.998' stroke='black' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M4.5 13H5.25C6.49264 13 7.5 14.0074 7.5 15.25C7.5 16.4926 6.49264 17.5 5.25 17.5H4.5'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.5 20.5C12.1569 20.5 13.5 19.1569 13.5 17.5V16C13.5 14.3431 12.1569 13 10.5 13V20.5Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.5 20.5V14.5C16.5 13.6716 17.1716 13 18 13H19.5'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.499 17.498H18.749'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M22.809 6.55905C23.0904 6.84008 23.2486 7.22136 23.249 7.61905V22C23.2479 23.2415 22.2415 24.2475 21 24.248H2.99999C1.75774 24.2486 0.750098 23.2423 0.748994 22V4.00005C0.748463 3.40279 0.985419 2.82983 1.40765 2.40741C1.82988 1.98499 2.40274 1.74778 2.99999 1.74805H17.377C17.7749 1.74787 18.1566 1.90578 18.438 2.18705L22.809 6.55905Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.249 1.74805V6.24805C17.249 7.07647 17.9206 7.74805 18.749 7.74805H23.249'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIconMediumViewBox>
  )
}

export { PdfFileIcon }
