import React from 'react'

import { AnyColor, theme } from '@guiker/components-core'

const FurnishedIcon: React.FC<{ color?: AnyColor }> = ({ color: colorKey }) => {
  const color = theme.palette.getColor(colorKey)
  return (
    <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0)'>
        <path
          d='M20.25 14.3596H3.75L1.5 10.6096H22.5L20.25 14.3596Z'
          stroke={color || 'currentColor'}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M0.75 10.6096H23.25'
          stroke={color || 'currentColor'}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M3.75002 14.3596L1.88402 22.2896C1.84254 22.4645 1.86523 22.6484 1.94795 22.8079C2.03066 22.9675 2.16791 23.092 2.3347 23.1589C2.5015 23.2257 2.68676 23.2305 2.85677 23.1723C3.02678 23.114 3.17023 22.9967 3.26102 22.8416L8.25002 14.3596'
          stroke={color || 'currentColor'}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M20.25 14.3596L22.116 22.2896C22.1575 22.4645 22.1348 22.6484 22.0521 22.8079C21.9694 22.9675 21.8321 23.092 21.6653 23.1589C21.4985 23.2257 21.3133 23.2305 21.1432 23.1723C20.9732 23.114 20.8298 22.9967 20.739 22.8416L15.75 14.3596'
          stroke={color || 'currentColor'}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M17.25 4.60962V10.6096'
          stroke={color || 'currentColor'}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M20.25 4.60962C20.25 5.40527 19.9339 6.16833 19.3713 6.73094C18.8087 7.29355 18.0456 7.60962 17.25 7.60962C16.4544 7.60962 15.6913 7.29355 15.1287 6.73094C14.5661 6.16833 14.25 5.40527 14.25 4.60962'
          stroke={color || 'currentColor'}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M14.25 0.859619V2.35962'
          stroke={color || 'currentColor'}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M17.25 0.859619V2.35962'
          stroke={color || 'currentColor'}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M20.25 0.859619V2.35962'
          stroke={color || 'currentColor'}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M4.5 10.6096L2.25 7.60962H12L9.75 10.6096H4.5Z'
          stroke={color || 'currentColor'}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0'>
          <rect width='24' height='24' fill='white' transform='translate(0 0.0366211)' />
        </clipPath>
      </defs>
    </svg>
  )
}

export { FurnishedIcon }
